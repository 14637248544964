import SocialTokenQuery from '@scandiweb/mageplaza-social-login/src/query/SocialToken.query';
import { executeLoginFlow } from '@scandiweb/mageplaza-social-login/src/util/login';
import { connect } from 'react-redux';

import { BILLING_URL, SHIPPING_URL } from 'Route/Checkout/Checkout.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer
} from 'StendersComponent/MyAccountSignIn/MyAccountSignIn.container';
import { updateIsLoading } from 'Store/MyAccount/MyAccount.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import transformToNameValuePair from 'Util/Form/Transform';
import history from 'Util/History';
import { fetchMutation, getErrorMessage } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

export {
    mapStateToProps
};

/** @namespace Scandipwa/Component/MyAccountSignIn/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    setMyAccountLoading: (isLoading) => dispatch(updateIsLoading(isLoading))
});

export * from 'StendersComponent/MyAccountSignIn/MyAccountSignIn.container';

/** @namespace Scandipwa/Component/MyAccountSignIn/Container */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    state = {
        ...this.state,
        usedSocialType: '',
        customerExists: false
    };

    containerFunctions = {
        ...this.containerFunctions,
        setUsedSocialType: this.setUsedSocialType.bind(this),
        setCustomerExists: this.setCustomerExists.bind(this),
        sendSocialTokenQuery: this.sendSocialTokenQuery.bind(this)
    };

    setUsedSocialType(type) {
        const { setUsedSocialTypeForOverlay } = this.props;

        if (setUsedSocialTypeForOverlay) {
            setUsedSocialTypeForOverlay(type);
        }

        this.setState(() => ({
            usedSocialType: type
        }));
    }

    setCustomerExists(exists) {
        const { setCustomerExistsForOverlay } = this.props;

        if (setCustomerExistsForOverlay) {
            setCustomerExistsForOverlay(exists);
        }

        this.setState(() => ({
            customerExists: exists
        }));
    }

    async sendSocialTokenQuery() {
        const {
            usedSocialType,
            customerExists
        } = this.state;

        const {
            onSignIn,
            setMyAccountLoading
        } = this.props;

        const { token: guestQuoteId } = BrowserDatabase.getItem('guest_quote_id');

        const { socialToken: { token } } = await fetchMutation([
            SocialTokenQuery.getQuery({ guestQuoteId, customerExists, usedSocialType })
        ]);

        await executeLoginFlow(token);
        onSignIn();
        setMyAccountLoading(false);
    }

    containerProps() {
        const {
            usedSocialType,
            customerExists
        } = this.state;
        const {
            renderNewsletterCheckbox
        } = this.props;

        return {
            ...super.containerProps(),
            usedSocialType,
            customerExists,
            renderNewsletterCheckbox
        };
    }

    async onSignInSuccess(form, fields) {
        const {
            signIn,
            showNotification,
            onSignIn,
            setLoadingState,
            totals: { is_virtual },
            isCheckout
        } = this.props;

        const {
            isSignIn
        } = this.state;

        setLoadingState(true);
        const fieldPairs = transformToNameValuePair(fields);

        if (!isSignIn) {
            this.setState({ isSignIn: true });

            try {
                await signIn(fieldPairs);
                onSignIn();
            } catch (error) {
                showNotification('error', __(getErrorMessage(error)));
                this.setState({ isSignIn: false });
            } finally {
                setLoadingState(false);
            }
        }

        setLoadingState(false);

        if (is_virtual && isCheckout) {
            history.push({ pathname: appendWithStoreCode(BILLING_URL) });
        } else if (!is_virtual && isCheckout) {
            history.push({ pathname: appendWithStoreCode(SHIPPING_URL) });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
