/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

import {
    ESTONIA_LABEL,
    FINLAND_LABEL,
    LATVIA_LABEL,
    LITHUANIA_LABEL
} from '../config/MakeCommerce.config';

export const MakeCommercePaymentType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
    methodType: PropTypes.string.isRequired,
    country: PropTypes.string,
    displayName: PropTypes.string,
    logoUrl: PropTypes.string,
    imagePath: PropTypes.string,
    minAmount: PropTypes.number,
    maxAmount: PropTypes.number,
    countries: PropTypes.arrayOf(PropTypes.string)
});

export const MakeCommercePaymentArrayType = PropTypes.arrayOf(MakeCommercePaymentType);

export const MakeCommercePaymentsType = PropTypes.shape({
    other: MakeCommercePaymentArrayType,
    payLater: MakeCommercePaymentArrayType,
    cards: MakeCommercePaymentArrayType,
    banklinks: MakeCommercePaymentArrayType
});

export const MakeCommercePaymentCountryGroupsType = PropTypes.shape({
    [LATVIA_LABEL]: PropTypes.arrayOf(MakeCommercePaymentType),
    [ESTONIA_LABEL]: PropTypes.arrayOf(MakeCommercePaymentType),
    [FINLAND_LABEL]: PropTypes.arrayOf(MakeCommercePaymentType),
    [LITHUANIA_LABEL]: PropTypes.arrayOf(MakeCommercePaymentType)
});

export const MakeCommercePaymentUiType = PropTypes.shape({
    mode: PropTypes.string,
    inlineUselogo: PropTypes.string,
    chorder: PropTypes.string,
    widgetTitle: PropTypes.string,
    groupBanklinks: PropTypes.bool,
    widgetCountryselector: PropTypes.string,
    widgetGroupcc: PropTypes.bool,
    widgetGroupTitle: PropTypes.string,
    widgetGroupCcInline: PropTypes.bool,
    countrySortOrder: PropTypes.string,
    logoSize: PropTypes.string,
    openDefault: PropTypes.bool,
    cardShopName: PropTypes.string,
    referenceText: PropTypes.string,
    cardPrefill: PropTypes.bool
});

export const MakeCommercePaymentUiTypeDefaultValues = {
    mode: 'inline',
    inlineUseLogo: 'text',
    chorder: '',
    widgetTitle: __('Make Commerce'),
    groupBanklinks: false,
    widgetCountryselector: 'flag',
    widgetGroupcc: false,
    widgetGroupCcInline: false,
    countrySortOrder: '',
    logoSize: 'm',
    openDefault: false,
    cardShopName: '',
    referenceText: '',
    cardPrefill: ''
};

export const MakeCommerceShippingLocationItem = PropTypes.shape({
    location_id: PropTypes.string,
    name: PropTypes.string
});

export const MakeCommerceShippingLocations = PropTypes.shape({
    groupName: PropTypes.string,
    groupCountry: PropTypes.string,
    locations: PropTypes.arrayOf(MakeCommerceShippingLocationItem)
});

export const MakeCommerceShippingProviderType = PropTypes.shape({
    locations: PropTypes.arrayOf(MakeCommerceShippingLocations)
});

export const MakeCommerceShippingType = PropTypes.shape({
    smartpostmc_parcel: MakeCommerceShippingProviderType,
    omnivamc_parcel: MakeCommerceShippingProviderType,
    dpdmc_parcel: MakeCommerceShippingProviderType
});

export const MakeCommerceSelectedPaymentMethodType = PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    country: PropTypes.string,
    countryLabel: PropTypes.string
});

export const MakeCommerceSelectedPaymentMethodTypeDefaultValues = {
    name: '',
    url: '',
    country: '',
    countryLabel: ''
};
