import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Barcode from 'react-barcode';

import CmsBlock from 'Component/CmsBlock';

import './MyAccountLoyalty.style';

/** @namespace Stenders/Loyalty/Component/MyAccountLoyalty/Component */
export class MyAccountLoyaltyComponent extends PureComponent {
    static propTypes = {
        loyaltyNumber: PropTypes.string.isRequired
    };

    renderLoyaltyDescription() {
        return (
            <div block="MyAccountLoyalty" elem="Description">
                <CmsBlock identifier="my-account-loyalty" />
            </div>
        );
    }

    render() {
        const { loyaltyNumber } = this.props;
        return (
            <div block="MyAccountLoyalty">
                <h3 block="MyAccountLoyalty" elem="Title">{ __('Your Loyal Customer Number') }</h3>
                <Barcode value={ loyaltyNumber } format="CODE128" width="2" height="90" />
                { this.renderLoyaltyDescription() }
            </div>
        );
    }
}

export default MyAccountLoyaltyComponent;
