import { toggleScroll } from '@scandipwa/scandipwa/src/util/Browser';

import Overlay from 'StendersComponent/Overlay/Overlay.component';

/** @namespace Scandipwa/Component/Overlay/Component */
export class OverlayComponent extends Overlay {
    // eslint-disable-next-line
    freezeScroll() {
        this.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
        toggleScroll(false);
        document.body.style.marginTop = `${-this.YoffsetWhenScrollDisabled}px`;
        // scrolling back to the top
        window.scrollTo(0, 0);
    }
}

export default OverlayComponent;
