/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { updateConfig } from 'Store/Config/Config.action';
import { fetchQuery } from 'Util/Request';
import getStore from 'Util/Store';

import MageplazaGiftWrapQuery from '../query/Config.query';

/** @namespace Scandiweb/MageplazaGiftWrap/Plugin/GiftWrapCurrencySwitcher/Plugin/configUpdate */
export const configUpdate = async () => {
    const { dispatch } = getStore();

    const {
        mpGiftWrap
    } = await fetchQuery(MageplazaGiftWrapQuery.getGiftWrapField());

    dispatch(updateConfig({ mpGiftWrap }));
};

/** @namespace Scandiweb/MageplazaGiftWrap/Plugin/GiftWrapCurrencySwitcher/Plugin/addActionToTheCurrencySelect */
export const addActionToTheCurrencySelect = (args, callback, instance) => {
    const result = callback(...args);

    configUpdate(instance);

    return result;
};

export default {
    'Component/CurrencySwitcher/Container': {
        'member-function': {
            _handleCurrencySelect: addActionToTheCurrencySelect
        }
    }
};
