export * from 'StendersRoute/Checkout/Checkout.config';

export const OMNIVA_CARRIER_CODE = 'omnivamc';
export const OMNIVA_COURIER_METHOD_CODE = 'courier';
export const PARCEL_METHOD_CODE = 'parcel';
export const FLATRATE_METHOD_CODE = 'flatrate';
export const TABEL_RATE_CARRIER_CODE = 'tablerate';
export const TABEL_RATE_METHOD_CODE = 'bestway';
export const MATRIX_RATE_CARRIER_CODE = 'matrixrate';
export const CLOSE_CHECKOUT_SUMMARY_THRES = 250;
export const NEW_MOBILE_WIDTH = 810;
export const CHECKOUT_BACK_PATH = 'CHECKOUT_BACK_PATH';
export const FREE_PAYMENT_METHOD_CODE = 'free';

// return and refund page links
export const RETURNS_AND_REFUNDS_PATH = {
    lv: '/atgriesana',
    ru: '/atgriesana',
    default: '/refunds-and-returns'
};
