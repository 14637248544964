/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const SET_IS_MC_LOADING = 'SET_IS_MC_LOADING';
export const SET_MC_PAYMENT_CONFIG = 'SET_MC_PAYMENT_CONFIG';
export const SET_SELECTED_MC_PAYMENT_METHOD = 'SET_SELECTED_MC_PAYMENT_TYPE';
export const SET_MC_SHIPPING_LOCATIONS = 'SET_MC_SHIPPING_LOCATIONS';
export const SET_SELECTED_MC_SHIPPING_LOCATION = 'SET_SELECTED_MC_SHIPPING_LOCATION';

/** @namespace Scandiweb/MakeCommerce/Store/MakeCommerce/Action/setMcPaymentConfig */
export const setMcPaymentConfig = (value) => ({
    type: SET_MC_PAYMENT_CONFIG,
    value
});

/** @namespace Scandiweb/MakeCommerce/Store/MakeCommerce/Action/setMcIsLoading */
export const setMcIsLoading = (value) => ({
    type: SET_IS_MC_LOADING,
    value
});

/** @namespace Scandiweb/MakeCommerce/Store/MakeCommerce/Action/setSelectedMcPaymentMethod */
export const setSelectedMcPaymentMethod = (value) => ({
    type: SET_SELECTED_MC_PAYMENT_METHOD,
    value
});

/** @namespace Scandiweb/MakeCommerce/Store/MakeCommerce/Action/setMcShippingLocations */
export const setMcShippingLocations = (value) => ({
    type: SET_MC_SHIPPING_LOCATIONS,
    value
});

/** @namespace Scandiweb/MakeCommerce/Store/MakeCommerce/Action/setSelectedMcShippingLocation */
export const setSelectedMcShippingLocation = (value) => ({
    type: SET_SELECTED_MC_SHIPPING_LOCATION,
    value
});
