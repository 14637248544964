/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { CheckboxGroupDataType } from '../../type/gdpr.type';
import Checkbox from '../Checkbox';

import './Checkboxes.style';

/** @namespace Scandiweb/AmastyGdpr/Component/Checkboxes/Component */
export class CheckboxesComponent extends PureComponent {
    /**
     * defines the props that could be passed for this component.
     */
    static propTypes = {
        scope: PropTypes.string.isRequired,
        CheckboxGroupData: CheckboxGroupDataType,
        onCheckboxStateChanged: PropTypes.func.isRequired
    };

    static defaultProps = {
        CheckboxGroupData: {}
    };

    render() {
        const {
            scope,
            CheckboxGroupData,
            onCheckboxStateChanged
        } = this.props;

        if (!CheckboxGroupData) {
            return null;
        }

        return (
            <>
                { CheckboxGroupData.map(
                    (data) => (
                        <Checkbox
                          key={ `${scope}-${data.link}` }
                          data={ data }
                          scope={ scope }
                          onStateChanged={ onCheckboxStateChanged }
                        />
                    )
                ) }
            </>
        );
    }
}

export default CheckboxesComponent;
