import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/PromotionWidget/Query */
export class PromotionWidgetQuery {
    getContent(categoryId) {
        return new Field('promoWidget')
            .addArgument('categoryId', 'Int', categoryId);
    }
}

export default new PromotionWidgetQuery();
