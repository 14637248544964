/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageplazaGiftWrap/Query/Mutation/GiftWrapUpdateData/Query */
export class GiftWrapUpdateDataQuery {
    updateData(
        cartId,
        itemId,
        wrap,
        postcard
    ) {
        return new Field('mpUpdateGiftWrap')
            .addArgument('cart_id', 'String!', cartId)
            .addArgument('item_id', 'Int!', itemId)
            .addArgument('wrap', 'GiftWrapFieldsInput', wrap)
            .addArgument('postcard', 'GiftWrapFieldsInput', postcard);
    }
}

export default new GiftWrapUpdateDataQuery();
