/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable max-lines */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Popup from 'Component/Popup';
import StyledButton from 'Component/StyledButton/StyledButton.container';

import {
    GiftWrapOptionsType,
    MPGiftWrapConfigType,
    MpGiftWrapWrappersType
} from '../../type/GiftWrap';
import GiftWrapCard from '../GiftWrapCard';
import { GIFTWRAP_TAB_ID, POSTCARD_TAB_ID } from './GiftWrapPopup.config';

import './GiftWrapPopup.style';

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapPopup/Component */
export class GiftWrapPopupComponent extends PureComponent {
    static propTypes = {
        onTabChange: PropTypes.func.isRequired,
        mpGiftWrap: MPGiftWrapConfigType.isRequired,
        onSelectCategory: PropTypes.func.isRequired,
        categoryRef: PropTypes.node.isRequired,
        giftWrappers: MpGiftWrapWrappersType.isRequired,
        onSelectWrap: PropTypes.func.isRequired,
        onRemoveAll: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        selectedOptions: GiftWrapOptionsType.isRequired,
        giftWrapOptions: GiftWrapOptionsType.isRequired,
        getCategoryOptions: PropTypes.func.isRequired,
        getFilteredAndSortedWrappers: PropTypes.func.isRequired,
        popupId: PropTypes.string.isRequired,
        postCardText: PropTypes.string.isRequired,
        giftNoteText: PropTypes.string.isRequired,
        onUpdate: PropTypes.func.isRequired,
        onGiftNoteChange: PropTypes.func.isRequired,
        onPostCardChange: PropTypes.func.isRequired,
        isCartItem: PropTypes.bool.isRequired,
        onSaveAll: PropTypes.func.isRequired,
        isAllCart: PropTypes.bool.isRequired,
        activeTab: PropTypes.string.isRequired
    };

    renderCategories() {
        const {
            onSelectCategory,
            categoryRef,
            getCategoryOptions
        } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.select }
              label={ __('Category') }
              options={ getCategoryOptions() }
              events={ { onChange: onSelectCategory } }
              elemRef={ categoryRef }
            />
        );
    }

    renderGiftWrapCards(wraps = []) {
        const {
            onSelectWrap,
            selectedOptions,
            mpGiftWrap: {
                customerNotice
            },
            giftWrapOptions
        } = this.props;
        const {
            wrap: {
                wrap_id
            } = {},
            postcard: {
                wrap_id: postcard_id
            } = {}
        } = giftWrapOptions || selectedOptions || {};

        return (
            wraps.map((el, i) => (
                <GiftWrapCard
                  wrap={ el }
                  key={ el.wrap_id }
                  radioName={ el.wrap_type }
                  onSelectWrap={ onSelectWrap }
                  isSelected={ wrap_id === el.wrap_id || postcard_id === el.wrap_id }
                  customerNotice={ i === 0 && customerNotice }
                />
            ))
        );
    }

    renderWrappers() {
        const {
            giftWrappers: {
                wrappers
            } = {},
            getFilteredAndSortedWrappers
        } = this.props;
        const wraps = getFilteredAndSortedWrappers(wrappers, 'wrap');

        if (wraps?.length === 0) {
            return this.renderAbsentGiftWrapEntity('Gift Wrap');
        }

        return (
            <>
                { this.renderGiftWrapCards(wraps) }
                { this.renderGiftNote() }
            </>
        );
    }

    renderGiftNote() {
        const {
            mpGiftWrap: {
                giftMessageEnabled
            },
            giftNoteText,
            onGiftNoteChange
        } = this.props;
        const {
            mpGiftWrap: {
                giftMessageMaxCharacters
            }
        } = this.props;

        if (!giftMessageEnabled) {
            return null;
        }

        return (
            <Field
              type={ FIELD_TYPE.textarea }
              validationRule={ {
                  range: {
                      max: giftMessageMaxCharacters
                  }
              } }
              events={ { onChange: onGiftNoteChange } }
              validateOn={ ['onChange'] }
              isCountShown
              attr={ {
                  placeholder: __('Gift note'),
                  defaultValue: giftNoteText || ''
              } }
            />
        );
    }

    renderAbsentGiftWrapEntity(type) {
        return (
            <div block="Postcards" elem="Missing">
                <span>
                    { __('NOTE: You must create at least one ') }
                    { type }
                    { __(' to add it to the product!') }
                </span>
            </div>
        );
    }

    renderPostCards() {
        const {
            giftWrappers: {
                wrappers
            } = {},
            getFilteredAndSortedWrappers
        } = this.props;
        const postcards = getFilteredAndSortedWrappers(wrappers, 'postcard');

        if (postcards?.length === 0) {
            return this.renderAbsentGiftWrapEntity('Postcard');
        }

        return (
            <section block="Postcards">
                { this.renderGiftWrapCards(postcards) }
                { this.renderGiftMessageField() }
            </section>
        );
    }

    renderGiftMessageField() {
        const {
            postCardText,
            onPostCardChange,
            mpGiftWrap: {
                giftMessageMaxCharacters
            }
        } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.textarea }
              events={ { onChange: onPostCardChange } }
              validationRule={ {
                  range: {
                      max: giftMessageMaxCharacters
                  }
              } }
              validateOn={ ['onChange'] }
              isCountShown
              attr={ {
                  placeholder: __('Gift message'),
                  defaultValue: postCardText || ''
              } }
            />
        );
    }

    renderSaveButton(isDisabled) {
        const {
            onSave,
            onUpdate,
            onSaveAll,
            isCartItem,
            isAllCart
        } = this.props;
        const buttonAction = isCartItem ? onUpdate : onSave;

        return (
            <StyledButton
              handleClick={ isAllCart ? onSaveAll : buttonAction }
              isDisabled={ isDisabled }
              type="submit"
            >
                { __('Save') }
            </StyledButton>
        );
    }

    renderRemoveButton(isDisabled) {
        const {
            onRemoveAll
        } = this.props;

        return (
            <StyledButton
              handleClick={ onRemoveAll }
              isDisabled={ isDisabled }
              type="submit"
            >
                { __('Remove') }
            </StyledButton>
        );
    }

    renderButtons() {
        const {
            giftWrapOptions
        } = this.props;
        const isDisabled = !giftWrapOptions?.wrap?.wrap_id && !giftWrapOptions?.postcard?.wrap_id;

        return (
            <div block="GiftWrap" elem="Buttons">
                { this.renderRemoveButton(isDisabled) }
                { this.renderSaveButton(isDisabled) }
            </div>
        );
    }

    renderDescription() {
        const {
            mpGiftWrap: {
                description
            }
        } = this.props;

        return (
            <p block="GiftWrapPopup" elem="Description">{ description }</p>
        );
    }

    renderTabs() {
        const { activeTab, onTabChange } = this.props;

        return (
            <div block="GiftWrapPopup" elem="Tabs">
                <div block="GiftWrapPopup" elem="TabsNavigation">
                    <div block="GiftWrapPopup" elem="Tab" mods={ { isActive: activeTab === GIFTWRAP_TAB_ID } }>
                        <input
                          type="radio"
                          id={ GIFTWRAP_TAB_ID }
                          value={ GIFTWRAP_TAB_ID }
                          name="tab"
                          checked={ activeTab === GIFTWRAP_TAB_ID }
                          onChange={ onTabChange }
                        />
                        <label htmlFor={ GIFTWRAP_TAB_ID }>{ __('Gift wrap') }</label>
                    </div>
                    <div block="GiftWrapPopup" elem="Tab" mods={ { isActive: activeTab === POSTCARD_TAB_ID } }>
                        <input
                          type="radio"
                          id={ POSTCARD_TAB_ID }
                          value={ POSTCARD_TAB_ID }
                          name="tab"
                          checked={ activeTab === POSTCARD_TAB_ID }
                          onChange={ onTabChange }
                        />
                        <label htmlFor={ POSTCARD_TAB_ID }>{ __('Postcard') }</label>
                    </div>
                </div>
                <div block="GiftWrapPopup" elem="TabContent">
                    { activeTab === GIFTWRAP_TAB_ID && this.renderWrappers() }
                    { activeTab === POSTCARD_TAB_ID && this.renderPostCards() }
                </div>
            </div>
        );
    }

    renderPopupContent() {
        return (
            <>
                { this.renderDescription() }
                <Form>
                    { this.renderTabs() }
                    { this.renderButtons() }
                </Form>
            </>
        );
    }

    render() {
        const { popupId } = this.props;

        return (
            <Popup
              id={ popupId }
              mix={ { block: 'GiftWrapPopup' } }
            >
                { this.renderPopupContent() }
            </Popup>
        );
    }
}

export default GiftWrapPopupComponent;
