import { connect } from 'react-redux';

import {
    ACCOUNT_ORDER_HISTORY,
    ACCOUNT_URL
} from 'Route/MyAccount/MyAccount.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountOrderContainer as SourceMyAccountOrderContainer
} from 'SourceComponent/MyAccountOrder/MyAccountOrder.container';
import { updateIsMenuVisible, updateMenuTabArray } from 'Store/MyAccountMenu/MyAccountMenu.action';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace Scandipwa/Component/MyAccountOrder/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    tabArray: state.MyAccountMenuReducer.tabArray,
    device: state.ConfigReducer.device
});

/** @namespace Scandipwa/Component/MyAccountOrder/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateMenuTabArray: (tabArray) => dispatch(updateMenuTabArray(tabArray)),
    updateIsMenuVisible: (isMenuVisible) => dispatch(updateIsMenuVisible(isMenuVisible))
});

/** @namespace Scandipwa/Component/MyAccountOrder/Container */
export class MyAccountOrderContainer extends SourceMyAccountOrderContainer {
    resetTabArray() {
        const { updateMenuTabArray } = this.props;
        this.showMenu();
        appendWithStoreCode(ACCOUNT_URL);
        updateMenuTabArray([]);
    }

    removeTab(index) {
        const { tabArray, updateMenuTabArray } = this.props;
        appendWithStoreCode(ACCOUNT_ORDER_HISTORY);
        updateMenuTabArray(tabArray.slice(0, index + 1));
    }

    goToOrdersPage() {
        history.push(appendWithStoreCode('/sales/order/history'));
    }

    showMenu() {
        const { updateIsMenuVisible } = this.props;

        updateIsMenuVisible(true);
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            ...this.state,
            activeOrderInformationTab: ''
        };
    }

    handleInformationTabClick(tab) {
        const { activeOrderInformationTab } = this.state;
        this.setState({ activeOrderInformationTab: activeOrderInformationTab === tab ? '' : tab });
    }

    async requestOrderDetails() {
        const {
            match: {
                params: {
                    orderId
                }
            },
            getOrderById,
            changeTabName,
            setTabSubheading,
            tabArray,
            updateMenuTabArray
        } = this.props;

        if (!isSignedIn()) {
            return;
        }

        const order = await getOrderById(orderId);

        if (!order) {
            history.replace(appendWithStoreCode(`${ ACCOUNT_ORDER_HISTORY }`));

            return;
        }

        const { increment_id, status, id: uid } = order;

        // decode uid of order before setting into state
        order.id = atob(uid);

        const tabName = __('Order # %s', increment_id);
        changeTabName(tabName);
        setTabSubheading(status);
        this.handleChangeHeaderState();
        this.setState({ order, isLoading: false });
        if (!tabArray.includes(tabName)) {
            updateMenuTabArray([__('My Orders'), tabName]);
        }
    }

    componentWillUnmount() {
        const { tabArray } = this.props;

        super.componentWillUnmount();
        updateMenuTabArray([tabArray.splice(-1)]);
    }

    containerProps() {
        const { tabArray, device } = this.props;

        const { activeOrderInformationTab } = this.state;

        return {
            ...super.containerProps(),
            tabArray,
            device,
            activeOrderInformationTab
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        resetTabArray: this.resetTabArray.bind(this),
        removeTab: this.removeTab.bind(this),
        showMenu: this.showMenu.bind(this),
        goToOrdersPage: this.goToOrdersPage.bind(this),
        handleInformationTabClick: this.handleInformationTabClick.bind(this)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOrderContainer);
