/* eslint-disable fp/no-let */
import PropTypes from 'prop-types';
import {
    PureComponent
} from 'react';
import { connect } from 'react-redux';

import BrowserDatabase from 'Util/BrowserDatabase';

import {
    IS_INTRO_VIDEO_ENDED
} from '../IntroVideo/IntroVideo.config';
import HeroSliderText from './HeroSliderText.component';
import {
    HERO_SLIDER_TITLE
} from './HeroSliderText.config';

/** @namespace Scandipwa/Component/HeroSliderText/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Scandipwa/Component/HeroSliderText/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/HeroSliderText/Container */
export class HeroSliderTextContainer extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        textColor: PropTypes.string.isRequired,
        storeId: PropTypes.string.isRequired
    };

    state = {
        isVideoEnded: false
    };

    containerFunctions = {};

    componentDidMount() {
        this.splitTextIntoLines();
        window.addEventListener('resize', this.resizeText);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeText);
    }

    containerProps() {
        const {
            title,
            description,
            textColor,
            storeId
        } = this.props;

        const {
            isVideoEnded
        } = this.state;

        return {
            title,
            description,
            textColor,
            isVideoEnded,
            storeId
        };
    }

    /* Splitting the Hero widget text depending on their line width compared to
       the containing division for the intro video animation. */
    splitTextIntoLines() {
        const {
            title
        } = this.props;

        const isVideoEnded = BrowserDatabase.getItem(IS_INTRO_VIDEO_ENDED);

        const heroSliderTitle = document.getElementsByClassName(HERO_SLIDER_TITLE)[0];

        if (!heroSliderTitle) {
            return;
        }

        const titleLineClass = isVideoEnded
            ? 'HeroSliderText-TitleLine_isVideoEnded'
            : 'HeroSliderText-TitleLine';

        let line = [];
        let testLine = [];
        const output = [];

        title.split(' ').forEach((word) => {
            testLine.push(word);
            heroSliderTitle.innerHTML = `<h1 style="overflow-y: visible;">${testLine.join(' ')}</h1>`;
            if (heroSliderTitle.scrollWidth > heroSliderTitle.clientWidth) {
                output.push(`<div class="${titleLineClass}"><h1>${line.join(' ')}</h1></div>`);
                testLine = [testLine[testLine.length - 1]];
                line = [];
            }
            line.push(word);
        });

        heroSliderTitle.innerHTML = '';
        output.forEach((outputLine) => {
            heroSliderTitle.innerHTML += outputLine;
        });
        heroSliderTitle.innerHTML += `<div class="${titleLineClass}"><h1 data-scroll>${line.join(' ')}</h1></div>`;
        heroSliderTitle.innerHTML = heroSliderTitle.innerHTML.split('<h1></h1>').join('');
    }

    resizeText = () => {
        this.splitTextIntoLines();
        this.setState({
            isVideoEnded: BrowserDatabase.getItem(IS_INTRO_VIDEO_ENDED)
        });
    };

    render() {
        return (
            <HeroSliderText
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeroSliderTextContainer);
