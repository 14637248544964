/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { setSelectedMcShippingLocation } from '../../store/MakeCommerce/MakeCommerce.action';
import { MakeCommerceShippingType } from '../../type/MakeCommerce.type';
import { LocationFieldsComponent } from './LocationFields.component';

/** @namespace Scandiweb/MakeCommerce/Component/LocationFields/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    shippingLocations: state.MakeCommerceReducer.mcShippingLocations
});

/** @namespace Scandiweb/MakeCommerce/Component/LocationFields/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setSelectedLocation: (location) => dispatch(setSelectedMcShippingLocation(location))
});

/** @namespace Scandiweb/MakeCommerce/Component/LocationFields/Container */
export class LocationFieldsContainer extends PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        shippingLocations: MakeCommerceShippingType,
        countryId: PropTypes.string.isRequired,
        setSelectedLocation: PropTypes.func.isRequired
    };

    static defaultProps = {
        shippingLocations: {}
    };

    state = {
        divisions: [],
        areas: []
    };

    containerFunctions = {
        handleDivisionChange: this.handleDivisionChange.bind(this),
        handleAreaChange: this.handleAreaChange.bind(this)
    };

    componentDidMount() {
        // vvv normalizeDivision onMount
        const { id, shippingLocations } = this.props;
        const divisions = this.normalizeDivisions(shippingLocations[id]);
        this.setState({ divisions });
    }

    containerProps() {
        const {
            id
        } = this.props;

        const {
            divisions,
            areas
        } = this.state;

        const formattedDivision = divisions ? divisions
            .map(
                (area) => {
                    const label = area.label.charAt(0).toUpperCase() + area.label.toLowerCase().slice(1);

                    return {
                        ...area,
                        label
                    };
                }
            ) : undefined;

        return {
            id,
            divisions: formattedDivision,
            areas
        };
    }

    normalizeDivisions({ locations }) {
        const { countryId, id } = this.props;

        return Object.values(locations).reduce((acc, { groupCountry, groupName, locations }) => {
            // vvv remove unnecessary options:
            // - options outside the current country
            // - empty named options
            // - options without inner locations
            if (groupCountry !== countryId
                || !groupName
                || !locations
                || !locations.length) {
                return acc;
            }

            // vvv remove duplicate group names, we don't care about the inner values, because they
            // will be merged in the `areas` array.
            const duplicate = acc.findIndex(({ value }) => value === groupName);
            if (duplicate > -1) {
                return acc;
            }

            acc.push({
                id: id + groupName,
                label: groupName,
                value: groupName,
                options: locations
            });

            return acc;
        }, []);
    }

    normalizeAreas(locations) {
        const { id } = this.props;
        const merged = locations.reduce((acc, { locations }) => {
            acc.push(locations);
            return acc;
        }, []).flat();

        return merged.map(({ location_id, name }) => ({
            id: `${id}-${location_id}`,
            label: name,
            value: location_id
        }));
    }

    handleDivisionChange(divisionName) {
        const {
            id,
            shippingLocations
        } = this.props;

        // vvv find all locations that match the same groupName, because some values has duplicate
        // groupNames.
        const { locations } = shippingLocations[id];
        const location = locations.filter(({ groupName }) => groupName === divisionName);
        const areas = this.normalizeAreas(location);

        this.setState({ areas });
    }

    handleAreaChange(areaValue) {
        const { setSelectedLocation } = this.props;
        const { areas } = this.state;
        const selectedArea = areas.find(({ value }) => areaValue === value);

        setSelectedLocation(selectedArea);
    }

    render() {
        return (
            <LocationFieldsComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationFieldsContainer);
