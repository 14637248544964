import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountAddressTableContainer as SourceMyAccountAddressTableContainer
} from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.container';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/MyAccountAddressTable/Container */
export class MyAccountAddressTableContainer extends SourceMyAccountAddressTableContainer {
    containerProps() {
        const { isPopUp } = this.props;

        return {
            ...super.containerProps(),
            isPopUp
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAddressTableContainer);
