import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/StoreSwitcher/Query */
export class StoreSwitcherQuery {
    getQuery({
        pathname, storeCode
    }) {
        return new Field('storeSwitcher')
            .addArgument('current_url', 'String!', pathname)
            .addArgument('store_code', 'String!', storeCode)
            .addFieldList(this._getChildFields());
    }

    _getChildFields() {
        return [
            'url'
        ];
    }
}

export default new StoreSwitcherQuery();
