import { showNotification } from 'Store/Notification/Notification.action';
import { fetchQuery } from 'Util/Request';

import SeoHtmlTemplatesQuery from '../../query/SeoHtmlTemplates.query';
import { getHtmlSitemap, getStoreViews } from './HtmlSitemap.action';

/** @namespace SeoSuite/Store/HtmlSitemap/Dispatcher */
export class HtmlSitemapDispatcher {
    requestHtmlSitemap(dispatch, id) {
        const query = SeoHtmlTemplatesQuery.getMwHtmlSitemap(id);

        return fetchQuery(query).then(
            /** @namespace SeoSuite/Store/HtmlSitemap/Dispatcher/HtmlSitemapDispatcher/requestHtmlSitemap/fetchQuery/then */
            (getSitemap) => {
                dispatch(getHtmlSitemap(getSitemap));
            },
            /** @namespace SeoSuite/Store/HtmlSitemap/Dispatcher/HtmlSitemapDispatcher/requestHtmlSitemap/fetchQuery/then/catch */
            (error) => {
                dispatch(showNotification('error', error[0].message));
            }
        );
    }

    requestStoreViews(dispatch) {
        const query = SeoHtmlTemplatesQuery.requestAllStoreViews();

        return fetchQuery(query).then(
            /** @namespace SeoSuite/Store/HtmlSitemap/Dispatcher/HtmlSitemapDispatcher/requestStoreViews/fetchQuery/then */
            (getStores) => {
                dispatch(getStoreViews(getStores));
            },
            /** @namespace SeoSuite/Store/HtmlSitemap/Dispatcher/HtmlSitemapDispatcher/requestStoreViews/fetchQuery/then/catch */
            (error) => {
                dispatch(showNotification('error', error[0].message));
            }
        );
    }
}

export default new HtmlSitemapDispatcher();
