import { SET_KEY_TREASURES_DATA } from './KeyTreasures.action';

/** @namespace Scandipwa/Store/KeyTreasures/Reducer/getInitialState */
export const getInitialState = () => ({
    dataByProduct: {}
});

/** @namespace Scandipwa/Store/KeyTreasures/Reducer/KeyTreasuresReducer */
export const KeyTreasuresReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case SET_KEY_TREASURES_DATA:
        const {
            productId,
            image,
            vimeoVideoURL,
            ingredients
        } = action;

        return {
            ...state,
            dataByProduct: {
                ...state.dataByProduct,
                [productId]: {
                    image,
                    vimeoVideoURL,
                    ingredients
                }
            }
        };
    default:
        return state;
    }
};

export default KeyTreasuresReducer;
