import { trimEndSlash } from 'SourceUtil/Url/Url.js';
import getStore from 'Util/Store';

export * from 'SourceUtil/Url/Url.js';

/**
 * Overridden to not use window.location.href for getting storePrefix to be more accurate
 *
 * @namespace Scandipwa/Util/Url/appendWithStoreCode
 */
export const appendWithStoreCode = (pathname) => {
    const { ConfigReducer: { base_link_url } = {} } = getStore().getState() || {};

    // eslint-disable-next-line fp/no-let
    let storePrefix;

    if (!base_link_url) {
        storePrefix = `/${window.storeConfigurations.storeCode}/`;
    } else {
        const { pathname: pathnameStorePrefix } = new URL(base_link_url);

        storePrefix = pathnameStorePrefix;
    }

    if (!pathname) {
        return trimEndSlash(storePrefix);
    }

    // match URLs which have the store code in pathname
    if (new RegExp(`^/(${window.storeList.join('|')})/`, 'i').test(pathname)) {
        return pathname;
    }

    // trim the last slash from URL, and append it to pathname
    return trimEndSlash(storePrefix).concat(!pathname.startsWith('/') ? `/${ pathname }` : pathname);
};

/**
 * Overridden to use this file appendWithStoreCode
 *
 * @namespace Scandipwa/Util/Url/isHomePageUrl
 */
export const isHomePageUrl = (pathname) => {
    const isHomePage = pathname === appendWithStoreCode('/')
        || pathname === '/'
        || pathname === appendWithStoreCode('')
        || pathname === '';

    return isHomePage;
};
