/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MakeCommerce/Query/Shipping/Query */
export class ShippingQuery {
    getShippingConfig() {
        return new Field('makeCommerceConfig')
            .addField(this.getShippingConfigField());
    }

    getShippingConfigField() {
        return new Field('shipping')
            .addFieldList(this.getShippingConfigFieldList());
    }

    getShippingConfigFieldList() {
        return [
            this.getCarrierField('smartpostmc_parcel'),
            this.getCarrierField('omnivamc_parcel'),
            this.getCarrierField('dpdmc_parcel')
        ];
    }

    getCarrierField(carrier) {
        return new Field(carrier)
            .addField(this.getLocationField());
    }

    getLocationField() {
        return new Field('locations')
            .addFieldList(this.getLocationFieldList());
    }

    getLocationFieldList() {
        return [
            'groupName',
            'groupCountry',
            this.getLocationOptionsField()
        ];
    }

    getLocationOptionsField() {
        return new Field('locations')
            .addFieldList(this.getLocationOptionsFieldList());
    }

    getLocationOptionsFieldList() {
        return [
            'location_id',
            'name',
            'city',
            'address'
        ];
    }
}

export default new ShippingQuery();
