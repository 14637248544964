/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import GiftWrapInfoSection from 'Component/GiftWrapInfoSection';
import { fetchMutation } from 'Util/Request';
import getStore from 'Util/Store';

import { allowedProductType, GIFT_WRAP_PER_CART } from '../config/GiftWrap.config';
import GiftWrapSetAllCartDataQuery from '../query/Mutation/GiftWrapSetAllCartData.query';
import GiftWrapSetDataQuery from '../query/Mutation/GiftWrapSetData.query';
import GiftWrapSetOneTypeAllCartDataQuery from '../query/Mutation/GiftWrapSetOneTypeAllCartData.query';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

const modifyMapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        productAdd: state.CartReducer?.isLoading,
        cartItemsCount: state.CartReducer?.cartTotals?.items?.length,
        cartId: state.CartReducer?.cartTotals?.id,
        items: state.CartReducer?.cartTotals?.items,
        allCartWrap: state.CartReducer?.cartTotals?.mpGiftWrapData,
        allCartPostcard: state.CartReducer?.cartTotals?.mpPostcardData
    };
};

const renderSkuAndStock = (args, callback, instance) => {
    const { giftWrapType } = getStore().getState().ConfigReducer.mpGiftWrap || {};
    const {
        setWrapData,
        product: {
            type_id: productType
        }
    } = instance.props;

    // vvv original extension works only with simple|configurable|bundle type of the products
    const isAllowedProductType = productType in allowedProductType;

    if (!giftWrapType || giftWrapType === GIFT_WRAP_PER_CART || !isAllowedProductType) {
        return callback(...args);
    }

    return (
        <>
            { callback(...args) }
            { /* vvv to pass selected options from BE */ }
            <GiftWrapInfoSection selectedOptions="" handleWrapData={ setWrapData } />
        </>
    );
};

const addAllCartData = async (cartId, allCartWrap, allCartPostcard) => {
    const cartDispatcher = (await CartDispatcher).default;
    const { dispatch } = getStore();
    const allCartWrapData = allCartWrap || {};
    const allCartPostcardData = allCartPostcard || {};
    const {
        wrap_id: wrapId = null,
        gift_message: wrapMessage = ''
    } = allCartWrapData;
    const {
        wrap_id: postcardId = null,
        gift_message: postcardMessage = ''
    } = allCartPostcardData;

    if (allCartWrap || allCartPostcard) {
        await fetchMutation(GiftWrapSetAllCartDataQuery.setData(
            cartId,
            wrapId,
            postcardId,
            wrapMessage,
            postcardMessage
        ));
    }

    cartDispatcher.updateInitialCartData(dispatch);
};

const addOneTypeAllCartData = async (cartId, data) => {
    const cartDispatcher = (await CartDispatcher).default;
    const { dispatch } = getStore();

    if (data) {
        const { wrap_id, gift_message = '' } = data;
        await fetchMutation(GiftWrapSetOneTypeAllCartDataQuery.setData(cartId, wrap_id, gift_message));
    }

    cartDispatcher.updateInitialCartData(dispatch);
};

const setGiftWrapData = async (
    cartId,
    item_id,
    wrap_id,
    postcard_id,
    wrapMessage,
    postcardMessage,
    allCartWrap,
    allCartPostcard
) => {
    const cartDispatcher = (await CartDispatcher).default;
    const { dispatch } = getStore();

    await fetchMutation(GiftWrapSetDataQuery.setData(
        cartId,
        item_id,
        wrap_id,
        postcard_id,
        wrapMessage,
        postcardMessage
    ));

    // If isAllCart=true it means that we has full or partially assigned AllCart data
    const isAllCart = !!(allCartWrap || allCartPostcard);
    // isAllCartPartial is a flag indicated of existence only one of the GiftWrap Data type
    const isAllCartPartial = !!(wrap_id || postcard_id);
    // If wrap_id !== null and isAllCartPartial == true it means that allCartPostcard may be empty and vice versa
    const wrapData = wrap_id ? allCartPostcard : allCartWrap;

    if (isAllCart && isAllCartPartial) {
        addOneTypeAllCartData(cartId, wrapData);
    }

    cartDispatcher.updateInitialCartData(dispatch);
};

// vvv set GiftWrapper data to the CartItem
const setGiftWrapper = (itemsCount, props) => {
    const {
        cartId,
        items,
        giftWrapOptions: {
            wrap = {},
            postcard = {}
        },
        allCartWrap,
        allCartPostcard
    } = props;
    const {
        wrap_id = null,
        giftNoteMessage = {}
    } = wrap;
    // vvv giftNoteMessage.message can be null or undefined
    const wrapMessage = (giftNoteMessage.message !== null && giftNoteMessage.message !== undefined)
        ? giftNoteMessage.message
        : '';
    const {
        wrap_id: postcard_id = null,
        postcardMessage: tmp_message
    } = postcard;
    const postcardMessage = tmp_message !== null ? tmp_message : '';
    // Get the array of the last added items to the cart
    const lastItems = items.slice(items.length - itemsCount);
    const item_ids = [];

    lastItems.forEach((currentItem) => {
        item_ids.push(currentItem.item_id);
    });

    if (item_ids.length === 0) {
        return;
    }

    if (wrap_id >= 0 || postcard_id >= 0) {
        /**
         * In case if the product is possible adding more than one product to cart simultaneously
         * There it can be more than one new CartItems each with own item_id value
         */
        item_ids.forEach((item_id) => setGiftWrapData(
            cartId,
            item_id,
            wrap_id,
            postcard_id,
            wrapMessage,
            postcardMessage,
            allCartWrap,
            allCartPostcard
        ));
    }
};

const componentDidUpdate = (args, callback, instance) => {
    const [prevProps] = args;
    const {
        product: { id: prevId },
        productAdd: prevProductAdd,
        cartItemsCount: prevCartItemsCount
    } = prevProps;
    const {
        product: { id },
        minQuantity,
        setQuantity,
        productAdd,
        cartItemsCount,
        cartId,
        allCartWrap,
        allCartPostcard,
        giftWrapOptions: {
            wrap = null,
            postcard = null
        }
    } = instance.props;
    const diffItemsCount = cartItemsCount - prevCartItemsCount;
    // If isAllCart=true it means that we has full or partially assigned AllCart data
    const isAllCart = !!(allCartWrap || allCartPostcard);
    const isGiftWrapEmpty = !wrap && !postcard;

    if (id !== prevId) {
        setQuantity(minQuantity);
    }

    if (productAdd === prevProductAdd) {
        return;
    }

    /**
     * prevProductAdd is alias for 'isLoading' state for the Cart component
     * prevProductAdd=true if new items has been added to the cart and state 'isLoading' for Cart changed to the 'false'.
     * It means that new product just now has been added to the cart and GiftWrap data can be safely added to it
     */
    if (prevProductAdd && diffItemsCount > 0) {
        setGiftWrapper(diffItemsCount, instance.props);

        // vvv Set AllCart data as for wrap as postcard if all_cart is true and user hasn't chose anything from GiftWrap
        if (isAllCart && isGiftWrapEmpty) {
            addAllCartData(cartId, allCartWrap, allCartPostcard);
        }
    }
};

const state = (originalMembers) => ({
    ...originalMembers,
    giftWrapOptions: {}
});

const addItemsCountPropsToContainerProps = (args, callback, instance) => {
    const {
        productAdd,
        cartItemsCount,
        cartId,
        items,
        allCartWrap,
        allCartPostcard
    } = instance.props;
    const {
        giftWrapOptions = {}
    } = instance.state;

    return {
        ...callback(...args),
        productAdd,
        cartItemsCount,
        giftWrapOptions,
        cartId,
        items,
        allCartWrap,
        allCartPostcard
    };
};

// Add new method 'setWrapData' to the 'ProductActions' class
const containerFunctions = (members, instance) => ({
    ...members,
    setWrapData: ((giftWrapOptions) => {
        instance.setState({ giftWrapOptions });
    })
});

export default {
    'Component/ProductActions/Container/mapStateToProps': {
        function: modifyMapStateToProps
    },
    'Component/ProductActions/Component': {
        'member-function': {
            renderSkuAndStock,
            componentDidUpdate
        }
    },
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps: addItemsCountPropsToContainerProps
        },
        'member-property': {
            containerFunctions,
            state
        }

    }
};
