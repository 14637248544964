/* eslint-disable max-lines */
/* eslint-disable max-len */
import Checkboxes from '@scandiweb/amasty-gdpr/src/component/Checkboxes/Checkboxes.container';
import { REGISTRATION_SCOPE } from '@scandiweb/amasty-gdpr/src/config';
import PropTypes from 'prop-types';
import { Suspense } from 'react';

import DiscountSlider from 'Component/DiscountSlider';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import {
    FIFTEEN_PERCENT_DISCOUNT,
    FIVE_PERCENT_DISCOUNT
} from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import StyledButton from 'Component/StyledButton/StyledButton.container';
import {
    MyAccountCreateAccountComponent as SourceMyAccountCreateAccount
} from 'StendersComponent/MyAccountCreateAccount/MyAccountCreateAccount.component';
import { RefType } from 'Type/Common.type';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { validatePassword } from 'Util/Validator';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { validatePhoneNumber } from '../../util/PhoneValidator/Validator';
import { STORE_CODES_WITH_PHONE } from '../MyAccount/MyAccount.config';

import './MyAccountCreateAccount.override.style.scss';

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Component */
export class MyAccountCreateAccountComponent extends SourceMyAccountCreateAccount {
    static propTypes = {
        ...super.propTypes,
        isMobile: PropTypes.bool.isRequired,
        isSubmitButtonEnabled: PropTypes.bool.isRequired,
        onGDPRStateChanged: PropTypes.func.isRequired,
        formWrapperRef: RefType.isRequired
    };

    discounts = [
        {
            key: 'lb1',
            title: __('%s% discount', FIVE_PERCENT_DISCOUNT),
            // eslint-disable-next-line max-len
            description: __('%s% discount on unlimited quantities of each product and assortment of products.', FIVE_PERCENT_DISCOUNT)
        },
        {
            key: 'lb2',
            title: __('%s% discount on your birthday', FIFTEEN_PERCENT_DISCOUNT),
            // eslint-disable-next-line max-len
            description: __('We\'ll send you a voucher code for %s% off on your birthday. You can use it when you make purchases in our e-shop.', FIFTEEN_PERCENT_DISCOUNT)
        },
        {
            key: 'lb3',
            title: __('Special offers and gifts'),
            description: __('We\'ll surprise you with special offers, better prices and gifts with your purchase.')
        }
    ];

    /**
     * Overridden to add subLabels, and phone number input for Latvian website.
     * Modified to also change field ids on Create Account Page.
     */
    renderCreateAccountSignUpInfoFields() {
        const { location: { state: { email = '', mobilenumber = '+371', date_of_birth = '' } = {} } } = history;
        const {
            range,
            minimunPasswordCharacter,
            onInputValidated,
            isCreateAccountPage
        } = this.props;
        const storeConfig = BrowserDatabase.getItem('config');
        const code = storeConfig?.storeConfig?.code;

        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                <Field
                  type={ FIELD_TYPE.email }
                  attr={ {
                      id: isCreateAccountPage ? 'page_email' : 'email',
                      name: 'email',
                      defaultValue: email,
                      placeholder: __(' '),
                      altPlaceholder: __('Email'),
                      autocomplete: 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  addRequiredTag
                  onValidated={ onInputValidated }
                />
                { (code && code in STORE_CODES_WITH_PHONE) && (
                <Field
                  type={ FIELD_TYPE.tel }
                  attr={ {
                      id: isCreateAccountPage ? 'page_mobilenumber' : 'mobilenumber',
                      name: 'mobilenumber',
                      defaultValue: mobilenumber,
                      altPlaceholder: __('Phone'),
                      autocomplete: 'mobilenumber'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.newPhoneNumber,
                      match: (value) => validatePhoneNumber(value)
                  } }
                  subLabel={ __('Text explaining why we need a phone nr.') }
                  onValidated={ onInputValidated }
                />
                ) }
                <Field
                  type={ FIELD_TYPE.date }
                  attr={ {
                      id: isCreateAccountPage ? 'page_date_of_birth' : 'date_of_birth',
                      name: 'date_of_birth',
                      defaultValue: date_of_birth,
                      placeholder: __('dd/mm/yyyy'),
                      altPlaceholder: __('Birthday (optional)')
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: false,
                      inputType: VALIDATION_INPUT_TYPE.date
                  } }
                  subLabel={ __('Let us know when your birthday is, and receive a surprise.') }
                  onValidated={ onInputValidated }
                />
                <div block="MyAccountOverlay" elem="PasswordBlock">
                    <Field
                      type={ FIELD_TYPE.password }
                      attr={ {
                          id: isCreateAccountPage ? 'page_password' : 'password',
                          name: 'password',
                          placeholder: __(' '),
                          altPlaceholder: __('Password'),
                          autocomplete: 'new-password'
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true,
                          inputType: VALIDATION_INPUT_TYPE.password,
                          match: (value) => {
                              const email = isCreateAccountPage ? document.getElementById('page_email') : document.getElementById('email');

                              if (value && email.value === value) {
                                  return __('Passwords can\'t be the same as email!');
                              }

                              return validatePassword(value, range, minimunPasswordCharacter);
                          }
                      } }
                      addRequiredTag
                      onValidated={ onInputValidated }
                    />
                    <Field
                      type={ FIELD_TYPE.password }
                      attr={ {
                          id: isCreateAccountPage ? 'page_confirm_password' : 'confirm_password',
                          name: 'confirm_password',
                          placeholder: __(' '),
                          altPlaceholder: __('Confirm password'),
                          autocomplete: 'new-password'
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true,
                          inputType: VALIDATION_INPUT_TYPE.password,
                          match: (value) => {
                              const password = document.querySelector('.MyAccountOverlay-PasswordBlock input[name="password"]');
                              return value && password.value === value;
                          },
                          customErrorMessages: {
                              onMatchFail: __('Passwords do not match!')
                          }
                      } }
                      addRequiredTag
                      onValidated={ onInputValidated }
                    />
                </div>
                { this.renderCheckboxes() }
            </fieldset>
        );
    }

    renderSubmitButton() {
        const { isSubmitButtonEnabled } = this.props;

        return (
            <div block="MyAccountOverlay" elem="Buttons">
                <StyledButton
                  mix={ { block: 'MyAccountOverlay', elem: 'SignUpButton' } }
                  type="submit"
                  isDisabled={ !isSubmitButtonEnabled }
                >
                    { __('Sign up') }
                </StyledButton>
            </div>
        );
    }

    renderSubscribeToNewsletter() {
        const { isLandingPage } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.checkbox }
              label={ __('Subscribe to newsletter') }
              attr={ {
                  id: `is_subscribed${isLandingPage ? '' : '-sidebar'}`,
                  name: 'is_subscribed',
                  placeholder: __('Your Tax/VAT Number')
              } }
              mix={ { block: 'MyAccountOverlay', elem: 'Checkbox' } }
            />
        );
    }

    renderCheckboxes() {
        const {
            newsletterActive,
            onGDPRStateChanged
        } = this.props;
        const storeConfig = BrowserDatabase.getItem('config');
        const code = storeConfig?.storeConfig?.code;
        const smsCheckboxField = (code && code in STORE_CODES_WITH_PHONE) ? (
            <Field
              type={ FIELD_TYPE.checkbox }
              attr={ {
                  name: 'smssubscriber'
              } }
              label={ __('SMS Subscription') }
            />
        ) : null;

        return (
            <>
                <Checkboxes
                  scope={ REGISTRATION_SCOPE }
                  onValidityChanged={ onGDPRStateChanged }
                />
                { /* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                { newsletterActive ? this.renderSubscribeToNewsletter() : null }
                { smsCheckboxField }
            </>
        );
    }

    /**
     * New method to render discount section
     */
    renderDiscountSection() {
        const {
            isMobile,
            activeDiscount,
            onActiveDiscountChange,
            sliderRef
        } = this.props;

        return (
            <div
              block="MyAccountOverlay"
              elem="DiscountSection"
              mods={ { isMobile } }
            >
                <Suspense fallback={ null }>
                    <DiscountSlider
                      showCrumbs
                      sliderRef={ sliderRef }
                      activeImage={ activeDiscount }
                      onActiveImageChange={ onActiveDiscountChange }
                      mix={ { block: 'MyAccountOverlay', elem: 'DiscountWrapper' } }
                    >
                        { this.discounts.map(({ key, title, description }) => (
                            <div
                              key={ key }
                              block="MyAccountOverlay"
                              elem="SubDiscountWrapper"
                              mods={ { isMobile } }
                            >
                            <div
                              block="MyAccountOverlay"
                              elem="PercentText"
                            >
                                <h4>{ title }</h4>
                                <p>{ description }</p>
                            </div>
                            </div>
                        )) }
                    </DiscountSlider>
                </Suspense>
            </div>
        );
    }

    renderCreateAccountForm() {
        const {
            customerExists,
            sendSocialTokenQuery,
            formWrapperRef
        } = this.props;

        if (customerExists) {
            sendSocialTokenQuery();
        }

        return (
            <div ref={ formWrapperRef }>
                { super.renderCreateAccountForm() }
            </div>
        );
    }

    /**
     * Overrode to add altPlaceholder
     */
    renderVatNumberField() {
        const {
            showTaxVatNumber,
            vatNumberRequired,
            isCreateAccountPage
        } = this.props;

        if (!showTaxVatNumber) {
            return null;
        }

        return (
            <Field
              type={ FIELD_TYPE.text }
              label={ __('Tax/VAT Number') }
              attr={ {
                  id: isCreateAccountPage ? 'page_taxvat' : 'taxvat',
                  name: 'taxvat',
                  placeholder: __(' '),
                  altPlaceholder: __('Your Tax/VAT Number')
              } }
              validateOn={ ['onChange'] }
              validationRule={ {
                  isRequired: vatNumberRequired
              } }
              addRequiredTag={ vatNumberRequired }
            />
        );
    }

    /**
     * replace VALIDATION_INPUT_TYPE.alphaSpace with VALIDATION_INPUT_TYPE.fullName
     */
    renderCreateAccountPersonalInfoFields() {
        const { location: { state: { firstName = '', lastName = '' } = {} } } = history;
        const {
            onInputValidated,
            isCreateAccountPage
        } = this.props;

        return (
            <fieldset
              block="MyAccountOverlay"
              elem="Legend"
              mix={ { block: 'MyAccountOverlay', elem: 'FullName' } }
            >
                <Field
                  type={ FIELD_TYPE.text }
                  attr={ {
                      id: isCreateAccountPage ? 'page_firstname' : 'firstname',
                      name: 'firstname',
                      defaultValue: firstName,
                      placeholder: __(' '),
                      altPlaceholder: __('First name'),
                      autocomplete: 'given-name'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.fullName,
                      isRequired: true
                  } }
                  addRequiredTag
                  onValidated={ onInputValidated }
                />
                <Field
                  type={ FIELD_TYPE.text }
                  attr={ {
                      id: isCreateAccountPage ? 'page_lastname' : 'lastname',
                      name: 'lastname',
                      defaultValue: lastName,
                      placeholder: __(' '),
                      altPlaceholder: __('Last name'),
                      autocomplete: 'family-name'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.fullName,
                      isRequired: true
                  } }
                  addRequiredTag
                  onValidated={ onInputValidated }
                />
                { this.renderVatNumberField() }
            </fieldset>
        );
    }
}

export default MyAccountCreateAccountComponent;
