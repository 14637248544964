/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import { TOP_BANNER } from '../../config/Banner.config';
import {
    BannersType,
    LocationType
} from '../../types/Banner.type';
import Banner from '../Banner';

/** @namespace Scandiweb/AmastySpecialPromotionsPro/Component/Banners/Component/BannersComponent */
export class BannersComponent extends PureComponent {
    static propTypes = {
        banners: BannersType,
        location: LocationType.isRequired
    };

    static defaultProps = {
        banners: []
    };

    renderBanner({
        image,
        link,
        altText,
        hoverText,
        position
    }) {
        return (
            <Banner
              renderEmpty={ image === '' }
              image={ image }
              link={ link }
              altText={ altText }
              hoverText={ hoverText }
              key={ image }
              position={ position }
            />
        );
    }

    render() {
        const { location, banners = [] } = this.props;

        if (!banners || !banners.length) {
            if (location === TOP_BANNER) {
                return this.renderBanner({ image: '' });
            }

            return null;
        }

        return (
            <>
                { banners
                    .filter(({ position }) => position === location)
                    .map(this.renderBanner) }
            </>
        );
    }
}

export default BannersComponent;
