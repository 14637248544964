/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageplazaGiftWrap/Query/Mutation/GiftWrapSetOneTypeAllCartData/Query */
export class GiftWrapSetOneTypeAllCartDataQuery {
    setData(
        cartId,
        Id,
        message = '',
    ) {
        return new Field('mpSetOneTypeGiftWrapAllCart')
            .addArgument('cart_id', 'String!', cartId)
            .addArgument('wrap_id', 'Int', Id)
            .addArgument('wrap_message', 'String', message);
    }
}

export default new GiftWrapSetOneTypeAllCartDataQuery();
