/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Loader from 'Component/Loader';

import { SelectOptionsType } from '../../type/Paypal.type';

import './PaypalBillingAgreements.style';

/** @namespace Scandiweb/PaypalExpress/Component/PaypalBillingAgreements/Component */
export class PaypalBillingAgreementsComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        billingAgreementId: PropTypes.string.isRequired,
        selectOptions: SelectOptionsType.isRequired,
        handleAgreementSelect: PropTypes.func.isRequired
    };

    render() {
        const {
            isLoading,
            billingAgreementId,
            selectOptions,
            handleAgreementSelect
        } = this.props;

        return (
            <div block="PaypalBillingAgreements">
                <Field
                  type={ FIELD_TYPE.select }
                  attr={ {
                      id: 'ba_agreement_id',
                      name: 'ba_agreement_id',
                      value: billingAgreementId,
                      selectPlaceholder: __('Please Select Billing Agreement...')
                  } }
                  events={ {
                      onChange: handleAgreementSelect
                  } }
                  label={ __('Billing Agreement') }
                  options={ selectOptions }
                  mix={ { block: 'PaypalBillingAgreements', elem: 'Select' } }
                />
                <Loader isLoading={ isLoading } />
            </div>
        );
    }
}

export default PaypalBillingAgreementsComponent;
