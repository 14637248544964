/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { CUSTOMER } from '@scandipwa/scandipwa/src/store/MyAccount/MyAccount.dispatcher';

import { STORE_CODES_WITH_PHONE } from 'Component/MyAccount/MyAccount.config';
import BrowserDatabase from 'Util/BrowserDatabase';

const addPhoneNumberField = (args) => {
    const customer = BrowserDatabase.getItem(CUSTOMER);
    const { storeConfig: { code } } = BrowserDatabase.getItem('config');
    return (code in STORE_CODES_WITH_PHONE)
        ? [
            ...args,
            {
                key: 'mobilenumber',
                label: __('Mobile Number'),
                source: customer
            }
        ]
        : Array.from(args);
};

export default {
    'Component/MyAccountCustomerTable/Component': {
        'member-function': {
            dataPairArray: addPhoneNumberField
        }
    }
};
