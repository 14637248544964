import { LOGIN_BUTTON_CMS_TYPE } from '../component/LoginButton/LoginButton.config';
import LoginButtonContainer from '../component/LoginButton/LoginButton.container';

/** @namespace Stenders/LoginButton/Plugin/AddToWidgetFactory/addWidget */
export const addWidget = (originalMember) => ({
    ...originalMember,
    [LOGIN_BUTTON_CMS_TYPE]: {
        component: LoginButtonContainer
    }
});

export default {
    'Scandipwa/Component/WidgetFactory/Component': {
        'member-property': {
            renderMap: addWidget
        }
    }
};
