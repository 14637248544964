/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { MFGiftWrapItemType } from '../../type/GiftWrap';
import { createGiftWrapObject } from '../../util/GiftWrap';
import GiftWrapCartItem from './GiftWrapCartItem.component';

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapCartItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currencyCode: state.CartReducer.cartTotals.quote_currency_code,
    giftWrapMessageFee: state.ConfigReducer.mpGiftWrap?.giftMessageFee,
    cartId: state.CartReducer.cartTotals?.id,
    giftWrapType: state.ConfigReducer.mpGiftWrap?.giftWrapType,
    mpGiftWrapData: state.CartReducer.cartTotals?.mpGiftWrapData,
    mpPostcardData: state.CartReducer.cartTotals?.mpPostcardData
});

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapCartItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapCartItem/Container */
export class GiftWrapCartItemContainer extends PureComponent {
    static propTypes = {
        giftWrapData: MFGiftWrapItemType.isRequired,
        giftWrapPostcardData: MFGiftWrapItemType.isRequired,
        currencyCode: PropTypes.string,
        giftWrapMessageFee: PropTypes.number.isRequired,
        popupId: PropTypes.string.isRequired,
        itemId: PropTypes.number.isRequired,
        cartId: PropTypes.string.isRequired,
        giftWrapType: PropTypes.number.isRequired,
        mpGiftWrapData: MFGiftWrapItemType.isRequired,
        mpPostcardData: MFGiftWrapItemType.isRequired
    };

    static defaultProps = {
        currencyCode: null
    };

    containerFunctions = {
        preventAnchorEvent: this.preventAnchorEvent
    };

    containerProps() {
        const {
            giftWrapData = {},
            giftWrapPostcardData = {},
            currencyCode,
            giftWrapMessageFee,
            popupId,
            itemId,
            giftWrapType,
            mpGiftWrapData,
            mpPostcardData
        } = this.props;

        return {
            giftWrapData: createGiftWrapObject('giftWrap', giftWrapData),
            postcardData: createGiftWrapObject('postcard', giftWrapPostcardData),
            currencyCode,
            giftWrapMessageFee,
            popupId,
            itemId,
            giftWrapType,
            mpGiftWrapData,
            mpPostcardData
        };
    }

    preventAnchorEvent(event) {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    }

    render() {
        return (
            <GiftWrapCartItem
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftWrapCartItemContainer);
