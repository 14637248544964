/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import {
    MAKECOMMERCE_METHOD_CODE,
    UI_MODE_INLINE
} from '../../config/MakeCommerce.config';
import {
    MakeCommercePaymentsType,
    MakeCommercePaymentUiType,
    MakeCommerceSelectedPaymentMethodType
} from '../../type/MakeCommerce.type';
import {
    convertPaymentMethodsToCountryGroups,
    convertPaymentMethodsToList
} from '../../util/MakeCommerce.util';
import MCGroupWidget from '../MCGroupWidget';
import MCPaymentMethod from '../MCPaymentMethod';
import MCTabbedWidget from '../MCTabbedWidget';

/** @namespace Scandiweb/MakeCommerce/Component/MCPaymentWrapper/Component */
export class MCPaymentWrapperComponent extends PureComponent {
    static propTypes = {
        ui: MakeCommercePaymentUiType.isRequired,
        selectedPaymentCode: PropTypes.string.isRequired,
        selectedMCPaymentMethod: MakeCommerceSelectedPaymentMethodType.isRequired,
        onMakeCommerceClick: PropTypes.func.isRequired,
        paymentMethods: MakeCommercePaymentsType.isRequired,
        quote_currency_code: PropTypes.string.isRequired
    };

    renderListItem = (item) => {
        const {
            selectedPaymentCode,
            selectedMCPaymentMethod: {
                name: selectedName,
                country: selectedCountry
            },
            onMakeCommerceClick
        } = this.props;
        const { name, country } = item;

        // vvv 1. any payment method inside makecommerce will have the `paymentCode`
        // vvv 2. payment methods don't have a unique name, so we check for both name, and country.
        const isSelected = selectedPaymentCode === MAKECOMMERCE_METHOD_CODE
                && selectedName === name
                && selectedCountry === country;

        return (
            <MCPaymentMethod
              key={ name + country }
              item={ item }
              isSelected={ isSelected }
              onMakeCommerceClick={ onMakeCommerceClick }
            />
        );
    };

    renderMethodsAsList() {
        const {
            paymentMethods,
            ui: {
                chorder
            }
        } = this.props;

        const list = convertPaymentMethodsToList(paymentMethods, chorder);

        return list && list.map(this.renderListItem);
    }

    renderSingleWidget(label, methods, isSelected, onMakeCommerceClick) {
        return (
            <MCGroupWidget
              key={ label }
              isRoot
              isSelected={ isSelected }
              isExpandable={ false }
              widgetLabel={ label }
              methods={ methods }
              onMakeCommerceClick={ onMakeCommerceClick }
            />
        );
    }

    renderCreditCardsWidget() {
        const {
            paymentMethods: { cards },
            selectedPaymentCode,
            selectedMCPaymentMethod: { countryLabel },
            onMakeCommerceClick,
            ui: {
                widgetGroupTitle: creditCardsGroupTitle
            }
        } = this.props;

        if (!cards) {
            return null;
        }

        const isSelected = selectedPaymentCode === MAKECOMMERCE_METHOD_CODE
            && countryLabel === creditCardsGroupTitle;

        return (
            <MCGroupWidget
              key={ creditCardsGroupTitle }
              isRoot
              isSelected={ isSelected }
              isExpandable={ false }
              widgetLabel={ creditCardsGroupTitle }
              methods={ cards }
              onMakeCommerceClick={ onMakeCommerceClick }
              selectedPaymentCode={ selectedPaymentCode }
            />
        );
    }

    renderWidget = ([key, methods], idx) => {
        const {
            selectedPaymentCode,
            selectedMCPaymentMethod: {
                countryLabel
            },
            onMakeCommerceClick
        } = this.props;
        const isSelected = selectedPaymentCode === MAKECOMMERCE_METHOD_CODE
            && key === countryLabel;

        if (!methods) {
            return null;
        }

        return (
            <MCGroupWidget
              key={ key }
              methods={ methods }
              widgetLabel={ key }
              isSelected={ isSelected }
              onMakeCommerceClick={ onMakeCommerceClick }
              selectedPaymentCode={ selectedPaymentCode }
              isDefaultSelected={ idx === 0 }
              isRoot
            />
        );
    };

    renderCountryGroupsWidgets() {
        const {
            ui: {
                chorder,
                widgetGroupcc: areCreditCardsGrouped
            },
            paymentMethods
        } = this.props;

        const widgets = convertPaymentMethodsToCountryGroups(paymentMethods, areCreditCardsGrouped, chorder);
        return Object.entries(widgets).map(this.renderWidget);
    }

    renderTabbedWidget() {
        const {
            ui: {
                chorder,
                widgetTitle,
                widgetGroupcc: areCreditCardsGrouped
            },
            paymentMethods,
            selectedPaymentCode,
            selectedMCPaymentMethod: {
                countryLabel
            },
            onMakeCommerceClick
        } = this.props;

        const widgets = convertPaymentMethodsToCountryGroups(paymentMethods, areCreditCardsGrouped, chorder);

        const isSelected = selectedPaymentCode === MAKECOMMERCE_METHOD_CODE
        && countryLabel === widgetTitle;

        return (
            <MCTabbedWidget
              key={ widgetTitle }
              widgets={ widgets }
              isSelected={ isSelected }
              onMakeCommerceClick={ onMakeCommerceClick }
              selectedPaymentCode={ selectedPaymentCode }
            />
        );
    }

    render() {
        const {
            ui: {
                mode,
                groupBanklinks: areCountriesGrouped,
                widgetGroupcc: areCreditCardsGrouped
            },
            quote_currency_code
        } = this.props;

        if (mode === UI_MODE_INLINE) {
            return this.renderMethodsAsList();
        }

        if (quote_currency_code === 'EUR') {
            return (
                <>
                    { areCreditCardsGrouped && this.renderCreditCardsWidget() }
                    { areCountriesGrouped && this.renderCountryGroupsWidgets() }
                    { !areCountriesGrouped && this.renderTabbedWidget() }
                </>
            );
        }

        return (null);
    }
}

export default MCPaymentWrapperComponent;
