/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    CheckoutDeliveryOptionContainer,
    mapDispatchToProps as originalMapDispatchToProps,
    mapStateToProps as originalMapStateToProps
} from 'Component/CheckoutDeliveryOption/CheckoutDeliveryOption.container';
import { noopFn } from 'Util/Common';

import { setSelectedMcShippingLocation } from '../../store/MakeCommerce/MakeCommerce.action';
import { MCDeliveryOptionComponent } from './MCDeliveryOption.component';

/** @namespace Scandiweb/MakeCommerce/Component/MCDeliveryOption/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...originalMapStateToProps(state),
    shippingLocations: state.MakeCommerceReducer.mcShippingLocations
});

/** @namespace Scandiweb/MakeCommerce/Component/MCDeliveryOption/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...originalMapDispatchToProps(dispatch),
    setSelectedMcShippingLocation: (location) => dispatch(setSelectedMcShippingLocation(location))
});

/** @namespace Scandiweb/MakeCommerce/Component/MCDeliveryOption/Container */
export class MCDeliveryOptionContainer extends CheckoutDeliveryOptionContainer {
    static propTypes = {
        ...super.propTypes,
        id: PropTypes.string.isRequired,
        countryId: PropTypes.string.isRequired,
        selectedMCParcelLocation: PropTypes.shape({}),
        setSelectedMCParcelLocation: PropTypes.func
    };

    static defaultProps = {
        ...super.defaultProps,
        shippingLocations: {},
        selectedMCParcelLocation: {},
        setSelectedMCParcelLocation: noopFn
    };

    containerProps() {
        const {
            id,
            shippingLocations,
            countryId,
            shipping_method,
            selectedMCParcelLocation,
            setSelectedMCParcelLocation
        } = this.props;

        return {
            ...super.containerProps(),
            id,
            shippingLocations,
            countryId,
            shipping_method,
            selectedMCParcelLocation,
            setSelectedMCParcelLocation
        };
    }

    // vvv override to set `hasDropdown` option before calling parent method.
    onOptionClick() {
        const { id, shippingLocations, setSelectedMcShippingLocation } = this.props;
        if (!shippingLocations || !shippingLocations[id]) {
            setSelectedMcShippingLocation({});
        }
        super.onOptionClick();
    }

    render() {
        return (
            <MCDeliveryOptionComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MCDeliveryOptionContainer);
