/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import {
    FieldOptionsType
} from 'Type/Field.type';

import './LocationFields.style';

/** @namespace Scandiweb/MakeCommerce/Component/LocationFields/Component */
export class LocationFieldsComponent extends PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        divisions: FieldOptionsType,
        areas: FieldOptionsType,
        handleDivisionChange: PropTypes.func.isRequired,
        handleAreaChange: PropTypes.func.isRequired
    };

    static defaultProps = {
        divisions: [],
        areas: []
    };

    renderDivisionField() {
        const {
            id,
            divisions,
            handleDivisionChange
        } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.select }
              attr={ {
                  id: `field-select-division-${id}`,
                  name: 'mcLocationGroupName',
                  noPlaceholder: true
              } }
              events={ {
                  onChange: handleDivisionChange
              } }
              options={ divisions }
            />
        );
    }

    renderAreaField() {
        const {
            id,
            handleAreaChange,
            areas
        } = this.props;

        if (!areas?.length) {
            return null;
        }

        return (
            <Field
              type={ FIELD_TYPE.select }
              attr={ {
                  id: `field-select-country-area-${id}`,
                  name: 'mcLocationCode',
                  noPlaceholder: true
              } }
              events={ {
                  onChange: handleAreaChange
              } }
              options={ areas }
            />
        );
    }

    render() {
        return (
            <div block="LocationFields">
                { this.renderDivisionField() }
                { this.renderAreaField() }
            </div>
        );
    }
}
