import ContentWrapper from 'Component/ContentWrapper';
import NoMatchHandler from 'Route/NoMatchHandler';

export const render = (args, callback, instance) => {
    const {
        seoConfigurationData: {
            product_markup_enabled = true
        } = {}
    } = instance.props;

    if (product_markup_enabled) {
        return callback(...args);
    }

    return (
        <NoMatchHandler>
            <main
              block="ProductPage"
              aria-label="Product page"
            >
                <ContentWrapper
                  wrapperMix={ { block: 'ProductPage', elem: 'Wrapper' } }
                  label={ __('Main product details') }
                >
                    { instance.renderProductPageContent() }
                </ContentWrapper>
                { instance.renderAdditionalSections() }
                { instance.renderReviewPopup() }
            </main>
        </NoMatchHandler>
    );
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            render
        }
    }
};
