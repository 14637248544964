/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageplazaSocialLogin/Query/GetSocialAuthResult/Query */
export class GetSocialAuthResultQuery {
    getQuery() {
        return new Field('getSocialAuthResult')
            .addFieldList(this._getChildFields());
    }

    _getChildFields() {
        return [
            'isLoggedIn',
            'isAuthorizedByExternalProvider'
        ];
    }
}

export default new GetSocialAuthResultQuery();
