/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { fetchQuery } from 'Util/Request';

import ShippingQuery from '../../query/Shipping.query';
import { setMcShippingLocations } from '../../store/MakeCommerce/MakeCommerce.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    return {
        ...callback(...args),
        setMcShippingLocations: (locations) => dispatch(setMcShippingLocations(locations))
    };
};

const fetchShippingConfig = async (instance) => {
    const {
        setMcShippingLocations
    } = instance.props;

    try {
        const {
            makeCommerceConfig: {
                shipping: mcShippingLocations
            }
        } = await fetchQuery(ShippingQuery.getShippingConfig());

        setMcShippingLocations(mcShippingLocations);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('>>> Failed to fetch MakeCommerce\'s shipping config', e);
    }
};

const addFetchShippingConfigToComponentDidMount = (args, callback, instance) => {
    callback(...args);
    fetchShippingConfig(instance);
};

const addLocationIdToCartTotals = (args, callback) => ([
    ...callback(...args),
    'location_id'
]);

export default {
    'Component/CheckoutDeliveryOptions/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/CheckoutDeliveryOptions/Container': {
        'member-function': {
            componentDidMount: addFetchShippingConfigToComponentDidMount
        }
    },
    'Query/Cart/Query': {
        'member-function': {
            _getCartTotalFields: addLocationIdToCartTotals
        }
    }
};
