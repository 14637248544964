/* eslint-disable max-lines */
import {
    ProductPriceComponent as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';
import { rearrangePriceString } from 'Util/Price';

import './ProductPrice.extended.style.scss';

/** @namespace Scandipwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    /**
     * Overriden price render funtions to rearrage price val and currency symbol
     */
    renderPrice(price, label) {
        const {
            discountPercentage
        } = this.props;

        const {
            value: priceValue,
            currency = 'EUR'
        } = price;

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        if ((!priceValue && priceValue !== 0)) {
            return null;
        }

        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                { this.renderPriceBadge(label) }
                <span
                  itemProp={ itemProp }
                  content={ content }
                  block="ProductPrice"
                  elem="PriceValue"
                >
                    { rearrangePriceString(priceValue, currency) }
                </span>
            </PriceSemanticElementName>
        );
    }

    renderSubPrice(price) {
        const {
            value: priceExclTax = 0,
            currency = 'EUR'
        } = price;

        if (!priceExclTax && priceExclTax !== 0) {
            return null;
        }

        return (
            <span
              aria-label={ __('Current product price excl. tax') }
              block="ProductPrice"
              elem="SubPrice"
            >
                { __('Excl. tax: %s', rearrangePriceString(priceExclTax, currency)) }
            </span>
        );
    }

    renderOldPrice() {
        const {
            price: {
                originalPrice: {
                    value: originalPriceValue,
                    currency = 'EUR'
                } = {}
            } = {},
            discountPercentage,
            isSchemaRequired,
            variantsCount
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
              itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
            >
                { rearrangePriceString(originalPriceValue, currency) }
            </del>
        );
    }

    renderRegularPrice(price) {
        const {
            value,
            valueFormatted,
            currency = 'EUR'
        } = price;

        if (!value || value <= 0 || !valueFormatted) {
            return null;
        }

        return (
            <del block="ProductPrice" elem="HighPrice">{ rearrangePriceString(value, currency) }</del>
        );
    }

    renderTierPrice() {
        const {
            tierPrice,
            price: {
                currency = 'EUR',
                finalPrice: {
                    valueFormatted = 0
                } = {}
            } = {}
        } = this.props;

        if (!tierPrice || tierPrice === valueFormatted) {
            return null;
        }

        return (
            <p block="ProductPrice" elem="TierPrice">
                { __('As low as') }
                <strong>{ ` ${rearrangePriceString(tierPrice, currency)}` }</strong>
            </p>
        );
    }
}

export default ProductPriceComponent;
