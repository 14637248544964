import { HtmlSitemapReducer } from '../store/HtmlSitemap';
import { SeoBaseReducer } from '../store/SeoBase';

export class HtmlSitemapReducerPlugin {
    aroundGetReducers = (args, callback, instance) => ({
        ...callback.apply(instance, args),
        HtmlSitemapReducer,
        SeoBaseReducer
    });
}

const { aroundGetReducers } = new HtmlSitemapReducerPlugin();

export const config = {
    'Store/Index/getStaticReducers': {
        function: [
            {
                position: 10,
                implementation: aroundGetReducers
            }
        ]
    }
};

export default config;
