/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { MAKECOMMERCE_METHOD_CODE } from '../../config/MakeCommerce.config';
import { setSelectedMcPaymentMethod } from '../../store/MakeCommerce/MakeCommerce.action';
import {
    MakeCommercePaymentCountryGroupsType,
    MakeCommercePaymentUiType,
    MakeCommerceSelectedPaymentMethodType,
    MakeCommerceSelectedPaymentMethodTypeDefaultValues
} from '../../type/MakeCommerce.type';
import MCTabbedWidgetComponent from './MCTabbedWidget.component';

/** @namespace Scandiweb/MakeCommerce/Component/MCTabbedWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    selectedMCPaymentMethod: state.MakeCommerceReducer.selectedMCPaymentMethod,
    ui: state.MakeCommerceReducer.ui
});

/** @namespace Scandiweb/MakeCommerce/Component/MCTabbedWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setSelectedMcPaymentMethod: (method) => dispatch(setSelectedMcPaymentMethod(method))
});

/** @namespace Scandiweb/MakeCommerce/Component/MCTabbedWidget/Container */
export class MCTabbedWidgetContainer extends PureComponent {
    static propTypes = {
        ui: MakeCommercePaymentUiType.isRequired,
        isSelected: PropTypes.bool.isRequired,
        selectedPaymentCode: PropTypes.string.isRequired,
        widgets: MakeCommercePaymentCountryGroupsType.isRequired,
        onMakeCommerceClick: PropTypes.func.isRequired,
        setSelectedMcPaymentMethod: PropTypes.func.isRequired,
        selectedMCPaymentMethod: MakeCommerceSelectedPaymentMethodType
    };

    static defaultProps = {
        selectedMCPaymentMethod: MakeCommerceSelectedPaymentMethodTypeDefaultValues
    };

    containerFunctions = {
        onClickHandler: this.onClickHandler.bind(this),
        onFlagClickHandler: this.onFlagClickHandler.bind(this),
        getDropdownOptions: this.getDropdownOptions.bind(this)
    };

    state = {
        selectedTab: ''
    };

    componentDidMount() {
        const { ui: { openDefault } } = this.props;

        this.resetSelectedTab();

        if (openDefault) {
            this.onClickHandler();
        }
    }

    containerProps() {
        const {
            ui,
            isSelected,
            widgets,
            selectedPaymentCode,
            onMakeCommerceClick
        } = this.props;

        const {
            selectedTab
        } = this.state;

        return {
            ui,
            selectedTab,
            isSelected,
            widgets,
            selectedPaymentCode,
            onMakeCommerceClick
        };
    }

    resetSelectedTab() {
        const { widgets } = this.props;
        const tabs = Object.keys(widgets);
        this.setState({ selectedTab: tabs[0] });
    }

    onClickHandler() {
        const {
            ui: {
                widgetTitle
            },
            setSelectedMcPaymentMethod,
            selectedPaymentCode,
            onMakeCommerceClick,
            widgets,
            selectedMCPaymentMethod: {
                countryLabel: selectedWidgetLabel
            }
        } = this.props;

        if (selectedPaymentCode !== MAKECOMMERCE_METHOD_CODE) {
            onMakeCommerceClick({ code: MAKECOMMERCE_METHOD_CODE });
        } else if (selectedWidgetLabel === widgetTitle) {
            return;
        }

        this.resetSelectedTab();
        const firstMethod = Object.values(widgets)[0][0];
        const { name, country, url } = firstMethod;
        setSelectedMcPaymentMethod({
            countryLabel: widgetTitle,
            name,
            country,
            url
        });
    }

    getDropdownOptions() {
        const {
            widgets
        } = this.props;

        if (!widgets) {
            return [];
        }

        return Object.keys(widgets).map((label) => ({
            id: label,
            value: label,
            label
        }));
    }

    onFlagClickHandler(selectedTab) {
        const {
            setSelectedMcPaymentMethod,
            widgets
        } = this.props;

        const {
            selectedTab: prevSelectedTab
        } = this.state;

        if (prevSelectedTab === selectedTab) {
            return;
        }

        const firstMethod = widgets[selectedTab][0];
        const { name, country, url } = firstMethod;
        setSelectedMcPaymentMethod({
            name,
            country,
            url
        });
        this.setState({ selectedTab });
    }

    render() {
        return (
            <MCTabbedWidgetComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MCTabbedWidgetContainer);
