import { REMOVE_Y_PRODUCT } from '../store/Cart/ChooseGift.action';

const removeYProduct = (args, callback) => {
    const result = callback(...args);
    const [state, action] = args;

    const {
        type
    } = action;

    if (type !== REMOVE_Y_PRODUCT) {
        return result;
    }

    return {
        ...result,
        cartTotals: {
            ...state.cartTotals,
            yProduct: null
        }
    };
};

const carryIsLoading = (args, callback) => {
    const result = callback(...args);
    const [, state] = args;
    result.isLoading = state.isLoading;
    return result;
};

export default {
    'Store/Cart/Reducer/CartReducer': {
        function: removeYProduct
    },
    'Store/Cart/Reducer/updateCartTotals': {
        function: carryIsLoading
    }
};
