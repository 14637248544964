export * from 'SourceUtil/Cart/Cart.js';

/** @namespace Scandipwa/Util/Cart/getCartItemsQtyInCart */
export const getCartItemsQtyInCart = (state) => {
    const quantityMap = {};
    const {
        CartReducer: {
            cartTotals: {
                items: cartItems = []
            } = {}
        } = {}
    } = state;

    cartItems.forEach((item) => {
        const { sku, qty, is_gift } = item;

        if (!sku) {
            return;
        }

        if (quantityMap[sku]) {
            quantityMap[sku] = {
                totalQty: quantityMap[sku].totalQty + qty,
                giftQty: is_gift ? quantityMap[sku].giftQty + qty : 0
            };
        } else {
            quantityMap[sku] = {
                totalQty: qty,
                giftQty: is_gift ? qty : 0
            };
        }
    });

    return quantityMap;
};
