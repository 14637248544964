import { PureComponent } from 'react';

import './CheckmarkIcon.style';

/** @namespace Scandipwa/Component/CheckmarkIcon/Component */
export class CheckmarkIconComponent extends PureComponent {
    render() {
        return (
            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" block="CheckmarkIcon">
                <g>
                    <path
                      d="M14.3333 7.05715V7.67048C14.3325 9.1081 13.867 10.5069
                    13.0062 11.6584C12.1454 12.8098 10.9355 13.6521
                    9.55691 14.0597C8.17829 14.4674 6.70484 14.4184
                    5.35631 13.9202C4.00779 13.422 2.85644 12.5012 2.07397 11.2952C1.29151 10.0892
                    0.919861 8.66253 1.01445 7.22803C1.10904 5.79353 1.6648
                    4.42803 2.59885 3.3352C3.5329 2.24236 4.79519 1.48074
                    6.19746 1.16391C7.59973 0.847089 9.06685 0.99204 10.38 1.57715"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                    />
                    <path
                      d="M14.3337 2.33716L7.66699 9.01049L5.66699 7.01049"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                    />
                </g>
                <defs>
                    <clipPath>
                        <rect width="16" height="16" />
                    </clipPath>
                </defs>
            </svg>
        );
    }
}

export default CheckmarkIconComponent;
