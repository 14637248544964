import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { toggleMyAccountOverlay } from 'Store/GMyAccountOverlay/GMyAccountOverlay.action';

import LoginButtonComponent from './LoginButton.component';

/** @namespace Stenders/LoginButton/Component/LoginButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    isSignedIn: state.MyAccountReducer.isSignedIn
});

/** @namespace Stenders/LoginButton/Component/LoginButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    toggleMyAccountOverlayState: (_state) => dispatch(toggleMyAccountOverlay())
});

/** @namespace Stenders/LoginButton/Component/LoginButton/Container */
export class LoginButtonContainer extends PureComponent {
    static propTypes = {
        'data-button-title': PropTypes.string.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        isMobile: PropTypes.bool.isRequired,
        toggleMyAccountOverlayState: PropTypes.func.isRequired
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            myAccountOverayState: false
        };
    }

    toggleMyAccountOverlayState() {
        const { myAccountOverayState: state } = this.state;

        this.setState({ myAccountOverayState: !state });
    }

    openMyAccountOverlay = () => {
        const {
            isSignedIn,
            toggleMyAccountOverlayState
        } = this.props;

        if (!isSignedIn) {
            toggleMyAccountOverlay();
            toggleMyAccountOverlayState();
        }
    };

    render() {
        const {
            'data-button-title': buttonTitle,
            isSignedIn,
            isMobile
        } = this.props;

        return (
            <LoginButtonComponent
              buttonTitle={ buttonTitle }
              openMyAccountOverlay={ this.openMyAccountOverlay }
              isSignedIn={ isSignedIn }
              isMobile={ isMobile }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginButtonContainer);
