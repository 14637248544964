/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getCustomerData } from '../data/customer';
import { getProductDetailsData } from '../data/product';
import { GTM_EVENT_KEY_PRODUCT_DETAIL } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/General/fireNotFoundEvent */
export const fireProductDetailsEvent = debounceCallback(async (product) => {
    const waitForEventsCanFireEvent = () => new Promise((resolve) => {
        const handleEventsCanFire = () => {
            document.removeEventListener('ProductCanFire', handleEventsCanFire);
            resolve();
        };

        document.addEventListener('ProductCanFire', handleEventsCanFire);
    });

    waitForEventsCanFireEvent().then(
        /** @namespace Scandiweb/Gtm/Event/Product/fireProductDetailsEvent/debounceCallback/waitForEventsCanFireEvent/then */
        async () => {
            const { customerId } = await getCustomerData();
            pushToDataLayer({
                event: GTM_EVENT_KEY_PRODUCT_DETAIL,
                customerId,
                ...await getProductDetailsData(product)
            });
        }
    );
});
