/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MakeCommerce/Query/PlaceOrder/Query */
export class PlaceOrderQuery {
    placeOrder(cartId, country, billingAddress, isGuest, guestEmail, additional_data, orderDetails) {
        return new Field('makeCommercePlaceOrder')
            .addArgument('cartId', 'String!', cartId)
            .addArgument('country', 'String', country)
            .addArgument('billingAddress', 'String', JSON.stringify(billingAddress))
            .addArgument('isGuest', 'Boolean', isGuest)
            .addArgument('guestEmail', 'String', guestEmail)
            .addArgument('additional_data', 'String', JSON.stringify(additional_data))
            .addArgument('orderDetails', 'String', orderDetails);
    }
}

export default new PlaceOrderQuery();
