/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

import CheckoutDeliveryOptionComponent from 'Component/CheckoutDeliveryOption/CheckoutDeliveryOption.component';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import LocationFields from 'Component/LocationFields';

import './MCDeliveryOption.style';

/** @namespace Scandiweb/MakeCommerce/Component/MCDeliveryOption/Component */
export class MCDeliveryOptionComponent extends CheckoutDeliveryOptionComponent {
    static propTypes = {
        ...this.propTypes,
        selectedMCParcelLocation: PropTypes.shape({}),
        setSelectedMCParcelLocation: PropTypes.func
    };

    renderRadioButton() {
        const {
            id,
            isSelected
        } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.radio }
              attr={ {
                  id: `option-${id}`,
                  name: `option-${id}`,
                  checked: !!isSelected
              } }
            />
        );
    }

    renderContentHeading() {
        return (
            <div block="CheckoutDeliveryOption" elem="MCShippingHeading">
                { this.renderRadioButton() }
                { this.renderRow() }
            </div>
        );
    }

    renderLocationFields() {
        const {
            id,
            shippingLocations,
            countryId,
            selectedMCParcelLocation,
            setSelectedMCParcelLocation
        } = this.props;

        if (!shippingLocations || !shippingLocations[id]) {
            return null;
        }

        return (
            <LocationFields
              locations={ shippingLocations[id].locations }
              id={ id }
              countryId={ countryId }
              selectedMCParcelLocation={ selectedMCParcelLocation }
              setSelectedMCParcelLocation={ setSelectedMCParcelLocation }
            />
        );
    }

    renderContentWithDropdownFields() {
        return (
            <div block="CheckoutDeliveryOption" elem="MCShippingWithDropdown">
                { this.renderContentHeading() }
            </div>
        );
    }

    renderContent() {
        const {
            id,
            shippingLocations
        } = this.props;

        if (!shippingLocations || !shippingLocations[id]) {
            return this.renderContentHeading();
        }

        return this.renderContentWithDropdownFields();
    }

    render() {
        const {
            option: {
                available,
                carrier_code = ''
            } = {},
            onOptionClick,
            isSelected
        } = this.props;

        if (carrier_code.includes('dpd')) {
            return (
                <li
                  block="CheckoutDeliveryOption"
                  mods={ {
                      isDisabled: !available,
                      isActive: isSelected,
                      isHoverExcluded: !available || isSelected,
                      isDPD: true
                  } }
                >
                    <div block="CheckoutDeliveryOption" elem="ButtonFieldsWrapper">
                        <button
                          block="CheckoutDeliveryOption"
                          mods={ { isDisabled: !available, isMakeCommerce: true } }
                          elem="Button"
                          type="button"
                          onClick={ onOptionClick }
                          disabled={ !available }
                        >
                            { this.renderContent() }
                        </button>
                        { isSelected && this.renderLocationFields() }
                    </div>
                    <div
                      block="CheckoutDeliveryOption"
                      elem="DPDInfo"
                    >
                        <div>
                            { __('Sustainable choice!') }
                        </div>
                        <div>
                            { __('Dpd compensates for 100% of the co2 produced in the delivery.') }
                        </div>
                    </div>
                </li>
            );
        }

        return (
            <li
              block="CheckoutDeliveryOption"
              mods={ {
                  isDisabled: !available,
                  isActive: isSelected,
                  isHoverExcluded: !available || isSelected
              } }
            >
                <button
                  block="CheckoutDeliveryOption"
                  mods={ { isDisabled: !available, isMakeCommerce: true } }
                  elem="Button"
                  type="button"
                  onClick={ onOptionClick }
                  disabled={ !available }
                >
                    { this.renderContent() }
                </button>
                { isSelected && this.renderLocationFields() }
            </li>
        );
    }
}

export default MCDeliveryOptionComponent;
