/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    Menu as SourceMenu,
    TYPE_CATEGORY,
    TYPE_CMS_PAGE
} from 'StendersUtil/Menu/Menu';

export * from 'StendersUtil/Menu/Menu';

/** @namespace Scandipwa/Util/Menu */
export class Menu extends SourceMenu {
    getMenuData({
        url,
        url_type,
        category_id,
        category_title,
        category_description,
        category_image,
        category_video,
        ...item
    }) {
        return {
            ...item,
            url: this.getMenuUrl({
                url,
                url_type,
                category_id,
                category_description,
                category_title,
                category_image,
                category_video
            }),
            children: {}
        };
    }

    getMenuUrl({
        url,
        url_type,
        category_id,
        category_description,
        category_title,
        category_image,
        category_video
    }) {
        switch (url_type) {
        case TYPE_CATEGORY:
            return {
                pathname: url,
                search: '',
                state: {
                    category: category_id,
                    categoryTitle: category_title,
                    categoryDescription: category_description,
                    categoryImage: category_image,
                    categoryVideo: category_video
                }
            };
        case TYPE_CMS_PAGE:
            return {
                pathname: url,
                search: '',
                state: { page: true }
            };
        default:
            return url;
        }
    }
}

export default new Menu();
