/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import MyAccountBreadcrumb from 'Component/MyAccountBreadcrumb';
import MyAccountOrderInformation from 'Component/MyAccountOrderInformation';
import MyAccountOrderTabs from 'Component/MyAccountOrderTabs';
import {
    MyAccountOrderComponent as StendersMyAccountOrderComponent
} from 'StendersComponent/MyAccountOrder/MyAccountOrder.component';

import {
    ORDER_REFUNDS
} from './MyAccountOrder.config';

import './MyAccountOrder.override.styles.scss';

/** @namespace Scandipwa/Component/MyAccountOrder/Component */
export class MyAccountOrderComponent extends StendersMyAccountOrderComponent {
    renderBreadCrumbs() {
        const {
            tabArray,
            showMenu,
            device: {
                IsMediumTablet,
                isMobile
            }
        } = this.props;

        if (!IsMediumTablet && !isMobile) {
            return null;
        }

        if (!tabArray.length) {
            return (
                <div block="MyAccount" elem="BreadcrumbsWrapper">
                    <ul block="MyAccount" elem="Breadcrumbs">
                        <li block="MyAccount" elem="Breadcrumb">
                            { __('My Account') }
                        </li>
                    </ul>
                </div>
            );
        }

        return (
            <div block="MyAccount" elem="BreadcrumbsWrapper">
                <ul block="MyAccount" elem="Breadcrumbs">
                    <li
                      block="MyAccount"
                      elem="Breadcrumb"
                      onClick={ showMenu }
                      onKeyDown={ showMenu }
                    >
                        { __('My Account') }
                    </li>
                    { tabArray.map((tab, index) => (this.renderMyAccountBreadcrumb(tab, index))) }
                </ul>
            </div>
        );
    }

    renderOrderInformation() {
        const {
            order,
            activeTab,
            activeOrderInformationTab,
            handleInformationTabClick
        } = this.props;

        if (activeTab === ORDER_REFUNDS) {
            return null;
        }

        return (
            <MyAccountOrderInformation
              order={ order }
              activeOrderInformationTab={ activeOrderInformationTab }
              handleInformationTabClick={ handleInformationTabClick }
            />
        );
    }

    renderActions() {
        const {
            handleChangeActiveTab,
            activeTab
        } = this.props;

        return (
            <div block="MyAccountOrder" elem="Actions">
                <div block="MyAccountOrder" elem="Buttons">
                    { this.renderBaseInfo() }
                    <div block="MyAccountOrder" elem="ButtonsWrapper">
                        { this.renderReorderButton() }
                        { this.renderSubscriptionButton() }
                        { this.renderPrintOrder() }
                    </div>
                </div>
                { this.renderBreadCrumbs() }
                { this.renderOrderComments() }
                <MyAccountOrderTabs
                  tabs={ this.shouldTabsRender() }
                  handleChangeActiveTab={ handleChangeActiveTab }
                  activeTab={ activeTab }
                />
                { this.renderPrintAllAction() }
            </div>
        );
    }

    renderMyAccountBreadcrumb(tabName, index) {
        const {
            tabArray,
            goToOrdersPage,
            removeTab
        } = this.props;

        const isSelected = index === tabArray.length - 1;

        if (index === 0) {
            return (
                <MyAccountBreadcrumb
                  tab={ tabName }
                  key={ tabName }
                  index={ index }
                  removeTab={ goToOrdersPage }
                  isSelected={ isSelected }
                />
            );
        }

        return (
            <MyAccountBreadcrumb
              tab={ tabName }
              key={ tabName }
              index={ index }
              removeTab={ removeTab }
              isSelected={ isSelected }
            />
        );
    }
}

export default MyAccountOrderComponent;
