export const SET_CP_DATA = 'SET_CP_DATA';
export const SET_CP_CHOOSE_POPUP = 'SET_CP_CHOOSE_POPUP';
export const SET_CP_CLOSE = 'SET_CP_CLOSE';
export const SET_CP_CURRENT = 'SET_CP_CURRENT';

/** @namespace Stenders/CustomPopup/Store/CustomPopup/Action/setPopupsData */
export const setPopupsData = (popups) => ({
    type: SET_CP_DATA,
    popups
});

/** @namespace Stenders/CustomPopup/Store/CustomPopup/Action/setIsChoosePopup */
export const setIsChoosePopup = (isChoosePopup) => ({
    type: SET_CP_CHOOSE_POPUP,
    isChoosePopup
});

/** @namespace Stenders/CustomPopup/Store/CustomPopup/Action/setIsPopupClose */
export const setIsPopupClose = (isPopupClose) => ({
    type: SET_CP_CLOSE,
    isPopupClose
});

/** @namespace Stenders/CustomPopup/Store/CustomPopup/Action/setCurrentPopup */
export const setCurrentPopup = (currentPopup) => ({
    type: SET_CP_CURRENT,
    currentPopup
});
