import { connect } from 'react-redux';

import {
    LinkContainer as SourceLinkContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/Link/Link.container';
import { RefType } from 'Type/Common.type';

export * from 'SourceComponent/Link/Link.container';

/** @namespace Scandipwa/Component/Link/Container */
export class LinkContainer extends SourceLinkContainer {
    static propTypes = {
        ...super.propTypes,
        linkRef: RefType
    };

    static defaultProps = {
        ...super.defaultProps,
        linkRef: undefined
    };

    containerProps() {
        const { linkRef } = this.props;

        return {
            ...super.containerProps(),
            linkRef
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkContainer);
