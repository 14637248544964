import TextPlaceholder from 'Component/TextPlaceholder';

const renderName = (args, callback, instance) => {
    const { product: { name, product_seo_name }, use_product_seo_name } = instance.props;
    const productName = use_product_seo_name ? product_seo_name || name : name;

    return (
            <h1 block="ProductActions" elem="Title" itemProp="name">
                <TextPlaceholder content={ productName } length="medium" />
            </h1>
    );
};

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderName
        }
    }
};
