import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import StendersWidgetComponent from './StendersWidget.component';

/** @namespace Stenders/Widget/Component/StendersWidget/Container/mapStateToProps */
export const mapStateToProps = (_) => ({
});

/** @namespace Stenders/Widget/Component/StendersWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = (_) => ({
});

/** @namespace Stenders/Widget/Component/StendersWidget/Container */
export class StendersWidgetContainer extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        image1: PropTypes.string.isRequired,
        image2: PropTypes.string.isRequired
    };

    containerProps() {
        const {
            title,
            description,
            link,
            image1,
            image2
        } = this.props;

        return {
            title,
            description,
            link,
            image1,
            image2
        };
    }

    render() {
        return (
            <StendersWidgetComponent { ...this.containerProps() } />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StendersWidgetContainer);
