/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import OrderQuery from '../query/Order.query';

const GIFT_WRAP_PRICE_LINE_KEY = 'giftWrapPriceLine';
const POSTCARD_PRICE_LINE_KEY = 'postcardPriceLine';

const renderGiftWrapSectionAfterSummary = (args, callback, instance) => {
    const {
        total: {
            mpGiftWrap
        }
    } = instance.props;

    const postcardAmountValue = mpGiftWrap?.postcardAmount?.value || 0;
    const postcardAmountCurrency = mpGiftWrap?.postcardAmount?.currency || null;
    const giftWrapAmountValue = mpGiftWrap?.giftWrapAmount?.value || 0;
    const giftWrapAmountCurrency = mpGiftWrap?.giftWrapAmount?.currency || null;

    return (
        <>
         { callback(...args) }
         { /* vvv We use !! to prevent rendering the 0 */ }
         { !!giftWrapAmountValue
             && instance.renderPriceLine(
                 __('Gift Wrap'),
                 giftWrapAmountValue,
                 giftWrapAmountCurrency,
                 {},
                 GIFT_WRAP_PRICE_LINE_KEY
             ) }
         { /* vvv We use !! to prevent rendering the 0 */ }
         { !!postcardAmountValue
             && instance.renderPriceLine(
                 __('Postcard'),
                 postcardAmountValue,
                 postcardAmountCurrency,
                 {},
                 POSTCARD_PRICE_LINE_KEY
             ) }
        </>
    );
};

const addGiftWrapFields = (args, callback) => ([
    ...callback(...args),
    OrderQuery.getMPGiftWrapField()
]);

export default {
    'Component/MyAccountOrderTotals/Component': {
        'member-function': {
            renderDiscounts: renderGiftWrapSectionAfterSummary
        }
    },
    'Query/Order/Query': {
        'member-function': {
            _getOrderItemTotalFields: addGiftWrapFields
        }
    }
};
