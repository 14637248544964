/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const ANIMATION_DURATION = 300;
export const AUTO_SLIDE_INTERVAL = 3000;
export const HEIGHT_TRANSITION_SPEED_ON_MOUNT = 300;
export const ACTIVE_SLIDE_PERCENT = 0.1;
export const NUDGE_ANIMATION_DURATION = 1000;
export const NUDGE_TRANSLATE_X = 10;
export const DRAGGABLE_WIDTH_FRACTION = 0.75;
