/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    createContext, useEffect, useState
} from 'react';
import { useDispatch } from 'react-redux';

import { ChildrenType } from 'SourceType/Common.type';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActivePopup } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { prepareQuery } from 'Util/Query';
import {
    executeGet,
    fetchMutation,
    getErrorMessage
} from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import {
    GDPR_COOKIES_DETAILS_MODAL_POPUP_KEY,
    GDPR_POPUP_KEY,
    GDPR_SETTINGS_MODAL_POPUP_KEY,
    MESSAGE_ANONYMIZED_SUCCESS,
    MESSAGE_CSV_DOWNLOAD_SUCCESS,
    MESSAGE_DELETE_SUCCESS,
    MESSAGE_GDPR_NOT_ALLOWED
} from '../config';
import ActionsQuery from '../query/Actions.query';
import CheckScopeQuery from '../query/CheckScope.query';
import CookiesSettingsQuery from '../query/CookiesSettings.query';
import PolicyRestrictionQuery from '../query/PolicyRestriction.query';
import PrivacySettingsQuery from '../query/PrivacySettings.query';
import {
    checkUserAcceptanceInBrowserDB,
    hasAmCookie,
    storeUserAcceptanceInBrowserDB,
    updateLogStatusForCheckboxesInBrowserDB,
    updateUserAcceptanceToBrowserDB
} from '../util/GDPR.util';

export const GDPRContext = createContext({
});

/** @namespace Scandiweb/AmastyGdpr/Context/GDPR/Context/GDPRProvider */
export const GDPRProvider = ({ children }) => {
    const dispatch = useDispatch();
    /** vvv context state */
    const [isExtensionAllowed, setIsExtensionAllowed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [groupData, setGroupData] = useState([]);
    const [gdprSelectedGroupNames, setGdprSelectedGroupNames] = useState([]);
    const [privacySettings, setPrivacySettings] = useState([]);
    const [isCookiesBarVisible, setIsCookiesBarVisible] = useState(false);
    const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
    const [isCookiesDetailsModalVisible, setIsCookiesDetailsModalVisible] = useState(false);
    const [selectedCookiesDetails, setSelectedCookiesDetails] = useState({});
    /** ^^^ context state */

    /**
     * fetching initial data
     * {
     *   noPolicyRestriction -> if the GDPR extension should be enabled,
     *   privacySettings -> settings for MyAccount's PrivacyPageSettings
     *   cookieSettings -> cookies group data for cookies bar
     * }
     */
    const fetchInitialData = async () => {
        setIsLoading(true);

        const {
            gdpr: {
                noPolicyRestriction,
                cookieSettings,
                privacySettings
            }
        } = await executeGet(
            prepareQuery([
                PolicyRestrictionQuery.getData(),
                CookiesSettingsQuery.getData(),
                PrivacySettingsQuery.getData()]),
            'gdpr',
            ONE_MONTH_IN_SECONDS
        );

        if (!noPolicyRestriction) {
            // vvv warning developers why the extension will not show up.
            // eslint-disable-next-line no-console
            console.warn(MESSAGE_GDPR_NOT_ALLOWED);
            setIsExtensionAllowed(false);
            setIsLoading(false);
            return;
        }

        const { groupData } = cookieSettings;
        setGroupData(groupData);
        setPrivacySettings(privacySettings);
        setIsLoading(false);
        setIsExtensionAllowed(true);

        if (checkUserAcceptanceInBrowserDB() && hasAmCookie()) {
            return;
        }

        setIsCookiesBarVisible(true);
    };

    /** componentDidMount */
    useEffect(() => {
        fetchInitialData();
    }, []);

    /** handles checkbox checked change */
    const handleOnChangeChecked = (name) => {
        const idx = groupData.findIndex(({ name: groupName }) => groupName === name);
        const newGroupData = groupData;
        newGroupData[idx].checked = !newGroupData[idx].checked;
        setGroupData(newGroupData);
    };

    const handleAcceptCookies = async () => {
        await fetchMutation(ActionsQuery.acceptCookies());
        setIsCookiesBarVisible(false);
        storeUserAcceptanceInBrowserDB();
    };

    const handleDeclineCookies = () => {
        setIsCookiesBarVisible(false);
    };

    // this function checks if there is checkbox enabled for
    // this scope i.e. 'registration', 'checkout',...
    // vvv
    const handleCheckScope = async (scope = null) => {
        try {
            const { gdprCheckScope } = await executeGet(
                prepareQuery([CheckScopeQuery.getCheckScopeQuery(scope)]),
                'gdprCheckScope',
                ONE_MONTH_IN_SECONDS
            );

            return gdprCheckScope;
        } catch {
            return null;
        }
    };

    const handleAnonymiseData = async () => {
        try {
            await fetchMutation(ActionsQuery.anonymiseData());
            dispatch(showNotification('success', MESSAGE_ANONYMIZED_SUCCESS));
        } catch (e) {
            dispatch(showNotification('error', getErrorMessage(e)));
        }
    };

    const handleDownloadPersonalData = async (orderId = null) => {
        try {
            const { gdprDownload } = await fetchMutation(ActionsQuery.downloadCookiesLog(orderId));

            dispatch(showNotification('success', MESSAGE_CSV_DOWNLOAD_SUCCESS));

            return gdprDownload;
        } catch {
            dispatch(showNotification('error', __('An error has occurred!')));

            return null;
        }
    };

    const handleDeleteRequest = async () => {
        try {
            await fetchMutation(ActionsQuery.deleteCookies());
            dispatch(showNotification('success', MESSAGE_DELETE_SUCCESS));
        } catch (e) {
            dispatch(showNotification('error', getErrorMessage(e)));
        }
    };

    const handleUpdateSelectedCookies = () => {
        const listNames = groupData.reduce((acc, { checked, name }) => {
            if (!checked) {
                return acc;
            }

            acc.push(name);

            return acc;
        }, []);

        setGdprSelectedGroupNames(listNames);
        updateUserAcceptanceToBrowserDB(listNames, gdprSelectedGroupNames);

        const cookiesString = groupData.reduce((acc, { checked, groupId }) => {
            if (checked) {
                acc.push(groupId);
            }

            return acc;
        }, []).toString();

        try {
            fetchMutation(ActionsQuery.updateAcceptedCookies(cookiesString));
            listNames.forEach((name) => updateLogStatusForCheckboxesInBrowserDB(name));
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    };

    const showCookiesBar = () => {
        dispatch(showPopup(GDPR_POPUP_KEY));
    };

    const dismissCookiesBar = () => {
        setIsCookiesBarVisible(false);
        dispatch(hideActivePopup(GDPR_POPUP_KEY));
    };

    const showSettingsModal = () => {
        setIsSettingsModalVisible(true);
        dispatch(showPopup(GDPR_SETTINGS_MODAL_POPUP_KEY));
    };

    const dismissSettingsModal = () => {
        setIsSettingsModalVisible(false);
        dispatch(hideActivePopup(GDPR_SETTINGS_MODAL_POPUP_KEY));
    };

    const showCookiesDetailsModal = (selectedGroup) => {
        const { name: title } = selectedGroup;
        setSelectedCookiesDetails(selectedGroup);
        setIsCookiesDetailsModalVisible(true);
        dispatch(
            showPopup(
                GDPR_COOKIES_DETAILS_MODAL_POPUP_KEY,
                { title }
            )
        );
    };

    const dismissCookiesDetailsModal = () => {
        setIsCookiesDetailsModalVisible(false);
        dispatch(hideActivePopup(GDPR_COOKIES_DETAILS_MODAL_POPUP_KEY));
    };

    const value = {
        // vvv state
        isLoading,
        isCookiesBarVisible,
        isSettingsModalVisible,
        isCookiesDetailsModalVisible,
        isExtensionAllowed,
        selectedCookiesDetails,
        setSelectedCookiesDetails,
        groupData,
        privacySettings,
        gdprSelectedGroupNames,

        // vvv actions
        handleCheckScope,
        handleOnChangeChecked,
        handleAcceptCookies,
        handleDeclineCookies,
        handleAnonymiseData,
        handleDeleteRequest,
        handleDownloadPersonalData,
        handleUpdateSelectedCookies,

        // vvv popups
        showCookiesBar,
        dismissCookiesBar,
        showSettingsModal,
        dismissSettingsModal,
        showCookiesDetailsModal,
        dismissCookiesDetailsModal
    };

    return (
        <GDPRContext.Provider value={ value }>
            { children }
        </GDPRContext.Provider>
    );
};

GDPRContext.displayName = 'GDPRContext';
GDPRProvider.displayName = 'GDPRProvider';
GDPRProvider.propTypes = {
    children: ChildrenType.isRequired
};
