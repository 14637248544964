/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { noopFn } from 'Util/Common';

import {
    MFGiftWrapItemType,
    MPGiftWrapConfigType
} from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/type/GiftWrap';
import {
    createGiftWrapObject
} from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/util/GiftWrap';
import GiftWrapCheckoutShippingSection from './GiftWrapCheckoutSection.component';

/** @namespace Scandipwa/Component/GiftWrapCheckoutSection/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    mpGiftWrap: state.ConfigReducer.mpGiftWrap,
    currencyCode: state.CartReducer.cartTotals.quote_currency_code,
    giftWrapMessageFee: state.ConfigReducer.mpGiftWrap?.giftMessageFee
});

/** @namespace Scandipwa/Component/GiftWrapCheckoutSection/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandipwa/Component/GiftWrapCheckoutSection/Container */
export class GiftWrapCheckoutSectionContainer extends PureComponent {
    static propTypes = {
        mpGiftWrap: MPGiftWrapConfigType.isRequired,
        giftWrapData: MFGiftWrapItemType.isRequired,
        giftWrapPostcardData: MFGiftWrapItemType.isRequired,
        currencyCode: PropTypes.string,
        giftWrapMessageFee: PropTypes.number.isRequired,
        popupId: PropTypes.string.isRequired,
        onGiftWrapChange: PropTypes.func
    };

    static defaultProps = {
        currencyCode: null,
        onGiftWrapChange: noopFn
    };

    containerFunctions = {};

    containerProps() {
        const {
            giftWrapData = {},
            giftWrapPostcardData = {},
            currencyCode,
            giftWrapMessageFee,
            mpGiftWrap,
            popupId,
            onGiftWrapChange
        } = this.props;

        return {
            giftWrapData: createGiftWrapObject('giftWrap', giftWrapData),
            postcardData: createGiftWrapObject('postcard', giftWrapPostcardData),
            currencyCode,
            giftWrapMessageFee,
            mpGiftWrap,
            popupId,
            onGiftWrapChange
        };
    }

    render() {
        return (
            <GiftWrapCheckoutShippingSection
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftWrapCheckoutSectionContainer);
