/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    PAYPAL_BILLING_AGREEMENT_PAYMENT_METHOD_CODE
} from '../../component/PaypalSmartButtons/PaypalSmartButtons.config';

const addPayPalBillingAgreementToPaymentData = (args, callback, instance) => {
    const { paymentMethod: code } = instance.state;
    const [fields] = args;
    const { ba_agreement_id } = fields || {};

    if (code !== PAYPAL_BILLING_AGREEMENT_PAYMENT_METHOD_CODE) {
        return callback(...args);
    }

    return {
        code,
        additional_data: {
            ba_agreement_id
        }
    };
};

// vvv fixes the binding issue in the CheckoutBilling component
const fixBindingForSetOrderButtonVisibility = (args, callback, instance) => {
    callback(...args);

    // eslint-disable-next-line no-param-reassign
    instance.setOrderButtonVisibility = instance.setOrderButtonVisibility.bind(instance);
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            _getPaymentData: addPayPalBillingAgreementToPaymentData
        }
    },
    'Scandipwa/Component/CheckoutBilling/Container': {
        'member-function': {
            _getPaymentData: addPayPalBillingAgreementToPaymentData
        }
    },
    'Component/CheckoutBilling/Component': {
        'member-function': {
            __construct: fixBindingForSetOrderButtonVisibility
        }
    },
    'Scandipwa/Component/CheckoutBilling/Component': {
        'member-function': {
            __construct: fixBindingForSetOrderButtonVisibility
        }
    }
};
