/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { MAKECOMMERCE_METHOD_CODE } from '../../config/MakeCommerce.config';
import { setSelectedMcPaymentMethod } from '../../store/MakeCommerce/MakeCommerce.action';
import {
    MakeCommercePaymentType,
    MakeCommercePaymentUiType,
    MakeCommerceSelectedPaymentMethodType
} from '../../type/MakeCommerce.type';
import MCGroupWidgetComponent from './MCGroupWidget.component';

/** @namespace Scandiweb/MakeCommerce/Component/MCGroupWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    selectedMCPaymentMethod: state.MakeCommerceReducer.selectedMCPaymentMethod,
    ui: state.MakeCommerceReducer.ui
});

/** @namespace Scandiweb/MakeCommerce/Component/MCGroupWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setSelectedMcPaymentMethod: (method) => dispatch(setSelectedMcPaymentMethod(method))
});

/** @namespace Scandiweb/MakeCommerce/Component/MCGroupWidget/Container */
export class MCGroupWidgetContainer extends PureComponent {
    static propTypes = {
        ui: MakeCommercePaymentUiType.isRequired,
        methods: PropTypes.arrayOf(MakeCommercePaymentType),
        selectedPaymentCode: PropTypes.string.isRequired,
        selectedMCPaymentMethod: MakeCommerceSelectedPaymentMethodType.isRequired,
        onMakeCommerceClick: PropTypes.func.isRequired,
        isSelected: PropTypes.bool.isRequired,
        isRoot: PropTypes.bool,
        widgetLabel: PropTypes.string.isRequired,
        setSelectedMcPaymentMethod: PropTypes.func.isRequired,
        isDefaultSelected: PropTypes.bool
    };

    static defaultProps = {
        isRoot: false,
        isDefaultSelected: false,
        methods: []
    };

    containerFunctions = {
        onClickHandler: this.onClickHandler.bind(this)
    };

    componentDidMount() {
        const {
            ui: { openDefault },
            isDefaultSelected
        } = this.props;

        if (openDefault && isDefaultSelected) {
            this.onClickHandler();
        }
    }

    containerProps() {
        const {
            ui,
            isSelected,
            isRoot,
            widgetLabel,
            methods,
            selectedMCPaymentMethod,
            selectedPaymentCode,
            onMakeCommerceClick
        } = this.props;

        return {
            ui,
            isSelected,
            isRoot,
            widgetLabel,
            methods,
            selectedMCPaymentMethod,
            selectedPaymentCode,
            onMakeCommerceClick
        };
    }

    onClickHandler() {
        const {
            setSelectedMcPaymentMethod,
            selectedPaymentCode,
            onMakeCommerceClick,
            widgetLabel,
            methods: [firstMethod],
            selectedMCPaymentMethod: {
                countryLabel: selectedWidgetLabel
            }
        } = this.props;

        if (selectedPaymentCode !== MAKECOMMERCE_METHOD_CODE) {
            onMakeCommerceClick({ code: MAKECOMMERCE_METHOD_CODE });
        } else if (selectedWidgetLabel === widgetLabel) {
            return;
        }

        const { name, country, url } = firstMethod;
        setSelectedMcPaymentMethod({
            countryLabel: widgetLabel,
            name,
            country,
            url
        });
    }

    render() {
        return (
            <MCGroupWidgetComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MCGroupWidgetContainer);
