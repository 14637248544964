/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { fetchQuery } from 'Util/Request';

import PaypalBillingAgreementsQuery from '../../query/PaypalBillingAgreements.query';
import PaypalBillingAgreements from './PaypalBillingAgreements.component';
import { BILLING_AGREEMENT_STATUS_ACTIVE } from './PaypalBillingAgreements.config';

/** @namespace Scandiweb/PaypalExpress/Component/PaypalBillingAgreements/Container */
export class PaypalBillingAgreementsContainer extends PureComponent {
    static propTypes = {
        setOrderButtonEnableStatus: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleAgreementSelect: this.handleAgreementSelect.bind(this)
    };

    componentDidMount() {
        const { setOrderButtonEnableStatus } = this.props;

        setOrderButtonEnableStatus(false);
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            isLoading: true,
            billingAgreementId: '',
            billingAgreements: []
        };

        this.fetchBillingAgreements();
    }

    async fetchBillingAgreements() {
        const {
            paypalBillingAgreementsPage: {
                billingAgreements
            } = {}
        } = await fetchQuery(PaypalBillingAgreementsQuery.getQuery());

        this.setState({
            isLoading: false,
            billingAgreements
        });
    }

    handleAgreementSelect(billingAgreementId) {
        const { setOrderButtonEnableStatus } = this.props;

        this.setState({ billingAgreementId });
        setOrderButtonEnableStatus(!!billingAgreementId);
    }

    getBillingAgreementOptions() {
        const { billingAgreements } = this.state;

        const selectOptions = billingAgreements
            // vvv filter billing agreements, so we only have active ones as an option
            .filter(({ status }) => status === BILLING_AGREEMENT_STATUS_ACTIVE)
            .map(({ id, reference_id }) => {
                const option = {
                    id: reference_id,
                    name: reference_id,
                    value: id,
                    label: reference_id
                };

                return option;
            });

        return selectOptions;
    }

    containerProps() {
        const {
            isLoading,
            billingAgreementId
        } = this.state;

        return {
            isLoading,
            billingAgreementId,
            selectOptions: this.getBillingAgreementOptions()
        };
    }

    render() {
        return (
            <PaypalBillingAgreements
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default PaypalBillingAgreementsContainer;
