import { connect } from 'react-redux';

import { CUR_CURRENCY } from 'Component/CurrencySwitcher/CurrencySwitcher.config';
import { DEFAULT_CURRENCY } from 'Component/StoreSwitcher/StoreSwitcher.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    RouterContainer as StendersRouterContainer
} from 'StendersComponent/Router/Router.container';
import { ConfigDispatcher } from 'Store/Config/Config.dispatcher';
import {
    isMobile,
    isMobileClientHints,
    isUsingClientHints
} from 'Util/Mobile';

import {
    DEFAULT_FONT_SIZE,
    FONT_SIZE_PERCENTAGE,
    MAX_WIDE_SCREEN_RESOLUTION,
    WIDE_SCREEN_RESOLUTION
} from './Router.config';

export {
    mapStateToProps
};

/** @namespace Scandipwa/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateCurrency: (options) => ConfigDispatcher.updateCurrency(dispatch, options)
});

/** @namespace Scandipwa/Component/Router/Container */
export class RouterContainer extends StendersRouterContainer {
    state = {
        ...this.state,
        isBeforeNarrowDesktop: false,
        width: window.innerWidth
    };

    updateWidth() {
        this.setState({ width: window.innerWidth });
    }

    /**
     * Overriden to add a function that makes font size larger on wider screens
     */
    async handleResize() {
        const { updateConfigDevice } = this.props;

        if (isUsingClientHints) {
            const { platform, model } = await isMobileClientHints.getDeviceData();
            updateConfigDevice({
                isMobile: isMobile.any(),
                android: isMobile.android(platform),
                ios: isMobile.iOS(platform),
                blackberry: isMobile.blackBerry(model),
                opera: isMobile.opera(model),
                safari: isMobile.safari(model),
                windows: isMobile.windows(model),
                isDesktopTable: window.matchMedia('(min-width: 744px)').matches,
                IsMediumTablet: window.matchMedia('(max-width: 960px)').matches
            });
        } else {
            updateConfigDevice({
                isMobile: isMobile.any(),
                android: isMobile.android(),
                ios: isMobile.iOS(),
                blackberry: isMobile.blackBerry(),
                opera: isMobile.opera(),
                safari: isMobile.safari(),
                windows: isMobile.windows(),
                isDesktopTable: window.matchMedia('(min-width: 744px)').matches,
                IsMediumTablet: window.matchMedia('(max-width: 960px)').matches
            });
        }

        if (window.innerWidth > WIDE_SCREEN_RESOLUTION) {
            if (window.innerWidth > MAX_WIDE_SCREEN_RESOLUTION) {
                const fontCoef = window.innerWidth / MAX_WIDE_SCREEN_RESOLUTION;
                const fontSize = Math.floor(FONT_SIZE_PERCENTAGE * fontCoef);
                document.documentElement.style.setProperty('--root-font-size', `${fontSize}%`);
            } else {
                const fontCoef = window.innerWidth / WIDE_SCREEN_RESOLUTION;
                const fontSize = Math.floor(FONT_SIZE_PERCENTAGE * fontCoef);
                document.documentElement.style.setProperty('--root-font-size', `${fontSize}%`);
            }
        } else {
            document.documentElement.style.setProperty('--root-font-size', `${DEFAULT_FONT_SIZE}px`);
        }
    }

    /**
     * Overridden to pass isCheckout prop when pathname includes checkout
     */
    containerProps() {
        const {
            device: { isMobile },
            storeCode,
            pageType
        } = this.props;

        const { isBeforeNarrowDesktop, width } = this.state;

        const { pathname } = location;
        const isCheckout = pathname.includes(CHECKOUT_URL);

        return {
            ...super.containerProps(),
            isCheckout,
            isMobile,
            storeCode,
            pageType,
            isBeforeNarrowDesktop,
            width
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        updateTargetCurrency: this.updateTargetCurrency.bind(this)
    };

    componentDidMount() {
        const { pathname } = location;

        super.componentDidMount();
        this.updateTargetCurrency(pathname);
        window.addEventListener('resize', this.updateWidth.bind(this));
    }

    componentDidUpdate(prevProps) {
        const { isLoading, updateMeta } = this.props;
        const { isLoading: prevIsLoading } = prevProps;
        const { pathname } = location;
        const { currentUrl } = this.state;

        if (pathname !== currentUrl) {
            this.setState({ currentUrl: pathname });
        }

        if (!this.handleCheckIfOnlyMainItemsRender()) {
            this.setRenderAllItems();
        } else {
            this.setRenderOnlyMainItems();
        }

        if (!isLoading && isLoading !== prevIsLoading) {
            const {
                description,
                default_description,
                keywords,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code,
                canonical_url,
                robots
            } = this.props;

            const { value: metaTitle = meta_title } = meta_title;

            updateMeta({
                default_title,
                title: metaTitle || meta_title || default_title,
                default_description,
                description: description || default_description,
                default_keywords,
                keywords: keywords || default_keywords,
                title_prefix,
                title_suffix,
                status_code,
                canonical_url,
                robots
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWidth.bind(this));
    }

    async updateTargetCurrency(pathname) {
        const { updateCurrency } = this.props;

        // default currency store list
        const targetStoreList = ['de', 'lv', 'ru'];
        const currentStoreCode = pathname.split('/')[1];
        const currentCurrencyCode = sessionStorage.getItem(CUR_CURRENCY);

        if (currentStoreCode
            && targetStoreList.includes(currentStoreCode)
            && currentCurrencyCode !== DEFAULT_CURRENCY
        ) {
            await updateCurrency({ DEFAULT_CURRENCY });
            await sessionStorage.setItem(CUR_CURRENCY, DEFAULT_CURRENCY);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
