import { STENDERS_WIDGET_CMS_TYPE } from '../component/StendersWidget.config';
import StendersWidgetContainer from '../component/StendersWidget.container';

/** @namespace OrderbySku/Plugin/AddOrderBySKUWidgets/addOrderBySKUWidgets */
export const addWidget = (originalMember) => ({
    ...originalMember,
    [STENDERS_WIDGET_CMS_TYPE]: {
        component: StendersWidgetContainer
    }
});

export default {
    'Scandipwa/Component/WidgetFactory/Component': {
        'member-property': {
            renderMap: addWidget
        }
    }
};
