import { Field } from 'Util/Query';

export function getCanonical() {
    return new Field('mw_canonical_url').addFieldList(['url', 'code']);
}
const getHreflangTagsItems = () => new Field('items').addFieldList(['url', 'code']);

export function getHreflangTags() {
    return new Field('mw_hreflangs').addFieldList([getHreflangTagsItems()]);
}

const _getDefaultFields = (args, callback) => [
    ...callback(...args),
    'category_seo_name',
    getCanonical(),
    getHreflangTags()
];

export default {
    'Query/Category/Query': {
        'member-function': {
            _getDefaultFields
        }
    }
};
