/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ExpandableContentComponent as StendersExpandableContent
} from 'StendersComponent/ExpandableContent/ExpandableContent.component';
import { noopFn } from 'Util/Common';

import './ExpandableContent.override.style';

/** @namespace Scandipwa/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends StendersExpandableContent {
    static defaultProps = {
        ...this.defaultProps,
        isExpandableOnDesktop: true,
        isContentExpanded: true,
        currentItem: -1,
        renderHeadingContent: noopFn,
        afterButton: null,
        noButtonIcon: false
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    scrollToExpandedContent() {
        const { isContentExpanded } = this.state;
        const elem = this.expandableContentRef && this.expandableContentRef.current;

        if (isContentExpanded || !elem) {
            return;
        }

        super.scrollToExpandedContent();
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    toggleExpand() {
        const {
            onClick,
            onExpandedChange
        } = this.props;

        if (onClick) {
            onClick();

            return;
        }
        this.setState(
            ({ isContentExpanded }) => ({ isContentExpanded: !isContentExpanded }),
            () => {
                if (onExpandedChange) {
                    onExpandedChange(this.state.isContentExpanded);
                }

                this.scrollToExpandedContent();
            }
        );
    }

    /**
     * Overridden to add condition
     */
    renderButtonIcon() {
        const { noButtonIcon } = this.props;

        if (noButtonIcon) {
            return null;
        }

        return super.renderButtonIcon();
    }

    renderButton() {
        const { isContentExpanded } = this.state;
        const {
            heading,
            mix,
            afterButton
        } = this.props;

        return (
            <div
              block="ExpandableContent"
              elem="ButtonWrapper"
            >
                <div
                  role="button"
                  tabIndex={ 0 }
                  block="ExpandableContent"
                  elem="Button"
                  mods={ { isContentExpanded } }
                  mix={ { ...mix, elem: 'ExpandableContentButton' } }
                  onClick={ this.toggleExpand }
                  onKeyDown={ this.toggleExpand }
                >
                    <div
                      block="ExpandableContent"
                      elem="Heading"
                      mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                    >
                        { typeof heading === 'string' ? (
                            <TextPlaceholder content={ heading } length="medium" />
                        ) : (
                            heading
                        ) }

                    </div>
                    { this.renderButtonIcon() }

                </div>
                { afterButton ?? null }
            </div>
        );
    }
}

export default ExpandableContentComponent;
