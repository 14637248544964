/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import CheckBoxIcon from 'Component/CheckBoxIcon';
import {
    FieldComponent as SourceFieldComponent
} from 'Component/Field/Field.component';
import { noopFn } from 'Util/Common';

import './ConsentCheckboxField.style.scss';

export * from 'Component/Field/Field.component';

/** @namespace Scandiweb/AmastyGdpr/Component/ConsentCheckboxField/Component */
export class ConsentCheckboxFieldComponent extends SourceFieldComponent {
    /**
     * Overridden to change the layout: the text is carried out of the <label> tag.
     * @returns {JSX.Element}
     */
    renderCheckbox() {
        const {
            type,
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange },
            events,
            isDisabled,
            label,
            isCheckout
        } = this.props;
        const { id = '', checked, value = '' } = newAttr;
        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };

        const isButtonDisabled = (!value.match('none') && isDisabled);
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : false);

        return (
            <label
              htmlFor={ id }
              block="Field"
              elem={ `${elem}Label` }
              mix={ { block: 'GDPRCheckBox', elem: 'Label' } }
            >
                <input
                  ref={ (elem) => setRef(elem) }
                  type={ type }
                    /* Core eslint problem */
                    /* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
                  { ...newAttr }
                  { ...inputEvents }
                    /* eslint-enable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
                  defaultChecked={ isChecked }
                />
                <div
                  block="CheckBoxIconControl"
                  mods={ { isCheckout, isChecked: isCheckout && isChecked } }
                  mix={ { block: 'GDPRCheckBox', elem: 'IconControl' } }
                >
                    <CheckBoxIcon />
                    <div
                      block="CheckBoxIconControl"
                      elem="CheckboxState"
                      mods={ { isCheckout, isChecked } }
                    />
                </div>
                <div
                  block="Field"
                  elem="LabelContent"
                  mix={ { block: 'GDPRCheckBox', elem: 'LabelContent' } }
                >
                    { label }
                </div>
            </label>
        );
    }
}

export default ConsentCheckboxFieldComponent;
