/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { ESCAPE_KEY } from '../config/GiftWrap.config';

const changeEscapeFunctionality = (args, callback, instance) => {
    const [KeyboardEvent] = args;
    const { keyCode } = KeyboardEvent;

    if (keyCode === ESCAPE_KEY) {
        instance.hidePopUp();
        return null;
    }

    return callback(...args);
};

export default {
    'Component/Popup/Component': {
        'member-function': {
            handleKeyDown: changeEscapeFunctionality
        }
    }
};
