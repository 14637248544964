/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * ! This plugin is only needed for Version 5.3.0
 * ! You can delete it if you're using any version after 5.3.0
 * ! https://github.com/scandipwa/scandipwa/pull/5006
 */

import CheckoutOrderSummaryPriceLine from 'Component/CheckoutOrderSummaryPriceLine';

const renderTax = (_args, _callback, instance) => {
    const {
        totals: {
            tax_amount = 0,
            quote_currency_code,
            items_qty
        },
        cartDisplayConfig: {
            display_full_tax_summary,
            display_zero_tax_subtotal
        } = {}
    } = instance.props;

    if (!quote_currency_code || (!tax_amount && !display_zero_tax_subtotal)) {
        return null;
    }

    return (
        <CheckoutOrderSummaryPriceLine
          price={ tax_amount?.toFixed(2) }
          currency={ quote_currency_code }
          itemsQty={ items_qty }
          title={ __('Tax included') }
          mods={ { withAppendedContent: display_full_tax_summary } }
        >
            { instance.renderTaxFullSummary() }
        </CheckoutOrderSummaryPriceLine>
    );
};

const renderSubTotal = (_args, _callback, instance) => {
    const {
        totals: { quote_currency_code },
        cartSubtotal,
        cartSubtotalSubPrice
    } = instance.props;

    const title = __('Subtotal');

    return (
            <CheckoutOrderSummaryPriceLine
              price={ cartSubtotal?.toFixed(2) }
              currency={ quote_currency_code }
              title={ title }
              subPrice={ cartSubtotalSubPrice?.toFixed(2) }
            />
    );
};

export default {
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderTax,
            renderSubTotal
        }
    }
};
