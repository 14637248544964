/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { roundPrice } from 'Util/Price';

export const DL_VAL_CATEGORY_LIMIT = 5;

/** @namespace Scandiweb/Gtm/Data/Product/getProductVariantData */
export const getProductVariantData = async (product) => {
    const { variants = [] } = product;
    const { sku } = variants[0] || {};

    return sku || '';
};

/** @namespace Scandiweb/Gtm/Data/Product/getProductDimensionsData */
export const getProductDimensionsData = async (product) => {
    const { variants = [], dimensions } = product;
    const { dimensions: childDimensions } = variants[0] || {};
    let rawDimensions = childDimensions || dimensions;
    if (!rawDimensions) {
        return {};
    }
    rawDimensions = JSON.parse(rawDimensions);

    Object.keys(rawDimensions).forEach((key) => {
        rawDimensions[key] = rawDimensions[key].includes(',') ? rawDimensions[key].split(',')[0] : rawDimensions[key];
    });

    const enhancedVariantsArray = Object.keys(rawDimensions).filter((key) => key.includes('#'));
    const normalVariantsArray = Object.keys(rawDimensions).filter((key) => !key.includes('#'));
    const newRawDimensions = {};

    enhancedVariantsArray.forEach((enhancedVariant) => {
        if (newRawDimensions[enhancedVariant.split('#')[0]]) {
            // eslint-disable-next-line max-len
            newRawDimensions[enhancedVariant.split('#')[0]] = `${newRawDimensions[enhancedVariant.split('#')[0]]}-${rawDimensions[enhancedVariant]}`;
        } else {
            newRawDimensions[enhancedVariant.split('#')[0]] = rawDimensions[enhancedVariant];
        }
    });
    normalVariantsArray.forEach((normalVariant) => {
        newRawDimensions[normalVariant] = rawDimensions[normalVariant];
    });

    return newRawDimensions || rawDimensions || {};
};

/** @namespace Scandiweb/Gtm/Data/Product/getProductCategoriesData */
export const getProductCategoriesData = async ({ categories = [] }) => (
    categories[categories.length - 1]?.name
);

/** @namespace Scandiweb/Gtm/Data/Product/getProductPriceData */
export const getProductPriceData = async (product) => {
    const { variants = [], type_id, price_range } = product;

    if (!price_range) {
        return -1;
        // ^^^ must be replaced, if price range is not present
    }

    if (type_id === 'grouped') {
        return 0;
    }

    const {
        price_range: {
            minimum_price: {
                final_price: {
                    value: discountValue = null
                } = {},
                regular_price: {
                    value = 0
                } = {}
            }
        } = {}
    } = variants[0] || product;

    return +roundPrice(discountValue || value);
};

/** @namespace Scandiweb/Gtm/Data/Product/getProductData */
export const getProductData = async (product) => {
    const {
        sku,
        name
    } = product;

    return {
        id: sku,
        name,
        price: await getProductPriceData(product),
        category: await getProductCategoriesData(product),
        ...await getProductDimensionsData(product)
    };
};

/** @namespace Scandiweb/Gtm/Data/Product/getProductListEntryData */
export const getProductListEntryData = async (
    product,
    position,
) => ({
    ...await getProductData(product),
    position
});

/** @namespace Scandiweb/Gtm/Data/Product/getProductDetailsData */
export const getProductDetailsData = async (product) => {
    const {
        price_range: {
            maximum_price: {
                final_price: {
                    currency = ''
                } = {}
            }
        } = {}
    } = product;

    return {
        ecommerce: {
            currencyCode: await currency,
            detail: {
                products: [await getProductData(product)]
            }
        }
    };
};
