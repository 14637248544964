import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { toggleMyAccountOverlay } from 'Store/GMyAccountOverlay/GMyAccountOverlay.action';

import PLPPromoBannerComponent from './PLPPromoBanner.component';

/** @namespace Scandipwa/Component/PLPPromoBanner/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    isSignedIn: state.MyAccountReducer.isSignedIn
});

/** @namespace Scandipwa/Component/PLPPromoBanner/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    toggleMyAccountOverlay: (_state) => dispatch(toggleMyAccountOverlay())
});

/** @namespace Scandipwa/Component/PLPPromoBanner/Container */
export class PLPPromoBannerContainer extends PureComponent {
    static propTypes = {
        'data-title': PropTypes.string.isRequired,
        'data-description': PropTypes.string.isRequired,
        'data-link-text': PropTypes.string.isRequired,
        'data-link-url': PropTypes.string.isRequired,
        'data-image': PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        toggleMyAccountOverlay: PropTypes.func.isRequired
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            accountState: false
        };
    }

    toggleAccountState() {
        const { accountState: state } = this.state;

        this.setState({ accountState: !state });
    }

    openMyAccountOverlay = () => {
        const { toggleMyAccountOverlay } = this.props;

        toggleMyAccountOverlay();
        this.toggleAccountState();
    };

    render() {
        const {
            'data-title': title,
            'data-description': description,
            'data-link-text': linkText,
            'data-link-url': linkUrl,
            'data-image': image,
            isMobile,
            isSignedIn
        } = this.props;

        return (
            <PLPPromoBannerComponent
              title={ title }
              description={ description }
              linkText={ linkText }
              linkUrl={ linkUrl }
              image={ image }
              openMyAccountOverlay={ this.openMyAccountOverlay }
              isMobile={ isMobile }
              isSignedIn={ isSignedIn }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PLPPromoBannerContainer);
