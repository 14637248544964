/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/PaypalExpress/Query/PaypalSmartButtonsConfig/Query */
export class PaypalSmartButtonsConfigQuery {
    getQuery() {
        return new Field('paypalSmartButtonsConfig')
            .addFieldList(this.getSmartButtonsConfigFields());
    }

    getSmartButtonsConfigFields() {
        return [
            this.getProductSmartButtonStyleConfigField(),
            this.getCheckoutSmartButtonStyleConfigField(),
            this.getCartSmartButtonStyleConfigField(),
            this.getMiniCartSmartButtonStyleConfigField()
        ];
    }

    getProductSmartButtonStyleConfigField() {
        return new Field('product')
            .addFieldList(this.getSmartButtonStyleConfigFields());
    }

    getCheckoutSmartButtonStyleConfigField() {
        return new Field('checkout')
            .addFieldList(this.getSmartButtonStyleConfigFields());
    }

    getCartSmartButtonStyleConfigField() {
        return new Field('cart')
            .addFieldList(this.getSmartButtonStyleConfigFields());
    }

    getMiniCartSmartButtonStyleConfigField() {
        return new Field('miniCart')
            .addFieldList(this.getSmartButtonStyleConfigFields());
    }

    getSmartButtonStyleConfigFields() {
        return [
            'layout',
            'size',
            'color',
            'shape',
            'label'
        ];
    }
}

export default new PaypalSmartButtonsConfigQuery();
