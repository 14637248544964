/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import Checkboxes from '../component/Checkboxes';
import {
    CHECKOUT_SCOPE,
    NEWSLETTER_SUBSCRIPTION_SCOPE,
    REGISTRATION_SCOPE
} from '../config/GDPR.config';
import {
    sendTheConsentLog
} from '../util/GDPR.util';

const addToNewsLetterOnFormSubmit = (args, callback) => {
    callback(...args);

    const [fields] = args;

    // eslint-disable-next-line dot-notation
    sendTheConsentLog(NEWSLETTER_SUBSCRIPTION_SCOPE, fields.elements['newsletterEmail'].value);
};

const addToOnFormSubmit = (scope) => (args, callback) => {
    sendTheConsentLog(scope)
        .then(
            () => callback(...args)
        );
};

export default {
    'Component/NewsletterSubscription/Container': {
        'member-function': {
            onFormSubmit: addToNewsLetterOnFormSubmit
        }
    },
    'Component/MyAccountNewsletterSubscription/Container': {
        'member-function': {
            onCustomerSave: addToOnFormSubmit(NEWSLETTER_SUBSCRIPTION_SCOPE)
        }
    },
    'Component/MyAccountCreateAccount/Container': {
        'member-function': {
            onSuccess: addToOnFormSubmit(REGISTRATION_SCOPE)
        }
    },
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess: addToOnFormSubmit(CHECKOUT_SCOPE)
        }
    }
};
