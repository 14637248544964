/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import DiscountRule from './DiscountRule.component';

/** @namespace Scandiweb/AmastySpecialPromotionsPro/Component/DiscountRule/Container */
export class DiscountRuleContainer extends PureComponent {
    static propTypes = {
        price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        currency: PropTypes.string,
        itemsQty: PropTypes.number,
        title: PropTypes.string.isRequired,
        coupon_code: PropTypes.string,
        amastyDiscounts: PropTypes.arrayOf(
            PropTypes.shape({
                rule_id: PropTypes.number.isRequired,
                discount_amount: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired
            })
        )
    };

    static defaultProps = {
        itemsQty: 0,
        coupon_code: '',
        currency: 'USD',
        amastyDiscounts: []
    };

    containerFunctions = {
        handleShowAllButtonClick: this.handleShowAllButtonClick.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            isExpanding: false,
            expandableContentHeight: '0',
            isOpen: false
        };
    }

    handleShowAllButtonClick() {
        const { isExpanding } = this.state;

        this.setState(({ isOpen }) => ({ isOpen: !isOpen, isExpanding: !isExpanding }));
    }

    containerProps() {
        const { isOpen } = this.state;
        const {
            price,
            title,
            coupon_code,
            currency,
            amastyDiscounts
        } = this.props;

        return {
            isOpen,
            price,
            title,
            coupon_code,
            currency,
            amastyDiscounts
        };
    }

    render() {
        return <DiscountRule { ...this.containerProps() } { ...this.containerFunctions } />;
    }
}

export default DiscountRuleContainer;
