/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createElement, lazy } from 'react';

import { PRODUCT_SLIDER_RULE } from '../../component/ProductSlider/ProductSlider.config';

const ProductSlider = lazy(() => import('../../component/ProductSlider'));

const extractDomAttributes = (domNode) => {
    const { attribs, children } = domNode;

    const conditionSetting = children[0].attribs;

    const autoplaySpeed = parseInt(attribs['data-autoplay-speed'], 10);
    const autoplay = attribs['data-autoplay'] === 'true';

    return {
        sliderConfig: {
            autoplay,
            autoplaySpeed,
            infinite: attribs['data-infinite-loop'] === 'true',
            showArrows: attribs['data-show-arrows'] === 'true',
            showDots: attribs['data-show-dots'] === 'true',
            dataCarouselMode: attribs['data-carousel-mode']
        },
        productQueryParams: {
            conditionsEncoded: conditionSetting.conditions_encoded,
            productsCount: conditionSetting.products_count,
            sortOrder: conditionSetting.sort_order
        },
        pageBuilderStyles: {
            classes: attribs.class,
            dataPbStyle: attribs['data-pb-style']
        }
    };
};

const addReplacementRule = (originalMember) => ([
    ...originalMember,
    {
        query: { dataContentType: PRODUCT_SLIDER_RULE },
        replace: (domNode) => (
            createElement(ProductSlider, extractDomAttributes(domNode))
        )
    }
]);

export default {
    'Component/Html/Component': {
        'member-property': {
            rules: addReplacementRule
        }
    }
};
