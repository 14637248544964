/* eslint-disable max-lines, fp/no-let */
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import InstagramSection from './InstagramSection.component';
import { IMAGE_GAP, IMAGES_COUNT, SLIDER_BREAKPOINT } from './InstagramSection.config';

/** @namespace Scandipwa/Component/InstagramSection/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/InstagramSection/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandipwa/Component/InstagramSection/Container */
export class InstagramSectionContainer extends PureComponent {
    static propTypes = {
        'data-title': PropTypes.string.isRequired,
        'data-link-text': PropTypes.string.isRequired,
        'data-link-url': PropTypes.string.isRequired,
        'data-image1': PropTypes.string.isRequired,
        'data-image2': PropTypes.string.isRequired,
        'data-image3': PropTypes.string.isRequired,
        'data-image4': PropTypes.string.isRequired,
        'data-image5': PropTypes.string.isRequired,
        'data-image6': PropTypes.string.isRequired,
        'data-image7': PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    state = {
        currentIndex: 0
    };

    containerFunctions = {
        handleCurrentSlideChange: this.handleCurrentSlideChange.bind(this),
        handleNextClick: this.handleNextClick.bind(this),
        handlePrevClick: this.handlePrevClick.bind(this)
    };

    componentDidMount() {
        this.handleIsSlider();
        window.addEventListener('resize', this.updateSliderItemWidth);
    }

    componentDidUpdate() {
        this.updateSliderItemWidth();
        this.handleIsSlider();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSliderItemWidth);
    }

    containerProps() {
        const {
            'data-title': title,
            'data-link-text': linkText,
            'data-link-url': linkUrl,
            'data-image1': image1,
            'data-image2': image2,
            'data-image3': image3,
            'data-image4': image4,
            'data-image5': image5,
            'data-image6': image6,
            'data-image7': image7,
            isMobile
        } = this.props;

        const { currentIndex, sliderItemWidth, isSlider } = this.state;

        return {
            title,
            linkText,
            linkUrl,
            images: [image1, image2, image3, image4, image5, image6, image7],
            isMobile,
            currentIndex,
            sliderItemWidth,
            isSlider,
            slideRef: this.slideRef
        };
    }

    __construct() {
        this.scrollbar = createRef();
        this.cardsWrapper = createRef();
        this.slideRef = createRef();

        this.state = {
            ...this.state,
            sliderItemWidth: 0,
            isSlider: false
        };
    }

    handleIsSlider = () => {
        const { isSlider } = this.state;

        if (window.innerWidth > SLIDER_BREAKPOINT && isSlider) {
            this.setState({ isSlider: false });
        } else if (window.innerWidth < SLIDER_BREAKPOINT && !isSlider) {
            this.setState({ isSlider: true });
        }
    };

    updateSliderItemWidth = () => {
        const { sliderItemWidth } = this.state;

        this.handleIsSlider();

        if (window.innerWidth > SLIDER_BREAKPOINT) {
            return;
        }

        const slide = this.slideRef.current;

        if (slide && slide.offsetWidth + IMAGE_GAP !== sliderItemWidth) {
            this.setState({
                sliderItemWidth: slide.offsetWidth + IMAGE_GAP,
                currentIndex: 0
            });
        }
    };

    handleCurrentSlideChange(ind) {
        this.setState({
            currentIndex: ind
        });
    }

    moveView() {
        this.selectedRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center'
        });
    }

    handleNextClick() {
        const {
            currentIndex
        } = this.state;

        if (currentIndex < IMAGES_COUNT - 1) {
            this.setState({
                currentIndex: currentIndex + 1
            });
        }
    }

    handlePrevClick() {
        const {
            currentIndex
        } = this.state;

        if (currentIndex > 0) {
            this.setState({
                currentIndex: currentIndex - 1
            });
        }
    }

    render() {
        return (
            <InstagramSection
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstagramSectionContainer);
