import {
    BreadcrumbComponent as StendersBreadcrumb
} from 'StendersComponent/Breadcrumb/Breadcrumb.component';

import './Breadcrumb.extended.style';

/** @namespace Scandipwa/Component/Breadcrumb/Component */
export class BreadcrumbComponent extends StendersBreadcrumb {}

export default BreadcrumbComponent;
