/* eslint-disable max-lines */
import { getFiltersCount } from 'Util/Category';
import { getQueryParam } from 'Util/Url';

import { addHreflangTags } from '../util/AddHrefLangTags.js';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        seoConfigurationData: state.ConfigReducer.seoConfigurationData
    };
};

export class CategoryPageContainerPlugin {
    containerProps = (args, callback, instance) => {
        const { seoConfigurationData } = instance.props;

        return {
            ...callback(...args),
            seoConfigurationData
        };
    };

    componentDidMount = (args, callback, instance) => {
        const { isSearchPage } = instance.props;
        callback(...args);

        if (isSearchPage) {
            return;
        }

        const {
            category: { mw_hreflangs = {} },
            history: {
                location: { pathname }
            }
        } = instance.props;

        if (
            mw_hreflangs
            && 'items' in mw_hreflangs
            && !pathname.includes('search')
        ) {
            addHreflangTags(mw_hreflangs.items);
        }
    };

    componentDidUpdate = (args, cb, instance) => {
        const { isSearchPage } = instance.props;

        if (isSearchPage) {
            cb(...args);
            return;
        }

        const {
            category: { id, mw_hreflangs = {} },
            history: {
                location: { pathname }
            }
        } = instance.props;

        const [prevProps] = args;
        const {
            category: { id: prevId, mw_hreflangs: prevMwHreflangs }
        } = prevProps;

        if (
            id !== prevId
            && id !== undefined
            && prevId !== undefined
            && prevMwHreflangs
            && prevMwHreflangs.items
        ) {
            prevMwHreflangs.items.forEach((data) => {
                const previousHreflang = document.querySelector(
                    `[rel="alternate"][hreflang="${data.code}"][href="${data.url}"]`
                );

                if (previousHreflang) {
                    previousHreflang.remove();
                }
            });
        }

        if (
            mw_hreflangs !== undefined
            && mw_hreflangs !== prevMwHreflangs
            && !pathname.includes('search')
        ) {
            addHreflangTags(mw_hreflangs.items);
        }

        cb(...args);
    };

    constructRobotsTag = (props) => {
        const {
            seoConfigurationData: {
                noindex_for_ln_count,
                category_ln_robots,
                robots_for_ln_combinations
            } = {},
            selectedInfoFilter: { customFilters = {} }
        } = props;

        const selectedFiltersCount = getFiltersCount(customFilters);

        if (!selectedFiltersCount) {
            return 'index, follow';
        }

        // eslint-disable-next-line fp/no-let
        let robots = selectedFiltersCount ? category_ln_robots : '';

        if (selectedFiltersCount >= noindex_for_ln_count) {
            return 'noindex, follow';
        }

        if (selectedFiltersCount && robots_for_ln_combinations && robots_for_ln_combinations.length) {
            robots_for_ln_combinations.forEach((element) => {
                const attributeNames = element.attribute.split('+');
                // eslint-disable-next-line fp/no-let
                let customFiltersApplied = true;

                attributeNames.forEach((attributeName) => {
                    if (!customFilters[attributeName]) {
                        customFiltersApplied = false;
                    }
                });

                robots = customFiltersApplied ? element.robots : robots;
            });
        }

        return robots || 'index, follow';
    };

    updateMeta = (args, callback, instance) => {
        if (instance.props.category.mw_canonical_url) {
            const { isSearchPage } = instance.props;
            if (isSearchPage) {
                callback(...args);

                return;
            }
            const {
                updateMetaFromCategory,
                category,
                category: {
                    mw_canonical_url: { url: canonical_url },
                    meta_description: description,
                    meta_title: metaTitle
                } = {},
                history: { location },
                seoConfigurationData: {
                    use_pager_for_canonicals: usePager,
                    crop_meta_title: cropMetaTitle,
                    crop_meta_description: cropMetaDescription
                } = {}
            } = instance.props;

            const updatedDescription = description && cropMetaDescription
                ? description.substring(0, cropMetaDescription)
                : description;
            const updatedTitle = metaTitle && cropMetaTitle
                ? metaTitle.substring(0, cropMetaTitle)
                : metaTitle;

            const pageNumber = getQueryParam('page', location);
            const meta_robots = this.constructRobotsTag(instance.props);

            if (pageNumber && usePager) {
                const updatedUrl = canonical_url.concat(`?page=${pageNumber}`);

                updateMetaFromCategory({
                    ...category,
                    meta_robots,
                    canonical_url: updatedUrl,
                    meta_description: updatedDescription,
                    meta_title: updatedTitle
                });

                return;
            }

            updateMetaFromCategory({
                ...category,
                meta_robots,
                canonical_url,
                description: updatedDescription,
                meta_title: updatedTitle
            });
        }
    };

    componentWillUnmount = (_args, _callback, instance) => {
        const { isSearchPage } = instance.props;

        if (isSearchPage) {
            _callback(..._args);
            return;
        }

        const { category } = instance.props;

        if ('mw_hreflangs' in category && 'items' in category.mw_hreflangs) {
            const {
                mw_hreflangs: { items }
            } = category;

            if (items) {
                items.forEach((data) => {
                    const previousHreflang = document.querySelector(
                        `[rel="alternate"][hreflang="${data.code}"][href="${data.url}"]`
                    );

                    if (previousHreflang) {
                        previousHreflang.remove();
                    }
                });
            }
        }
    };
}

const {
    componentDidMount,
    componentDidUpdate,
    componentWillUnmount,
    updateMeta,
    containerProps
} = new CategoryPageContainerPlugin();

export default {
    'Route/CategoryPage/Container': {
        'member-function': {
            componentDidUpdate,
            componentWillUnmount,
            componentDidMount,
            updateMeta,
            containerProps
        }
    },
    'Route/CategoryPage/Container/mapStateToProps': {
        function: [
            {
                position: 50,
                implementation: mapStateToProps
            }
        ]
    }
};
