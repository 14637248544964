/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import GDPRFooterLink from '../component/GDPRFooterLink';

const RENDER_COOKIES_SETTINGS_LINK = 'render_cookies_settings_link';

/**
 * renders the cookies settings link comp, which is responsible to show the cookies settings modal.
 */
const renderCookiesSettingsLink = () => <GDPRFooterLink />;

/**
 * override the method to append cookies settings link to the additional info column.
 */
const renderColumn = (args, callback) => {
    const [column, ...rest] = args;
    const { title, items } = column;

    if (title.toString() !== __('Additional info').toString()) {
        return callback(...args);
    }

    const newColumn = {
        ...column,
        items: [
            ...items,
            {
                render: RENDER_COOKIES_SETTINGS_LINK
            }
        ]
    };

    return callback(newColumn, ...rest);
};

/**
 * override the property to append the render function to the render map.
 */
const renderMap = (member, instance) => ({
    ...member,
    [RENDER_COOKIES_SETTINGS_LINK]: {
        render: renderCookiesSettingsLink.bind(instance)
    }
});

const renderContent = (args, callback, instance) => (
        <div block="Footer" elem="Content">
            { instance.renderColumns() }
        </div>
);

export default {
    'Component/Footer/Component': {
        'member-function': {
            renderContent,
            renderColumn
        },
        'member-property': {
            renderMap
        }
    }
};
