/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Image from 'Component/Image';
import { rearrangePriceString } from 'Util/Price';

import { GiftWrapperType } from '../../type/GiftWrap';
import { getPrice } from '../../util/GiftWrap';
import { CARD_DESCRIPTION_MAX_LENGTH } from './GiftWrapCard.config';

import './GiftWrapCard.style';

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapCard/Component */
export class GiftWrapCardComponent extends PureComponent {
    static propTypes = {
        image: PropTypes.string.isRequired,
        customerNotice: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        wrap: GiftWrapperType.isRequired,
        radioName: PropTypes.string.isRequired,
        handleWrapSelect: PropTypes.func.isRequired,
        isSelected: PropTypes.bool.isRequired,
        currency: PropTypes.string.isRequired
    };

    renderCardCheckbox() {
        const {
            wrap: {
                name
            },
            radioName,
            isSelected
        } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.radio }
              attr={ {
                  name: radioName,
                  value: name,
                  defaultChecked: isSelected,
                  checked: isSelected
              } }
            />
        );
    }

    renderCardName() {
        const {
            wrap: {
                name
            }
        } = this.props;

        return (
            <p block="GiftWrapCard" elem="Name">
                { name }
            </p>
        );
    }

    renderExcludingTax() {
        const { wrap: { price } } = this.props;

        if (Number(price) === 0.00) {
            return null;
        }

        return (
            <p block="GiftWrapCard" elem="TaxInfo">
                { __('VAT excl.') }
            </p>
        );
    }

    renderCardPrice() {
        const {
            wrap: {
                price
            },
            currency
        } = this.props;
        const formattedPrice = rearrangePriceString(getPrice(price), currency);

        return (
            <p block="GiftWrapCard" elem="Price">
                { formattedPrice }
            </p>
        );
    }

    renderPromo() {
        const { customerNotice } = this.props;

        if (!customerNotice) {
            return null;
        }

        return (
            <div block="GiftWrapCard" elem="Promo">{ __(customerNotice) }</div>
        );
    }

    render() {
        const {
            wrap: {
                image,
                description
            },
            handleWrapSelect,
            isSelected
        } = this.props;

        const translatedDescription = __(description || '');
        const displayedDescription = translatedDescription.slice(0, CARD_DESCRIPTION_MAX_LENGTH).trim()
            + (translatedDescription.length > CARD_DESCRIPTION_MAX_LENGTH ? '...' : '');

        return (
            <div block="GiftWrapCard" elem="Wrapper">
                { /* vvv label is wrapping the elements and doesn't need htmlFor attr */ }
                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                <label
                  aria-hidden
                  block="GiftWrapCard"
                  mods={ { isSelected } }
                  onClick={ handleWrapSelect }
                >
                    { this.renderCardCheckbox() }
                    <div block="GiftWrapCard" elem="Info">
                        <div block="GiftWrapCard" elem="NameAndPrice">
                            { this.renderCardName() }
                            { this.renderCardPrice() }
                        </div>
                        <div block="GiftWrapCard" elem="Description">
                            <Image
                              src={ `/media/${image}` }
                              mix={ { block: 'GiftWrapCard', elem: 'Image' } }
                            />
                            <p block="GiftWrapCard" elem="DescriptionText">
                                { displayedDescription }
                            </p>
                            { this.renderExcludingTax() }
                        </div>
                    </div>
                </label>
                { this.renderPromo() }
            </div>
        );
    }
}

export default GiftWrapCardComponent;
