/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import MPGiftWrapCartQuery from '../query/Cart.query';

const addGiftWrapFields = (args, callback) => ([
    ...callback(...args),
    MPGiftWrapCartQuery.getGiftWrapDataField(),
    MPGiftWrapCartQuery.getGiftWrapPostcardDataField()
]);

const addGiftWrapCartFields = (args, callback) => ([
    ...callback(...args),
    'mp_gift_wrap_tax',
    'mp_gift_wrap_amount',
    'mp_gift_wrap_base_amount',
    'mp_gift_wrap_postcard_amount',
    'mp_gift_wrap_postcard_base_amount',
    MPGiftWrapCartQuery.getCartGiftWrapDataField(),
    MPGiftWrapCartQuery.getCartGiftWrapPostcardDataField()
]);

export default {
    'Query/Cart/Query': {
        'member-function': {
            _getCartItemFields: addGiftWrapFields,
            _getCartTotalsFields: addGiftWrapCartFields
        }
    }
};
