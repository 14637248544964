/** @namespace Scandipwa/Util/Loyalty/getFromShop */
export const getFromShop = () => {
    const url = new URL(window.location);
    const utmMedium = url.searchParams.get('utm_medium');
    const utmSource = url.searchParams.get('utm_source');
    if (utmMedium === 'shopqrcode' && utmSource) {
        return utmSource;
    }

    return '';
};
