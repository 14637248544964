/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { GDPRProvider } from '../context/GDPR.context';

/**
 * override to plugin the GDPR context provider to the app context provider.
 */
const contextProviders = (member) => [
    (children) => (<GDPRProvider>{ children }</GDPRProvider>),
    ...member
];

export default {
    'Component/App/Component': {
        'member-property': {
            contextProviders
        }
    }
};
