import Checkboxes from '@scandiweb/amasty-gdpr/src/component/Checkboxes/Checkboxes.container';
import { NEWSLETTER_SUBSCRIPTION_SCOPE } from '@scandiweb/amasty-gdpr/src/config';
import PropTypes from 'prop-types';

import { FIELD_TYPE } from 'Component/Field/Field.config';
import FieldForm from 'Component/FieldForm';
import Form from 'Component/Form';

import newsletterSubscriptionForm from './NewsletterForm.form';

import './PopupSubscription.style';

/** @namespace Stenders/CustomPopup/Component/PopupSubscription/Component */
export class PopupSubscriptionComponent extends FieldForm {
    static propTypes = {
        onFormSubmit: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired
    };

    /**
     * Overriden to remove the consent checkbox - it's managed by Amasty GDPR module
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    get fieldMap() {
        const { isCheckmarkShown, isLoading } = this.props;

        return newsletterSubscriptionForm(isCheckmarkShown, isLoading);
    }

    renderActions() {
        return (
            <>
                <Checkboxes scope={ NEWSLETTER_SUBSCRIPTION_SCOPE } />
                <button
                  type={ FIELD_TYPE.submit }
                  block="Button"
                  mods={ { isHollow: true } }
                  aria-label={ __('Submit') }
                >
                { __('Subscribe') }
                </button>
            </>
        );
    }

    renderFormBody() {
        const { isLoading } = this.props;

        return (
            <div block="FieldForm" elem="Fieldset" mods={ { isLoading } }>
                { super.renderFormBody() }
            </div>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getFormProps() {
        const {
            onFormSubmit,
            setStateOnError
        } = this.props;

        return {
            onSubmit: onFormSubmit,
            onError: setStateOnError
        };
    }

    render() {
        return (
            <div block="PopupSubscription">
                { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */ }
                <Form { ...this.getFormProps() } isNewsletter>
                    { this.renderFormBody() }
                </Form>
            </div>
        );
    }
}

export default PopupSubscriptionComponent;
