/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import FIELD_TYPE from 'Component/Field/Field.config';
import BrowserDatabase from 'Util/BrowserDatabase';
import { validatePassword } from 'Util/Validator';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { validatePhoneNumber } from '../../util/PhoneValidator/Validator';
import { STORE_CODES_WITH_PHONE } from '../MyAccount/MyAccount.config';

/**
 * Returns customer forms fields
 * @param props
 * @returns {[{addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue, name: string, placeholder: *}}, {addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue, name: string, placeholder: *}}, ...[{addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue, name: string, placeholder: *}}]|*[]]}
 * @namespace Scandipwa/Component/MyAccountCustomerForm/Form/customerInformationFields */
export const customerInformationFields = (props) => {
    const {
        firstname,
        lastname,
        taxvat,
        showTaxVatNumber,
        handleChangeEmailCheckbox,
        handleChangePasswordCheckbox,
        showEmailChangeField,
        showPasswordChangeField,
        vatNumberRequired
    } = props;

    return [
        ...(!showPasswordChangeField ? [{
            type: FIELD_TYPE.text,
            label: __('First Name'),
            attr: {
                name: 'firstname',
                defaultValue: firstname,
                placeholder: __('Your first name'),
                altPlaceholder: __('First Name')
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true
            }
        },
        {
            type: FIELD_TYPE.text,
            label: __('Last Name'),
            attr: {
                name: 'lastname',
                defaultValue: lastname,
                placeholder: __('Your last name'),
                altPlaceholder: __('Last Name')
            },
            addRequiredTag: true,
            validateOn: ['onChange'],
            validationRule: {
                isRequired: true
            }
        }] : []),
        ...(showTaxVatNumber ? [
            {
                type: FIELD_TYPE.text,
                label: __('Tax/VAT Number'),
                attr: {
                    name: 'taxvat',
                    defaultValue: taxvat,
                    placeholder: __('Your tax/VAT number')
                },
                addRequiredTag: vatNumberRequired,
                validateOn: ['onChange'],
                validationRule: {
                    isRequired: vatNumberRequired
                }
            }
        ] : []),
        {
            type: FIELD_TYPE.checkbox,
            attr: {
                name: 'showEmailChangeField',
                defaultChecked: showEmailChangeField
            },
            events: {
                onChange: handleChangeEmailCheckbox
            },
            label: __('Change Email')
        },
        {
            type: FIELD_TYPE.checkbox,
            attr: {
                name: 'showPasswordChangeField',
                defaultChecked: showPasswordChangeField
            },
            events: {
                onChange: handleChangePasswordCheckbox
            },
            label: __('Change Password')
        }
    ];
};

/**
 * Returns customer email and password forms fields
 * @param props
 * @returns {[{addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue, name: string, placeholder: *}}, {addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue, name: string, placeholder: *}}, ...[{addRequiredTag: boolean, validateOn: string[], validationRule: {isRequired: boolean}, label: *, type: string, attr: {defaultValue, name: string, placeholder: *}}]|*[]]}
 * @namespace Scandipwa/Component/MyAccountCustomerForm/Form/customerEmailAndPasswordFields */
export const customerEmailAndPasswordFields = (props) => {
    const { storeConfig: { code } } = BrowserDatabase.getItem('config');
    const {
        minimunPasswordCharacter,
        showEmailChangeField,
        showPasswordChangeField,
        handleEmailInput,
        handlePasswordInput,
        currentPassword,
        email,
        range,
        dob,
        mobilenumber
    } = props;

    return [
        ...(showEmailChangeField && !showPasswordChangeField ? [
            {
                type: FIELD_TYPE.email,
                label: __('Email'),
                attr: {
                    name: 'email',
                    value: email,
                    placeholder: __('Your email'),
                    altPlaceholder: __('Email')
                },
                events: {
                    onChange: handleEmailInput
                },
                addRequiredTag: true,
                validateOn: ['onChange'],
                validationRule: {
                    inputType: VALIDATION_INPUT_TYPE.email,
                    isRequired: true
                }
            },
            {
                type: FIELD_TYPE.date,
                label: __('Birthday (optional)'),
                subLabel: __('Let us know when your birthday is, and receive a surprise.'),
                attr: {
                    name: 'dob',
                    defaultValue: dob,
                    placeholder: __('dd/mm/yyyy'),
                    altPlaceholder: __('Birthday (optional)')
                },
                isAnimated: true,
                isGrayBG: true,
                validateOn: ['onChange'],
                validationRule: {
                    inputType: VALIDATION_INPUT_TYPE.date,
                    isRequired: false
                }
            }
        ] : []),
        ...(showEmailChangeField && !showPasswordChangeField && (code in STORE_CODES_WITH_PHONE) ? [
            {
                type: FIELD_TYPE.tel,
                label: __('Phone'),
                subLabel: __('Text explaining why we need a phone nr.'),
                attr: {
                    name: 'mobilenumber',
                    defaultValue: mobilenumber,
                    placeholder: __(' '),
                    altPlaceholder: __('Phone')
                },
                isAnimated: true,
                isGrayBG: true,
                validateOn: ['onChange'],
                validationRule: {
                    isRequired: true,
                    inputType: VALIDATION_INPUT_TYPE.newPhoneNumber,
                    match: (value) => validatePhoneNumber(value)
                }
            }
        ] : []),
        ...(showPasswordChangeField ? [
            {
                label: __('Current password'),
                type: FIELD_TYPE.password,
                attr: {
                    id: 'currentPassword',
                    name: 'password',
                    placeholder: __('Current password'),
                    'aria-label': __('Current password'),
                    value: currentPassword
                },
                events: {
                    onChange: handlePasswordInput
                },
                addRequiredTag: true,
                validateOn: ['onChange'],
                validationRule: {
                    inputType: VALIDATION_INPUT_TYPE.password,
                    isRequired: true
                }
            }
        ] : []),
        ...(showPasswordChangeField ? [
            {
                label: __('New password'),
                type: FIELD_TYPE.password,
                attr: {
                    id: 'newPassword',
                    name: 'newPassword',
                    placeholder: __('New password'),
                    'aria-label': __('New password')
                },
                addRequiredTag: true,
                validateOn: ['onChange'],
                validationRule: {
                    inputType: VALIDATION_INPUT_TYPE.password,
                    isRequired: true,
                    match: (value) => {
                        const password = document.getElementById('currentPassword');

                        if (value && password.value === value) {
                            return __('New passwords can\'t be the same as old password!');
                        }

                        return validatePassword(value, range, minimunPasswordCharacter);
                    }
                }
            },
            {
                type: FIELD_TYPE.password,
                label: __('Confirm new Password '),
                attr: {
                    name: 'confirmNewPassword',
                    placeholder: __('Confirm new password'),
                    'aria-label': __('Confirm new password')
                },
                addRequiredTag: true,
                validateOn: ['onChange'],
                validationRule: {
                    isRequired: true,
                    inputType: VALIDATION_INPUT_TYPE.password,
                    match: (value) => {
                        const password = document.getElementById('newPassword');
                        return password.value === value;
                    },
                    customErrorMessages: {
                        onMatchFail: __('Passwords do not match!')
                    }
                }
            }
        ] : [])
    ];
};
