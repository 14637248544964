export const GET_HTML_SITEMAP = 'GET_HTML_SITEMAP';
export const GET_STORE_VIEWS = 'GET_STORE_VIEWS';

/** @namespace SeoSuite/Store/HtmlSitemap/Action/getHtmlSitemap */
export const getHtmlSitemap = (data) => ({
    type: GET_HTML_SITEMAP,
    data
});

/** @namespace SeoSuite/Store/HtmlSitemap/Action/getStoreViews */
export const getStoreViews = (stores) => ({
    type: GET_STORE_VIEWS,
    stores
});
