import Loader from 'Component/Loader';
import {
    CategoryPage,
    CmsPage,
    NoMatch,
    ProductPage,
    UrlRewrites as SourceUrlRewrites
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';

export {
    ProductPage,
    CategoryPage,
    CmsPage,
    NoMatch
};

/** @namespace Scandipwa/Route/UrlRewrites/Component */
export class UrlRewritesComponent extends SourceUrlRewrites {
    /**
     * override to use loader on the URLRewrites default page(fallback)
     */
    renderDefaultPage() {
        return (
            <main block="UrlRewrites" elem="Loader">
                <Loader isLoading />
            </main>
        );
    }
}

export default UrlRewritesComponent;
