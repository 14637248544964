/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { CUSTOMER } from '@scandipwa/scandipwa/src/store/MyAccount/MyAccount.dispatcher';

import FIELD_TYPE from 'Component/Field/Field.config';
import { STORE_CODES_WITH_PHONE } from 'Component/MyAccount/MyAccount.config';
import BrowserDatabase from 'Util/BrowserDatabase';

import { validatePhoneNumber } from '../../../../../src/util/PhoneValidator/Validator';

const addMobileNumberField = (args, callback, _) => {
    const { mobilenumber } = BrowserDatabase.getItem(CUSTOMER);
    const { storeConfig: { code } } = BrowserDatabase.getItem('config');

    return (code in STORE_CODES_WITH_PHONE)
        ? [
            {
                type: FIELD_TYPE.text,
                label: __('Mobile Number'),
                attr: {
                    name: 'mobilenumber',
                    defaultValue: mobilenumber,
                    placeholder: __('Mobile Number')
                },
                addRequiredTag: true,
                validateOn: ['onChange'],
                validationRule: {
                    isRequired: false,
                    match: (value) => validatePhoneNumber(value)
                }
            },
            ...callback(...args)
        ]
        : Array.from(callback(...args));
};

export default {
    'Component/MyAccountCustomerForm/Form/customerInformationFields': {
        function: addMobileNumberField
    }
};
