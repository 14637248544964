/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';

import { LocationFieldsComponent as SourceLocationFields }
from '../../../packages/@scandiweb/make-commerce/src/component/LocationFields/LocationFields.component';

import './LocationFields.style.scss';

/** @namespace Scandipwa/Component/LocationFields/Component */
export class LocationFieldsComponent extends SourceLocationFields {
    renderDivisionSelectedLocation() {
        const { selectedDivision } = this.props;

        const formattedValue = selectedDivision.charAt(0).toUpperCase() + selectedDivision.toLowerCase().slice(1);

        return (
            <>
                <p block="LocationFields" elem="SelectedValue">
                    { formattedValue }
                </p>
                <i block="LocationFields" elem="DownArrow" />
            </>
        );
    }

    renderDivisionSearchAndOptions() {
        const {
            handleInputChange, handleDivisionChange, divisions, divisionSuggestions
        } = this.props;

        const optionsToRender = divisionSuggestions.length === 0 ? divisions : divisionSuggestions;

        return (
            <>
                <Field
                  type={ FIELD_TYPE.text }
                  attr={ {
                      id: 'field-text-division',
                      name: 'mcLocationDivision',
                      placeholder: __('Search')
                  } }
                  events={ {
                      onChange: (e) => handleInputChange(e, divisions)
                  } }
                  mix={ { block: 'LocationFields', elem: 'LocationInput' } }
                />
                <div id="mc-locationFields-division-options" mix={ { block: 'LocationFields', elem: 'Options' } }>
                    { optionsToRender.map(({
                        id, label, value
                    }) => (
                            <div mix={ { block: 'LocationFields', elem: 'OptionsWrapper' } }>
                                <div
                                  mix={ { block: 'LocationFields', elem: 'Option' } }
                                  key={ id }
                                // eslint-disable-next-line react/jsx-no-bind
                                  onClick={ () => handleDivisionChange(value) }
                                >
                                    { label }
                                </div>
                            </div>
                    )) }
                </div>
            </>
        );
    }

    renderDivisionField() {
        const { isExpandedDivision, toggleDivisionDropDown, selectedDivision } = this.props;
        const selectedLocationMods = !selectedDivision || isExpandedDivision;

        return (
            <div block="LocationFields" elem="FieldsWrapper">
                <div block="LocationFields" elem="SelectedLocation" onClick={ toggleDivisionDropDown }>

                    <p
                      block="LocationFields"
                      elem="SelectedLabel"
                      mods={ { selectedLocationMods } }
                    >
                        { __('Choose your city or region') }
                    </p>

                    { !isExpandedDivision
                        ? this.renderDivisionSelectedLocation()
                        : <i block="LocationFields" elem="UpArrow" /> }
                </div>
                { isExpandedDivision && this.renderDivisionSearchAndOptions() }
            </div>
        );
    }

    renderAreaSelectedLocation() {
        const { selectedArea } = this.props;

        return (
            <>
                <p block="LocationFields" elem="SelectedValue">
                    { selectedArea }
                </p>
                <i block="LocationFields" elem="DownArrow" />
            </>
        );
    }

    renderAreaSearchAndOptions() {
        const {
            handleInputChange, areas, areaSuggestions, handleAreaChange
        } = this.props;

        const optionsToRender = areaSuggestions.length === 0 ? areas : areaSuggestions;

        return (
            <>
                <Field
                  type={ FIELD_TYPE.text }
                  attr={ {
                      id: 'field-text-area',
                      name: 'mcLocationArea',
                      placeholder: __('Search')
                  } }
                  events={ {
                      onChange: (e) => handleInputChange(e, areas)
                  } }
                  mix={ { block: 'LocationFields', elem: 'LocationInput' } }
                />
                <div id="mc-locationFields-area-options" mix={ { block: 'LocationFields', elem: 'Options' } }>
                    { optionsToRender.map(({ id, label, value }) => (
                        <div mix={ { block: 'LocationFields', elem: 'OptionsWrapper' } }>
                            <div
                              mix={ { block: 'LocationFields', elem: 'Option' } }
                              key={ id }
                            // eslint-disable-next-line react/jsx-no-bind
                              onClick={ () => handleAreaChange(value) }
                            >
                                { label }
                            </div>
                            <div mix={ { block: 'LocationFields', elem: 'OptionAddress' } }>
                                { value }
                            </div>
                        </div>
                    )) }
                </div>
            </>
        );
    }

    renderAreaField() {
        const { isExpandedArea, toggleAreaDropDown, selectedArea } = this.props;
        const selectedLocationMods = !selectedArea || isExpandedArea;

        return (
            <div block="LocationFields" elem="FieldsWrapper">
                <div block="LocationFields" elem="SelectedLocation" onClick={ toggleAreaDropDown }>
                    <p
                      block="LocationFields"
                      elem="SelectedLabel"
                      mods={ { selectedLocationMods } }
                    >
                        { __('Choose Your Parcel') }
                    </p>
                    { !isExpandedArea ? this.renderAreaSelectedLocation()
                        : <i block="LocationFields" elem="UpArrow" /> }
                </div>
                { isExpandedArea && this.renderAreaSearchAndOptions() }
            </div>
        );
    }
}
