/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';

import { FLAG_SELECTOR_CODE } from '../../config/MakeCommerce.config';
import {
    MakeCommercePaymentCountryGroupsType,
    MakeCommercePaymentUiType
} from '../../type/MakeCommerce.type';
import Flag from '../Flag';
import MCGroupWidget from '../MCGroupWidget';

import './MCTabbedWidget.style';

/** @namespace Scandiweb/MakeCommerce/Component/MCTabbedWidget/Component */
export class MCTabbedWidgetComponent extends PureComponent {
    static propTypes = {
        ui: MakeCommercePaymentUiType.isRequired,
        isSelected: PropTypes.bool.isRequired,
        selectedTab: PropTypes.string.isRequired,
        selectedPaymentCode: PropTypes.string.isRequired,
        widgets: MakeCommercePaymentCountryGroupsType.isRequired,
        onFlagClickHandler: PropTypes.func.isRequired,
        getDropdownOptions: PropTypes.func.isRequired,
        onMakeCommerceClick: PropTypes.func.isRequired,
        onClickHandler: PropTypes.func.isRequired
    };

    renderHeading() {
        const {
            ui: {
                widgetTitle
            },
            onClickHandler,
            isSelected
        } = this.props;

        return (
            <button
              block="CheckoutPayment"
              mods={ { isSelected } }
              elem="Button"
              type="button"
              onClick={ onClickHandler }
            >
                <div block="CheckoutPayment" elem="Heading">
                    <Field
                      type={ FIELD_TYPE.checkbox }
                      attr={ {
                          id: 'option-makecommerce-tabbed-widget',
                          name: 'option-makecommerce-tabbed-widget',
                          checked: isSelected
                      } }
                      label={ widgetTitle }
                      isDisabled={ false }
                    />
                    <p block="CheckoutPayment" elem="Description">{ __('Secured and fast transaction') }</p>
                </div>
            </button>
        );
    }

    renderSingleFlag = (key) => {
        const {
            selectedTab,
            onFlagClickHandler
        } = this.props;

        return (
            <Flag
              key={ key }
              label={ key }
              isSelected={ key === selectedTab }
              setSelectedTab={ onFlagClickHandler }
            />
        );
    };

    renderFlagSelector() {
        const {
            widgets
        } = this.props;

        return (
            <div block="FlagsWrapper">
                { Object.keys(widgets).map(this.renderSingleFlag) }
            </div>
        );
    }

    renderDropdown() {
        const {
            selectedTab,
            onFlagClickHandler,
            getDropdownOptions
        } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.select }
              mix={ { block: 'MCFieldSelect' } }
              attr={ {
                  id: 'makecommerce-country-selector',
                  name: 'makecommerce-country-selector',
                  value: selectedTab,
                  noPlaceholder: true
              } }
              options={ getDropdownOptions() }
              events={ {
                  onChange: onFlagClickHandler
              } }
            />
        );
    }

    renderSelector() {
        const {
            ui: {
                widgetCountryselector: selectorType
            }
        } = this.props;

        if (selectorType === FLAG_SELECTOR_CODE) {
            return this.renderFlagSelector();
        }

        return this.renderDropdown();
    }

    renderSingleWidget = ([key, methods]) => {
        const {
            selectedTab,
            selectedPaymentCode,
            onMakeCommerceClick
        } = this.props;
        const isSelected = selectedTab === key;

        return (
            <MCGroupWidget
              key={ key }
              mix={ { isSelected } }
              methods={ methods }
              widgetLabel={ key }
              isSelected={ isSelected }
              onMakeCommerceClick={ onMakeCommerceClick }
              selectedPaymentCode={ selectedPaymentCode }
              isRoot={ false }
            />
        );
    };

    renderWidgets() {
        const {
            widgets
        } = this.props;

        return Object.entries(widgets).map(this.renderSingleWidget);
    }

    renderInnerContent() {
        const {
            isSelected
        } = this.props;

        return (
            <div block="MCTabbedWidget" elem="InnerContent" mods={ { isSelected } }>
                { this.renderSelector() }
                { this.renderWidgets() }
            </div>
        );
    }

    render() {
        return (
            <li block="CheckoutPayment" mods={ { isMakeCommerce: true, isTabbedWidget: true } }>
                { this.renderHeading() }
                { this.renderInnerContent() }
            </li>
        );
    }
}

export default MCTabbedWidgetComponent;
