export * from 'SourceStore/Checkout/Checkout.action';

export const UPDATE_ORDER_DETAILS = 'UPDATE_ORDER_DETAILS';
export const UPDATE_BILLING_ADDRESS = 'UPDATE_BILLING_ADDRESS';

/** @namespace Scandipwa/Store/Checkout/Action/updateOrderDetails */
export const updateOrderDetails = (orderDetails) => ({
    type: UPDATE_ORDER_DETAILS,
    orderDetails
});

/** @namespace Scandipwa/Store/Checkout/Action/updateBillingAddress */
export const updateBillingAddress = (billingAddress) => ({
    type: UPDATE_BILLING_ADDRESS,
    billingAddress
});
