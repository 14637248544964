/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BrowserDatabase from 'Util/BrowserDatabase';
import { prepareQuery } from 'Util/Query';
import { executeGet, fetchMutation } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { getStore } from 'Util/Store';

import {
    AM_COOKIE_NAME,
    DAY_IN_MILLISECONDS,
    GDPR_ACTION_TAKEN,
    GDPR_BROWSER_DATABASE_LOCATION,
    GDPR_BROWSER_DATABASE_VALUE,
    NEWSLETTER_SUBSCRIPTION_SCOPE
} from '../config/GDPR.config';
import CheckScopeQuery from '../query/CheckScope.query';
import LogConsentQuery from '../query/LogConsent.query';

/** @namespace Scandiweb/AmastyGdpr/Util/GDPR/Util/getDaysInMilliSeconds */
export const getDaysInMilliSeconds = (days) => (days * DAY_IN_MILLISECONDS);

/** @namespace Scandiweb/AmastyGdpr/Util/GDPR/Util/storeUserAcceptanceInBrowserDB */
export const storeUserAcceptanceInBrowserDB = () => {
    const days = getStore().getState().ConfigReducer.gdpr__cookie_policy__auto_cleaning_days;

    BrowserDatabase.setItem(
        GDPR_BROWSER_DATABASE_VALUE,
        GDPR_BROWSER_DATABASE_LOCATION,
        getDaysInMilliSeconds(days)
    );
};

/** @namespace Scandiweb/AmastyGdpr/Util/GDPR/Util/checkUserAcceptanceInBrowserDB */
export const checkUserAcceptanceInBrowserDB = () => BrowserDatabase.getItem(GDPR_BROWSER_DATABASE_LOCATION);

/** @namespace Scandiweb/AmastyGdpr/Util/GDPR/Util/hasAmCookie */
export const hasAmCookie = () => document.cookie.indexOf(`${AM_COOKIE_NAME}=`) >= 0;

/** @namespace Scandiweb/AmastyGdpr/Util/GDPR/Util/storeUserAcceptanceToCheckboxesInBrowserDB */
export const storeUserAcceptanceToCheckboxesInBrowserDB = (Checkbox) => {
    const days = getStore().getState().ConfigReducer.gdpr__cookie_policy__auto_cleaning_days;

    BrowserDatabase.setItem(
        GDPR_BROWSER_DATABASE_VALUE,
        `${GDPR_BROWSER_DATABASE_LOCATION}-${Checkbox}`,
        getDaysInMilliSeconds(days)
    );
};

/** @namespace Scandiweb/AmastyGdpr/Util/GDPR/Util/removeUserAcceptanceToCheckboxesInBrowserDB */
export const removeUserAcceptanceToCheckboxesInBrowserDB = (Checkbox) => {
    BrowserDatabase.deleteItem(`${GDPR_BROWSER_DATABASE_LOCATION}-${Checkbox}`);
};

/** @namespace Scandiweb/AmastyGdpr/Util/GDPR/Util/updateUserAcceptanceToBrowserDB */
export const updateUserAcceptanceToBrowserDB = (newCookiesList, oldCookieList) => {
    newCookiesList.forEach((cookie) => {
        if (!BrowserDatabase.getItem(cookie)) {
            const days = getStore().getState().ConfigReducer.gdpr__cookie_policy__auto_cleaning_days;

            BrowserDatabase.setItem(
                { Action: GDPR_BROWSER_DATABASE_VALUE, logged: false },
                `${GDPR_BROWSER_DATABASE_LOCATION}-${cookie}`,
                getDaysInMilliSeconds(days)
            );
        }
    });
    oldCookieList.forEach((cookie) => {
        if (!newCookiesList.includes(cookie)) {
            BrowserDatabase.deleteItem(`${GDPR_BROWSER_DATABASE_LOCATION}-${cookie}`);
        }
    });
};

/** @namespace Scandiweb/AmastyGdpr/Util/GDPR/Util/updateLogStatusForCheckboxesInBrowserDB */
export const updateLogStatusForCheckboxesInBrowserDB = (name) => {
    const days = getStore().getState().ConfigReducer.gdpr__cookie_policy__auto_cleaning_days;

    BrowserDatabase.setItem(
        { Action: GDPR_BROWSER_DATABASE_VALUE, logged: true },
        `${GDPR_BROWSER_DATABASE_LOCATION}-${name}`,
        getDaysInMilliSeconds(days)
    );
};

/** @namespace Scandiweb/AmastyGdpr/Util/GDPR/Util/isLoggedForCheckboxesInBrowserDB */
export const isLoggedForCheckboxesInBrowserDB = (Checkbox) => {
    const item = BrowserDatabase.getItem(`${GDPR_BROWSER_DATABASE_LOCATION}-${Checkbox}`);
    if (item !== null) {
        return item.logged;
    }

    return false;
};

/** @namespace Scandiweb/AmastyGdpr/Util/GDPR/Util/setGdprActionTaken */
export const setGdprActionTaken = () => {
    const key = BrowserDatabase.getItem(GDPR_ACTION_TAKEN);

    if (!key) {
        BrowserDatabase.setItem(true, GDPR_ACTION_TAKEN);
    }
};

/** @namespace Scandiweb/AmastyGdpr/Util/GDPR/Util/sendTheConsentLog */
export const sendTheConsentLog = async (scope, email = null) => {
    const { gdprCheckScope } = await executeGet(
        prepareQuery([CheckScopeQuery.getCheckScopeQuery(scope)]),
        'gdprCheckScope',
        ONE_MONTH_IN_SECONDS
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const { name, consentCode } of gdprCheckScope) {
        if (isLoggedForCheckboxesInBrowserDB(name)) {
            return;
        }

        try {
            if (scope === NEWSLETTER_SUBSCRIPTION_SCOPE) {
                // eslint-disable-next-line no-await-in-loop
                await fetchMutation(LogConsentQuery.getLogConsentMutation(scope, consentCode, email));
            } else {
                // eslint-disable-next-line no-await-in-loop
                await fetchMutation(LogConsentQuery.getLogConsentMutation(scope, consentCode));
            }

            updateLogStatusForCheckboxesInBrowserDB(name);
        } catch (e) {
            console.error(e);
        }
    }
};
