/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

import {
    AFTER_BANNER,
    PRODUCT_LABEL,
    TOP_BANNER
} from '../config/Banner.config';

export const image = PropTypes.string;
export const link = PropTypes.string;
export const altText = PropTypes.string;
export const hoverText = PropTypes.string;
export const position = PropTypes.number;
export const renderEmpty = PropTypes.bool;

export const BannersType = PropTypes.arrayOf(PropTypes.shape({
    image,
    link,
    altText,
    hoverText,
    position
}));

export const LocationType = PropTypes.oneOf([TOP_BANNER, AFTER_BANNER, PRODUCT_LABEL]);
