/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { rearrangePriceString } from 'Util/Price';

import { GIFT_WRAP_PER_ITEM } from '../../config/GiftWrap.config';
import { CartGiftWrapType, CartPostcardType, MFGiftWrapItemType } from '../../type/GiftWrap';
import { convertPrice, getIsOptions, getSelectedOptions } from '../../util/GiftWrap';
import GiftWrapButton from '../GiftWrapButton';
import GiftWrapPopup from '../GiftWrapPopup';

import './GiftWrapCartItem.style.scss';

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapCartItem/Component */
export class GiftWrapCartItemComponent extends PureComponent {
    static propTypes = {
        giftWrapData: CartGiftWrapType.isRequired,
        postcardData: CartPostcardType.isRequired,
        currencyCode: PropTypes.string.isRequired,
        giftWrapMessageFee: PropTypes.number.isRequired,
        preventAnchorEvent: PropTypes.func.isRequired,
        popupId: PropTypes.string.isRequired,
        itemId: PropTypes.number.isRequired,
        giftWrapType: PropTypes.number.isRequired,
        mpGiftWrapData: MFGiftWrapItemType.isRequired,
        mpPostcardData: MFGiftWrapItemType.isRequired
    };

    // vvv structure was copied from extension
    renderAllCartMessage(label) {
        return (
            <p block="Note">
                <span block="Note" elem="AllCart">{ `${ label } ${__('All Cart')}` }</span>
            </p>
        );
    }

    renderGiftWrapFields() {
        const {
            currencyCode,
            giftWrapMessageFee = 0,
            giftWrapData: {
                giftWrapId,
                giftWrapName,
                giftWrapPrice,
                giftWrapUseGiftMessage,
                giftWrapMessage,
                giftWrapAllCart
            }
        } = this.props;
        const isMessageVisible = giftWrapUseGiftMessage && giftWrapMessage !== '';
        const feePriceConverted = convertPrice(giftWrapMessageFee);
        const giftFeePrice = rearrangePriceString(feePriceConverted, currencyCode);
        const formattedPrice = rearrangePriceString(giftWrapPrice, currencyCode);

        // vvv if it's undefined, then don't render anything
        if (!giftWrapId) {
            return null;
        }

        // vvv If it's set by all cart
        if (giftWrapAllCart) {
            return this.renderAllCartMessage(__('Gift Wrap'));
        }

        // vvv structure was copied from extension
        return (
            <p block="Note">
                <span block="Note" elem="Label">{ `${__('Wrapper')}:` }</span>
                <span block="Note" elem="Content">{ `${giftWrapName}` }</span>
                <span block="Note" elem="Price">{ `(${formattedPrice})` }</span>
                { isMessageVisible && (
                    <>
                        <span block="Note" elem="Label">{ `${__('Gift Note')}:` }</span>
                        <span block="Note" elem="Content">{ `${giftWrapMessage}` }</span>
                        <span block="Note" elem="Price">{ `(${giftFeePrice})` }</span>
                    </>
                ) }
            </p>
        );
    }

    renderPostcardFields() {
        const {
            currencyCode,
            postcardData: {
                postcardId,
                postcardName,
                postcardPrice,
                postcardMessage,
                postcardAllCart
            }
        } = this.props;
        const formattedPrice = rearrangePriceString(postcardPrice, currencyCode);

        // vvv if it's undefined, then don't render anything
        if (!postcardId) {
            return null;
        }

        // vvv If it's set by all cart
        if (postcardAllCart) {
            return this.renderAllCartMessage(__('Postcard'));
        }

        // vvv structure was copied from extension
        return (
            <p block="Note">
                <span block="Note" elem="Label">{ `${__('Postcard')}:` }</span>
                <span block="Note" elem="Content">{ `${postcardName}` }</span>
                <span block="Note" elem="Price">{ `(${formattedPrice})` }</span>
                { postcardMessage && (
                    <>
                        <span block="Note" elem="Label">{ `${__('Message')}:` }</span>
                        <span block="Note" elem="Content">{ postcardMessage }</span>
                    </>
                ) }
            </p>
        );
    }

    renderContent() {
        const {
            popupId,
            itemId,
            postcardData: {
                postcardId,
                postcardMessage,
                postcardAllCart
            } = {},
            giftWrapData: {
                giftWrapId,
                giftWrapMessage,
                giftWrapAllCart
            } = {},
            giftWrapType,
            mpGiftWrapData,
            mpPostcardData
        } = this.props;
        const isOptions = getIsOptions(giftWrapId, postcardId);
        const selectedOptions = getSelectedOptions(
            giftWrapId,
            postcardId,
            giftWrapMessage,
            postcardMessage,
            giftWrapAllCart,
            postcardAllCart
        );
        // vvv this is works fine only if 'Both' or 'Per Item' gift wrap type was choosing without any products in cart
        const isAllCart = postcardAllCart && giftWrapAllCart;
        // vvv this need because when switch from 'Both' to 'Per Item' the all_cart status doesn't cleaned properly
        const isPerItem = (!mpGiftWrapData || !mpGiftWrapData.wrap_id) && (!mpPostcardData || !mpPostcardData.wrap_id);
        const allowDisplay = giftWrapType === GIFT_WRAP_PER_ITEM ? isPerItem : (isPerItem || !isAllCart);

        return (
            <>
                { this.renderGiftWrapFields() }
                { this.renderPostcardFields() }
                { allowDisplay && (
                    <GiftWrapButton
                      popupId={ popupId }
                      isOptions={ isOptions }
                    />
                ) }
                <GiftWrapPopup
                  popupId={ popupId }
                  itemId={ itemId }
                  selectedOptions={ selectedOptions }
                  isWrapApplied={ isOptions }
                  isCartItem
                />
            </>
        );
    }

    render() {
        const { preventAnchorEvent } = this.props;

        return (
            <div
              block="GiftWrapCartItem"
              elem="Wrapper"
              aria-hidden="true"
              onClick={ preventAnchorEvent }
            >
                { this.renderContent() }
            </div>
        );
    }
}

export default GiftWrapCartItemComponent;
