/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import { rearrangePriceString } from 'Util/Price';

import GiftWrapButton from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/component/GiftWrapButton';
import GiftWrapPopup from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/component/GiftWrapPopup';
import {
    CartGiftWrapType,
    CartPostcardType
} from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/type/GiftWrap';
import {
    convertPrice,
    getIsOptions,
    getSelectedOptions
} from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/util/GiftWrap';
import { GIFT_WRAP_CHECKOUT_SUMMARY_SECTION_KEY } from './GiftWrapCheckoutSummarySection.config';

import './GiftWrapCheckoutSummarySection.style.scss';

/** @namespace Scandipwa/Component/GiftWrapCheckoutSummarySection/Component */
export class GiftWrapCheckoutSummarySectionComponent extends PureComponent {
    static propTypes = {
        giftWrapData: CartGiftWrapType.isRequired,
        postcardData: CartPostcardType.isRequired,
        currencyCode: PropTypes.string.isRequired,
        giftWrapMessageFee: PropTypes.number.isRequired,
        popupId: PropTypes.string.isRequired
    };

    renderGiftWrapFields() {
        const {
            currencyCode,
            giftWrapMessageFee = 0,
            giftWrapData: {
                giftWrapId,
                giftWrapName,
                giftWrapPrice,
                giftWrapUseGiftMessage,
                giftWrapMessage
            }
        } = this.props;
        const isMessageVisible = giftWrapUseGiftMessage && giftWrapMessage !== '';
        const feePriceConverted = convertPrice(giftWrapMessageFee);
        const giftFeePrice = rearrangePriceString(feePriceConverted, currencyCode);
        const formattedPrice = rearrangePriceString(giftWrapPrice, currencyCode);

        // vvv if it's undefined, then don't render anything
        if (!giftWrapId) {
            return null;
        }

        // vvv structure was copied from extension
        return (
            <p block="Note">
                <span block="Note" elem="Label">{ `${__('Wrapper')}:` }</span>
                <span block="Note" elem="Content">{ `${giftWrapName}` }</span>
                <span block="Note" elem="Price">{ `(${formattedPrice})` }</span>
                { isMessageVisible && (
                    <>
                        <span block="Note" elem="Label">{ `${__('Gift Note')}:` }</span>
                        <span block="Note" elem="Content">{ `${giftWrapMessage}` }</span>
                        <span block="Note" elem="Price">{ `(${giftFeePrice})` }</span>
                    </>
                ) }
            </p>
        );
    }

    renderPostcardFields() {
        const {
            currencyCode,
            postcardData: {
                postcardId,
                postcardName,
                postcardPrice,
                postcardMessage
            }
        } = this.props;
        const formattedPrice = rearrangePriceString(postcardPrice, currencyCode);

        // vvv if it's undefined, then don't render anything
        if (!postcardId) {
            return null;
        }

        // vvv structure was copied from extension
        return (
            <p block="Note">
                <span block="Note" elem="Label">{ `${__('Postcard')}:` }</span>
                <span block="Note" elem="Content">{ `${postcardName}` }</span>
                <span block="Note" elem="Price">{ `(${formattedPrice})` }</span>
                { postcardMessage && (
                    <>
                        <span block="Note" elem="Label">{ `${__('Message')}:` }</span>
                        <span block="Note" elem="Content">{ postcardMessage }</span>
                    </>
                ) }
            </p>
        );
    }

    renderContent() {
        const {
            popupId,
            postcardData: {
                postcardId,
                postcardMessage
            } = {},
            giftWrapData: {
                giftWrapId,
                giftWrapMessage
            } = {}
        } = this.props;
        const isOptions = getIsOptions(giftWrapId, postcardId);
        const selectedOptions = getSelectedOptions(giftWrapId, postcardId, giftWrapMessage, postcardMessage);

        return (
            <>
                { this.renderGiftWrapFields() }
                { this.renderPostcardFields() }
                <GiftWrapButton
                  popupId={ popupId }
                  isOptions={ isOptions }
                />
                <GiftWrapPopup
                  popupId={ popupId }
                  selectedOptions={ selectedOptions }
                  isAllCart
                />
            </>
        );
    }

    render() {
        return (
            <ExpandableContent
              key={ GIFT_WRAP_CHECKOUT_SUMMARY_SECTION_KEY }
              heading={ __('Gift Wrap') }
              mix={ {
                  block: 'GiftWrapCheckoutSummarySection',
                  elem: 'ExpandableContent'
              } }
              isArrow
            >
                { this.renderContent() }
            </ExpandableContent>
        );
    }
}

export default GiftWrapCheckoutSummarySectionComponent;
