/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { SHIPPING_METHOD_CODES } from '../../config/MakeCommerce.config';

const mapStateToProps = (args, callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        mcSelectedShippingLocation: state.MakeCommerceReducer.selectedMCLocation
    };
};

const prepareExtensionInformation = (args, callback, instance) => {
    const [addressInformation, restOfArgs = []] = args;
    const { shipping_method_code } = addressInformation;
    const { mcSelectedShippingLocation: { value: locationValue } } = instance.props;

    // vvv check if the customer picked a makecommerce shipping method,
    // or if the shipping method doesn't require a specific locationId.
    if (!SHIPPING_METHOD_CODES.includes(shipping_method_code) || !locationValue) {
        return callback(...args);
    }

    const { shipping_address } = addressInformation;
    const modifiedAddressInfo = {
        ...addressInformation,
        shipping_address: {
            ...shipping_address,
            extension_attributes: [
                {
                    attribute_code: 'location_id',
                    value: locationValue
                }
            ]
        }
    };

    const modifiedArgs = [modifiedAddressInfo, ...restOfArgs];
    return callback(...modifiedArgs);
};

// vvv when the user is checking the same billing address as shipping address, pwa copies the
// shipping address, but billing address need to be trimmed before sent to the BE to match the
// schema.
const removeExtensionAttributesFromBillingAddress = (args, callback) => {
    // eslint-disable-next-line no-unused-vars
    const [{ extension_attributes, ...restOfAddress }] = args;
    return callback(restOfAddress);
};

export default {
    'Route/Checkout/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/Checkout/Container': {
        'member-function': {
            saveAddressInformation: prepareExtensionInformation,
            trimAddressMagentoStyle: removeExtensionAttributesFromBillingAddress
        }
    }

};
