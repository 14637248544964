/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';
import StyledButton from 'Component/StyledButton/StyledButton.container';

import { GDPR_COOKIES_DETAILS_MODAL_POPUP_KEY } from '../../config';
import { cookiesGroupDataType } from '../../type/gdpr.type';

import './CookiesDetailsModal.style';

/** @namespace Scandiweb/AmastyGdpr/Component/CookiesDetailsModal/Component */
export class CookiesDetailsModalComponent extends PureComponent {
    /**
     * defines the props that could be passed for this component.
     */
    static propTypes = {
        groupData: cookiesGroupDataType,
        dismissPopup: PropTypes.func.isRequired
    };

    static defaultProps ={
        groupData: {
            name: '',
            description: '',
            groupId: '',
            isEssential: false,
            cookies: []

        }
    };

    renderDescription() {
        const {
            groupData: {
                description
            }
        } = this.props;

        return (
            <p>{ description }</p>
        );
    }

    renderTableHeader() {
        return (
            <tr>
                <th>{ __('Cookie Name') }</th>
                <th>{ __('Cookie Provider') }</th>
                <th>{ __('Cookie Description') }</th>
                <th>{ __('Cookie Lifetime') }</th>
                <th>{ __('Cookie Type') }</th>
            </tr>
        );
    }

    renderSingleCookie({
        name,
        provider,
        description,
        lifetime = '',
        type
    }) {
        const descriptionWithoutTags = description.replaceAll('&#039;', '\'')
            .replaceAll(/<\/?[a-z]+>/g, '');

        return (
            <tr>
                <td>{ name }</td>
                <td>{ provider }</td>
                <td>{ descriptionWithoutTags }</td>
                <td>{ lifetime }</td>
                <td>{ type }</td>
            </tr>
        );
    }

    renderTable() {
        const {
            groupData: {
                cookies
            }
        } = this.props;

        if (!cookies) {
            return null;
        }

        return (
            <div block="GDPRCookiesDetails" elem="Table">
                <table>
                    <thead>
                        { this.renderTableHeader() }
                    </thead>
                    <tbody>
                        { cookies.map(this.renderSingleCookie) }
                    </tbody>
                </table>
            </div>
        );
    }

    renderDoneButton() {
        const {
            dismissPopup
        } = this.props;

        return (
            <StyledButton
              handleClick={ dismissPopup }
            >
                { __('Done') }
            </StyledButton>
        );
    }

    render() {
        const {
            groupData
        } = this.props;

        if (!groupData) {
            return null;
        }

        return (
            <Popup
              mix={ { block: 'GDPRCookiesDetails' } }
              id={ GDPR_COOKIES_DETAILS_MODAL_POPUP_KEY }
            >
                { this.renderDescription() }
                { this.renderTable() }
                { this.renderDoneButton() }
            </Popup>
        );
    }
}

export default CookiesDetailsModalComponent;
