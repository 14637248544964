/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Children, cloneElement } from 'react';

import { fetchMutation } from 'Util/Request';
import getStore from 'Util/Store';

import GiftWrapCartItem from '../component/GiftWrapCartItem';
import { allowedProductType, GIFT_WRAP_PER_CART } from '../config/GiftWrap.config';
import GiftWrapRemoveDataQuery from '../query/Mutation/GiftWrapRemoveData.query';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

const PRODUCT_SKU_DEFAULT_VALUE = 'product-sku';

const getCartItemUniqueId = (itemId, productSKU) => `${productSKU.replace(' ', '-')}-${itemId}`;

const mapStateToProps = (args, callback) => {
    const [
        {
            ConfigReducer: {
                mpGiftWrap: {
                    enabled: isMPGiftWrapEnabled,
                    giftWrapType
                } = {}
            } = {},
            CartReducer: {
                cartTotals: {
                    id: cartId = null
                } = {}
            }
        }
    ] = args;

    return {
        ...callback(...args),
        isMPGiftWrapEnabled,
        giftWrapType,
        cartId
    };
};

const addGiftWrapperConfigToContainerProps = (args, callback, { props }) => {
    const {
        isMPGiftWrapEnabled,
        giftWrapType,
        cartId
    } = props;

    return {
        ...callback(...args),
        isMPGiftWrapEnabled,
        giftWrapType,
        cartId
    };
};

const removeItemData = async (cartId, itemId) => {
    const cartDispatcher = (await CartDispatcher).default;
    const { dispatch } = getStore();

    await fetchMutation(GiftWrapRemoveDataQuery.removeData(cartId, itemId));

    cartDispatcher.updateInitialCartData(dispatch);
};

const addGiftWrapperAfterTitle = (args, callback, instance) => {
    const {
        isCartOverlay,
        isMPGiftWrapEnabled,
        giftWrapType,
        cartId,
        item: {
            mp_gift_wrap_data: mpGiftWrapData,
            mp_gift_wrap_postcard_data: mpPostcardData,
            item_id: itemId,
            product: {
                sku: productSKU = PRODUCT_SKU_DEFAULT_VALUE,
                type_id: productType
            } = {}
        } = {}
    } = instance.props;

    const isInvalidType = !giftWrapType || giftWrapType === GIFT_WRAP_PER_CART;
    const wrapper = callback(...args);

    if (giftWrapType === GIFT_WRAP_PER_CART
        && ((!mpGiftWrapData.all_cart && mpGiftWrapData.wrap_id)
        || (!mpPostcardData.all_cart && mpPostcardData.wap_id))) {
        removeItemData(cartId, itemId);
    }
    // vvv original extension works only with simple|configurable|bundle type of the products
    const isAllowedProductType = productType in allowedProductType;

    // vvv if extension is disable or it's not our type then skip
    if (
        !isMPGiftWrapEnabled
        || isInvalidType
        || isCartOverlay
        || !isAllowedProductType
    ) {
        return wrapper;
    }

    return cloneElement(
        wrapper,
        wrapper.props,
        Children.map(wrapper.props.children, (cChild, i) => {
            if (i !== wrapper.props.children.length - 1) {
                return cChild;
            }

            return (
                <>
                    { cChild }
                    <GiftWrapCartItem
                      giftWrapData={ mpGiftWrapData }
                      giftWrapPostcardData={ mpPostcardData }
                      popupId={ getCartItemUniqueId(itemId, productSKU) }
                      itemId={ itemId }
                    />
                </>
            );
        })
    );
};

export default {
    'Component/CartItem/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CartItem/Container': {
        'member-function': {
            containerProps: addGiftWrapperConfigToContainerProps
        }
    },
    'Component/CartItem/Component': {
        'member-function': {
            renderTitle: addGiftWrapperAfterTitle
        }
    }
};
