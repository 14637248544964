/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

// This plugin is for fixing an issue regarding `toFixed()` function applied to a null string, it
// was fixed in newer version than Scandipwa 5.3.0 there is no need for this plugin if you have a
// higher version, and it would be ok to remove it.

import CheckoutOrderSummaryPriceLine from 'Component/CheckoutOrderSummaryPriceLine';

const renderTax = (args, callback, instance) => {
    const {
        totals: {
            tax_amount = 0,
            quote_currency_code,
            items_qty
        },
        cartDisplayConfig: {
            display_full_tax_summary,
            display_zero_tax_subtotal
        } = {}
    } = instance.props;

    if (!quote_currency_code || (!tax_amount && !display_zero_tax_subtotal)) {
        return null;
    }

    return (
        <CheckoutOrderSummaryPriceLine
          price={ tax_amount?.toFixed(2) }
          currency={ quote_currency_code }
          itemsQty={ items_qty }
          title={ __('Tax included') }
          mods={ { withAppendedContent: display_full_tax_summary } }
        >
            { instance.renderTaxFullSummary() }
        </CheckoutOrderSummaryPriceLine>
    );
};

const renderSubTotal = (_args, _callback, instance) => {
    const {
        totals: { quote_currency_code },
        cartSubtotal,
        cartSubtotalSubPrice
    } = instance.props;

    return (
            <CheckoutOrderSummaryPriceLine
              price={ cartSubtotal?.toFixed(2) }
              currency={ quote_currency_code }
              title={ __('Subtotal') }
              subPrice={ cartSubtotalSubPrice?.toFixed(2) }
            />
    );
};

export default {
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderTax,
            renderSubTotal
        }
    }
};
