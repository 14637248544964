import { Field } from 'Util/Query';

const getRobotsCombinationFieldList = () => new Field('robots_for_ln_combinations')
    .addFieldList([
        'attribute',
        'robots'
    ]);

const getSeoConfigurationFieldList = () => new Field('seoConfigurationData').addFieldList([
    'noindex_follow_pages',
    'noindex_follow_user_pages',
    'noindex_nofollow_user_pages',
    'category_ln_robots',
    'noindex_for_ln_count',
    'noindex_for_multiple_values',
    'is_disable_canonicals_for_noindex',
    'use_pager_for_canonicals',
    'use_product_seo_name',
    'use_category_seo_name',
    'crop_meta_title',
    'crop_meta_description',
    'og_enabled_product',
    'og_enabled_category',
    'og_enabled_cms',
    'og_enabled_website',
    'category_markup_enabled',
    'product_markup_enabled',
    'igonredCanonicalPages',
    'trailingSlash',
    'homeTrailingSlash',
    'addPageNumToMetaTitle',
    'addPageNumToMetaDescription',
    'addPageNumToMetaKeywords',
    getRobotsCombinationFieldList()
]);

export const _getStoreConfigFields = (args, callback) => [
    ...callback(...args),
    'id',
    getSeoConfigurationFieldList()
];

export default {
    'Scandipwa/Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};
