/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import { PaymentMethodType } from 'Type/Checkout.type';

import './PaypalPayment.style';

/** @namespace Scandiweb/PaypalExpress/Component/PaypalPayment/Component */
export class PaypalPaymentComponent extends PureComponent {
    static propTypes = {
        isSelected: PropTypes.bool,
        method: PaymentMethodType.isRequired,
        handleClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        isSelected: false
    };

    render() {
        const {
            isSelected,
            method: { title } = {},
            handleClick
        } = this.props;

        // disable checkbox in order to skip direct clicks on checkbox and handle clicks on entire button instead
        return (
            <li
              block="CheckoutPayment"
              mix={ { block: 'PaypalPayment' } }
            >
                <button
                  block="CheckoutPayment"
                  mix={ { block: 'PaypalPayment', elem: 'Button' } }
                  mods={ { isSelected } }
                  elem="Button"
                  type="button"
                  onClick={ handleClick }
                >
                    <div block="PaypalPayment" elem="Heading">
                        <Field
                          type={ FIELD_TYPE.checkbox }
                          attr={ {
                              id: `option-${title}`,
                              name: 'option-paypal-express',
                              checked: isSelected
                          } }
                          label={ __('PayPal') }
                          isDisabled={ false }
                        />
                        <p block="PaypalPayment" elem="Description">{ __('Secured and fast transaction') }</p>
                    </div>
                </button>
            </li>
        );
    }
}

export default PaypalPaymentComponent;
