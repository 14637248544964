import { formatPrice } from 'SourceUtil/Price';

export * from 'SourceUtil/Price/Price';
/** @namespace Scandipwa/Util/Price/rearrangePriceString */
export const rearrangePriceString = (price, currency) => {
    if (currency !== 'EUR') {
        return formatPrice(price, currency);
    }

    const priceString = formatPrice(price, currency);
    const currencySymbol = priceString.match(/[^\d.,-]+/)[0]; // Extract the currency symbol
    const priceValue = priceString.match(/[\d.,]+/)[0]; // Extract price numeric val
    const minusSymbol = priceString.match(/-?/)[0]; // Extract minus symbol;
    const rearrangedString = `${minusSymbol}${priceValue } ${ currencySymbol}`;

    return rearrangedString;
};
