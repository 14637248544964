import { hasAmCookie } from '@scandiweb/amasty-gdpr/src/util/GDPR.util';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    BEFORE_NARROW_DESKTOP_BREAKPOINT
} from 'Component/Router/Router.config';
import {
    toggleMyAccountOverlay
} from 'Store/GMyAccountOverlay/GMyAccountOverlay.action';
import { CustomerType } from 'Type/Account.type';
import { toggleScroll } from 'Util/Browser';
import { isHomePageUrl } from 'Util/Url';

import { CP_CONV_COOKIE_LIFETIME_DAYS } from '../../config/CustomPopup.config';
import {
    setCurrentPopup,
    setIsChoosePopup,
    setIsPopupClose
} from '../../store/CustomPopup/CustomPopup.action';
import {
    isPopupAllowed,
    storePopupViewedInBrowserDB
} from '../../util/CustomPopup.util';
import CustomPopupComponent from './CustomPopup.component';

/** @namespace Stenders/CustomPopup/Component/CustomPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    areOtherOverlaysOpen: state.OverlayReducer.areOtherOverlaysOpen,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    popups: state.CustomPopupReducer.popups,
    currentPopup: state.CustomPopupReducer.currentPopup,
    isChoosePopup: state.CustomPopupReducer.isChoosePopup,
    isPopupClose: state.CustomPopupReducer.isPopupClose,
    isIntroVideoEnded: state.IntroVideoReducer.isIntroVideoEnded,
    isCookiesBarVisible: state.CookiePopupReducer.isCookiesBarVisible,
    customer: state.MyAccountReducer.customer
});

/** @namespace Stenders/CustomPopup/Component/CustomPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    toggleMyAccountOverlay: (_state) => dispatch(toggleMyAccountOverlay()),
    setIsChoosePopup: (state) => dispatch(setIsChoosePopup(state)),
    setIsPopupClose: (state) => dispatch(setIsPopupClose(state)),
    setCurrentPopup: (state) => dispatch(setCurrentPopup(state))
});

/** @namespace Stenders/CustomPopup/Component/CustomPopup/Container */
export class CustomPopupContainer extends PureComponent {
    static propTypes = {
        isMobile: PropTypes.bool.isRequired,
        isSignedIn: PropTypes.bool,
        // eslint-disable-next-line react/forbid-prop-types
        popups: PropTypes.array,
        // eslint-disable-next-line react/forbid-prop-types
        currentPopup: PropTypes.object,
        isIntroVideoEnded: PropTypes.bool,
        isCookiesBarVisible: PropTypes.bool,
        // eslint-disable-next-line react/forbid-prop-types
        scrollContext: PropTypes.object.isRequired,
        isPopupClose: PropTypes.bool,
        isChoosePopup: PropTypes.bool,
        toggleMyAccountOverlay: PropTypes.func.isRequired,
        setIsChoosePopup: PropTypes.func.isRequired,
        setIsPopupClose: PropTypes.func.isRequired,
        setCurrentPopup: PropTypes.func.isRequired,
        customer: CustomerType.isRequired
    };

    static defaultProps = {
        isSignedIn: false,
        popups: [],
        currentPopup: {},
        isCookiesBarVisible: false,
        isIntroVideoEnded: false,
        isPopupClose: false,
        isChoosePopup: false
    };

    containerFunctions = {
        closeCustomPopup: this.closeCustomPopup.bind(this),
        openMyAccountOverlay: this.openMyAccountOverlay.bind(this),
        openPopupLink: this.openPopupLink.bind(this)
    };

    isScrollFrozen = false;

    componentDidMount() {
        this.refreshScrollAndVisibility();
    }

    componentDidUpdate() {
        this.refreshScrollAndVisibility();
    }

    componentWillUnmount() {
        if (this.isScrollFrozen) {
            this.isScrollFrozen = false;
            this.unfreezeScroll();
        }
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            lastPathname: window.location.pathname
        };
    }

    refreshScrollAndVisibility() {
        const {
            isCookiesBarVisible,
            isIntroVideoEnded,
            isPopupClose,
            isChoosePopup,
            setIsPopupClose,
            setIsChoosePopup,
            setCurrentPopup,
            currentPopup
        } = this.props;

        const {
            lastPathname,
            isPopupToShow,
            isHidden
        } = this.state;

        const {
            location: {
                pathname = ''
            } = {}
        } = window;

        if (pathname !== lastPathname) {
            setIsChoosePopup(true);
            this.setState({
                lastPathname: pathname
            });
        }

        if (currentPopup.id && !isPopupToShow) {
            this.setState({
                isPopupToShow: true
            });
        }

        if (!currentPopup.id && isPopupToShow) {
            this.setState({
                isPopupToShow: false
            });
        }

        if (isPopupClose) {
            if (isPopupToShow) {
                setCurrentPopup({});
            }
            setIsPopupClose(false);
        }

        if (isChoosePopup) {
            this.choosePopup();
            setIsChoosePopup(false);
        }

        const newIsHidden = !isPopupToShow
            || isCookiesBarVisible
            || (!isIntroVideoEnded && isHomePageUrl(pathname))
            || !hasAmCookie();

        if (newIsHidden !== isHidden) {
            this.setState({
                isHidden: newIsHidden
            });
        }

        if (newIsHidden && this.isScrollFrozen) {
            this.isScrollFrozen = false;
            this.unfreezeScroll();
        } else if (!newIsHidden && !this.isScrollFrozen) {
            this.isScrollFrozen = true;
            this.freezeScroll();
        }
    }

    choosePopup() {
        const {
            isSignedIn,
            popups,
            currentPopup,
            setCurrentPopup,
            customer: { is_subscribed } = {}
        } = this.props;

        const {
            isPopupToShow
        } = this.state;

        if (popups.length > 0) {
            const popup = popups.find((popup) => isPopupAllowed(popup, isSignedIn, is_subscribed));
            if (popup && popup !== currentPopup) {
                setCurrentPopup(popup);
            } else if (isPopupToShow) {
                setCurrentPopup({});
            }
        } else if (isPopupToShow) {
            setCurrentPopup({});
        }
    }

    /**
     * Created freeze/unfreze scroll
     */
    freezeScroll() {
        const {
            scrollContext,
            isMobile
        } = this.props;

        toggleScroll(false);
        scrollContext?.scroll?.stop();
        if (isMobile || window.innerWidth <= BEFORE_NARROW_DESKTOP_BREAKPOINT) {
            this.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
            document.body.style.marginTop = `${-this.YoffsetWhenScrollDisabled}px`;
        }
    }

    unfreezeScroll() {
        const {
            scrollContext,
            isMobile
        } = this.props;

        toggleScroll(true);
        scrollContext?.scroll?.start();
        if (isMobile || window.innerWidth <= BEFORE_NARROW_DESKTOP_BREAKPOINT) {
            document.body.style.marginTop = 0;
            window.scrollTo(0, this.YoffsetWhenScrollDisabled);
        }
    }

    containerProps() {
        const {
            isHidden,
            isPopupToShow
        } = this.state;

        const {
            currentPopup
        } = this.props;

        const {
            isSubscriptionForm,
            htmlContent,
            yesBtnText,
            yesBtnUrl,
            bottomImage
        } = currentPopup;

        return {
            isSubscriptionForm,
            htmlContent,
            yesBtnText,
            yesBtnUrl,
            bottomImage,
            isPopupToShow,
            isHidden
        };
    }

    closeCustomPopup() {
        const {
            currentPopup,
            setIsChoosePopup
        } = this.props;

        storePopupViewedInBrowserDB(currentPopup.id, currentPopup.cookieLifetime);
        setIsChoosePopup(true);
        this.refreshScrollAndVisibility();
    }

    openMyAccountOverlay() {
        const {
            setIsPopupClose,
            currentPopup,
            toggleMyAccountOverlay
        } = this.props;

        storePopupViewedInBrowserDB(currentPopup.id, CP_CONV_COOKIE_LIFETIME_DAYS);
        setIsPopupClose(true);
        this.refreshScrollAndVisibility();
        toggleMyAccountOverlay();
    }

    openPopupLink() {
        const {
            currentPopup,
            setIsChoosePopup
        } = this.props;

        storePopupViewedInBrowserDB(currentPopup.id, CP_CONV_COOKIE_LIFETIME_DAYS);
        setIsChoosePopup(true);
        this.refreshScrollAndVisibility();
    }

    render() {
        const {
            isHidden
        } = this.state;

        if (isHidden) {
            return null;
        }

        return (
            <CustomPopupComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomPopupContainer);
