export const UPDATE_IS_MENU_VISIBLE = 'UPDATE_IS_MY_ACCOUNT_MENU_VISIBLE';

/** @namespace Scandipwa/Store/MyAccountMenu/Action/updateIsMenuVisible */
export const updateIsMenuVisible = (isMyAccountMenuVisible) => ({
    type: UPDATE_IS_MENU_VISIBLE,
    isMyAccountMenuVisible
});

export const UPDATE_MENU_TAB_ARRAY = 'UPDATE_MENU_TAB_ARRAY';

/** @namespace Scandipwa/Store/MyAccountMenu/Action/updateMenuTabArray */
export const updateMenuTabArray = (tabArray) => ({
    type: UPDATE_MENU_TAB_ARRAY,
    tabArray
});
