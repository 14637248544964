import history from 'Util/History';

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        seoConfigurationData: state.ConfigReducer.seoConfigurationData,
        description: state.MetaReducer.description,
        keywords: state.MetaReducer.keywords,
        canonical_url: state.MetaReducer.canonical_url,
        category: state.CategoryReducer || {}
    };
};

const containerProps = (args, callback, instance) => {
    const {
        seoConfigurationData
    } = instance.props;

    return {
        ...callback(...args),
        seoConfigurationData
    };
};

const shouldUpdateRobots = (pages) => {
    // eslint-disable-next-line fp/no-let
    let output = false;

    pages.forEach((path) => {
        if (path.includes('*')) {
            const resultRegExp = path.replaceAll('*', '.*');

            if (new RegExp(resultRegExp).test(`${history.location.pathname}/`)) {
                output = true;
            }
        } else {
            const resultRegExp = path.concat('$');

            if (new RegExp(resultRegExp).test(history.location.pathname)) {
                output = true;
            }
        }
    });

    return output;
};

const updateRobots = (props) => {
    const {
        seoConfigurationData: {
            noindex_follow_pages = '',
            noindex_follow_user_pages = '',
            noindex_nofollow_user_pages = '',
            noindex_for_ln_count = '',
            robots_for_ln_combinations = [],
            category_ln_robots
        } = {},
        meta_title: title,
        description,
        keywords,
        canonical_url,
        status_code,
        updateMeta,
        category = {}
    } = props;

    const currentMeta = {
        title, description, keywords, canonical_url, status_code
    };
    const noindexFollowPages = /\S/.test(noindex_follow_pages ?? '')
        ? noindex_follow_pages.split(',') : '';
    const noindexFollowUserPages = /\S/.test(noindex_follow_user_pages ?? '')
        ? noindex_follow_user_pages.split(',') : '';
    const noindexNofollowUserPages = /\S/.test(noindex_nofollow_user_pages ?? '')
        ? noindex_nofollow_user_pages.split(',') : '';

    if (typeof noindexFollowPages !== 'string' && shouldUpdateRobots(noindexFollowPages)) {
        updateMeta({
            ...currentMeta,
            robots: 'noindex, follow'
        });
    }

    if (typeof noindexFollowUserPages !== 'string' && shouldUpdateRobots(noindexFollowUserPages)) {
        updateMeta({
            ...currentMeta,
            robots: 'noindex, follow'
        });
    }

    if (typeof noindexNofollowUserPages !== 'string' && shouldUpdateRobots(noindexNofollowUserPages)) {
        updateMeta({
            ...currentMeta,
            robots: 'noindex, nofollow'
        });
    }

    if (Object.keys(category).length !== 0) {
        if (window.location.href.includes('?customFilters')) {
            if (noindex_for_ln_count === '' && robots_for_ln_combinations.length === 0) {
                updateMeta({
                    ...currentMeta,
                    robots: category_ln_robots
                });
            }

            return;
        }
        updateMeta({
            ...currentMeta,
            robots: null
        });
    }
};

const componentDidUpdate = (args, callback, instance) => {
    callback(...args);

    const {
        isLoading,
        meta_title: title
    } = instance.props;

    const [prevProps] = args;
    const { isLoading: prevIsLoading, meta_title: prevTitle } = prevProps;

    if ((!isLoading && isLoading !== prevIsLoading) || title !== prevTitle) {
        updateRobots(instance.props);
    }
};

export default {
    'Component/Router/Container/mapStateToProps': {
        function: [
            {
                position: 50,
                implementation: mapStateToProps
            }
        ]
    },
    'Component/Router/Container': {
        'member-function': {
            componentDidUpdate,
            containerProps
        }
    }
};
