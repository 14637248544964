/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyGdpr/Query/LogConsent/Query */
export class LogConsentQuery {
    getLogConsentMutation(scope, consentCode, email = null) {
        const mutation = new Field('gdprLogConsent');

        this.addLogConsentMutationArguments(mutation, scope, consentCode, email);

        return mutation;
    }

    addLogConsentMutationArguments(mutation, scope, consentCode, email = null) {
        return mutation.addArgument('scope', 'String', scope)
            .addArgument('consentCode', 'String', consentCode)
            .addArgument('email', 'String', email);
    }
}

export default new LogConsentQuery();
