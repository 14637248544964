import { connect } from 'react-redux';

import {
    TYPE_NOTFOUND
} from 'SourceRoute/UrlRewrites/UrlRewrites.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';

export { mapDispatchToProps, mapStateToProps };

/** @namespace Scandipwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    /**
     * override to not use NoMatch as a fallback type
     */
    getFallbackType() {
        const fallbackType = super.getFallbackType();

        if (fallbackType === TYPE_NOTFOUND) {
            return '';
        }

        return fallbackType;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
