import { LocomotiveScrollContext } from 'react-locomotive-scroll';

import { AFTER_ITEMS_TYPE } from 'Component/Router/Router.config';

import CustomPopup from '../component/CustomPopup';
import { CP_MODAL_NAME, CP_MODAL_POSITION } from '../config/CustomPopup.config';

/**
 * overriding to plugin the CustomPopup component on every route.
 */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
const afterItemsType = (member) => [
    ...member,
    {
        component:
            <LocomotiveScrollContext.Consumer>
                { (scrollContext) => (
                    <CustomPopup scrollContext={ scrollContext } />
                ) }
            </LocomotiveScrollContext.Consumer>,
        position: CP_MODAL_POSITION,
        name: CP_MODAL_NAME
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            [AFTER_ITEMS_TYPE]: afterItemsType
        }
    }
};
