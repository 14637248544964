/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const GIFT_WRAP_POPUP_ID_PREFIX = 'GiftWrapPopup';
export const ONE_MONTH_IN_SECONDS = 2592000;
// vvv the number are coming from the schema vendor/mageplaza/module-gift-wrap-graphql/etc/schema.graphqls
export const PAGE_SIZE = 20;
export const GIFTWRAP_TAB_ID = 'giftwrap';
export const POSTCARD_TAB_ID = 'postcard';
