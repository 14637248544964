/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageplazaGiftWrap/Query/Mutation/GiftWrapRemoveAllCartData/Query */
export class GiftWrapRemoveAllCartDataQuery {
    removeData(cartId) {
        return new Field('mpRemoveGiftWrapAllCart')
            .addArgument('cart_id', 'String!', cartId);
    }
}

export default new GiftWrapRemoveAllCartDataQuery();
