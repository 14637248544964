import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';
import { ChildrenType, MixType, RefType } from 'Type/Common.type';

import { DEFAULT_BUTTON_CLASS_NAME_BLOCK } from './StyledButton.config';

import './StyledButton.style.scss';

/** @namespace Scandipwa/Component/StyledButton/Component */
export class StyledButtonComponent extends PureComponent {
    static propTypes = {
        buttonRef: RefType.isRequired,
        handleButtonClick: PropTypes.func.isRequired,
        className: PropTypes.string,
        spanRef: RefType.isRequired,
        innerText: PropTypes.string.isRequired,
        to: PropTypes.string,
        type: PropTypes.string,
        ariaLabel: PropTypes.string,
        mix: MixType.isRequired,
        children: ChildrenType,
        handleMouseOver: PropTypes.func,
        handleFocus: PropTypes.func,
        handleMouseLeave: PropTypes.func,
        handleKeyDown: PropTypes.func,
        // eslint-disable-next-line react/forbid-prop-types
        style: PropTypes.object,
        tabIndex: PropTypes.number,
        isDisabled: PropTypes.bool,
        isFeedbackDisplayed: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        feedbackStyle: PropTypes.object.isRequired
    };

    static defaultProps = {
        className: DEFAULT_BUTTON_CLASS_NAME_BLOCK,
        isDisabled: false,
        handleMouseOver: undefined,
        handleFocus: undefined,
        handleMouseLeave: undefined,
        handleKeyDown: undefined,
        style: undefined,
        tabIndex: undefined,
        ariaLabel: undefined,
        children: undefined,
        type: undefined,
        to: undefined
    };

    render() {
        const {
            buttonRef,
            handleButtonClick,
            spanRef,
            innerText,
            mix,
            isDisabled,
            to,
            type,
            ariaLabel,
            className,
            handleMouseOver,
            handleMouseLeave,
            handleFocus,
            handleKeyDown,
            style,
            tabIndex,
            children,
            isFeedbackDisplayed,
            feedbackStyle
        } = this.props;

        const toSetClassName = (
            className === DEFAULT_BUTTON_CLASS_NAME_BLOCK
            && (
                mix
                && mix.block === DEFAULT_BUTTON_CLASS_NAME_BLOCK
                && !mix.elem
            )
        )
            ? undefined
            : className;

        if (to) {
            return (
                <Link
                  className={ toSetClassName }
                  mix={ mix }
                  block="StyledButton"
                  elem="Button"
                  onClick={ handleButtonClick }
                  disabled={ isDisabled }
                  linkRef={ buttonRef }
                  to={ to }
                >
                    <span
                      ref={ spanRef }
                      className={ `click ${isFeedbackDisplayed ? 'feedback' : ''}` }
                      style={ feedbackStyle }
                    />
                    { children || __(innerText) }
                </Link>
            );
        }

        return (
            <button
              className={ toSetClassName }
              mix={ mix }
              block="StyledButton"
              elem="Button"
              ref={ buttonRef }
              onClick={ handleButtonClick }
              onMouseOver={ handleMouseOver }
              onMouseLeave={ handleMouseLeave }
              onFocus={ handleFocus }
              onKeyDown={ handleKeyDown }
              style={ style }
              tabIndex={ tabIndex }
              disabled={ isDisabled }
              type={ type }
              aria-label={ ariaLabel }
            >
                <span
                  ref={ spanRef }
                  className={ `click ${isFeedbackDisplayed ? 'feedback' : ''}` }
                  style={ feedbackStyle }
                />
                { children || __(innerText) }
            </button>
        );
    }
}
