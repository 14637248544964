/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageplazaGiftWrap/Query/Mutation/GiftWrapRemoveData/Query */
export class GiftWrapRemoveDataQuery {
    removeData(
        cartId,
        itemId,
    ) {
        return new Field('mpRemoveGiftWrap')
            .addArgument('cart_id', 'String!', cartId)
            .addArgument('item_id', 'Int!', itemId);
    }
}

export default new GiftWrapRemoveDataQuery();
