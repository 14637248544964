/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageplazaGiftWrap/Query/Mutation/GiftWrapSetAllCartData/Query */
export class GiftWrapSetAllCartDataQuery {
    setData(
        cartId,
        wrapId,
        postcardId,
        wrapMessage = '',
        postcardMessage = ''
    ) {
        return new Field('mpSetGiftWrapAllCart')
            .addArgument('cart_id', 'String!', cartId)
            .addArgument('wrap_id', 'Int', wrapId)
            .addArgument('postcard_id', 'Int', postcardId)
            .addArgument('wrap_message', 'String', wrapMessage)
            .addArgument('postcard_message', 'String', postcardMessage);
    }
}

export default new GiftWrapSetAllCartDataQuery();
