import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import
{ LocationFieldsContainer as SourceLocationFields, mapDispatchToProps, mapStateToProps }
from '../../../packages/@scandiweb/make-commerce/src/component/LocationFields/LocationFields.container';
import { LocationFieldsComponent } from './LocationFields.component';
import { OPTION_HEIGHT, OPTIONS_DEFAULT_HEIGHT } from './LocationFields.config';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/LocationFields/Container */
export class LocationFieldsContainer extends SourceLocationFields {
    static propTypes = {
        ...this.propTypes,
        selectedMCParcelLocation: PropTypes.shape({}).isRequired,
        setSelectedMCParcelLocation: PropTypes.func.isRequired
    };

    state = {
        ...this.state,
        areaSuggestions: [],
        divisionSuggestions: [],
        isExpandedDivision: false,
        isExpandedArea: false,
        selectedDivision: '',
        selectedArea: ''
    };

    containerFunctions = {
        handleInputChange: this.handleInputChange.bind(this),
        toggleDivisionDropDown: this.toggleDivisionDropDown.bind(this),
        toggleAreaDropDown: this.toggleAreaDropDown.bind(this),
        handleDivisionChange: this.handleDivisionChange.bind(this),
        handleAreaChange: this.handleAreaChange.bind(this)
    };

    componentDidMount() {
        super.componentDidMount();

        const {
            id,
            shippingLocations,
            setSelectedLocation,
            selectedMCParcelLocation,
            setSelectedMCParcelLocation
        } = this.props;
        const { selectedArea, selectedDivision } = this.state;

        if (!selectedArea && !selectedDivision && selectedMCParcelLocation[id]?.value) {
            const { locations } = shippingLocations[id];
            const { city: divisionName, value: areaValue } = selectedMCParcelLocation[id];

            const location = locations.filter(({ groupName }) => groupName === divisionName);
            const areas = this.normalizeAreas(location);
            const selectedArea = areas.find(({ value }) => areaValue === value);

            setSelectedMCParcelLocation({ [id]: selectedArea });
            setSelectedLocation(selectedArea);
            this.setState({
                isExpandedArea: false,
                selectedDivision: divisionName,
                selectedArea: selectedArea.label
            });
        }
    }

    containerProps() {
        const {
            areaSuggestions, divisionSuggestions, isExpandedDivision, isExpandedArea, selectedDivision, selectedArea
        } = this.state;

        return {
            ...super.containerProps(),
            areaSuggestions,
            divisionSuggestions,
            selectRef: this.selectRef,
            isExpandedDivision,
            isExpandedArea,
            selectedDivision,
            selectedArea
        };
    }

    toggleDivisionDropDown() {
        this.setState((prevState) => ({
            isExpandedDivision: !prevState.isExpandedDivision,
            divisionSuggestions: []
        }));
    }

    toggleAreaDropDown() {
        this.setState((prevState) => ({
            isExpandedArea: !prevState.isExpandedArea,
            areaSuggestions: []
        }));
    }

    handleInputChange(e, locations) {
        const { name, value } = e.target;
        const suggestions = locations.filter(
            (location) => location.label.toLowerCase().includes(value.toLowerCase())
        );
        const suggestionsLength = suggestions.length;
        const optionsHeight = suggestionsLength ? `${suggestionsLength * OPTION_HEIGHT }px` : OPTIONS_DEFAULT_HEIGHT;

        if (name === 'mcLocationArea') {
            const { areas } = this.state;

            this.setState((prevProps) => ({
                ...prevProps,
                areaSuggestions: suggestionsLength !== 0 ? suggestions : areas
            }));

            const divOptions = document.getElementById('mc-locationFields-area-options');
            divOptions.style.setProperty('--select-height', optionsHeight);
        } else {
            const { divisions } = this.state;

            this.setState((prevProps) => ({
                ...prevProps,
                divisionSuggestions: suggestionsLength !== 0 ? suggestions : divisions
            }));

            const divOptions = document.getElementById('mc-locationFields-division-options');
            divOptions.style.setProperty('--select-height', optionsHeight);
        }
    }

    handleDivisionChange(divisionName) {
        const {
            id,
            shippingLocations,
            setSelectedLocation,
            setSelectedMCParcelLocation
        } = this.props;

        const { locations } = shippingLocations[id];
        const location = locations.filter(({ groupName }) => groupName === divisionName);
        const areas = this.normalizeAreas(location);
        const { label } = areas[0];

        setSelectedMCParcelLocation({ [id]: areas[0] });
        setSelectedLocation(areas[0]);
        this.setState({ areas });
        this.setState({
            isExpandedDivision: false,
            selectedDivision: divisionName,
            selectedArea: label
        });
    }

    normalizeAreas(locations) {
        const { id } = this.props;
        const merged = locations.reduce((acc, { locations }) => {
            acc.push(locations);
            return acc;
        }, []).flat();

        return merged.map(({
            location_id, name, city, address
        }) => ({
            id: `${id}-${location_id}`,
            label: name,
            value: location_id,
            city,
            address
        }));
    }

    handleAreaChange(areaValue) {
        const { id, setSelectedLocation, setSelectedMCParcelLocation } = this.props;
        const { areas } = this.state;
        const selectedArea = areas.find(({ value }) => areaValue === value);
        const { label } = selectedArea;

        setSelectedMCParcelLocation({ [id]: selectedArea });
        setSelectedMCParcelLocation(selectedArea);
        setSelectedLocation(selectedArea);
        this.setState({
            isExpandedArea: false,
            selectedArea: label
        });
    }

    render() {
        return (
            <LocationFieldsComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationFieldsContainer);
