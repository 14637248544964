import Image from 'Component/Image';
import {
    SearchItemComponent as SourceSearchItem
} from 'SourceComponent/SearchItem/SearchItem.component';

import './SearchItem.override.style';

/** @namespace Scandipwa/Component/SearchItem/Component */
export class SearchItemComponent extends SourceSearchItem {
    renderImage() {
        const {
            product: { name },
            imgSrc,
            imageRef
        } = this.props;

        if (name && !imgSrc) {
            return (
                <div
                  block="SearchItem"
                  elem="Image"
                />
            );
        }

        return (
            <div ref={ imageRef }>
                <Image
                  block="SearchItem"
                  elem="Image"
                  src={ imgSrc }
                  alt={ __('Product %s thumbnail.', name) }
                  isPlaceholder={ !name }
                />
            </div>
        );
    }
}

export default SearchItemComponent;
