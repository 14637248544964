import { TITLE_META_NAME } from '../config/Meta.config';

export const renderMeta = (args, callback, instance) => {
    const { metadata } = instance.props;

    if (!metadata.length || metadata?.filter((meta) => (meta.name === TITLE_META_NAME)).length === 0) {
        metadata.push({ name: TITLE_META_NAME });
    }

    return (
        <>
            { instance.renderTitle() }
            { instance.renderCanonical() }
            { metadata.map((tag) => {
                const {
                    name = null,
                    property = null,
                    content = null
                } = tag;

                return (
                    <meta
                      key={ name || property }
                      name={ name }
                      property={ property }
                      content={ content }
                    />
                );
            }) }
        </>
    );
};

const isIgnoredCanonical = (ignoredUrls) => {
    const { host, href } = window.location;
    const updatedIgnoredUrls = [];

    // eslint-disable-next-line no-unused-expressions
    Array.isArray(ignoredUrls) && ignoredUrls.forEach((ignoredUrl, index) => {
        if (!ignoredUrl.includes(host)) {
            updatedIgnoredUrls[index] = window.location.origin + ignoredUrl.replace('*', '');
        } else {
            updatedIgnoredUrls[index] = ignoredUrl;
        }
    });

    return updatedIgnoredUrls.includes(href);
};

export const renderCanonical = (args, callback, instance) => {
    const { href } = window.location;
    const { canonical_url } = instance.props;
    const currentUrl = href.charAt(href.length - 1) === '/'
        ? href.slice(0, -1).split('//')[1]
        : href.split('//')[1];

    const {
        robots,
        base_url = '',
        base_link_url = '',
        seoConfigurationData: {
            is_disable_canonicals_for_noindex: disableCanonicals = false,
            igonredCanonicalPages,
            trailingSlash,
            homeTrailingSlash
        } = {}
    } = instance.props;

    const correct_base_link_url = base_link_url.slice(0, -1).split('//')[1];
    const correct_base_url = base_url.slice(0, -1).split('//')[1];
    const isIngredient = currentUrl.split('/').indexOf('ingredients') > -1;

    if ((disableCanonicals && robots?.includes('noindex')) || isIgnoredCanonical(igonredCanonicalPages)) {
        return null;
    }

    if (isIngredient) {
        return <link rel="canonical" href={ canonical_url } />;
    }

    if (currentUrl === correct_base_url || currentUrl === correct_base_link_url) {
        if (homeTrailingSlash && canonical_url.charAt(canonical_url.length - 1) !== '/') {
            return <RenderTrailingCanonical canonical_url={ canonical_url } />;
        }

        if (!homeTrailingSlash && canonical_url.charAt(canonical_url.length - 1) === '/') {
            return <RenderNonTrailingCanonical canonical_url={ canonical_url } />;
        }
    } else if (trailingSlash && canonical_url.charAt(canonical_url.length - 1) !== '/') {
        return <RenderTrailingCanonical canonical_url={ canonical_url } />;
    }

    if (!canonical_url) {
        return null;
    }

    return (
        <link rel="canonical" href={ canonical_url } />
    );
};

// eslint-disable-next-line react/prop-types, arrow-body-style
const RenderTrailingCanonical = ({ canonical_url }) => {
    const trailingSlash = `${canonical_url }/`;

    return (
        <link rel="canonical" href={ trailingSlash } />
    );
};

// eslint-disable-next-line react/prop-types, arrow-body-style
const RenderNonTrailingCanonical = ({ canonical_url }) => {
    // eslint-disable-next-line react/prop-types
    const nontrailingSlash = canonical_url.slice(0, -1);

    return (
        <link rel="canonical" href={ nontrailingSlash } />
    );
};

export const renderTitle = (args, callback, instance) => {
    const {
        title_prefix,
        title_suffix,
        title,
        metadata
    } = instance.props;

    if (!metadata.length || metadata?.filter((meta) => (meta.name === TITLE_META_NAME)).length === 0) {
        metadata.push({ name: TITLE_META_NAME });
    }

    const [{ content }] = metadata?.filter((meta) => (meta.name === TITLE_META_NAME));
    const newTitle = content || title;
    const titlePrefix = title_prefix ? `${ title_prefix }` : '';
    const titleSuffix = title_suffix ? `${ title_suffix }` : '';

    return (
        <title>
            { `${ titlePrefix }${ newTitle }${ titleSuffix }` }
        </title>
    );
};

export default {
    'Component/Meta/Component': {
        'member-function': {
            renderMeta,
            renderCanonical,
            renderTitle
        }
    }
};
