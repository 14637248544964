/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import StyledButton from 'Component/StyledButton/StyledButton.container';

import './SocialLoginButton.style';

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButton/Component */
export class SocialLoginButtonComponent extends PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    };

    renderButton() {
        const { label, type, onClick } = this.props;

        return (
            <div block="SocialLoginButton">
                <StyledButton
                  mix={ { block: 'SocialLoginButton', mods: { type } } }
                  handleClick={ onClick }
                >
                    { label }
                </StyledButton>
            </div>
        );
    }

    render() {
        return (
            <div block="SocialLoginButton">
                { this.renderButton() }
            </div>
        );
    }
}

export default SocialLoginButtonComponent;
