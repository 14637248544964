/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { rearrangePriceString } from 'Util/Price';

import GiftWrapButton
from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/component/GiftWrapButton';
import GiftWrapPopup
from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/component/GiftWrapPopup';
import {
    GiftWrapOptionsType
} from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/type/GiftWrap';
import {
    convertPrice,
    getFullPopupId,
    getPrice
} from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/util/GiftWrap';
import {
    GIFT_WRAP_POPUP_KEY,
    GIFT_WRAP_POSTCARD_LABEL,
    GIFT_WRAP_WRAPPER_LABEL
} from './GiftWrapInfoSection.config';

import './GiftWrapInfoSection.style';

/** @namespace Scandipwa/Component/GiftWrapInfoSection/Component */
export class GiftWrapInfoSectionComponent extends PureComponent {
    static propTypes = {
        giftWrapOptions: GiftWrapOptionsType.isRequired,
        onPopupSave: PropTypes.func.isRequired,
        currency: PropTypes.string.isRequired,
        handleWrapData: PropTypes.func.isRequired,
        giftWrapMessageFee: PropTypes.number.isRequired
    };

    renderWrapGiftNote() {
        const {
            giftWrapOptions,
            currency,
            giftWrapMessageFee
        } = this.props;
        const {
            wrap: {
                giftNoteMessage: { message } = {}
            } = {}
        } = giftWrapOptions || {};
        const feePriceConverted = convertPrice(giftWrapMessageFee);
        const giftFeePrice = rearrangePriceString(feePriceConverted, currency);

        if (!message) {
            return null;
        }

        return (
            <div block="Note">
                <span block="Note" elem="Label">{ `${__('Gift Note')}:` }</span>
                <span block="Note" elem="Content">{ `${message}` }</span>
                <span block="Note" elem="Price">{ `(${giftFeePrice})` }</span>
            </div>
        );
    }

    renderSelectedWrapper(wrapperLabel, name, price, currency) {
        const formattedPrice = rearrangePriceString(getPrice(price), currency);

        return (
            <div block="Note">
                <span block="Note" elem="Label">{ `${wrapperLabel}:` }</span>
                <span block="Note" elem="Content">{ `${name}` }</span>
                <span block="Note" elem="Price">{ `(${ formattedPrice })` }</span>
            </div>
        );
    }

    renderWrapOptions() {
        const {
            giftWrapOptions,
            currency
        } = this.props;
        const {
            wrap: {
                wrap_id,
                name,
                price
            } = {}
        } = giftWrapOptions || {};

        if (!wrap_id || !name) {
            return null;
        }

        return (
            <p block="GiftWrap" elem="Note">
                { this.renderSelectedWrapper(
                    GIFT_WRAP_WRAPPER_LABEL,
                    name,
                    price,
                    currency
                ) }
                { this.renderWrapGiftNote() }
            </p>
        );
    }

    renderPostCardMessage() {
        const {
            giftWrapOptions
        } = this.props;
        const {
            postcard: { postcardMessage } = {},
            postcard
        } = giftWrapOptions || {};

        // vvv early return if postcardMessage or postcard itself doesn't exist
        if (!postcardMessage || !postcard) {
            return null;
        }

        return (
            <div block="Note">
                <span block="Note" elem="Label">{ `${__('Message')}:` }</span>
                <span block="Note" elem="Content">{ postcardMessage }</span>
            </div>
        );
    }

    renderPostCard() {
        const {
            giftWrapOptions: {
                postcard: {
                    name,
                    price,
                    wrap_id
                } = {}
            } = {}, currency
        } = this.props;

        if (!wrap_id || !name) {
            return null;
        }

        return (
            <p block="GiftWrap" elem="Note">
                { this.renderSelectedWrapper(
                    GIFT_WRAP_POSTCARD_LABEL,
                    name,
                    price,
                    currency
                ) }
                { this.renderPostCardMessage() }
            </p>
        );
    }

    renderSelectedOptions() {
        const {
            giftWrapOptions = {},
            handleWrapData
        } = this.props;

        if (!giftWrapOptions || Object.keys(giftWrapOptions).length === 0) {
            return null;
        }

        handleWrapData(giftWrapOptions);

        return (
            <>
                { this.renderWrapOptions() }
                { this.renderPostCard() }
            </>
        );
    }

    render() {
        const {
            onPopupSave,
            giftWrapOptions
        } = this.props;
        // vvv this is for passing the button if it has options or no to render the right label
        const options = this.renderSelectedOptions();

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'giftWrap' } }
              aria-label="Gift-Wrap section"
            >
                { this.renderSelectedOptions() }
                <GiftWrapButton
                  popupId={ getFullPopupId(GIFT_WRAP_POPUP_KEY) }
                  isOptions={ !!options }
                />
                <GiftWrapPopup
                  popupId={ getFullPopupId(GIFT_WRAP_POPUP_KEY) }
                  onPopupSave={ onPopupSave }
                  selectedOptions={ giftWrapOptions }
                />
            </section>
        );
    }
}

export default GiftWrapInfoSectionComponent;
