import { EN_STORE_CODE } from '../config/CategoryPage.config';

/** @namespace SeoSuite/Util/AddHrefLangTags/removePreviousHreflangs */
export const removePreviousHreflangs = () => {
    const elements = document.querySelectorAll('[rel="alternate"]');
    Array.from(elements).forEach((element) => element.remove());
};

/** @namespace SeoSuite/Util/AddHrefLangTags/addHreflangTags */
export const addHreflangTags = (hreflangs) => {
    removePreviousHreflangs();

    if (hreflangs) {
        hreflangs.forEach((data) => {
            const hreflangTag = document.createElement('link');
            hreflangTag.href = data.url;
            hreflangTag.rel = 'alternate';
            hreflangTag.hreflang = data.code;

            if (data.code === EN_STORE_CODE) {
                const x = data.url.split('//');
                const y = x[1].split('/');

                if (y.indexOf(EN_STORE_CODE) === -1) {
                    y.splice(1, 0, EN_STORE_CODE);
                    const z = `${window.location.protocol}//${y.join('/')}`;
                    hreflangTag.href = z;
                }
            }

            if (
                !document.querySelector(
                    `[rel="alternate"][hreflang="${data.code}"][href="${data.url}"]`
                )
            ) {
                document
                    .getElementsByTagName('head')[0]
                    .appendChild(hreflangTag);
            }
        });
    }
};
