/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';

import {
    GDPR_COOKIES_SETTINGS_TEXT,
    ORDER_AND_RETURN_URL,
    ORDERS_AND_RETURNS_TEXT
} from '../../config';

import './GDPRFooterLink.style.scss';

/** @namespace Scandiweb/AmastyGdpr/Component/GDPRFooterLink/Component */
export class GDPRFooterLinkComponent extends PureComponent {
    static propTypes = {
        showPopup: PropTypes.func.isRequired
    };

    render() {
        const { showPopup } = this.props;

        return (
            <>
                <div block="GDPRFooterLink" key="GDPRFooterLink_Settings">
                    <button
                      block="GDPRFooterLink"
                      elem="Text"
                      onClick={ showPopup }
                    >
                        { GDPR_COOKIES_SETTINGS_TEXT }
                    </button>
                </div>
                <div block="GDPRFooterLink" key="GDPRFooterLink_Orders">
                    <Link
                      block="GDPRFooterLink"
                      elem="Text"
                      to={ ORDER_AND_RETURN_URL }
                    >
                        { ORDERS_AND_RETURNS_TEXT }
                    </Link>
                </div>
            </>
        );
    }
}

export default GDPRFooterLinkComponent;
