/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getGuestQuoteId } from '@scandipwa/scandipwa/src/util/Cart';

import { DL_VAL_CHECKOUT_BILLING_STEP, DL_VAL_CHECKOUT_SHIPPING_STEP } from '../../data/checkout';
import { fireCheckoutEvent, fireCheckoutOptionEvent, firePurchaseEvent } from '../../event/checkout';

const addFireCheckoutOptionEventForBillingPaypal = (args, callback) => {
    callback(...args);

    fireCheckoutOptionEvent(
        DL_VAL_CHECKOUT_BILLING_STEP,
        'paypal_express'
    );
};

const addFireCheckoutOptionEventForBilling = (args, callback, instance) => {
    callback(...args);

    const { paymentMethod } = instance.state;

    fireCheckoutOptionEvent(
        DL_VAL_CHECKOUT_BILLING_STEP,
        paymentMethod === 'checkmo' ? 'Check Money' : paymentMethod
    );
};

const addFireCheckoutEventForBilling = (args, callback, instance) => {
    const { flag } = instance.state;
    callback(...args);
    if (window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] === 'billing'
        && flag === undefined) {
        fireCheckoutEvent(DL_VAL_CHECKOUT_BILLING_STEP);
        instance.setState({ flag: 1 });
    }
};

const addFireCheckoutOptionEventForShipping = (args, callback, instance) => {
    callback(...args);

    const { selectedShippingMethod: { carrier_title } } = instance.state;
    const { selectedShippingMethod: { method_title } } = instance.state;
    const title = (!carrier_title || carrier_title === ' ') ? method_title : carrier_title;

    fireCheckoutOptionEvent(
        DL_VAL_CHECKOUT_SHIPPING_STEP,
        title
    );
};

const addFireCheckoutEventForShipping = (args, callback) => {
    callback(...args);

    const waitForEventsCanFireEvent = () => new Promise((resolve) => {
        const handleEventsCanFire = () => {
            document.removeEventListener('CheckoutCanFire', handleEventsCanFire);
            resolve();
        };

        document.addEventListener('CheckoutCanFire', handleEventsCanFire);
    });

    waitForEventsCanFireEvent().then(
        () => {
            if (!window.location.href.includes('/checkout/success')) {
                fireCheckoutEvent(DL_VAL_CHECKOUT_SHIPPING_STEP);
            }
        }
    );
};

const addFirePurchaseEvent = (args, callback) => {
    const [orderId] = args;
    const guestQuoteId = getGuestQuoteId();
    // ^^^ getting this here, as it will soon be reset
    firePurchaseEvent(orderId, guestQuoteId);
    // vvv This must be called after guest quote id is obtained
    callback(...args);
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            checkoutOptionEvent: addFireCheckoutOptionEventForBilling,
            componentDidUpdate: addFireCheckoutEventForBilling
        }
    },
    'Scandiweb/PaypalExpress/Component/PaypalSmartButtons/Container': {
        'member-function': {
            handleOnClick: addFireCheckoutOptionEventForBillingPaypal
        }
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            onShippingSuccess: addFireCheckoutOptionEventForShipping,
            componentDidMount: addFireCheckoutEventForShipping
        }
    },
    'Route/Checkout/Container': {
        'member-function': {
            setDetailsStep: addFirePurchaseEvent
        }
    }
};
