/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

import { PAGE_SIZE } from '../component/GiftWrapPopup/GiftWrapPopup.config';

/** @namespace Scandiweb/MageplazaGiftWrap/Query/GiftWrap/Query */
export class GiftWrapQuery {
    getWrapFields() {
        return [
            'total_count',
            this.getItemsField(),
            this.getPageInfoField()
        ];
    }

    getItemsFields() {
        return [
            'wrap_id',
            'name',
            'status',
            'price_type',
            'amount',
            'description',
            'image',
            'category',
            'sort_order',
            'created_at',
            'updated_at',
            'wrap_type',
            'price'
        ];
    }

    getPageInfoFields() {
        return [
            'pageSize',
            'currentPage',
            'hasNextPage',
            'hasPreviousPage',
            'startPage',
            'endPage'
        ];
    }

    getPageInfoField = () => new Field('page_info')
        .addFieldList(this.getPageInfoFields());

    getItemsField = () => new Field('items')
        .addFieldList(this.getItemsFields());

    getGiftWraps = (
        currentPage = 1,
        pageSize = PAGE_SIZE,
        filter = {}
    ) => new Field('mpGetGiftWrap')
        .addArgument('filter', 'GiftWrapWrapperFilterInput', filter)
        .addArgument('pageSize', 'Int', pageSize)
        .addArgument('currentPage', 'Int', currentPage)
        .addFieldList(this.getWrapFields());
}

export default new GiftWrapQuery();
