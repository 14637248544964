/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyGdpr/Query/CookiesSettings/Query */
export class CookiesSettingsQuery {
    getData() {
        return new Field('gdpr')
            .addField(this.getGdprCookieSettingsFields());
    }

    getGdprCookieSettingsFields() {
        return new Field('cookieSettings')
            .addField(this.getGroupDataFields());
    }

    getGroupDataFields() {
        return new Field('groupData')
            .addFieldList(this.getGroupDataFieldList());
    }

    getGroupDataFieldList() {
        return [
            'groupId',
            'isEssential',
            'name',
            'description',
            'checked',
            this.getCookiesField()
        ];
    }

    getCookiesField() {
        return new Field('cookies')
            .addFieldList(this.getCookiesFieldList());
    }

    getCookiesFieldList() {
        return [
            'name',
            'description',
            'provider',
            'type',
            'lifetime'
        ];
    }
}

export default new CookiesSettingsQuery();
