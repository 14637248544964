import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import StyledButton from 'Component/StyledButton';
import { mediaSource } from 'Util/Media/MediaSource';

import { BANNER_HEIGHT, BANNER_RATIO, LOGIN } from './PLPPromoBanner.config';

import './PLPPromoBanner.style';

/** @namespace Scandipwa/Component/PLPPromoBanner/Component */
export class PLPPromoBannerComponent extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        linkText: PropTypes.string.isRequired,
        linkUrl: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        openMyAccountOverlay: PropTypes.func.isRequired,
        isSignedIn: PropTypes.bool.isRequired
    };

    renderButton() {
        const {
            linkUrl,
            linkText,
            openMyAccountOverlay,
            isSignedIn
        } = this.props;

        if (linkUrl === LOGIN && !isSignedIn) {
            return (
                <StyledButton
                  mix={ { block: 'PLPPromoBanner', elem: 'Link' } }
                  handleClick={ openMyAccountOverlay }
                  className=""
                >
                    { linkText }
                </StyledButton>
            );
        }

        if (linkUrl && linkUrl !== LOGIN) {
            return (
                <StyledButton
                  mix={ { block: 'PLPPromoBanner', elem: 'Link' } }
                  to={ linkUrl }
                >
                    { linkText }
                </StyledButton>
            );
        }

        return null;
    }

    renderWidgetContent() {
        const {
            title,
            description
        } = this.props;

        return (
            <div block="PLPPromoBanner" elem="Content">
                <h2 block="PLPPromoBanner" elem="Title">
                    { title }
                </h2>
                <p block="PLPPromoBanner" elem="Text">
                    { description }
                </p>
                { this.renderButton() }
            </div>
        );
    }

    render() {
        const { image } = this.props;

        return (
            <div block="PLPPromoBanner">
                <div block="PLPPromoBanner" elem="Image">
                    <Image
                      src={ mediaSource(image) }
                      ratio={ BANNER_RATIO }
                      wrapperSize={ { height: BANNER_HEIGHT } }
                    />
                </div>
                { this.renderWidgetContent() }
            </div>
        );
    }
}

export default PLPPromoBannerComponent;
