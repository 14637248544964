/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * overriding to extend the store config with the settings coming from AmastyGDPR.
 */
export function addAmastyGDPRFieldsToStoreConfig(args, callback) {
    return ([
        ...callback(...args),
        'gdpr__cookie_policy__auto_cleaning_days',
        'gdpr__cookie_policy__bar',
        'gdpr__cookie_policy__bar_visibility',
        'gdpr__cookie_policy__first_visit_show',
        'gdpr__cookie_policy__log_guest',

        'gdpr__classic__background_color',
        'gdpr__classic__cookies_bar_location',
        'gdpr__classic__links_color',
        'gdpr__classic__notification_text',
        'gdpr__classic__policy_text_color',

        'gdpr__classic__accept_button__button_color',
        'gdpr__classic__accept_button__button_color_hover',
        'gdpr__classic__accept_button__button_order',
        'gdpr__classic__accept_button__button_text',
        'gdpr__classic__accept_button__text_color',
        'gdpr__classic__accept_button__text_color_hover',

        'gdpr__classic__decline_button__button_color',
        'gdpr__classic__decline_button__button_color_hover',
        'gdpr__classic__decline_button__button_order',
        'gdpr__classic__decline_button__button_text',
        'gdpr__classic__decline_button__enable',
        'gdpr__classic__decline_button__text_color',
        'gdpr__classic__decline_button__text_color_hover',

        'gdpr__classic__settings_button__button_color',
        'gdpr__classic__settings_button__button_color_hover',
        'gdpr__classic__settings_button__button_order',
        'gdpr__classic__settings_button__button_text',
        'gdpr__classic__settings_button__text_color',
        'gdpr__classic__settings_button__text_color_hover'
    ]);
}

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields: addAmastyGDPRFieldsToStoreConfig
        }
    }
};
