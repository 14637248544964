/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import getStore from '@scandipwa/scandipwa/src/util/Store';

/**
 *
 * @type {string}
 */
export const CUR_CURRENCY = 'current_currency';

/**
 *
 * @type {number}
 */
export const ONE_HOUR = 3600;

/**
 *
 * @param {Any} currency
 * @returns {void}
 * @namespace Scandipwa/Util/Currency/setCurrency */
export const setCurrency = (currency) => {
    sessionStorage.setItem(CUR_CURRENCY, currency);
};

/**
 *
 * @returns {String} currency
 * @namespace Scandipwa/Util/Currency/getCurrency */
export const getCurrency = () => {
    const store = getStore();

    const {
        ConfigReducer: {
            default_display_currency_code = 'EUR'
        } = {}
    } = store.getState();

    const currency = sessionStorage.getItem(CUR_CURRENCY);

    return (typeof currency === 'string') ? currency : default_display_currency_code;
};

/**
 *
 * @param {object} currencyData
 * @param {object} currencyRates
 * @returns {object} filtered currencyData object and currency (rates) object
 * @namespace Scandipwa/Util/Currency/returnFilteredCurrencies */
export const returnFilteredCurrencies = (currencyData, currencyRates) => {
    if (
        currencyData?.available_currencies_data?.length < 1 || currencyRates?.exchange_rates?.length < 1) {
        return ({ currencyData, currencyRates });
    }

    const { available_currencies_data: availableCurrencies = [] } = currencyData;
    const { base_curreny_code: base, exchange_rates: rates = [] } = currencyRates;

    return ({
        currencyData: {
            ...currencyData,
            available_currencies_data:
                availableCurrencies.filter(({ value }) => (
                    value === base || rates?.find(({ currency_to }) => currency_to === value)?.rate > 0
                ))
        },
        currency: currencyRates
    });
};
