/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable max-lines */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { noopFn } from 'Util/Common';
import { prepareQuery } from 'Util/Query';
import { executeGet, fetchMutation, fetchQuery } from 'Util/Request';

import { GIFT_WRAP_PER_ITEM } from '../../config/GiftWrap.config';
import GiftWrapQuery from '../../query/GiftWrap.query';
import GiftWrapCategoriesQuery from '../../query/GiftWrapCategories.query';
import GiftWrapRemoveAllCartDataQuery from '../../query/Mutation/GiftWrapRemoveAllCartData.query';
import GiftWrapRemoveDataQuery from '../../query/Mutation/GiftWrapRemoveData.query';
import GiftWrapSetAllCartDataQuery from '../../query/Mutation/GiftWrapSetAllCartData.query';
import GiftWrapSetOneTypeAllCartDataQuery from '../../query/Mutation/GiftWrapSetOneTypeAllCartData.query';
import GiftWrapUpdateDataQuery from '../../query/Mutation/GiftWrapUpdateData.query';
import {
    GiftWrapOptionsType,
    MFGiftWrapItemType,
    MPGiftWrapConfigType
} from '../../type/GiftWrap';
import GiftWrapPopupComponent from './GiftWrapPopup.component';
import { GIFTWRAP_TAB_ID, ONE_MONTH_IN_SECONDS } from './GiftWrapPopup.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    mpGiftWrap: state.ConfigReducer.mpGiftWrap,
    cartId: state.CartReducer.cartTotals?.id,
    allCartWrap: state.CartReducer.cartTotals?.mpGiftWrapData,
    allCartPostcard: state.CartReducer.cartTotals?.mpPostcardData,
    currencyCode: state.CartReducer.cartTotals.quote_currency_code
});

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (title) => dispatch(showNotification('error', title)),
    showPopup: (popupId, title) => dispatch(showPopup(popupId, { title })),
    updateCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    )
});

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapPopup/Container */
export class GiftWrapPopupContainer extends PureComponent {
    static propTypes = {
        mpGiftWrap: MPGiftWrapConfigType.isRequired,
        showPopup: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        getPopupState: PropTypes.func.isRequired,
        selectedOptions: GiftWrapOptionsType.isRequired,
        onPopupSave: PropTypes.func.isRequired,
        popupId: PropTypes.string.isRequired,
        isWrapApplied: PropTypes.bool.isRequired,
        isCartItem: PropTypes.bool.isRequired,
        cartId: PropTypes.string.isRequired,
        itemId: PropTypes.number.isRequired,
        updateCart: PropTypes.func.isRequired,
        isAllCart: PropTypes.bool.isRequired,
        allCartWrap: MFGiftWrapItemType.isRequired,
        allCartPostcard: MFGiftWrapItemType.isRequired,
        currencyCode: PropTypes.string.isRequired,
        onGiftWrapChange: PropTypes.func
    };

    static defaultProps = {
        onGiftWrapChange: noopFn
    };

    categoryRef = createRef(null);

    containerFunctions = {
        onGiftNoteCheckboxChange: this.onGiftNoteCheckboxChange.bind(this),
        closePopup: this.closePopup.bind(this),
        onSelectCategory: this.onSelectCategory.bind(this),
        onSelectWrap: this.onSelectWrap.bind(this),
        onSave: this.onSave.bind(this),
        getFilteredAndSortedWrappers: this.getFilteredAndSortedWrappers.bind(this),
        getCategoryOptions: this.getCategoryOptions.bind(this),
        onRemoveWrap: this.onRemoveWrap.bind(this),
        onRemovePostcard: this.onRemovePostcard.bind(this),
        onRemove: this.onRemove.bind(this),
        onUpdate: this.onUpdate.bind(this),
        onSaveAll: this.onSaveAll.bind(this),
        onRemoveAll: this.onRemoveAll.bind(this),
        onTabChange: this.onTabChange.bind(this),
        onGiftNoteChange: this.onGiftNoteChange.bind(this),
        onPostCardChange: this.onPostCardChange.bind(this),
        checkWrapPresence: this.checkWrapPresence.bind(this)
    };

    componentDidUpdate(prevProps) {
        const { currencyCode: prevCurrencyCode } = prevProps;
        const { currencyCode } = this.props;

        if (currencyCode !== prevCurrencyCode) {
            // vvv renew by the currency switch
            this.getWraps();
        }
    }

    __construct(props) {
        const {
            selectedOptions
        } = props;
        // vvv not destructuring it as properties can be null or absent;
        const message = selectedOptions?.wrap?.giftNoteMessage.message;

        this.state = {
            isGiftNoteChecked: !!message,
            selectedCategory: null,
            postCardText: '',
            giftNoteText: '',
            activeTab: GIFTWRAP_TAB_ID,
            giftWrapOptions: selectedOptions || null
        };

        this.getCategories();
        this.getWraps();
    }

    containerProps() {
        const {
            mpGiftWrap,
            selectedOptions,
            popupId,
            isWrapApplied,
            isCartItem,
            itemId,
            isAllCart,
            currencyCode
        } = this.props;
        const {
            isGiftNoteChecked,
            giftWrapperCategories,
            giftWrappers,
            selectedCategory,
            giftWrapOptions,
            activeTab,
            postCardText,
            giftNoteText,
            cartId
        } = this.state;

        return {
            postCardText,
            giftNoteText,
            activeTab,
            isGiftNoteChecked,
            mpGiftWrap,
            giftWrapperCategories,
            categoryRef: this.categoryRef,
            giftWrappers,
            selectedCategory,
            postCardTextareaRef: this.postCardTextareaRef,
            giftNoteTextareaRef: this.giftNoteTextareaRef,
            giftWrapOptions,
            selectedOptions,
            popupId,
            cartId,
            itemId,
            isWrapApplied,
            isCartItem,
            isAllCart,
            currencyCode
        };
    }

    onGiftNoteChange({ target: { value } }) {
        this.setState({ giftNoteText: value });
    }

    onPostCardChange({ target: { value } }) {
        this.setState({ postCardText: value });
    }

    onTabChange(e) {
        this.setState({ activeTab: e.target.value });
    }

    getFilteredAndSortedWrappers(wrappers, currentWrapType) {
        const { selectedCategory } = this.state;

        return wrappers
            ?.filter(
                ({ wrap_type: wrapType, category }) => wrapType === currentWrapType
                    && (selectedCategory
                        ? category?.split(',').includes(selectedCategory)
                        : true)
            )
            .sort((wrap1, wrap2) => wrap1.sort_order - wrap2.sort_order);
    }

    getCategories = async () => {
        const query = prepareQuery(
            GiftWrapCategoriesQuery.getGiftWrapCategories(1)
        );

        try {
            const {
                mpGiftWrapCategories: {
                    total_count: totalCount,
                    items: categories,
                    page_info: pageInfo
                }
            } = await executeGet(
                query,
                'GiftWrapCategoriesQuery',
                ONE_MONTH_IN_SECONDS
            );

            this.setState({
                giftWrapperCategories: { totalCount, categories, pageInfo }
            });
        } catch (_error) {
            throw new Error(`GiftWrapPopup (getCategories): ${_error}`);
        }
    };

    getCategoryOptions() {
        const { giftWrapperCategories } = this.state;

        return giftWrapperCategories?.categories
            .sort((cat1, cat2) => cat1.sort_order - cat2.sort_order)
            .map((category) => ({
                label: category.name,
                value: category.category_id
            }));
    }

    // vvv check if the any Gift Wrap data was added to the extension
    checkWrapPresence(type) {
        const {
            giftWrappers: {
                wrappers
            } = {}
        } = this.state;

        return !!wrappers.find((wrapper) => wrapper.wrap_type === type);
    }

    getWraps = async () => {
        const { selectedOptions } = this.props;

        try {
            const {
                mpGetGiftWrap: {
                    items: wrappers,
                    page_info: pageInfo,
                    total_count: totalCount
                }
            } = await fetchQuery(GiftWrapQuery.getGiftWraps());

            this.setState({ giftWrappers: { wrappers, pageInfo, totalCount } });

            // vvv setting state with full info about wraps after got the id from BE
            if (!selectedOptions) {
                return;
            }

            const giftWrapOptions = Object.keys(selectedOptions)
                .reduce((acc, curr) => ({
                    ...acc,
                    [curr]: {
                        ...wrappers
                            .filter((wrapper) => wrapper.wrap_id === selectedOptions[curr].wrap_id)[0]
                    }
                }), {});

            this.setState({ giftWrapOptions });
        } catch (_error) {
            throw new Error(`GiftWrapPopup (getWraps): ${_error}`);
        }
    };

    // vvv Remove Gift Wrap data on popup window
    onRemoveWrap() {
        const {
            giftWrapOptions: {
                postcard
            },
            isGiftNoteChecked
        } = this.state;

        if (isGiftNoteChecked) {
            const { current: { fieldRef } } = this.giftNoteTextareaRef;
            fieldRef.value = '';

            this.onGiftNoteCheckboxChange();
        }

        if (postcard) {
            this.setState((prevState) => ({
                giftWrapOptions: {
                    postcard: prevState.giftWrapOptions.postcard
                }
            }));

            return;
        }

        this.setState({
            giftWrapOptions: {}
        });
    }

    // vvv Remove Postcard data on popup window
    onRemovePostcard() {
        const {
            giftWrapOptions: {
                wrap
            }
        } = this.state;
        const {
            current: {
                fieldRef
            }
        } = this.postCardTextareaRef;

        if (wrap) {
            this.setState((prevState) => ({
                giftWrapOptions: {
                    wrap: prevState.giftWrapOptions.wrap
                }
            }));

            fieldRef.value = '';

            return;
        }

        this.setState({
            giftWrapOptions: {}
        });

        fieldRef.value = '';
    }

    onSelectWrap(obj) {
        this.setState((prevState) => ({
            ...prevState,
            giftWrapOptions: {
                ...prevState.giftWrapOptions,
                [obj.wrap_type]: obj
            }
        }));
    }

    // vvv remove Gift Wrap data from selected CartItem
    async onRemove() {
        const { isGiftNoteChecked } = this.state;
        const {
            cartId,
            itemId,
            updateCart,
            allCartWrap,
            allCartPostcard,
            mpGiftWrap,
            onGiftWrapChange
        } = this.props;
        const allCartWrapData = allCartWrap || {};
        const allCartPostcardData = allCartPostcard || {};
        const { giftWrapType } = mpGiftWrap;

        this.setState({
            giftWrapOptions: {}
        });

        if (isGiftNoteChecked) {
            this.onGiftNoteCheckboxChange();
        }

        this.closePopup();

        await fetchMutation(GiftWrapRemoveDataQuery.removeData(cartId, itemId));

        // vvv this need because when switch from Both GiftWrapType to PerItem the all_cart status doesn't cleaned properly
        if (giftWrapType !== GIFT_WRAP_PER_ITEM && (allCartWrap || allCartPostcard)) {
            const {
                wrap_id: wrapId = null,
                gift_message: wrapMessage = ''
            } = allCartWrapData;
            const {
                wrap_id: postcardId = null,
                gift_message: postcardMessage = ''
            } = allCartPostcardData;

            await fetchMutation(GiftWrapSetAllCartDataQuery.setData(
                cartId,
                wrapId,
                postcardId,
                wrapMessage,
                postcardMessage
            ));
        }

        await onGiftWrapChange();
        updateCart();
    }

    // vvv set/update Gift Wrap data for selected CartItem
    async onUpdate() {
        const {
            cartId,
            itemId,
            updateCart,
            allCartWrap,
            allCartPostcard,
            mpGiftWrap,
            onGiftWrapChange
        } = this.props;
        const {
            isGiftNoteChecked,
            giftWrapOptions = {}
        } = this.state;
        const {
            wrap = null,
            postcard = null
        } = giftWrapOptions;
        const postcardMessage = this.postCardTextareaRef.current?.fieldRef?.value || '';
        const giftNoteMessage = isGiftNoteChecked
            ? this.giftNoteTextareaRef?.current.fieldRef?.value || ''
            : '';
        const { giftWrapType } = mpGiftWrap;

        if (wrap && Object.keys(wrap).length > 0) {
            wrap.message = giftNoteMessage || '';
        }

        if (postcard && Object.keys(postcard).length > 0) {
            postcard.message = postcardMessage || '';
        }

        await fetchMutation(GiftWrapUpdateDataQuery.updateData(cartId, itemId, wrap, postcard));

        this.closePopup();

        // vvv this need because when switch from Both GiftWrapType to PerItem the all_cart status doesn't cleaned properly
        if (giftWrapType !== GIFT_WRAP_PER_ITEM) {
            /**
             * If we remove some wrap type and is all_cart set to true
             * then we need to replace it by data from 'mpGiftWrapData' (if exist)
             */
            if (allCartWrap && (!wrap || Object.keys(wrap).length === 0)) {
                const { wrap_id: wrapId, gift_message: wrapMessage = '' } = allCartWrap;
                await fetchMutation(GiftWrapSetOneTypeAllCartDataQuery.setData(cartId, wrapId, wrapMessage));
            }

            /**
             * If we remove some postcard type and is all_cart set to true
             * then we need to replace it by data from 'mpPostcardData' (if exist)
             */
            if (allCartPostcard && (!postcard || Object.keys(postcard).length === 0)) {
                const { wrap_id: postcardId, gift_message: pcardMessage = '' } = allCartPostcard;
                await fetchMutation(GiftWrapSetOneTypeAllCartDataQuery.setData(cartId, postcardId, pcardMessage));
            }
        }

        await onGiftWrapChange();
        updateCart();
    }

    onSave() {
        const {
            postCardText,
            giftNoteText,
            giftWrapOptions = {}
        } = this.state;
        const {
            mpGiftWrap: { giftMessageFee },
            onPopupSave
        } = this.props;
        const postcardMessage = postCardText || '';
        const giftNoteMessage = giftNoteText || '';
        const selectedOptionsObj = {
            ...giftWrapOptions
        };
        const {
            wrap = {},
            postcard = {}
        } = selectedOptionsObj;

        if (wrap) {
            wrap.giftNoteMessage = {
                message: giftNoteMessage || null,
                fee: giftMessageFee
            };
        }

        if (postcard) {
            postcard.postcardMessage = postcardMessage || null;
        }

        onPopupSave({ ...selectedOptionsObj });

        this.closePopup();
    }

    async onSaveAll() {
        const {
            cartId,
            showErrorNotification,
            updateCart,
            onGiftWrapChange
        } = this.props;
        const {
            postCardText,
            giftNoteText,
            giftWrapOptions = {}
        } = this.state;
        const postcardMessage = postCardText || '';
        const wrapMessage = giftNoteText || '';
        const {
            wrap: {
                wrap_id: wrapId
            } = {},
            postcard: {
                wrap_id: postcardId
            } = {}
        } = giftWrapOptions;

        this.closePopup();

        try {
            await fetchMutation(GiftWrapSetAllCartDataQuery.setData(
                cartId,
                wrapId,
                postcardId,
                wrapMessage,
                postcardMessage
            ));
        } catch (error) {
            showErrorNotification(error[0].message);
            this.getWraps();
        }

        await onGiftWrapChange();
        updateCart();
    }

    async onRemoveAll() {
        const {
            cartId,
            updateCart,
            onGiftWrapChange
        } = this.props;

        this.setState({
            giftWrapOptions: {},
            postCardText: '',
            giftNoteText: ''
        });

        this.closePopup();

        await fetchMutation(GiftWrapRemoveAllCartDataQuery.removeData(cartId));

        await onGiftWrapChange();
        updateCart();
    }

    onSelectCategory() {
        const {
            current: { value }
        } = this.categoryRef;

        this.setState({ selectedCategory: value });
    }

    onGiftNoteCheckboxChange() {
        const {
            isGiftNoteChecked,
            giftWrapOptions
        } = this.state;

        if (!giftWrapOptions || !giftWrapOptions.wrap) {
            this.setState({ isGiftNoteChecked: !isGiftNoteChecked });

            return;
        }

        const {
            giftNoteMessage,
            ...restValues
        } = giftWrapOptions.wrap;

        // vvv removing an obj property if the user unchecked the isGiftNoteChecked option when it has previous message
        if (isGiftNoteChecked && giftNoteMessage) {
            this.setState((prevState) => ({
                ...prevState,
                giftWrapOptions: {
                    ...prevState.giftWrapOptions,
                    wrap: { ...restValues }
                }
            }));
        }

        this.setState({ isGiftNoteChecked: !isGiftNoteChecked });
    }

    closePopup() {
        const {
            showPopup,
            popupId
        } = this.props;

        showPopup(popupId);
    }

    render() {
        const { mpGiftWrap } = this.props;

        if (!mpGiftWrap.enabled) {
            return null;
        }

        return (
            <GiftWrapPopupComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GiftWrapPopupContainer);
