import { STATUS_PASSWORD_UPDATED } from '@scandipwa/scandipwa/src/route/PasswordChangePage/PasswordChangePage.config';
import { Redirect } from 'react-router';

import { getQueryParam } from 'Util/Url';

const passAdditionalParamsToRedirect = (args, callback, _instance) => {
    const { passwordResetStatus } = _instance.state;
    const { location } = _instance.props;
    const key = getQueryParam('key', location);
    const id = getQueryParam('id', location);

    if (passwordResetStatus === STATUS_PASSWORD_UPDATED && key && id) {
        return (
            <Redirect to={ {
                pathName: '/',
                state: {
                    id,
                    key
                }
            } }
            />
        );
    }

    return (
        <>
            { callback(...args) }
        </>
    );
};

export const config = {
    'Route/PasswordChangePage/Container': {
        'member-function': {
            render: passAdditionalParamsToRedirect
        }
    }
};

export default config;
