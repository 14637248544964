/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { LATVIA_LANGS_LIST } from 'Component/StoreSwitcher/StoreSwitcher.config';
import { appendWithStoreCode } from 'Util/Url';

import { itemType } from '../../type/MagefanBlog';
import PDPRelatedPosts from './PDPRelatedPosts.component';

/** @namespace Scandiweb/MagefanBlog/Component/PDPRelatedPosts/Container/mapStateToProps */
export const mapStateToProps = () => ({
});

/** @namespace Scandiweb/MagefanBlog/Component/PDPRelatedPosts/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({

});

/** @namespace Scandiweb/MagefanBlog/Component/PDPRelatedPosts/Container */
export class PDPRelatedPostsContainer extends PureComponent {
    static propTypes = {
        relatedPosts: PropTypes.arrayOf({
            itemType
        })
    };

    static defaultProps = {
        relatedPosts: []
    };

    containerFunctions = {
        blogButtonLink: this.blogButtonLink.bind(this)
    };

    containerProps() {
        const { relatedPosts } = this.props;

        return { relatedPosts };
    }

    blogButtonLink() {
        const { pathname } = window.location;

        const notLatviaStore = Object.values(LATVIA_LANGS_LIST)
            .every((store) => !pathname.startsWith(`/${ store }/`));

        if (notLatviaStore) {
            return appendWithStoreCode('/articles');
        }

        return appendWithStoreCode('/raksti');
    }

    render() {
        return (
            <PDPRelatedPosts
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PDPRelatedPostsContainer);
