const addLoyaltySpecificFields = (args, callback) => [
    ...callback(...args),
    'loyalty_number'
];

export default {
    'Query/MyAccount/Query': {
        'member-function': {
            _getCustomerFields: addLoyaltySpecificFields
        }
    }
};
