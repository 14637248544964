/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const GDPR_NOTICE = 'AMASTY_GDPR_NOTICE';
export const GDPR_NOTICE_POSITION = 10000;
export const GDPR_POPUP_KEY = 'AMASTY_GDPR_POPUP';
export const GDPR_SETTINGS_MODAL_POPUP_KEY = 'AMASTY_GDPR_SETTINGS_MODAL';
export const GDPR_COOKIES_DETAILS_MODAL_POPUP_KEY = 'AMASTY_GDPR_COOKIES_DETAILS_MODAL';
export const GDPR_BROWSER_DATABASE_LOCATION = 'gdpr';
export const GDPR_BROWSER_DATABASE_VALUE = 'ACCEPTED';
export const GDPR_ACTION_TAKEN = 'GDPR_ACTION_TAKEN';

export const PRIVACY_SETTINGS = 'my-account-cookies-settings';
export const PRIVACY_SETTINGS_URL = '/privacy-settings';
export const PERSONAL_DATA_FILE_NAME = 'personal-data.csv';
export const ORDER_AND_RETURN_URL = '/orders-and-returns';
export const ORDERS_AND_RETURNS = 'ORDERS_AND_RETURNS';
export const GDPR_COOKIES_SETTINGS_TEXT = __('GDPR Cookies Settings');
export const ORDERS_AND_RETURNS_TEXT = __('Orders and Returns');
export const ORDER_ID = __('Order ID');
export const BILLING_LAST_NAME = __('Billing Last Name');
export const FIND_ORDER_BY = __('Find Order By');
export const EMAIL_TEXT = __('Email');
export const CUSTOMER_EMAIL = __('Email: ');
export const CONTINUE_TEXT = __('continue');
export const ORDER_INFO = __('Order info');
export const STATE = __('State: ');
export const STATUS = __('Status: ');
export const SHIPPING_METHOD = __('Shipping method: ');
export const PAYMENT_METHOD = __('Payment method: ');
export const CUSTOMER_INFO = __('Customer info');
export const FIRST_NAME = __('First Name: ');
export const MIDDLE_NAME = __('Middle Name: ');
export const LAST_NAME = __('Last Name: ');
export const BILLING_ADDRESS = __('Billing Address');
export const CITY = __('City: ');
export const BILLING_EMAIL = __('Billing email: ');
export const REGION = __('Region: ');
export const STREET = __('Street: ');
export const POST_CODE = __('Post code: ');
export const SHIPPING_ADDRESS = __('Shipping Address');
export const SHIPPING_EMAIL = __('Shipping email: ');
export const ANONYMIZE_DATA = __('Anonymise Data');

export const NEWSLETTER_SUBSCRIPTION_SCOPE = 'subscription';
export const REGISTRATION_SCOPE = 'registration';
export const CONTACT_US_SCOPE = 'contactUs';
export const CHECKOUT_SCOPE = 'checkout';

export const MESSAGE_GDPR_NOT_ALLOWED = __('Warning >>> GDPR is not allowed in this region!');
export const MESSAGE_ANONYMIZED_SUCCESS = __('Your cookies data has been anonymized!');
export const MESSAGE_CSV_DOWNLOAD_SUCCESS = __('Your personal data has been downloaded!');
export const MESSAGE_DELETE_SUCCESS = __('Your delete request has been sent!');

// eslint-disable-next-line no-magic-numbers
export const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
export const AM_COOKIE_NAME = 'amcookie_allowed';
