/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import FlagComponent from './Flag.component';

/** @namespace Scandiweb/MakeCommerce/Component/Flag/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
});

/** @namespace Scandiweb/MakeCommerce/Component/Flag/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
});

/** @namespace Scandiweb/MakeCommerce/Component/Flag/Container */
export class FlagContainer extends PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        isSelected: PropTypes.bool.isRequired,
        setSelectedTab: PropTypes.func.isRequired
    };

    containerFunctions = {
        onClick: this.onClickHandler.bind(this)
    };

    containerProps() {
        const {
            label,
            isSelected
        } = this.props;

        return {
            label,
            isSelected
        };
    }

    onClickHandler() {
        const {
            setSelectedTab,
            label
        } = this.props;

        setSelectedTab(label);
    }

    render() {
        return (
            <FlagComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlagContainer);
