/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import AmastySpecialPromotionsProQuery from '../query/AmastySpecialPromotionsPro.query';

export const extendCartQuery = (args, callback) => {
    const query = callback(...args);
    const amastyDiscounts = AmastySpecialPromotionsProQuery.getDiscounts();

    query.addField(amastyDiscounts);

    return query;
};

export default {
    'Query/Cart/Query': {
        'member-function': {
            getCartQuery: extendCartQuery
        }
    }
};
