import PropTypes from 'prop-types';
import { createRef, memo, PureComponent } from 'react';

import {
    PLAYER_STATE_LOADED,
    PLAYER_STATE_PLAYING
} from 'Component/VimeoPlayer/VimeoPlayer.config';
import { NEW_CATEGORY_TRANSITION_EVENT } from 'Route/CategoryPage/CategoryPage.extended.config';
import { MixType, RefType } from 'Type/Common.type';

import PageHeaderComponent from './PageHeader.component';

/** @namespace Scandipwa/Component/PageHeader/Container */
export class PageHeaderContainer extends PureComponent {
    static propTypes = {
        isImageDataloaded: PropTypes.bool.isRequired,
        vimeoVideoURL: PropTypes.string,
        imageSrc: PropTypes.string,
        // if title is null - then <h4> tag is not rendered at all
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        mix: MixType,
        blockRef: RefType
    };

    static defaultProps = {
        vimeoVideoURL: null,
        imageSrc: null,
        mix: {},
        blockRef: null
    };

    state = {
        isAnimationEnded: false
    };

    containerFunctions = {
        onVideoLoadingStateChanged: this.onVideoLoadingStateChanged.bind(this)
    };

    componentDidMount() {
        window.document.addEventListener(NEW_CATEGORY_TRANSITION_EVENT, this.eventHandler);
        // eslint-disable-next-line no-magic-numbers
        setTimeout(() => this.setState({ isAnimationEnded: true }), 1250);
    }

    componentWillUnmount() {
        window.document.removeEventListener(NEW_CATEGORY_TRANSITION_EVENT, this.eventHandler);

        const node = this.descRef.current;

        if (node) {
            node.classList.remove('PageHeader-Animation');
        }
    }

    eventHandler() {
        this.triggerAnimation = true;
        this.animateDescription();
    }

    animateDescription() {
        if (!this.triggerAnimation) {
            return;
        }

        this.setState({ isAnimationEnded: false }, () => {
            setTimeout(() => {
                this.setState({ isAnimationEnded: true });
            // eslint-disable-next-line no-magic-numbers
            }, 1250);
        });
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            isVideoLoaded: false
        };

        this.eventHandler = this.eventHandler.bind(this);
        this.descRef = createRef();
        this.triggerAnimation = false;
    }

    onVideoLoadingStateChanged({ state: newState }) {
        this.setState({
            isVideoLoaded: newState === PLAYER_STATE_LOADED || PLAYER_STATE_PLAYING
        });
    }

    containerProps() {
        const {
            vimeoVideoURL,
            imageSrc,
            title,
            text,
            mix,
            blockRef
        } = this.props;

        const {
            isVideoLoaded,
            isAnimationEnded
        } = this.state;

        // ↓↓ disable animation for video header
        if (vimeoVideoURL && !isAnimationEnded) {
            this.setState({ isAnimationEnded: true });
        }

        return {
            vimeoVideoURL,
            imageSrc,
            title,
            text,
            mix,
            blockRef,
            isVideoLoaded,
            isAnimationEnded,
            descRef: this.descRef
        };
    }

    render() {
        return (
            <PageHeaderComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export const MemoizedPageHeaderContainer = memo(PageHeaderContainer, (prevProps, nextProps) => {
    const {
        mix,
        ...restProps
    } = prevProps;

    const {
        mix: nextMix,
        ...restNextProps
    } = nextProps;

    if (JSON.stringify(mix) !== JSON.stringify(nextMix)) {
        return false;
    }

    const restPropsAreNotEqual = Object.keys(restProps).some(
        (key) => typeof restProps[key] !== 'function' && restProps[key] !== restNextProps[key]
    );

    return !restPropsAreNotEqual;
});

export default MemoizedPageHeaderContainer;
