import { crawlerRegEx } from 'Util/Browser';

export * from 'SourceUtil/Browser/Browser';

/** @namespace Scandipwa/Util/Browser/isCrawler */
export const isCrawler = () => {
    const {
        isCrawlerCache
    } = window;

    if (typeof isCrawlerCache === 'boolean') {
        return isCrawlerCache;
    }

    const isCrawlerTest = crawlerRegEx.test(navigator.userAgent);
    window.isCrawlerCache = isCrawlerTest;

    return isCrawlerTest;
};

/** @namespace Scandipwa/Util/Browser/toggleScrollMobile */
export const toggleScrollMobile = (state) => {
    const overflowType = state ? 'hidden' : 'unset';
    document.body.style.overflow = overflowType;
};
