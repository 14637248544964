/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import SPWAOrderQuery from 'Query/Order.query';
import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageplazaGiftWrap/Query/Order/Query */
export class OrderQuery {
    getMPGiftWrapField() {
        return new Field('mpGiftWrap')
            .addFieldList(this.getMPGiftWrapFields());
    }

    getMPGiftWrapFields() {
        return [
            this.getGiftWrapTaxField(),
            this.getGiftWrapAmountField(),
            this.getGiftWrapBaseAmountField(),
            this.getPostcardAmountField(),
            this.getPostcardBaseAmountField()
        ];
    }

    getGiftWrapTaxField() {
        return new Field('giftWrapTax')
            .addFieldList(SPWAOrderQuery._getOrderPriceFields());
    }

    getGiftWrapAmountField() {
        return new Field('giftWrapAmount')
            .addFieldList(SPWAOrderQuery._getOrderPriceFields());
    }

    getGiftWrapBaseAmountField() {
        return new Field('giftWrapBaseAmount')
            .addFieldList(SPWAOrderQuery._getOrderPriceFields());
    }

    getPostcardAmountField() {
        return new Field('postcardAmount')
            .addFieldList(SPWAOrderQuery._getOrderPriceFields());
    }

    getPostcardBaseAmountField() {
        return new Field('postcardBaseAmount')
            .addFieldList(SPWAOrderQuery._getOrderPriceFields());
    }
}

export default new OrderQuery();
