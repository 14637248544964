/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DataContainer from 'SourceUtil/Request/DataContainer';

import { CheckboxItemDataType } from '../../type/gdpr.type';
import {
    removeUserAcceptanceToCheckboxesInBrowserDB,
    storeUserAcceptanceToCheckboxesInBrowserDB
} from '../../util/GDPR.util';
import CheckboxComponent from './Checkbox.component';

/** @namespace Scandiweb/AmastyGdpr/Component/Checkbox/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/Checkbox/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/Checkbox/Container */
export class CheckboxContainer extends DataContainer {
    static propTypes = {
        data: CheckboxItemDataType,
        scope: PropTypes.string.isRequired,
        onStateChanged: PropTypes.func.isRequired
    };

    state = {
        consentCheckbox: {
            name: '',
            isChecked: ''
        }
    };

    handleCheckboxChange(event) {
        const {
            data: {
                name
            },
            onStateChanged
        } = this.props;
        const { target: { checked } } = event;

        const newState = {
            name,
            isChecked: checked
        };

        this.setState({
            consentCheckbox: newState
        });

        if (onStateChanged) {
            onStateChanged(newState);
        }

        if (checked) {
            storeUserAcceptanceToCheckboxesInBrowserDB(name);
        }

        removeUserAcceptanceToCheckboxesInBrowserDB(name);
    }

    generateId() {
        const {
            scope,
            data: {
                consentCode
            } = {}
        } = this.props;

        // vvv generating a unique string
        // eslint-disable-next-line no-magic-numbers
        const uniqueKey = (Math.random() + 1).toString(36).substring(7);
        const idAttribute = consentCode.replace(/([^a-zA-Z0-9_]|\s)/g, '_');

        return `C_${ scope }__${ idAttribute }}_${uniqueKey}`;
    }

    containerProps() {
        const {
            scope,
            data
        } = this.props;
        const {
            consentCheckbox
        } = this.state;

        return {
            scope,
            data,
            consentCheckbox,
            id: this.generateId()
        };
    }

    containerFunctions = {
        handleCheckboxChange: this.handleCheckboxChange.bind(this)
    };

    render() {
        return (
            <CheckboxComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxContainer);
