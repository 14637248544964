/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    CheckoutOrderSummaryPriceLine as SourceCheckoutOrderSummaryPriceLineComponent
} from 'SourceComponent/CheckoutOrderSummaryPriceLine/CheckoutOrderSummaryPriceLine.component';
import { rearrangePriceString } from 'Util/Price';

/** @namespace Scandipwa/Component/CheckoutOrderSummaryPriceLine/Component */
export class CheckoutOrderSummaryPriceLineComponent extends SourceCheckoutOrderSummaryPriceLineComponent {
    /**
     * Overriden price render funtions to rearrage price val and currency symbol
     */
    renderPrice() {
        const { price, currency } = this.props;

        return (
            <strong>
                { rearrangePriceString(price, currency) }
            </strong>
        );
    }

    renderSubPrice() {
        const { subPrice, currency } = this.props;

        if (!subPrice) {
            return null;
        }

        return (
            <span>
                { __('Excl. tax: %s', rearrangePriceString(subPrice, currency)) }
            </span>
        );
    }
}

export default CheckoutOrderSummaryPriceLineComponent;
