/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import GiftWrapCheckoutSection from 'Component/GiftWrapCheckoutSection';
import GiftWrapCheckoutSummarySection from 'Component/GiftWrapCheckoutSummarySection';
import { fetchMutation } from 'Util/Request';
import getStore from 'Util/Store';

import { GIFT_WRAP_PER_ITEM } from '../config/GiftWrap.config';
import GiftWrapRemoveAllCartDataQuery from '../query/Mutation/GiftWrapRemoveAllCartData.query';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

const GIFT_WRAP_SECTION_CHECKOUT_SUMMARY_POPUP_ID = 'gift-wrap-section-checkout-summary-popup';

const mapStateToProps = (args, callback) => {
    const [
        {
            ConfigReducer: {
                mpGiftWrap: {
                    enabled: isMPGiftWrapEnabled,
                    giftWrapType
                } = {}
            } = {}
        }
    ] = args;

    return {
        ...callback(...args),
        isMPGiftWrapEnabled,
        giftWrapType
    };
};

const removeAllCartData = async (cartId) => {
    const cartDispatcher = (await CartDispatcher).default;
    const { dispatch } = getStore();

    await fetchMutation(GiftWrapRemoveAllCartDataQuery.removeData(cartId));

    cartDispatcher.updateInitialCartData(dispatch);
};

const addGiftWrapSummaryAfterTax = (args, callback, instance) => {
    const {
        checkoutStep,
        isMPGiftWrapEnabled,
        giftWrapType,
        totals: {
            mpGiftWrapData,
            mpPostcardData,
            id
        } = {}
    } = instance.props;
    const isInvalidType = !giftWrapType || giftWrapType === GIFT_WRAP_PER_ITEM;
    const isSwitchToPerItem = giftWrapType === GIFT_WRAP_PER_ITEM
    && (mpGiftWrapData?.wrap_id
    || mpPostcardData?.wrap_id);

    // vvv remove existent all cart data if wrap type has been switched to the 'PerItem'
    if (isSwitchToPerItem) {
        removeAllCartData(id);
    }

    // vvv if extension is disable or it's not our type then skip
    if (
        !isMPGiftWrapEnabled
        || isInvalidType
        || checkoutStep
        // ^^^ if we are in checkout (any step) we don't need this component
    ) {
        return callback(...args);
    }

    return (
        <>
            <GiftWrapCheckoutSummarySection
              giftWrapData={ mpGiftWrapData }
              giftWrapPostcardData={ mpPostcardData }
              popupId={ GIFT_WRAP_SECTION_CHECKOUT_SUMMARY_POPUP_ID }
            />
            { callback(...args) }
        </>
    );
};

const renderDiscountCode = (args, callback, instance) => {
    const {
        isPickInStoreMethodSelected,
        isMPGiftWrapEnabled,
        giftWrapType,
        totals: {
            mpGiftWrapData,
            mpPostcardData
        } = {},
        onGiftWrapChange
    } = instance.props;
    const isInvalidType = !giftWrapType || giftWrapType === GIFT_WRAP_PER_ITEM;

    // vvv if extension is disable or it's not our type then skip
    if (
        !isMPGiftWrapEnabled
        || isInvalidType
        || isPickInStoreMethodSelected
        // ^^^ This condition is coming from original function
    ) {
        return callback(...args);
    }

    return (
        <>
            { callback(...args) }
            <GiftWrapCheckoutSection
              giftWrapData={ mpGiftWrapData }
              giftWrapPostcardData={ mpPostcardData }
              popupId={ GIFT_WRAP_SECTION_CHECKOUT_SUMMARY_POPUP_ID }
              onGiftWrapChange={ onGiftWrapChange }
            />
        </>
    );
};

export default {
    'Component/CheckoutOrderSummary/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderTotals: addGiftWrapSummaryAfterTax,
            renderDiscountCode
        }
    }
};
