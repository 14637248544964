import CloseIcon from 'Component/CloseIcon';
import Html from 'Component/Html';
import {
    NotificationComponent as StendersNotificationComponent
} from 'StendersComponent/Notification/Notification.component';
import CSS from 'Util/CSS';

import {
    ANIMATION_DURATION, ERROR_NOTIFICATION_LIFETIME, ERROR_TYPE,
    NOTIFICATION_LIFETIME
} from './Notification.config';

import './Notification.extended.style';

/** @namespace Scandipwa/Component/Notification/Component */
export class NotificationComponent extends StendersNotificationComponent {
    // eslint-disable-next-line
    componentDidMount() {
        const { notification: { msgType }, lifeTime } = this.props;

        // Make sure error notification stays a little longer
        if (msgType.toLowerCase() === ERROR_TYPE) {
            this.hideTimeout = setTimeout(() => this.hideNotification(), lifeTime || ERROR_NOTIFICATION_LIFETIME);
        } else {
            this.hideTimeout = setTimeout(() => this.hideNotification(), lifeTime || NOTIFICATION_LIFETIME);
        }

        CSS.setVariable(this.notification, 'animation-duration', `${ANIMATION_DURATION}ms`);
    }

    /**
     * Overriden to render a CloseIcon svg instead of the before/after hack.
     */
    render() {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        const messageText = msgText.value
            ? msgText[0].toLowerCase() + msgText.slice(1)
            : msgText.charAt(0).toLowerCase() + msgText.slice(1);
        const messageType = msgType === 'info' ? __('Warning') : msgType.charAt(0).toUpperCase() + msgType.slice(1);

        return (
            <div block="Notification" mods={ mods } ref={ this.notification } id={ id }>
                <button block="Notification" elem="Button" onClick={ this.hideNotification }>
                    <CloseIcon isNotification />
                </button>
                <p block="Notification" elem="Text">
                    <Html content={ `<b>${ __(messageType) }:</b> ${ __(messageText) }` } />
                </p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default NotificationComponent;
