/* eslint-disable no-unused-vars */
import {
    customerEmailAndPasswordFields,
    customerInformationFields
} from 'Component/MyAccountCustomerForm/MyAccountCustomerForm.form';
import StyledButton from 'Component/StyledButton/StyledButton.container';
import {
    MyAccountCustomerFormComponent as StendersMyAccountCustomerForm
} from 'StendersComponent/MyAccountCustomerForm/MyAccountCustomerForm.component';

/** @namespace Scandipwa/Component/MyAccountCustomerForm/Component */
export class MyAccountCustomerFormComponent extends StendersMyAccountCustomerForm {
    /**
     * Overridden to add date of birth
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    get customerInformationFieldMap() {
        const {
            showTaxVatNumber,
            handleChangeEmailCheckbox,
            handleChangePasswordCheckbox,
            handleEmailInput,
            showEmailChangeField,
            showPasswordChangeField,
            vatNumberRequired,
            email,
            customer: {
                firstname = '',
                lastname = '',
                dob = '',
                taxvat = '',
                mobilenumber = ''
            }
        } = this.props;

        return customerInformationFields({
            showTaxVatNumber,
            firstname,
            lastname,
            taxvat,
            dob,
            email,
            mobilenumber,
            handleEmailInput,
            handleChangePasswordCheckbox,
            handleChangeEmailCheckbox,
            showEmailChangeField,
            showPasswordChangeField,
            vatNumberRequired
        });
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    get emailAndPasswordFieldMap() {
        const {
            minimunPasswordCharacter,
            showEmailChangeField,
            showPasswordChangeField,
            handlePasswordInput,
            handleEmailInput,
            currentPassword,
            email,
            range,
            customer: {
                dob = '',
                mobilenumber = ''
            }
        } = this.props;

        return customerEmailAndPasswordFields({
            minimunPasswordCharacter,
            showEmailChangeField,
            showPasswordChangeField,
            handlePasswordInput,
            handleEmailInput,
            currentPassword,
            email,
            dob,
            mobilenumber,
            range
        });
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getFormProps() {
        const {
            setStateOnError
        } = this.props;

        return {
            onSubmit: this.onFormSuccess,
            onError: setStateOnError
        };
    }

    renderActions() {
        return (
            <StyledButton
              mix={ { block: 'MyAccountInformation', elem: 'Submit' } }
              type="submit"
            >
                { __('Save') }
            </StyledButton>
        );
    }
}

export default MyAccountCustomerFormComponent;
