/* eslint-disable simple-import-sort/sort */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
    CategoryItemContainer as StendersCategoryItemContainer
} from 'StendersComponent/CategoryItem/CategoryItem.container';
import CategoryItemComponent from './CategoryItem.component';

/** @namespace Scandipwa/Component/CategoryItem/Container */
export class CategoryItemContainer extends StendersCategoryItemContainer {
    static propTypes = {
        ...this.propTypes,
        categoryChildren: PropTypes.any.isRequired,
        isCategoryReset: PropTypes.bool.isRequired,
        categoryArray: PropTypes.arrayOf(PropTypes.string).isRequired
    };

    __construct(props) {
        super.__construct(props);

        this.state = { isCategorySelected: false };
    }

    componentDidUpdate() {
        const { category, categoryArray } = this.props;

        this.setState({ isCategorySelected: categoryArray[0] === category });
    }

    containerProps() {
        const {
            category,
            isLink,
            url,
            toggleMenuState,
            categoryChildren,
            isCategoryReset
        } = this.props;

        const { isCategorySelected } = this.state;

        return {
            category,
            isLink,
            url,
            toggleMenuState,
            categoryChildren,
            isCategoryReset,
            isCategorySelected
        };
    }

    currentCategoryFunc(e) {
        const { category, handleCategoryArray } = this.props;
        if (!e.ctrlKey) {
            handleCategoryArray(category);
        }
    }

    render() {
        return (
            <CategoryItemComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default CategoryItemContainer;
