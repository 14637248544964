import '../style/CheckoutPayment/CheckoutPayment.override.style.scss';

const addInstruction = (args, callback, _instance) => {
    const { instruction } = _instance.props.method;

    return (
        <>
            { callback(...args) }
            <p block="CheckoutPayment" elem="instructions">{ instruction }</p>
        </>
    );
};

export const config = {
    'Component/CheckoutPayment/Component': {
        'member-function': {
            render: addInstruction
        }
    }
};

export default config;
