/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { COUNTRY_NAMES_MAP } from '../config/MakeCommerce.config';

/**
 * `chOrder` or payment channels orders is a string with the admin preferred order of payment
 * channels separated with `,`. so, first we split the string on `,` then we sort the payment
 * channel based on their `name` property.
 *
 * @namespace Scandiweb/MakeCommerce/Util/MakeCommerce/Util/sortMethods */
export const sortMethods = (methods, chOrder) => {
    if (!chOrder) {
        return methods;
    }

    const sortOrder = chOrder.split(',');

    const sortFunc = (methodOne, methodTwo) => {
        const methodOneOrder = sortOrder.indexOf(methodOne.name);
        const methodTwoOrder = sortOrder.indexOf(methodTwo.name);

        if (methodOneOrder === methodTwoOrder) {
            return 0;
        }
        if (methodOneOrder === -1) {
            return 1;
        }
        if (methodTwoOrder === -1) {
            return -1;
        }
        if (methodOneOrder < methodTwoOrder) {
            return -1;
        }

        return 1;
    };

    return methods.sort(sortFunc);
};

/**
 * converts the payments channels into an array.
 * @namespace Scandiweb/MakeCommerce/Util/MakeCommerce/Util/convertPaymentMethodsToList */
export const convertPaymentMethodsToList = (channels, chOrder) => {
    const flattenMethods = Object.values(channels).reduce((acc, item) => acc.concat(item), []);
    const sorted = sortMethods(flattenMethods, chOrder);

    return sorted;
};

/**
 * converts the payments channels from { banklinks, payLater, ... }
 * into country groups {latvia, estonia, ...}.
 *
 * @namespace Scandiweb/MakeCommerce/Util/MakeCommerce/Util/convertPaymentMethodsToCountryGroups */
export const convertPaymentMethodsToCountryGroups = (paymentChannels, areCcGrouped, chOrder) => {
    const { cards = [], ...restOfMethods } = paymentChannels;
    const flattenMethods = Object.values(restOfMethods).flat();
    const sortedMethods = sortMethods(flattenMethods, chOrder);

    const widgetsList = sortedMethods.reduce((acc, item) => {
        const { country } = item;
        const countryLabel = COUNTRY_NAMES_MAP[country];

        if (!acc[countryLabel]) {
            acc[countryLabel] = [item];
        } else {
            acc[countryLabel].push(item);
        }

        return acc;
    }, {});

    // vvv if the credit cards shouldn't be displayed in a separate group, then concatenate them to
    // every country group.
    if (!areCcGrouped) {
        return Object.entries(widgetsList).reduce((acc, [key, methods]) => {
            acc[key] = methods.concat(cards);
            return acc;
        }, {});
    }

    return widgetsList;
};
