/**
 * @category Stenders
 * @author Sebastian Pavel <info@scandiweb.com>
 */

const renderTitle = (args, callback, instance) => {
    const {
        title_prefix,
        title_suffix,
        title
    } = instance.props;

    const titlePrefix = title_prefix ? `${ title_prefix }` : '';
    const titleSuffix = title_suffix ? `${ title_suffix }` : '';

    return (
        <title>{ `${ titlePrefix }${ title }${ titleSuffix }` }</title>
    );
};

export default {
    'Component/Meta/Component': {
        'member-function': {
            renderTitle
        }
    }
};
