/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getCheckoutEventData, getCheckoutOptionEventData } from '../data/checkout';
import {
    DL_VAL_EXISTING_CUSTOMER,
    getCustomerData,
    NEW_CUSTOMER,
    USER_EXISTING_CUSTOMER
} from '../data/customer';
import { getPurchaseEventData } from '../data/purchase';
import {
    GTM_EVENT_KEY_CHECKOUT,
    GTM_EVENT_KEY_CHECKOUT_OPTION,
    GTM_EVENT_KEY_PURCHASE
} from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

export const fireCheckoutOptionEvent = debounceCallback(async (step, option) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_CHECKOUT_OPTION,
        customerId,
        ...await getCheckoutOptionEventData(step, option)
    });
});

export const fireCheckoutEvent = debounceCallback(async (step) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_CHECKOUT,
        customerId,
        ...await getCheckoutEventData(step)
    });
});

export const firePurchaseEvent = debounceCallback(async (orderId, guestQuoteId) => {
    const { customerId, userExistingCustomer } = await getCustomerData();

    const waitForEventsCanFireEvent = () => new Promise((resolve) => {
        const handleEventsCanFire = () => {
            document.removeEventListener('PurchaseCanFire', handleEventsCanFire);
            resolve();
        };

        document.addEventListener('PurchaseCanFire', handleEventsCanFire);
    });

    waitForEventsCanFireEvent().then(
        /** @namespace Scandiweb/Gtm/Event/Checkout/firePurchaseEvent/debounceCallback/waitForEventsCanFireEvent/then */
        async () => {
            pushToDataLayer({
                event: GTM_EVENT_KEY_PURCHASE,
                customerId,
                customer_status: userExistingCustomer === DL_VAL_EXISTING_CUSTOMER
                    ? USER_EXISTING_CUSTOMER : NEW_CUSTOMER,
                ...await getPurchaseEventData(orderId, guestQuoteId)
            });
        }
    );
});
