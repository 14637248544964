/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';

import { MAKECOMMERCE_METHOD_CODE } from '../../config/MakeCommerce.config';
import {
    MakeCommercePaymentType,
    MakeCommercePaymentUiType,
    MakeCommerceSelectedPaymentMethodType,
    MakeCommerceSelectedPaymentMethodTypeDefaultValues
} from '../../type/MakeCommerce.type';
import MCPaymentMethod from '../MCPaymentMethod';

import './MCGroupWidget.style';

/** @namespace Scandiweb/MakeCommerce/Component/MCGroupWidget/Component */
export class MCGroupWidgetComponent extends PureComponent {
    static propTypes = {
        ui: MakeCommercePaymentUiType.isRequired,
        isSelected: PropTypes.bool.isRequired,
        isRoot: PropTypes.bool.isRequired,
        onClickHandler: PropTypes.func.isRequired,
        selectedPaymentCode: PropTypes.string.isRequired,
        selectedMCPaymentMethod: MakeCommerceSelectedPaymentMethodType,
        widgetLabel: PropTypes.string.isRequired,
        methods: PropTypes.arrayOf(MakeCommercePaymentType).isRequired,
        onMakeCommerceClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        selectedMCPaymentMethod: MakeCommerceSelectedPaymentMethodTypeDefaultValues
    };

    renderHeading() {
        const {
            widgetLabel,
            isSelected,
            onClickHandler
        } = this.props;

        return (
            <button
              block="CheckoutPayment"
              mods={ { isSelected } }
              elem="Button"
              type="button"
              onClick={ onClickHandler }
            >
                <div block="CheckoutPayment" elem="Heading">
                    <Field
                      type={ FIELD_TYPE.checkbox }
                      attr={ {
                          id: `option-makecommerce-widget-${widgetLabel}`,
                          name: `option-makecommerce-widget-${widgetLabel}`,
                          checked: isSelected
                      } }
                      label={ widgetLabel }
                      isDisabled={ false }
                    />
                    <p block="CheckoutPayment" elem="Description">{ __('Secured and fast transaction') }</p>
                </div>
            </button>
        );
    }

    renderSingleMethod = (item) => {
        const {
            widgetLabel,
            selectedPaymentCode,
            selectedMCPaymentMethod: {
                name: selectedName,
                country: selectedCountry
            },
            onMakeCommerceClick
        } = this.props;

        const { name, country } = item;

        // vvv 1. any payment method inside makecommerce will have the `paymentCode`
        // vvv 2. payment methods don't have a unique name, so we check for both name, and country.
        const isSelected = selectedPaymentCode === MAKECOMMERCE_METHOD_CODE
                && selectedName === name
                && selectedCountry === country;

        return (
            <MCPaymentMethod
              key={ widgetLabel + name + country }
              item={ item }
              isSelected={ isSelected }
              onMakeCommerceClick={ onMakeCommerceClick }
            />
        );
    };

    renderMethods() {
        const {
            isSelected,
            methods,
            ui: {
                logoSize
            }
        } = this.props;

        return (
            <div block="MCWidget" elem="PaymentMethodsWrapper" mods={ { logoSize, isSelected } }>
                { Object.values(methods).map(this.renderSingleMethod) }
            </div>
        );
    }

    renderRootContent() {
        const {
            isRoot
        } = this.props;

        return (
            <li block="CheckoutPayment" mods={ { isMakeCommerce: true, isMakeCommerceRoot: isRoot } }>
                { this.renderHeading() }
                { this.renderMethods() }
            </li>
        );
    }

    render() {
        const {
            isRoot
        } = this.props;

        if (isRoot) {
            return this.renderRootContent();
        }

        return this.renderMethods();
    }
}

export default MCGroupWidgetComponent;
