/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */

import ContactForm from 'Component/ContactForm';
import FacePLPPromoBanner from 'Component/FacePLPPromoBanner';
import FacePLPPromoBannerGR from 'Component/FacePLPPromoBannerGR';
import FacePLPPromoBannerLV from 'Component/FacePLPPromoBannerLV';
import HairPLPPromoBanner from 'Component/HairPLPPromoBanner';
import HairPLPPromoBannerGR from 'Component/HairPLPPromoBannerGR';
import HairPLPPromoBannerLV from 'Component/HairPLPPromoBannerLV';
import HeroSliderText from 'Component/HeroSliderText';
import { HERO_SLIDER_TEXT_CMS_TYPE } from 'Component/HeroSliderText/HeroSliderText.config';
import InstagramSection from 'Component/InstagramSection';
import IntroVideo from 'Component/IntroVideo';
import { INTRO_VIDEO_CMS_TYPE } from 'Component/IntroVideo/IntroVideo.config';
import LandingPageSlider from 'Component/LandingPageSlider';
import PLPPromoBanner from 'Component/PLPPromoBanner';
import PLPPromoBannerGR from 'Component/PLPPromoBannerGR';
import PLPPromoBannerLV from 'Component/PLPPromoBannerLV';
import PromotionWidget from 'Component/PromotionWidget';
import { WidgetFactory as SourceWidgetFactory } from 'SourceComponent/WidgetFactory/WidgetFactory.component';

import './WidgetFactory.extended.style.scss';

/** @namespace Scandipwa/Component/WidgetFactory/Component */
export class WidgetFactory extends SourceWidgetFactory {
    renderMap = {
        ...this.renderMap,
        InstagramSection: {
            component: InstagramSection
        },
        ContactUsFormWidget: {
            component: ContactForm
        },
        [HERO_SLIDER_TEXT_CMS_TYPE]: {
            component: HeroSliderText
        },
        PromotionWidget: {
            component: PromotionWidget
        },
        PLPPromoBannerGR: {
            component: PLPPromoBannerGR
        },
        PLPPromoBannerLV: {
            component: PLPPromoBannerLV
        },
        [INTRO_VIDEO_CMS_TYPE]: {
            component: IntroVideo
        },
        PLPPromoBanner: {
            component: PLPPromoBanner
        },
        FacePLPPromoBanner: {
            component: FacePLPPromoBanner
        },
        FacePLPPromoBannerGR: {
            component: FacePLPPromoBannerGR
        },
        FacePLPPromoBannerLV: {
            component: FacePLPPromoBannerLV
        },
        HairPLPPromoBanner: {
            component: HairPLPPromoBanner
        },
        HairPLPPromoBannerGR: {
            component: HairPLPPromoBannerGR
        },
        HairPLPPromoBannerLV: {
            component: HairPLPPromoBannerLV
        },
        LandingPageSliderWidget: {
            component: LandingPageSlider
        }
    };

    // Remove this function from Widget packages plugins.
    renderContent() {
        const {
            type
        } = this.props;

        const {
            component: Widget
        } = this.renderMap[type] || {};

        if (Widget !== undefined) {
            return (
                    <Widget { ...this.props } />
            );
        }

        return null;
    }
}

export default WidgetFactory;
