/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

// vvv svg files may exceed the max-len limit
/* eslint-disable max-len */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import {
    ESTONIA_LABEL,
    FINLAND_LABEL,
    LATVIA_LABEL,
    LITHUANIA_LABEL
} from '../../config/MakeCommerce.config';

import './Flag.style';

/** @namespace Scandiweb/MakeCommerce/Component/Flag/Component */
export class FlagComponent extends PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        isSelected: PropTypes.bool.isRequired
    };

    renderMap = {
        [ESTONIA_LABEL]: () => this.renderEE(),
        [FINLAND_LABEL]: () => this.renderFI(),
        [LATVIA_LABEL]: () => this.renderLV(),
        [LITHUANIA_LABEL]: () => this.renderLT()
    };

    renderEE() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
                <g fillRule="evenodd" strokeWidth="1pt">
                    <rect width="640" height="477.9" rx="0" ry="0" />
                    <rect width="640" height="159.3" y="320.7" fill="#fff" rx="0" ry="0" />
                    <path fill="#1791ff" d="M0 0h640v159H0z" />
                </g>
            </svg>
        );
    }

    renderLV() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
                <g fillRule="evenodd">
                    <path fill="#fff" d="M0 0h640v480H0z" />
                    <path fill="#981e32" d="M0 0h640v192H0zm0 288h640v192H0z" />
                </g>
            </svg>
        );
    }

    renderLT() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
                <g fillRule="evenodd" strokeWidth="1pt" transform="scale(.64143 .96773)">
                    <rect width="1063" height="708.7" fill="#006a44" rx="0" ry="0" transform="scale(.93865 .69686)" />
                    <rect width="1063" height="236.2" y="475.6" fill="#c1272d" rx="0" ry="0" transform="scale(.93865 .69686)" />
                    <path fill="#fdb913" d="M0 0h998v165H0z" />
                </g>
            </svg>
        );
    }

    renderFI() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
                <path fill="#fff" d="M0 0h640v480H0z" />
                <path fill="#002f6c" d="M0 175h640v131H0z" />
                <path fill="#002f6c" d="M176 0h130v480H175z" />
            </svg>
        );
    }

    render() {
        const { label, onClick, isSelected } = this.props;
        const renderFlag = this.renderMap[label] || {};

        return (
            <button block="Flag" mods={ { isSelected } } onClick={ onClick } type="button">
                { typeof renderFlag === 'function' && renderFlag() }
            </button>
        );
    }
}

export default FlagComponent;
