/** @namespace Scandipwa/Util/Transition/Index/transitionElementFromTo */
export const transitionElementFromTo = async ({
    elFrom,
    elToCallback,
    zIndex,
    isCopyCssProperties,
    action
}) => {
    if (!elFrom || !elToCallback) {
        return;
    }

    const {
        top: st, left: sl, width: sw, height: sh
    } = elFrom.getBoundingClientRect();

    const transitionWrapper = document.createElement('div');
    transitionWrapper.id = 'transition-wrapper';
    transitionWrapper.classList.add('element-transition');

    if (zIndex) {
        transitionWrapper.style.setProperty('--transition-el-z-index', zIndex);
    }

    const clonedElement = elFrom.cloneNode(true);

    if (isCopyCssProperties) {
        const styles = window.getComputedStyle(elFrom);

        ['font', 'background', 'outline', 'border', 'color'].forEach((key) => {
            clonedElement.style.setProperty(key, styles.getPropertyValue(key));
        });
    }

    clonedElement.style.top = `${ st }px`;
    clonedElement.style.left = `${ sl }px`;
    clonedElement.style.width = `${ sw }px`;
    clonedElement.style.height = `${ sh }px`;

    transitionWrapper.appendChild(clonedElement);
    document.body.appendChild(transitionWrapper);

    if (action) {
        action();
    }

    // wait until condition is met
    await new Promise((resolve, reject) => {
        // eslint-disable-next-line fp/no-let
        let maxTries = 100;

        const interval = setInterval(() => {
            if (maxTries <= 0) {
                clearInterval(interval);
                reject();
            }

            const condition = elToCallback();

            if (condition) {
                clearInterval(interval);
                resolve();
            }

            maxTries -= 1;
        // eslint-disable-next-line no-magic-numbers
        }, 100);
    });

    const imageTo = elToCallback();

    const {
        top: tt, left: tl, width: tw, height: th
    } = imageTo.getBoundingClientRect();

    clonedElement.style.transform = `translate3d(${ tl - sl }px, ${ tt - st }px, 0) scale(${ tw / sw }, ${ th / sh }`;

    setTimeout(() => {
        transitionWrapper.classList.add('image-transition--active');
        // eslint-disable-next-line no-magic-numbers
    }, 150);

    setTimeout(() => {
        transitionWrapper.remove();
        // eslint-disable-next-line no-magic-numbers
    }, 300);
};
