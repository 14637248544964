import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import TextPlaceholder from 'Component/TextPlaceholder';
import VimeoPlayer from 'Component/VimeoPlayer';
import { MixType, RefType } from 'Type/Common.type';

import Html from '../../component/Html';
import Image from '../../component/Image';

import './PageHeader.style';

/** @namespace Scandipwa/Component/PageHeader/Component */
export class PageHeaderComponent extends PureComponent {
    static propTypes = {
        isVideoLoaded: PropTypes.bool.isRequired,
        vimeoVideoURL: PropTypes.string.isRequired,
        imageSrc: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        mix: MixType.isRequired,
        blockRef: RefType.isRequired,
        descRef: RefType.isRequired,
        onVideoLoadingStateChanged: PropTypes.func.isRequired,
        isAnimationEnded: PropTypes.bool.isRequired
    };

    render() {
        const {
            isVideoLoaded,
            vimeoVideoURL,
            imageSrc,
            title,
            text,
            mix,
            blockRef,
            onVideoLoadingStateChanged,
            isAnimationEnded,
            descRef
        } = this.props;

        // eslint-disable-next-line fp/no-let
        let imageBlock = null;
        if (vimeoVideoURL) {
            imageBlock = (
                <VimeoPlayer
                  vimeoVideoURL={ vimeoVideoURL }
                  mix={ { block: 'PageHeader', elem: 'ImageContainer' } }
                  onVideoLoadingStateChanged={ onVideoLoadingStateChanged }
                  isShowLoader
                />
            );
        } else if (imageSrc) {
            imageBlock = (
                <div block="PageHeader" elem="ImageContainer">
                    { /* There are two modes: mobile - the <img> element is visible, the other tag is hidden... */ }
                    { /*
                        BTW it does not need lazy-loading, because this is the first image we want to load
                        when we open a category.
                      */ }
                    <Image
                      className="PageHeader-Image"
                      alt="Image"
                      src={ imageSrc }
                      isPlain
                    />
                    { /* ... and desktop - we hide the <img> and show the image as background */ }
                    <div
                      block="PageHeader"
                      elem="ImageBackground"
                      style={ { backgroundImage: `url(${ imageSrc })` } }
                    />
                </div>
            );
        }

        const titleBlock = title !== null
            ? (
                <h1 block="PageHeader" elem="Title">
                    <TextPlaceholder content={ title } />
                </h1>
            )
            : null;

        const isVideoLoading = vimeoVideoURL && !isVideoLoaded;

        return (
            <article
              block="PageHeader"
              mods={ {
                  hasImageBlock: !!imageBlock,
                  noImageBlock: !imageBlock,
                  hasVideo: !!vimeoVideoURL
              } }
              mix={ mix }
            >
                { imageBlock }
                <div
                  block="PageHeader"
                  elem="HeadTextContainer"
                  mods={ {
                      hasVideo: !!vimeoVideoURL,
                      hasImage: !!imageSrc && !vimeoVideoURL,
                      noImageBlock: !imageBlock,
                      isVideoLoading
                  } }
                >
                    <div
                      block="PageHeader"
                      elem="HeadText"
                      mods={ {
                          noImageBlock: !imageBlock,
                          hasImageBlock: !!imageBlock
                      } }
                      ref={ blockRef }
                    >
                        { titleBlock }
                        <div
                          block="PageHeader"
                          elem="Description"
                          mods={ { isAnimationEnded } }
                          ref={ descRef }
                        >
                            <Html content={ text } />
                        </div>
                    </div>
                </div>
            </article>
        );
    }
}

export default PageHeaderComponent;
