import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import { mediaSource } from 'Util/Media/MediaSource';

import {
    CP_LOGIN_LINK
} from '../../config/CustomPopup.config';
import PopupSubscription from '../PopupSubscription';

import './CustomPopup.style';

/** @namespace Stenders/CustomPopup/Component/CustomPopup/Component */
export class CustomPopupComponent extends PureComponent {
    static propTypes = {
        isSubscriptionForm: PropTypes.bool,
        htmlContent: PropTypes.string,
        yesBtnText: PropTypes.string,
        yesBtnUrl: PropTypes.string,
        isHidden: PropTypes.bool,
        closeCustomPopup: PropTypes.func.isRequired,
        openPopupLink: PropTypes.func.isRequired,
        openMyAccountOverlay: PropTypes.func.isRequired,
        bottomImage: PropTypes.string
    };

    static defaultProps = {
        isSubscriptionForm: false,
        htmlContent: '',
        isHidden: true,
        yesBtnText: __('OK'),
        yesBtnUrl: null,
        bottomImage: null
    };

    renderCTABtn() {
        const {
            yesBtnText,
            isSubscriptionForm,
            yesBtnUrl,
            openPopupLink,
            openMyAccountOverlay
        } = this.props;

        if (isSubscriptionForm) {
            return null;
        }

        if (yesBtnUrl === CP_LOGIN_LINK) {
            return (
                <button
                  block="CustomPopup"
                  elem="Link"
                  mix={ { block: 'Button' } }
                  onClick={ openMyAccountOverlay }
                >
                    { yesBtnText }
                </button>
            );
        }

        if (yesBtnUrl) {
            return (
                <Link
                  block="CustomPopup"
                  elem="Link"
                  mix={ { block: 'Button' } }
                  onClick={ openPopupLink }
                  to={ yesBtnUrl }
                >
                { yesBtnText }
                </Link>
            );
        }

        return null;
    }

    renderCloseButton() {
        const {
            closeCustomPopup
        } = this.props;

        return (
            <button
              block="CustomPopup"
              elem="CloseBtn"
              aria-label={ __('Close') }
              onClick={ closeCustomPopup }
            >
                <CloseIcon />
            </button>
        );
    }

    renderNewsletterSubscriptionBlock() {
        const { isSubscriptionForm } = this.props;
        if (!isSubscriptionForm) {
            return null;
        }

        return (<PopupSubscription key="PopupNewsletterSubscription" />);
    }

    renderOverlay() {
        return (
            <div
              block="CustomPopup"
              elem="Overlay"
            />
        );
    }

    renderBottomImage() {
        const {
            bottomImage
        } = this.props;

        if (!bottomImage) {
            return null;
        }

        return (
            <Image
              mix={ { block: 'CustomPopup', elem: 'BottomImage' } }
              ratio="custom"
              src={ mediaSource(bottomImage) }
            />
        );
    }

    render() {
        const {
            htmlContent,
            isHidden
        } = this.props;

        if (isHidden) {
            return null;
        }

        return (
            <div block="CustomPopup">
                { this.renderOverlay() }
                <div mix={ { block: 'CustomPopup', elem: 'Wrapper' } }>
                    <header block="CustomPopup" elem="Header">
                        { this.renderCloseButton() }
                    </header>
                    <div mix={ { block: 'CustomPopup', elem: 'Content' } }>
                        <Html content={ htmlContent } />
                        { this.renderNewsletterSubscriptionBlock() }
                        <div mix={ { block: 'CustomPopup', elem: 'Buttons' } }>
                            { this.renderCTABtn() }
                        </div>
                    </div>
                    { this.renderBottomImage() }
                </div>
            </div>
        );
    }
}

export default CustomPopupComponent;
