/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyGdpr/Query/Actions/Query */
export class ActionsQuery {
    acceptCookies() {
        return new Field('gdprAllow');
    }

    anonymiseData() {
        return new Field('gdprAnonymise');
    }

    anonymiseOrder(orderId) {
        return new Field('gdprAnonymiseOrder')
            .addArgument('orderId', 'String!', orderId);
    }

    deleteCookies() {
        return new Field('gdprDelete');
    }

    downloadCookiesLog(orderId) {
        return new Field('gdprDownload')
            .addArgument('orderId', 'String', orderId);
    }

    updateAcceptedCookies(cookiesString) {
        return new Field('gdprUpdate')
            .addArgument('cookiesString', 'String', cookiesString);
    }
}

export default new ActionsQuery();
