/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    CHECKOUT_URL,
    DETAILS_URL_STEP
} from 'Route/Checkout/Checkout.config';
import { UPDATE_TOTALS, updateTotals } from 'Store/Cart/Cart.action';

import { getCustomerData } from '../../data/customer';
import {
    fireAddToCartEvent,
    fireRemoveFromCartEvent,
    fireViewCartEvent
} from '../../event/cart';

// vvv Index items, so we have SKU => item
const indexItems = (items = []) => items.reduce(
    (acc, item) => ({ ...acc, [item.sku]: item }),
    {}
);

const fireCartEvents = (args, callback) => {
    const [state, action] = args;
    const { type, customerId } = action;
    const newState = callback(...args);

    if (type !== UPDATE_TOTALS) {
        return newState;
    }

    const { pathname } = window.location;
    const urlSplitter = pathname.split('/');
    const successCheckoutPath = `${CHECKOUT_URL }/${ DETAILS_URL_STEP}`;

    // vvv if its 'checkout/success' url
    if (pathname === successCheckoutPath) {
        return callback(...args);
    }

    const { cartTotals: { items, id, quote_currency_code: currencyCode } } = newState;
    const { cartTotals: { items: prevItems, id: prevId } } = state;
    const indexedItems = indexItems(items);
    const indexedPrevItems = indexItems(prevItems);

    if (!id || !prevId) {
        return newState;
    }

    // TODO: compare items, qty
    Object.entries(indexedItems).forEach(([sku, item]) => {
        const prevItem = indexedPrevItems[sku];

        if (!prevItem) {
            fireAddToCartEvent(item, currencyCode, customerId);
            // ^^^ item was added
            return;
        }

        const { qty } = item;
        const { qty: prevQty } = prevItem;

        // eslint-disable-next-line fp/no-delete
        delete indexedPrevItems[sku];
        // ^^^ Remove processed indexed items, all which will remain
        // in the map should be considered removed items

        if (qty === prevQty) {
            return;
        }

        if (qty > prevQty) {
            // ^^^ Item qty increased

            fireAddToCartEvent(
                {
                    ...item,
                    qty: qty - prevQty
                    // ^^^ If qty was increased => treat as delta add to cart
                },
                currencyCode,
                customerId
            );

            return;
        }

        // vvv Item qty decreased
        fireRemoveFromCartEvent(
            {
                ...item,
                qty: prevQty - qty
                // ^^^ if qty was decreased => treat as delta remove from cart
            },
            currencyCode,
            customerId
        );
    });

    if (!urlSplitter.includes('checkout') || !urlSplitter.includes('success')) {
        Object.values(indexedPrevItems).forEach((item) => {
        // ^^^ item was removed
            fireRemoveFromCartEvent(item, currencyCode, customerId);
        });
    }

    return callback(...args);
};

const aroundUpdateCartData = async (args) => {
    const [cartData, dispatch] = args;
    const { customerId } = await getCustomerData();

    dispatch(
        {
            ...updateTotals(cartData),
            customerId
        }
    );
};

const afterComponentDidUpdate = async (args, callback, instance) => {
    callback(...args);

    const { customerId } = await getCustomerData();
    const {
        isLoading,
        cartSubtotal,
        totals: {
            quote_currency_code: currencyCode,
            items
        } = {}
    } = instance.props;
    const {
        isInitialLoad,
        isGTMViewCartEventDispatched
    } = instance.state;

    // vvv Wait for cartItems to lead and initialLoad to finish and then send viewCart ONCE!
    if (
        !isLoading
        && !isInitialLoad
        && !isGTMViewCartEventDispatched
    ) {
        fireViewCartEvent(items, cartSubtotal, currencyCode, customerId);
        instance.setState({ isGTMViewCartEventDispatched: true });
    }
};

export default {
    'Store/Cart/Reducer/CartReducer': {
        function: fireCartEvents
    },
    'Store/Cart/Dispatcher': {
        'member-function': {
            _updateCartData: aroundUpdateCartData
        }
    },
    'Route/CartPage/Container': {
        'member-function': {
            componentDidUpdate: afterComponentDidUpdate
        }
    }
};
