/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Loader from 'Component/Loader';
import { TotalsType } from 'Type/MiniCart.type';

import {
    MakeCommercePaymentsType,
    MakeCommercePaymentUiType,
    MakeCommercePaymentUiTypeDefaultValues,
    MakeCommerceSelectedPaymentMethodType,
    MakeCommerceSelectedPaymentMethodTypeDefaultValues
} from '../../type/MakeCommerce.type';
import MCPaymentWrapperComponent from './MCPaymentWrapper.component';

export const MakeCommerceDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/MakeCommerce/MakeCommerce.dispatcher'
);

/** @namespace Scandiweb/MakeCommerce/Component/MCPaymentWrapper/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    paymentMethods: state.MakeCommerceReducer.paymentMethods,
    ui: state.MakeCommerceReducer.ui,
    selectedMCPaymentMethod: state.MakeCommerceReducer.selectedMCPaymentMethod,
    isLoading: state.MakeCommerceReducer.isLoading,
    totals: state.CartReducer.cartTotals
});

/** @namespace Scandiweb/MakeCommerce/Component/MCPaymentWrapper/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    initConfig: () => MakeCommerceDispatcher.then(
        ({ default: dispatcher }) => dispatcher.fetchMcPaymentConfig(dispatch)
    )
});

/** @namespace Scandiweb/MakeCommerce/Component/MCPaymentWrapper/Container */
export class MCPaymentWrapperContainer extends PureComponent {
    static propTypes = {
        paymentMethods: MakeCommercePaymentsType,
        ui: MakeCommercePaymentUiType,
        selectedPaymentCode: PropTypes.string.isRequired,
        selectedMCPaymentMethod: MakeCommerceSelectedPaymentMethodType,
        onMakeCommerceClick: PropTypes.func.isRequired,
        initConfig: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        totals: TotalsType.isRequired
    };

    static defaultProps = {
        paymentMethods: {
            other: [],
            payLater: [],
            cards: [],
            banklinks: []
        },
        ui: MakeCommercePaymentUiTypeDefaultValues,
        selectedMCPaymentMethod: MakeCommerceSelectedPaymentMethodTypeDefaultValues
    };

    containerFunctions = {
    };

    componentDidMount() {
        const { initConfig } = this.props;
        initConfig();
    }

    containerProps() {
        const {
            selectedPaymentCode,
            selectedMCPaymentMethod,
            onMakeCommerceClick,
            paymentMethods,
            ui,
            totals: { quote_currency_code }
        } = this.props;

        return {
            ui,
            selectedPaymentCode,
            selectedMCPaymentMethod,
            onMakeCommerceClick,
            paymentMethods,
            quote_currency_code
        };
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return <Loader isLoading />;
        }

        return (
            <MCPaymentWrapperComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MCPaymentWrapperContainer);
