/* eslint-disable react/forbid-elements, react/forbid-prop-types, jsx-a11y/no-static-element-interactions, react/jsx-no-bind */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import ProgressBar from 'Component/ProgressBar';
import Slider from 'Component/Slider';

import './InstagramSection.style';

/** @namespace Scandipwa/Component/InstagramSection/Component */
export class InstagramSectionComponent extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        linkText: PropTypes.string.isRequired,
        linkUrl: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(PropTypes.string).isRequired,
        handleCurrentSlideChange: PropTypes.func.isRequired,
        handleNextClick: PropTypes.func.isRequired,
        handlePrevClick: PropTypes.func.isRequired,
        currentIndex: PropTypes.number.isRequired,
        sliderItemWidth: PropTypes.number.isRequired,
        isSlider: PropTypes.bool.isRequired,
        slideRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]).isRequired
    };

    renderTitle() {
        const { title } = this.props;

        return (
            <div block="InstagramSection" elem="Title">
                <h3>{ title || '' }</h3>
            </div>
        );
    }

    renderProgressbar() {
        const {
            currentIndex, images, handlePrevClick, handleNextClick
        } = this.props;

        return (
            <ProgressBar
              currentItemIndex={ currentIndex }
              totalItems={ images.length }
              goPrev={ handlePrevClick }
              goNext={ handleNextClick }
              isArrowsShown
            />
        );
    }

    renderImages() {
        const { images, linkUrl } = this.props;

        return (
            <div block="InstagramSection" elem="Images">
                { images.map((image) => (
                    <Link block="Image" elem="Wrapper" to={ linkUrl }>
                        <Image src={ image } alt={ image } />
                    </Link>
                )) }
            </div>
        );
    }

    renderLink() {
        const { linkText, linkUrl } = this.props;

        if (!linkUrl || !linkText) {
            return null;
        }

        return (
            <div block="InstagramSection" elem="Link">
                <Link block="Link" to={ linkUrl }>{ linkText }</Link>
            </div>
        );
    }

    renderMobileImages() {
        const {
            images,
            linkUrl,
            currentIndex,
            handleCurrentSlideChange,
            slideRef,
            sliderItemWidth
        } = this.props;

        return (
            <div block="InstagramSection" elem="SliderWrapper">
                <Slider
                  mix={ { block: 'InstagramSection', elem: 'Slider' } }
                  activeImage={ currentIndex }
                  onActiveImageChange={ handleCurrentSlideChange }
                  isGallery
                  customSlideWidth={ sliderItemWidth }
                >
                    { images.map((image) => (
                        <Link block="Image" elem="Wrapper" to={ linkUrl }>
                            <div ref={ slideRef }>
                                <Image src={ image } alt={ image } />
                            </div>
                        </Link>
                    )) }
                </Slider>
            </div>
        );
    }

    render() {
        const { isSlider } = this.props;

        return (
            <div block="InstagramSection">
                <div block="InstagramSection" elem="Header">
                    { this.renderTitle() }
                    { this.renderLink() }
                </div>
                { isSlider && this.renderMobileImages() }
                { isSlider && this.renderProgressbar() }
                { !isSlider && this.renderImages() }
            </div>
        );
    }
}

export default InstagramSectionComponent;
