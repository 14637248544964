/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-lines */
import PropTypes from 'prop-types';

import CheckBoxIcon from 'Component/CheckBoxIcon';
import CheckmarkIcon from 'Component/CheckmarkIcon';
import LoadingIcon from 'Component/LoadingIcon';
import {
    FieldComponent as SourceField
} from 'StendersComponent/Field/Field.component';
import { noopFn } from 'Util/Common';

import { DATE_FIELD_TYPE, FIELD_TYPE, MAX_DATE_FIELD_LENGTH } from './Field.config';

import './Field.extended.style';

/** @namespace Scandipwa/Component/Field/Component */
export class FieldComponent extends SourceField {
    static propTypes = {
        ...this.propTypes,
        formatDateField: PropTypes.func.isRequired,
        moveCursorToEnd: PropTypes.func.isRequired,
        uid: PropTypes.number.isRequired
    };

    renderMap = {
        ...this.renderMap,
        [FIELD_TYPE.date]: this.renderDateInput.bind(this)
    };

    renderDateInput() {
        const {
            setRef, attr, events, formatDateField, moveCursorToEnd
        } = this.props;
        const { altPlaceholder, ...rest } = attr;

        return (
            <div block="Field" elem="DateWrapper">
                <input
                  ref={ (elem) => setRef(elem) }
                  type={ DATE_FIELD_TYPE }
                    /* eslint-disable-next-line spaced-comment,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
                  { ...rest }
                    /* eslint-disable-next-line spaced-comment,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
                  { ...events }
                  maxLength={ MAX_DATE_FIELD_LENGTH }
                  onKeyDown={ formatDateField }
                  onClick={ moveCursorToEnd }
                />
                <div block="Field" elem="PlaceHolder">
                    { altPlaceholder }
                </div>
            </div>
        );
    }

    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange },
            events,
            isDisabled,
            label,
            uid
        } = this.props;
        const { id = '', checked, value = '' } = newAttr;
        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };

        // eslint-disable-next-line fp/no-let
        let idForLabel = id;
        // eslint-disable-next-line fp/no-let
        let attributesForInput = newAttr;
        if (!id) {
            idForLabel = `f_${ uid }`;
            attributesForInput = {
                ...newAttr,
                id: idForLabel
            };
        }

        // if button value is "none" do not disable
        const isButtonDisabled = (!value.match('none') && isDisabled);
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : null);

        return (
          <div htmlFor={ idForLabel } block="Field" elem={ `${elem}Label` } mods={ { isDisabled } }>
            <input
              ref={ (elem) => setRef(elem) }
              disabled={ isButtonDisabled ? isDisabled : false }
              type={ type }
              { ...attributesForInput }
              { ...inputEvents }
              // shipping options have checked attr assigned so prioritize its value
              defaultChecked={ isChecked }
            />
            <div block="input-control" disabled={ isDisabled } />
            { label }
            { this.renderRequiredTag() }
          </div>
        );
    }

    /**
     * Overriden to pass maxlength attribute if prop is passed.
     * overridden to allow styling of checkbox
     * To pass isCheckout to render a special checkbox for checkout route.
     * to pass isChecked mod for styling purposes.
     */
    renderCheckbox() {
        const {
            type,
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange },
            events,
            isDisabled,
            label,
            isCheckout,
            uid
        } = this.props;
        const { id = '', checked, value = '' } = newAttr;
        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };

        // eslint-disable-next-line fp/no-let
        let idForLabel = id;
        // eslint-disable-next-line fp/no-let
        let attributesForInput = newAttr;
        if (!id) {
            idForLabel = `f_${ uid }`;
            attributesForInput = {
                ...newAttr,
                id: idForLabel
            };
        }

        const isButtonDisabled = (!value.match('none') && isDisabled);
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : false);

        return (
          <label htmlFor={ idForLabel } block="Field" elem={ `${elem}Label` } mods={ { isDisabled } }>
              <input
                ref={ (elem) => setRef(elem) }
                disabled={ isButtonDisabled ? isDisabled : false }
                type={ type }
                /* Core eslint problem */
                /* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
                { ...attributesForInput }
                { ...inputEvents }
                /* eslint-enable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
                defaultChecked={ isChecked }
              />
              <div block="CheckBoxIconControl" mods={ { isCheckout, isChecked: isCheckout && isChecked } }>
                  <CheckBoxIcon />
                  <div
                    block="CheckBoxIconControl"
                    elem="CheckboxState"
                    mods={ { isCheckout, isChecked } }
                  />
              </div>
              <div
                block="Field"
                elem="LabelContent"
              >
                { label }
              </div>
          </label>
        );
    }

    /**
 * Overriden to pass maxlength attribute if prop is passed.
 */
    renderTextArea() {
        const {
            setRef, attr, events, isDisabled, validationRule: { range: { max } = { max: null } } = {}
        } = this.props;

        return (
          <textarea
            ref={ (elem) => setRef(elem) }
            disabled={ isDisabled }
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            { ...attr }
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            { ...events }
            maxLength={ max }
          />
        );
    }

    /**
         * Overridden to make those icons render correctly inside the NewsletterSubscription form
         * in the Footer. We render both and show the correct one using CSS.
         *
         * Override super to remove redundant loader in product review popup
         * @returns {JSX.Element[]|[JSX.Element]|*}
         */
    renderInputsIcons() {
        const {
            isCheckmarkControlled,
            isLoading,
            isCheckmarkShown,
            validationRule,
            invalidInput,
            validationResponse,
            type
        } = this.props;

        if (isCheckmarkControlled) {
            return isCheckmarkShown ? <CheckmarkIcon /> : null;
        }

        if (type !== FIELD_TYPE.text) {
            return null;
        }

        if (!validationRule) {
            return null;
        }

        if (isLoading || (invalidInput && Object.keys(validationResponse || {}).length === 0)) {
            return <LoadingIcon />;
        }

        if (validationResponse === true) {
            return <CheckmarkIcon />;
        }

        return null;
    }

    /**
         * Overridden to render input validation status icons and to change the validation logic for inputs.
         * Added renderTextAreaText also typical error msg is set to display:none for text area only and replaced by length count turning red
         */
    render() {
        const {
            type,
            validationResponse,
            mix,
            attr: { name },
            isDisabled
        } = this.props;

        const inputRenderer = this.renderMap[type] ?? this.renderDefaultInput.bind(this);

        const { mods: { hasError = false } = {} } = mix;

        return (
          <div block="Field" elem="Wrapper" mods={ { type, name, isDisabled } }>
            <div
              block="Field"
              mods={ {
                  type,
                  isValid: !hasError && validationResponse === true,
                  hasError: Object.keys(validationResponse || {}).length !== 0
              } }
              mix={ mix }
            >
              { type !== FIELD_TYPE.checkbox && type !== FIELD_TYPE.radio && this.renderLabel() }
              <div
                block="Field"
                elem="InputWrapper"
                mods={ { isNewsletter: type === FIELD_TYPE.newsletterCheckbox } }
              >
                { inputRenderer && inputRenderer() }
                { this.renderInputsIcons() }
                { this.renderArrow() }
              </div>
            </div>
            { this.renderErrorMessages() }
            { this.renderTextAreaCount() }
            { this.renderSubLabel() }
          </div>
        );
    }
}

export default FieldComponent;
