import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps, mapStateToProps as sourceMapStateToProps,
    PopupContainer as SourcePopupContainer
} from 'SourceComponent/Popup/Popup.container';

export {
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/Popup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMyAccountOverlayVisible: state.GMyAccountOverlayReducer.isMyAccountOverlayVisible

});

/** @namespace Scandipwa/Component/Popup/Container */
export class PopupContainer extends SourcePopupContainer {
    static propTypes = {
        ...super.propTypes,
        isMyAccountOverlayVisible: PropTypes.bool
    };

    state = {
        shouldPopupStayVisible: false
    };

    containerProps() {
        const { isMyAccountOverlayVisible } = this.props;
        const { shouldPopupStayVisible } = this.state;
        return {
            ...super.containerProps(),
            isMyAccountOverlayVisible,
            shouldPopupStayVisible
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
