/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { SWITCH_ITEMS_TYPE } from 'Component/Router/Router.config';

import { ORDER_AND_RETURN_URL, ORDERS_AND_RETURNS } from '../config';

export const withStoreRegex = (path) => window.storeRegexText.concat(path);
export const OrderSearch = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "OrderSearch" */
    '../route/OrderSearch'
));

/**
  * adding new route for my account privacy settings
  */
// vvv disabled eslint because we are mimicking the same shape of the objects in the source
// scandipwa.
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
const switchItemsType = (member) => [
    ...member,
    {
        // vvv disabled eslint because we are mimicking the same shape of the objects in the
        // source scandipwa.
        component: <Route
          path={ withStoreRegex(ORDER_AND_RETURN_URL) }
        // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
          render={ (props) => <OrderSearch { ...props } /> }
        />,
        position: 999,
        name: ORDERS_AND_RETURNS
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: switchItemsType
        }
    }
};
