import {
    MyAccountOrderItemsTableComponent as StendersMyAccountOrderItemsTableComponent
} from 'StendersComponent/MyAccountOrderItemsTable/MyAccountOrderItemsTable.component';

import './MyAccountOrderItemsTable.extended.style';

/** @namespace Scandipwa/Component/MyAccountOrderItemsTable/Component */
export class MyAccountOrderItemsTableComponent extends StendersMyAccountOrderItemsTableComponent {
    // TODO implement logic
}

export default MyAccountOrderItemsTableComponent;
