import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import MyAccountBreadcrumbComponent from './MyAccountBreadcrumb.component';

/** @namespace Scandipwa/Component/MyAccountBreadcrumb/Container */
export class MyAccountBreadcrumbContainer extends PureComponent {
    static propTypes = {
        removeTab: PropTypes.func.isRequired,
        tab: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        isSelected: PropTypes.bool
    };

    static defaultProps = {
        isSelected: true
    };

    containerFunctions = {
        removeTab: this.removeTab.bind(this)
    };

    containerProps() {
        const { tab, index, isSelected } = this.props;

        return { tab, index, isSelected };
    }

    removeTab() {
        const { removeTab, index } = this.props;

        removeTab(index);
    }

    render() {
        return (
            <MyAccountBreadcrumbComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}
export default MyAccountBreadcrumbContainer;
