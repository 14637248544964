/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import MCDeliveryOption from '../../component/MCDeliveryOption';
import { SHIPPING_METHOD_CODES } from '../../config/MakeCommerce.config';

const replaceDeliveryOption = (args, callback, instance) => {
    const [option] = args;
    const { carrier_code, method_code } = option;

    if (!SHIPPING_METHOD_CODES.includes(method_code)) {
        return callback(...args);
    }

    const {
        estimateAddress: {
            country_id: countryId
        },
        selectShippingMethod,
        selectedShippingMethod: {
            method_code: selectedMethodCode,
            carrier_code: selectedCarrierCode
        },
        selectedMCParcelLocation,
        setSelectedMCParcelLocation
    } = instance.props;

    const isSelected = selectedMethodCode === method_code
        && selectedCarrierCode === carrier_code;

    // vvv method_code is not unique, and carrier_code is not unique, so we need a to create a
    // combination, and it is being used later for checking inside mcShippingLocations if it had a
    // dropdown fields.
    const id = `${carrier_code }_${ method_code}`;

    return (
        <MCDeliveryOption
          key={ id }
          id={ id }
          countryId={ countryId }
          isSelected={ isSelected }
          option={ option }
          onClick={ selectShippingMethod }
          selectedMCParcelLocation={ selectedMCParcelLocation }
          setSelectedMCParcelLocation={ setSelectedMCParcelLocation }
        />
    );
};

export default {
    'Component/CheckoutDeliveryOptions/Component': {
        'member-function': {
            renderDeliveryOption: replaceDeliveryOption
        }
    }
};
