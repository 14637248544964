/* eslint-disable react/forbid-elements */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Link as RouterLink } from 'react-router-dom';
import { stringify } from 'rebem-classname';

import {
    Link as SourceLinkComponent
} from 'SourceComponent/Link/Link.component';
import { RefType } from 'Type/Common.type';

export * from 'SourceComponent/Link/Link.component';

/** @namespace Scandipwa/Component/Link/Component */
export class LinkComponent extends SourceLinkComponent {
    static propTypes = {
        ...super.propTypes,
        linkRef: RefType
    };

    static defaultProps = {
        ...super.defaultProps,
        linkRef: undefined
    };

    renderRelativePathLink() {
        const {
            isOpenInNewTab,
            children,
            to,
            linkRef,
            ...props
        } = this.props;

        if (isOpenInNewTab) {
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <a
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...props }
                  onClick={ this.scrollToElement }
                  href={ to }
                  rel="noopener noreferrer"
                  target="_blank"
                  ref={ linkRef }
                >
                    { children }
                </a>
            );
        }

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <a
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...props }
              onClick={ this.scrollToElement }
              href={ to }
              ref={ linkRef }
            >
                { children }
            </a>
        );
    }

    renderAbsolutePathLink(classNameConverted) {
        const {
            isOpenInNewTab,
            children,
            to,
            linkRef,
            ...props
        } = this.props;

        if (isOpenInNewTab) {
            return (
                <a
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...props }
                  href={ to }
                    // eslint-disable-next-line react/forbid-dom-props
                  className={ classNameConverted }
                  rel="noopener noreferrer"
                  target="_blank"
                  ref={ linkRef }
                >
                    { children }
                </a>
            );
        }

        return (
            <a
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...props }
              href={ to }
                // eslint-disable-next-line react/forbid-dom-props
              className={ classNameConverted }
              ref={ linkRef }
            >
                { children }
            </a>
        );
    }

    render() {
        const {
            className,
            bemProps,
            children,
            to,
            isOpenInNewTab,
            linkRef,
            ...props
        } = this.props;

        if (!to) {
            return (
                <div { ...props } { ...bemProps } ref={ linkRef }>
                    { children }
                </div>
            );
        }

        // TODO: causing some relative links to be absolute which on moving through app breaks concept of SPA.
        // const path = typeof to === 'string' ? to : to.pathname;
        // const segments = path.split('/', 2);
        // if (
        //     (
        //         segments[0] === ''
        //         && segments[1]
        //         && (segments[1] in window.storeToWebsiteIdMap)
        //         && (window.currentWebsiteId !== window.storeToWebsiteIdMap[segments[1]])
        //     )
        //     || (
        //         path === '/'
        //         && (window.currentWebsiteId !== window.storeToWebsiteIdMap[path])
        //     )
        // ) {
        //     const classNameConverted = `${ className } ${ stringify(bemProps)}`;
        //     return this.renderAbsolutePathLink(classNameConverted);
        // }

        if (/^#/.test(to)) {
            return this.renderRelativePathLink();
        }

        const classNameConverted = `${ className } ${ stringify(bemProps)}`;

        if (/^https?:\/\//.test(to) || isOpenInNewTab) {
            return this.renderAbsolutePathLink(classNameConverted);
        }

        return (
            <RouterLink
              { ...props }
              to={ to }
              ref={ linkRef }
                // eslint-disable-next-line react/forbid-component-props
              className={ classNameConverted }
            >
                { children }
            </RouterLink>
        );
    }
}

export default LinkComponent;
