import {
    CheckoutReducer as sourceReducer,
    getInitialState
} from 'SourceStore/Checkout/Checkout.reducer';

import {
    UPDATE_BILLING_ADDRESS,
    UPDATE_ORDER_DETAILS
} from './Checkout.action';

/** @namespace Scandipwa/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_ORDER_DETAILS:
        const { orderDetails } = action;

        return {
            ...state,
            orderDetails
        };
    case UPDATE_BILLING_ADDRESS:
        const { billingAddress } = action;

        return {
            ...state,
            billingAddress
        };

    default:
        return sourceReducer(state, action);
    }
};

export default CheckoutReducer;
