import FIELD_TYPE from 'Component/Field/Field.config';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

/**
  * The consent checkbox was removed, as now it comes from AmastyGDPR module
  * @returns {[{validateOn: [string], validationRule: {isRequired: boolean, inputType: string}, type: string, attr: {name: string, placeholder: *, 'aria-label': *}}]}
  * @namespace Stenders/CustomPopup/Component/PopupSubscription/NewsletterForm/Form/newsletterSubscriptionForm */
export const newsletterSubscriptionForm = (isCheckmarkShown, isLoading) => [
    {
        type: FIELD_TYPE.email,
        attr: {
            name: 'newsletterEmail',
            isArrow: true,
            placeholder: __('Enter e-mail address'),
            'aria-label': __('Email address')
        },
        isCheckmarkShown,
        isLoading,
        isCheckmarkControlled: true,
        validationRule: {
            inputType: VALIDATION_INPUT_TYPE.email,
            isRequired: true
        }
    }
];

export default newsletterSubscriptionForm;
