/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';

import MageplazaGiftWrapQuery from '../query/Config.query';

const addGiftWrapToRequest = (args, callback) => ([
    ...callback(...args),
    MageplazaGiftWrapQuery.getGiftWrapField()
]);

const addGiftWrapToState = (args, callback) => ({
    ...callback(...args),
    mpGiftWrap: []
});

const getGiftWrapFromAction = (args, callback) => {
    const [, action] = args;
    const { type, config: { mpGiftWrap } = {} } = action;

    if (type !== UPDATE_CONFIG) {
        return callback(...args);
    }

    return {
        ...callback(...args),
        mpGiftWrap
    };
};

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addGiftWrapToRequest
        }
    },
    'Store/Config/Reducer/getInitialState': {
        function: addGiftWrapToState
    },
    'Store/Config/Reducer/ConfigReducer': {
        function: getGiftWrapFromAction
    }
};
