import { getFormattedRegion } from 'Util/Address';

/** @namespace Scandipwa/Component/MyAccountAddressTable/Table/getAddressTablePairArray */
export const getAddressTablePairArray = (props) => {
    const { address, countries } = props;
    const regionData = getFormattedRegion(address, countries);

    const fullname = `${address.firstname} ${address.lastname}`;

    const constructRegion = regionData.region
        ? `${address.city}, ${regionData.region}, ${address.postcode}`
        : `${address.city},  ${address.postcode}`;

    const fullAddress = `${address.street}, ${constructRegion}, ${address.country_id}`;

    return [
        {
            key: 'fullname',
            label: __('Full name'),
            source: { fullname }
        },
        {
            key: 'telephone',
            label: __('Phone number'),
            source: address
        },
        {
            key: 'address',
            label: __('Region'),
            source: { address: fullAddress }
        }
    ];
};

/** @namespace Scandipwa/Component/MyAccountAddressTable/Table/getOrderInfoAddressTablePairArray */
export const getOrderInfoAddressTablePairArray = (props) => {
    const { address, countries } = props;
    const regionData = getFormattedRegion(address, countries);

    const constructRegion = regionData.region
        ? `${address.city}, ${regionData.region}, ${address.postcode}`
        : `${address.city},  ${address.postcode}`;

    return [
        {
            key: 'firstname',
            label: __('First name'),
            source: address
        },
        {
            key: 'lastname',
            label: __('Last name'),
            source: address
        },
        {
            key: 'street',
            label: __('Street'),
            source: address
        },
        {
            key: 'region',
            label: __('Region'),
            source: { region: constructRegion }
        },
        {
            key: 'country',
            label: __('Country'),
            source: regionData
        },
        {
            key: 'telephone',
            label: __('Phone number'),
            source: address
        }
    ];
};
