/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import {
    AvailableSocialsType, SetCustomerExistsType,
    SetUsedSocialTypeType
} from '../../type/Social.type';
import SocialLoginButton from '../SocialLoginButton';

import './SocialLoginButtons.style';

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButtons/Component */
export class SocialLoginButtonsComponent extends PureComponent {
    static propTypes = {
        availableSocials: AvailableSocialsType.isRequired,
        setUsedSocialType: SetUsedSocialTypeType.isRequired,
        setCustomerExists: SetCustomerExistsType.isRequired
    };

    renderSocialButtons() {
        const { availableSocials } = this.props;

        const rows = this.refactorArray(availableSocials);
        return rows.map((row, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div block="SocialLoginButtons" elem="Row" key={ i }>
                { row.map((col) => (
                    this.renderColumns(col)
                )) }
            </div>
        ));
    }

    renderColumns(col) {
        const { setUsedSocialType, setCustomerExists } = this.props;

        return (
            <div block="SocialLoginButtons" elem="Column" key={ `${ col.type }_btn_container` }>
                <SocialLoginButton
                  key={ col.type }
                  social={ col }
                  setUsedSocialType={ setUsedSocialType }
                  setCustomerExists={ setCustomerExists }
                />
            </div>
        );
    }

    // Regular array [ 1, 2, 3, 4] to [[1, 2], [3, 4]] for rendering in two columns
    refactorArray = (array) => array.reduce((rows, key, index) => (index % 2 === 0 ? rows.push([key])
        : rows[rows.length - 1].push(key)) && rows, []);

    render() {
        return (
            <>
                <p block="SocialLoginButtons" elem="OrTitle">{ __('or') }</p>
                <div block="SocialLoginButtons">
                    { this.renderSocialButtons() }
                </div>
            </>
        );
    }
}

export default SocialLoginButtonsComponent;
