import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import {
    FLATRATE_METHOD_CODE,
    MATRIX_RATE_CARRIER_CODE
} from 'Route/Checkout/Checkout.config';
import {
    CheckoutDeliveryOptionComponent as StendersCheckoutDeliveryOption
} from 'StendersComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.component';
import { rearrangePriceString } from 'Util/Price';

import './CheckoutDeliveryOption.extended.styles.scss';

/** @namespace Scandipwa/Component/CheckoutDeliveryOption/Component */
export class CheckoutDeliveryOptionComponent extends StendersCheckoutDeliveryOption {
    renderEstimatedDelivery() {
        const {
            option: {
                carrier_code = '',
                estimated_delivery
            } = {},
            generalStoreWorkingHours,
            generalStoreStreet,
            generalStoreCity
        } = this.props;

        if (carrier_code === FLATRATE_METHOD_CODE) {
            return (
                <p
                  block="CheckoutDeliveryOption"
                  elem="EstimatedDelivery"
                >
                    { /* eslint-disable-next-line max-len */ }
                    { `${generalStoreWorkingHours}. ${generalStoreStreet}, ${generalStoreCity}. ${estimated_delivery}.` }
                </p>
            );
        }

        return (
            <p
              block="CheckoutDeliveryOption"
              elem="EstimatedDelivery"
            >
                { estimated_delivery }
            </p>
        );
    }
    // eslint-disable-next-line
    getOptionPrice() {
        const {
            currency,
            optionPrice
        } = this.props;

        return rearrangePriceString(optionPrice, currency);
    }

    renderSubPrice() {
        const {
            currency,
            optionSubPrice
        } = this.props;

        if (!optionSubPrice) {
            return null;
        }

        return (
            <div
              block="CheckoutDeliveryOption"
              elem="SubPrice"
            >
                { __('Excl. tax: %s', rearrangePriceString(optionSubPrice, currency)) }
            </div>
        );
    }

    renderRow() {
        const {
            option: {
                carrier_title,
                available,
                method_title,
                carrier_code
            } = {}
        } = this.props;

        const checkMethodTitle = method_title || '';

        const title = carrier_code === MATRIX_RATE_CARRIER_CODE
            ? `${carrier_title} ${checkMethodTitle}`
            : carrier_title;

        return (
            <div
              block="CheckoutDeliveryOption"
              elem="Row"
            >
                <div block="CheckoutDeliveryOption" elem="Span" mods={ { isDisabled: !available } }>
                    <div
                      block="CheckoutDeliveryOption"
                      elem="Title"
                    >
                     { title }
                    </div>
                    <div
                      block="CheckoutDeliveryOption"
                      elem="Price"
                    >
                        { this.renderPrice() }
                    </div>
                </div>
                { this.renderAvailabilityMessage() }
                { this.renderSubPrice() }
                { this.renderEstimatedDelivery() }
            </div>
        );
    }

    /**
     * Overridden to replace byggy id and name attributes - the carrier_title is empty
     */
    render() {
        const {
            option: {
                method_code,
                available
            } = {},
            onOptionClick,
            isSelected
        } = this.props;

        return (
            <li
              block="CheckoutDeliveryOption"
              mods={ {
                  isDisabled: !available,
                  isActive: isSelected,
                  isHoverExcluded: !available || (isSelected)
              } }
            >
                <button
                  block="CheckoutDeliveryOption"
                  mods={ { isDisabled: !available } }
                  elem="Button"
                  type="button"
                  onClick={ onOptionClick }
                  disabled={ !available }
                >
                    <Field
                      type={ FIELD_TYPE.radio }
                      attr={ {
                          id: `option-${ method_code }`,
                          name: `option-${ method_code }`,
                          checked: isSelected
                      } }
                    />
                    { this.renderRow() }
                </button>
            </li>
        );
    }
}

export default CheckoutDeliveryOptionComponent;
