/* eslint-disable max-lines */
import { fetchMutation } from '@scandipwa/scandipwa/src/util/Request';
import SocialTokenQuery from '@scandiweb/mageplaza-social-login/src/query/SocialToken.query';
import { executeLoginFlow } from '@scandiweb/mageplaza-social-login/src/util/login';
import { createRef } from 'react';
import { connect } from 'react-redux';

import { CONFIRMATION_REQUIRED } from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';
import {
    STATE_CONFIRM_EMAIL
} from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountCreateAccountContainer as SourceMyAccountCreateAccount
} from 'StendersComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';
import { updateIsLoading } from 'Store/MyAccount/MyAccount.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { transformDateForBE } from 'Util/Form/Form';
import transformToNameValuePair from 'Util/Form/Transform';

import { sendTheGivenConsentLog } from '../../util/GDPR/Functions';
import { getFromShop } from '../../util/Loyalty/Loyalty';

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    setMyAccountLoading: (isLoading) => dispatch(updateIsLoading(isLoading))
});

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile,
    showTaxVatNumber: state.ConfigReducer.show_vat_number_on_storefront
});

export * from 'StendersComponent/MyAccountCreateAccount/MyAccountCreateAccount.container';

/** @namespace Scandipwa/Component/MyAccountCreateAccount/Container */
export class MyAccountCreateAccountContainer extends SourceMyAccountCreateAccount {
    /** used to attach listeners to inputs */
    formWrapperRef = null;

    /** Value of each required input. We need this to track those inputs for which validation was not run */
    inputValues = {};

    inputListeners = {}; // so we can remove them

    /**
     * Is filled each time a validation logic is run on input
     * [name] => isValid
     */
    inputValidationResults = {};

    isGDPRValid = false;

    isFormValid = false;

    state = {
        ...this.state,
        usedSocialType: '',
        customerExists: false,
        activeDiscount: 0,
        isSubmitButtonEnabled: false
    };

    containerFunctions = {
        ...this.containerFunctions,
        setUsedSocialType: this.setUsedSocialType.bind(this),
        setCustomerExists: this.setCustomerExists.bind(this),
        sendSocialTokenQuery: this.sendSocialTokenQuery.bind(this),
        onActiveDiscountChange: this.onActiveDiscountChange.bind(this),
        onGDPRStateChanged: this.onGDPRStateChanged.bind(this),
        onInputValidated: this.onInputValidated.bind(this)
    };

    __construct(props) {
        super.__construct(props);
        this.formWrapperRef = createRef();
    }

    onGDPRStateChanged(isAllowed) {
        this.isGDPRValid = isAllowed;
        this._updateSubmitButtonState();
    }

    _updateSubmitButtonState() {
        // eslint-disable-next-line fp/no-let
        let newIsFormValid = this.isGDPRValid;

        if (newIsFormValid) {
            newIsFormValid = Object.keys(this.inputValues).every((name) => this.inputValues[name]);
        }

        if (newIsFormValid) {
            newIsFormValid = Object.keys(this.inputValidationResults)
                .every((name) => this.inputValidationResults[name]);
        }

        if (this.isFormValid !== newIsFormValid) {
            this.isFormValid = newIsFormValid;
            this.setState({
                isSubmitButtonEnabled: newIsFormValid
            });
        }
    }

    componentDidMount() {
        const inputs = Array.from(this.formWrapperRef.current.querySelectorAll('input'));
        const self = this;

        inputs.forEach((input) => {
            const name = input.getAttribute('name');

            if (
                input.getAttribute('type') === 'checkbox'
                || name === 'date_of_birth'
            ) {
                return;
            }

            self.inputValues[name] = input.value;
            self._attachListenerToInput(input, name);
        });
    }

    _attachListenerToInput(input, name) {
        const self = this;

        this.inputListeners[name] = () => {
            self.inputValues[name] = input.value;
            this._updateSubmitButtonState();
        };

        input.addEventListener('input', this.inputListeners[name]);
    }

    componentWillUnmount() {
        const inputs = Array.from(this.formWrapperRef.current.querySelectorAll('input'));

        inputs.forEach((input) => {
            const name = input.getAttribute('name');

            if (this.inputListeners[name]) {
                input.removeEventListener('input', this.inputListeners[name]);
            }
        });

        this.inputListeners = null;
    }

    onInputValidated(name, validationResult) {
        this.inputValidationResults[name] = validationResult === true;
        this._updateSubmitButtonState();
    }

    /**
     * New method to handle active discount change
     */
    onActiveDiscountChange(activeDiscount) {
        this.setState({ activeDiscount: Math.abs(activeDiscount) });
    }

    setUsedSocialType(type) {
        const { setUsedSocialTypeForOverlay } = this.props;

        if (setUsedSocialTypeForOverlay) {
            setUsedSocialTypeForOverlay(type);
        }

        this.setState(() => ({
            usedSocialType: type
        }));
    }

    setCustomerExists(exists) {
        const { setCustomerExistsForOverlay } = this.props;

        if (setCustomerExistsForOverlay) {
            setCustomerExistsForOverlay(exists);
        }

        this.setState(() => ({
            customerExists: exists
        }));
    }

    async sendSocialTokenQuery() {
        const {
            usedSocialType,
            customerExists
        } = this.state;

        const {
            setMyAccountLoading
        } = this.props;

        const { token: guestQuoteId } = BrowserDatabase.getItem('guest_quote_id');
        const { socialToken: { token } } = await fetchMutation([
            SocialTokenQuery.getQuery({ guestQuoteId, customerExists, usedSocialType })
        ]);

        await executeLoginFlow(token);
        setMyAccountLoading(false);
    }

    async onSuccess(form, fields) {
        const {
            createAccount,
            onSignIn,
            setSignInState,
            setLoadingState,
            isLoading,
            isLandingPage,
            showNotification,
            isMobile
        } = this.props;

        const {
            password,
            email,
            firstname,
            lastname,
            is_subscribed,
            taxvat,
            mobilenumber,
            smssubscriber,
            date_of_birth
        } = transformToNameValuePair(fields);

        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                is_subscribed,
                taxvat,
                mobilenumber,
                smssubscriber,
                dob: transformDateForBE(date_of_birth),
                from_shop: getFromShop()
            },
            password
        };

        if (isLoading) {
            return;
        }

        try {
            const code = await createAccount(customerData).catch(
                /** @namespace Scandipwa/Component/MyAccountCreateAccount/Container/MyAccountCreateAccountContainer/onSuccess/code/createAccount/catch */
                () => {
                    showNotification('error', __('Error creating account'));
                }
            );

            // if user needs confirmation
            if (code === CONFIRMATION_REQUIRED) {
                sendTheGivenConsentLog(fields);
                setSignInState(STATE_CONFIRM_EMAIL);
                if (isLandingPage || isMobile) {
                    showNotification(
                        'success',
                        // eslint-disable-next-line max-len
                        __('The email confirmation link has been sent to your email. Please confirm your account to proceed.')
                    );
                    history.push('/default/customer/account/login');
                }
            } else if (code !== false) {
                onSignIn();
            }
        } finally {
            setLoadingState(false);
        }
    }

    getVatNumberRequired() {
        const {
            showTaxVatNumber
        } = this.props;

        return showTaxVatNumber;
    }

    containerProps() {
        const {
            isMobile,
            isLandingPage,
            isCreateAccountPage
        } = this.props;
        const {
            usedSocialType,
            customerExists,
            activeDiscount,
            isSubmitButtonEnabled
        } = this.state;

        return {
            ...super.containerProps(),
            isMobile,
            usedSocialType,
            customerExists,
            activeDiscount,
            isSubmitButtonEnabled,
            isLandingPage,
            formWrapperRef: this.formWrapperRef,
            isCreateAccountPage
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountCreateAccountContainer);
