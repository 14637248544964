import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { LocomotiveScrollContext } from 'react-locomotive-scroll';

import Image from 'Component/Image';
import Link from 'Component/Link';
import { RU_STORE_CODE } from 'Component/MyAccountNewsletterSubscription/MyAccountNewsletterSubscription.config';
import BrowserDatabase from 'Util/BrowserDatabase';

import './StendersWidget.style.scss';

/** @namespace Stenders/Widget/Component/StendersWidget/Component */
export class StendersWidgetComponent extends PureComponent {
    static contextType = LocomotiveScrollContext;

    static propTypes = {
        title: PropTypes.string,
        description: PropTypes.string,
        link: PropTypes.string,
        image1: PropTypes.string,
        image2: PropTypes.string
    };

    static defaultProps = {
        title: '',
        description: '',
        link: '',
        image1: '',
        image2: ''
    };

    state = {
        scrollSpeed: '-3.1'
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    handleResize = () => {
        const { width } = document.body.getBoundingClientRect();

        // eslint-disable-next-line no-magic-numbers
        if (width > 810) {
            this.setState({ scrollSpeed: '-3.4' });
        }

        // eslint-disable-next-line no-magic-numbers
        if (width > 1161) {
            this.setState({ scrollSpeed: '-2.7' });
        }

        // eslint-disable-next-line no-magic-numbers
        if (width > 1280) {
            this.setState({ scrollSpeed: '-2.4' });
        }
    };

    renderFirstImage() {
        const { image1 } = this.props;

        return (
            <Image
              key={ image1 }
              mix={ { block: 'StendersWidget', elem: 'Image' } }
              width="100%"
              height="100%"
              ratio="custom"
              src={ image1 }
              style={ {
                  position: 'relative'
              } }
            />
        );
    }

    renderDescription() {
        const { description } = this.props;

        return (
                <div block="StendersWidget" elem="Description">
                    <div block="StendersWidget" elem="DescriptionContent">{ __(description) }</div>
                </div>
        );
    }

    render() {
        const {
            title, link, image2
        } = this.props;
        const {
            scrollSpeed
        } = this.state;

        const storeConfig = BrowserDatabase.getItem('config');
        const code = storeConfig?.storeConfig?.code;
        const ruStore = code === RU_STORE_CODE;

        return (
            <div
              block="StendersWidget"
              elem="Wrapper"
            >
                <div
                  block="StendersWidget"
                  elem="Image1"
                  data-scroll
                  data-scroll-speed="-3.45"
                >
                    { this.renderFirstImage() }
                </div>
                <div
                  block="StendersWidget"
                  elem="TitleAndContent"
                >
                    <h2 block="StendersWidget" elem="Title">{ __(title) }</h2>
                    <div block="StendersWidget" elem="Content">
                        <div block="StendersWidget" elem="DescriptionWrapper">
                            { this.renderDescription() }
                            <Link
                              block="StendersWidget"
                              elem="Link"
                              to={ `/${link}` }
                            >
                            { __('Read More') }
                            </Link>
                        </div>

                        <div block="StendersWidget" elem="Image2" mods={ { ruStore } }>
                            <Image
                              src={ image2 }
                              alt="Widget Second Image"
                              key={ `${image2}BBB` }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StendersWidgetComponent;
