export const UPDATE_IS_MENU_VISIBLE = 'UPDATE_IS_MENU_VISIBLE';
export const UPDATE_IS_SLIDER_DRAGGING = 'UPDATE_IS_SLIDER_DRAGGING';

/** @namespace Scandipwa/Store/MenuOverlay/Action/updateIsMenuVisible */
export const updateIsMenuVisible = (isMenuVisible) => ({
    type: UPDATE_IS_MENU_VISIBLE,
    isMenuVisible
});

/** @namespace Scandipwa/Store/MenuOverlay/Action/updateIsSliderDragging */
export const updateIsSliderDragging = (isSliderDragging) => ({
    type: UPDATE_IS_SLIDER_DRAGGING,
    isSliderDragging
});
