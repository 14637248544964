/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { CONFIRMATION_REQUIRED } from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';
import MyAccountQuery from 'Query/MyAccount.query';
import { updateIsLoading } from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import { getCustomerData } from '../../data/customer';
import {
    fireUserLoginAttemptEvent,
    fireUserLoginEvent,
    fireUserLogoutEvent,
    fireUserRegisterAttemptEvent,
    fireUserRegisterEvent
} from '../../event/user';

const addFireUserRegisterEvents = async (args, callback, instance) => {
    const [options, dispatch] = args;
    const { customer: { email }, password } = options;
    const mutation = MyAccountQuery.getCreateAccountMutation(options);
    dispatch(updateIsLoading(true));

    return fetchMutation(mutation).then(
        /** @namespace Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then */
        (data) => {
            const { createCustomer: { customer } } = data;
            const { confirmation_required, id: customerId } = customer;
            fireUserRegisterAttemptEvent(email);
            // ^^^ this function calls sign in and register
            fireUserRegisterEvent(email, customerId);

            if (confirmation_required) {
                dispatch(updateIsLoading(false));
                return CONFIRMATION_REQUIRED;
            }

            return instance.signIn({ email, password }, dispatch);
        },

        /** @namespace Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/fetchMutation/then/catch */
        (error) => {
            dispatch(updateIsLoading(false));
            dispatch(showNotification('error', getErrorMessage(error)));
            return false;
        }
    );
};

const addFireUserLoginEvents = async (args, callback) => {
    const [{ email }] = args;

    fireUserLoginAttemptEvent(email);
    await callback(...args);
    fireUserLoginEvent();
};

const addFireUserLogoutEvent = async (args, callback) => {
    const { customerId } = await getCustomerData();

    callback(...args);
    fireUserLogoutEvent(customerId);
};

export default {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            createAccount: addFireUserRegisterEvents,
            signIn: addFireUserLoginEvents,
            logout: addFireUserLogoutEvent
        }
    }
};
