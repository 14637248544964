/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import DiscountSlider from 'Component/DiscountSlider';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import MenuOverlay from 'Component/MenuOverlay';
import { RIGHT } from 'Component/MenuOverlay/MenuOverlay.config';
import {
    CUSTOMER_ACCOUNT_OVERLAY_KEY,
    STATE_CONFIRM_EMAIL
} from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import MyAccountSignIn from 'Component/MyAccountSignIn';
import MyAccountCreateAccount from 'StendersComponent/MyAccountCreateAccount';
import {
    MyAccountOverlay as StendersMyAccountOverlay
} from 'StendersComponent/MyAccountOverlay/MyAccountOverlay.component';
import {
    TabMapType
} from 'Type/Account.type';
import { noopFn } from 'Util/Common';

import './MyAccountOverlay.extended.style';

/** @namespace Scandipwa/Component/MyAccountOverlay/Component */
export class MyAccountOverlayComponent extends StendersMyAccountOverlay {
    static propTypes = {
        ...super.propTypes,
        isSignedIn: PropTypes.bool.isRequired,
        tabMap: TabMapType,
        firstname: PropTypes.string,
        handleLogout: PropTypes.func.isRequired,
        handleLinkClick: PropTypes.func.isRequired,
        toggleMenu: PropTypes.func
    };

    static defaultProps = {
        ...super.defaultProps,
        firstname: '',
        toggleMenu: noopFn
    };

    renderMap = {
        ...this.renderMap,
        [STATE_CONFIRM_EMAIL]: {
            render: () => this.renderConfirmEmail(),
            title: this.renderCreateAccountTitle()
        }
    };

    renderMyAccountMenu() {
        const { tabMap, handleLogout, handleLinkClick } = this.props;
        const modifiedTabMap = Object.entries(tabMap).filter((item) => item[0] !== 'edit');

        return (
            <div block="MyAccountOverlay" elem="Links">
                { modifiedTabMap.map(([key, value]) => (
                    <Link
                      block="MyAccountOverlay"
                      elem="Link"
                      to={ value.url }
                      onClick={ handleLinkClick }
                      key={ key }
                    >
                        { __(value.tabName) }
                    </Link>
                )) }
                <button block="MyAccountOverlay" elem="Link" onClick={ handleLogout }>{ __('Logout') }</button>
            </div>
        );
    }

    renderWelcomeMessage() {
        const { firstname, isSignedIn } = this.props;

        if (!isSignedIn || !firstname) {
            return null;
        }

        return (
            <div
              block="MyAccountOverlay"
              elem="Welcome"
              mods={ { type: 'Welcome' } }
            >
                { __('Welcome, %s!', firstname) }
            </div>
        );
    }

    renderTitle() {
        return (
            <h3 block="MyAccountOverlay" elem="Title">{ __('My Account') }</h3>
        );
    }

    renderSignIn() {
        const {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            onSignIn,
            setUsedSocialTypeForOverlay,
            setCustomerExistsForOverlay
        } = this.props;

        return (
            <MyAccountSignIn
              state={ state }
              onFormError={ onFormError }
              handleForgotPassword={ handleForgotPassword }
              handleCreateAccount={ handleCreateAccount }
              isCheckout={ isCheckout }
              setLoadingState={ setLoadingState }
              onSignIn={ onSignIn }
              setUsedSocialTypeForOverlay={ setUsedSocialTypeForOverlay }
              setCustomerExistsForOverlay={ setCustomerExistsForOverlay }
            />
        );
    }

    renderCreateAccount(isLandingPage = false, isCreateAccountPage = false) {
        const {
            state,
            handleSignIn,
            setSignInState,
            setLoadingState,
            onSignIn,
            setUsedSocialTypeForOverlay,
            setCustomerExistsForOverlay
        } = this.props;

        return (
            <MyAccountCreateAccount
              state={ state }
              handleSignIn={ handleSignIn }
              setLoadingState={ setLoadingState }
              setSignInState={ setSignInState }
              onSignIn={ onSignIn }
              isLandingPage={ isLandingPage }
              isCreateAccountPage={ isCreateAccountPage }
              setUsedSocialTypeForOverlay={ setUsedSocialTypeForOverlay }
              setCustomerExistsForOverlay={ setCustomerExistsForOverlay }
            />
        );
    }

    /**
     * New method to render discount section
     */
    renderDiscountSection() {
        const {
            accountMenuState,
            activeDiscount,
            onActiveDiscountChange,
            sliderRef
        } = this.props;

        return (
            <div
              block="MyAccountOverlay"
              elem="DiscountSection"
              mods={ { isCreateAccount: accountMenuState === 'createAccount' } }
            >
                <DiscountSlider
                  showCrumbs
                  sliderRef={ sliderRef }
                  activeImage={ activeDiscount }
                  onActiveImageChange={ onActiveDiscountChange }
                  mix={ { block: 'MyAccountOverlay', elem: 'DiscountWrapper' } }
                >
                    { this.discounts.map(({ key, title, description }) => (
                        <div
                          key={ key }
                          block="MyAccountOverlay"
                          elem="SubDiscountWrapper"
                        >
                          <div
                            block="MyAccountOverlay"
                            elem="PercentText"
                          >
                              <h4>{ title }</h4>
                              <p>{ description }</p>
                          </div>
                        </div>
                    )) }
                </DiscountSlider>
            </div>
        );
    }

    /**
     * Overridden to add extra div
     */
    renderMyAccount() {
        const { accountMenuState } = this.props;
        const { render, title } = this.renderMap[accountMenuState];

        return (
            <div block="MyAccountOverlay" elem="Action" mods={ { accountMenuState } }>
                <div block="MyAccountOverlay" elem="Heading">
                    { title }
                </div>
                { render() }
            </div>
        );
    }

    /**
     * Overridden to add isActive mod
     */
    renderSignInTitle() {
        const {
            handleCreateAccount,
            handleSignIn
        } = this.props;

        return (
            <div
              block="MyAccountOverlay"
              elem="SignInTitleWrapper"
            >
                <button
                  block="MyAccountOverlay"
                  elem="SignInTitle"
                  mods={ { isActive: true } }
                  type="button"
                  onClick={ handleSignIn }
                >
                    { __('Sign in') }
                </button>
                <button
                  block="MyAccountOverlay"
                  elem="CreateAccountTitle"
                  type="button"
                  onClick={ handleCreateAccount }
                >
                    { __('Create an account') }
                </button>
            </div>
        );
    }

    /**
     * Overridden to add isActive mod
     */
    renderCreateAccountTitle() {
        const {
            handleSignIn
        } = this.props;

        return (
            <div
              block="MyAccountOverlay"
              elem="CreateAccountTitleWrapper"
            >
                <button
                  block="MyAccountOverlay"
                  elem="SignInTitle"
                  type="button"
                  onClick={ handleSignIn }
                >
                    { __('Sign in') }
                </button>
                <div
                  block="MyAccountOverlay"
                  elem="CreateAccountTitle"
                  mods={ { isActive: true } }
                >
                    { __('Create an account') }
                </div>
            </div>
        );
    }

    render() {
        const {
            isLoading,
            toggleAccountState,
            accountState,
            isPortal,
            isSignedIn,
            usedSocialType
        } = this.props;

        if (isSignedIn) {
            return (
                <MenuOverlay
                  id={ CUSTOMER_ACCOUNT_OVERLAY_KEY }
                  mix={ { block: 'MyAccountOverlay', elem: 'MenuOverlay' } }
                  isVisible={ accountState }
                  toggleMenu={ toggleAccountState }
                  direction={ RIGHT }
                  isPortal={ isPortal }
                >
                    <Loader isLoading={ isLoading } />
                    <div
                      block="MyAccountOverlay"
                      elem="MyAccountSection"
                      mods={ { isLoggedIn: true } }
                    >
                        { this.renderWelcomeMessage() }
                        { this.renderTitle() }
                        <hr block="MyAccountOverlay" elem="Divider" />
                        { this.renderMyAccountMenu() }
                    </div>
                </MenuOverlay>
            );
        }

        return (
            <MenuOverlay
              id={ CUSTOMER_ACCOUNT_OVERLAY_KEY }
              mix={ {
                  block: 'MyAccountOverlay',
                  elem: 'MenuOverlay',
                  mods: { isAdditionalFieldsForm: !!usedSocialType }
              } }
              isVisible={ accountState }
              toggleMenu={ toggleAccountState }
              direction={ RIGHT }
              isPortal={ isPortal }
            >
                <Loader isLoading={ isLoading } />
                <div block="MyAccountOverlay" elem="MyAccountSection">
                    { this.renderMyAccount() }
                </div>
            </MenuOverlay>
        );
    }
}

/*
The next line is a workaround for the following bug somewhere in Mozaic/plugin system:
1) There is route/LoginAccount/LoginAccount.component from the base ScandiPWA folder.

2) it has the following declaration:
import { MyAccountOverlay } from 'Component/MyAccountOverlay/MyAccountOverlay.component';
export class LoginAccountComponent extends MyAccountOverlay

3) the LoginAccountComponent should extend overridden MyAccountOverlay from the site's scandipwa/ folder,
but in fact it extends MyAccountOverlay from the base ScandiPWA directory.
In other words, this `import` statement imports the class from the base ScandiPWA folder instead of theme or site.

This can be cured in two ways:

a) either we rename import in the base ScandiPWA directory like this:
import { MyAccountOverlayComponent } from 'Component/MyAccountOverlay/MyAccountOverlay.component';
export class LoginAccountComponent extends MyAccountOverlayComponent
Then it works as expected.

b) or we rename the local export from MyAccountOverlayComponent -> MyAccountOverlay
(that's what we do next)
 */
export const MyAccountOverlay = MyAccountOverlayComponent;

export default withRouter(MyAccountOverlayComponent);
