/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { GIFT_WRAP_POPUP_ID_PREFIX } from '../component/GiftWrapPopup/GiftWrapPopup.config';

/** @namespace Scandiweb/MageplazaGiftWrap/Util/GiftWrap/getPrice */
export const getPrice = (priceString) => {
    // vvv Using if instead of default value on args because sometime its null!
    if (!priceString) {
        return 0.00;
    }

    const firstSymbol = priceString[0];
    // eslint-disable-next-line no-restricted-globals
    const start = firstSymbol === '.' || !isNaN(firstSymbol) ? 0 : 1;

    const digits = priceString.slice(start, priceString.length)
        .replace(/\D/g, '');
    const numberString = `${digits.slice(0, digits.length - 2) }.${ digits.slice(digits.length - 2)}`;

    return parseFloat(numberString);
};

/** @namespace Scandiweb/MageplazaGiftWrap/Util/GiftWrap/createGiftWrapObject */
export const createGiftWrapObject = (
    label,
    giftWrapData
) => {
    if (!giftWrapData) {
        return {};
    }

    const {
        wrap_id: id,
        name,
        amount,
        price: priceWrap,
        use_gift_message: useGiftMessage,
        gift_message: giftMessage,
        all_cart: allCart
    } = giftWrapData;

    const price = priceWrap || `${amount}`;

    return {
        [`${label}Id`]: id,
        [`${label}Name`]: name,
        [`${label}Price`]: getPrice(price),
        [`${label}UseGiftMessage`]: useGiftMessage,
        [`${label}Message`]: giftMessage,
        [`${label}AllCart`]: allCart
    };
};

/** @namespace Scandiweb/MageplazaGiftWrap/Util/GiftWrap/getIsOptions */
export const getIsOptions = (giftWrapId, postCardId) => giftWrapId || postCardId;
// ^^^ If one of them is true it's enough to return true

/** @namespace Scandiweb/MageplazaGiftWrap/Util/GiftWrap/getSelectedOptions */
export const getSelectedOptions = (
    _giftWrapId,
    _postCardId,
    _giftWrapMessage,
    _postcardMessage,
    giftWrapAllCart,
    postcardAllCart
) => {
    const giftWrapId = giftWrapAllCart ? null : _giftWrapId;
    const giftWrapMessage = giftWrapAllCart ? '' : _giftWrapMessage;
    const postCardId = postcardAllCart ? null : _postCardId;
    const postcardMessage = postcardAllCart ? '' : _postcardMessage;

    return {
        wrap: {
            wrap_id: giftWrapId,
            giftNoteMessage: {
                message: giftWrapMessage
            }
        },
        postcard: {
            wrap_id: postCardId,
            postcardMessage
        }
    };
};

/** @namespace Scandiweb/MageplazaGiftWrap/Util/GiftWrap/getFullPopupId */
export const getFullPopupId = (popupId) => `${GIFT_WRAP_POPUP_ID_PREFIX}_${popupId}`;

/** @namespace Scandiweb/MageplazaGiftWrap/Util/GiftWrap/convertPrice */
export const convertPrice = (priceInUSD = 0) => {
    const {
        currency: {
            exchange_rates: exchangeRates = []
        },
        default_display_currency_code
    } = getStore().getState().ConfigReducer;
    const { quote_currency_code } = getStore().getState().CartReducer.cartTotals;
    const currency = quote_currency_code || default_display_currency_code;
    const { rate: currencyRate = 1 } = exchangeRates
        ? exchangeRates.find(({ currency_to }) => currency_to === currency) || {}
        : {};

    return priceInUSD * currencyRate;
};
