/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { MenuQuery as SourceMenuQuery } from 'StendersQuery/Menu.query';

/**
 * Menu Query
 * @class MenuQuery
 * @namespace Scandipwa/Query/Menu/Query */
export class MenuQuery extends SourceMenuQuery {
    _getMenuItemFields() {
        return [
            'url',
            'icon',
            'title',
            'item_id',
            'position',
            'url_type',
            'parent_id',
            'is_active',
            'item_class',
            'category_id',
            'cms_page_identifier',
            'category_description',
            'category_title',
            'category_image',
            'category_video'
        ];
    }
}

export default new MenuQuery();
