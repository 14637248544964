import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';

import { PAGE_PARAMETER_NAMES } from './Meta.config';
/** @namespace Scandipwa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    meta_title: state.MetaReducer.meta_title,
    currentPage: state.ProductListReducer?.currentArgs?.currentPage,
    filters: state.ProductListInfoReducer?.filters,
    selectedFilter: state.ProductListInfoReducer?.selectedFilter,
    pageType: state.UrlRewritesReducer?.urlRewrite?.type,
    storeCode: state.ConfigReducer?.store_code,
    productDescription: state.ProductReducer?.product?.description?.html,
    availableBrands: state.ConfigReducer?.brandsConfig?.brandDictionary
});

/** @namespace Scandipwa/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    /**
     * checks passed Mageworx settings and returns connected value and page number
     */
    combinePageParameter(pageParameter, value, option) {
        if (!value) {
            return '';
        }

        switch (option) {
        case PAGE_PARAMETER_NAMES.BEGINNING:
            return `${pageParameter} ${pageParameter ? '|' : ''} ${value}`;
        case PAGE_PARAMETER_NAMES.END:
            return `${value} ${pageParameter ? '|' : ''} ${pageParameter}`;
        default:
            return value;
        }
    }

    /**
     * overridden to add page num to keywords accordingly to Mageworx settings
     */
    _getKeywords() {
        const {
            keywords,
            default_keywords,
            currentPage,
            seoConfigurationData: {
                addPageNumToMetaKeywords: addNumOption = 'off'
            } = { addPageNumToMetaKeywords: 'off' }
        } = this.props;
        // eslint-disable-next-line
        let newKeywords = keywords;
        const pageParameter = (currentPage && currentPage !== 1) ? `${__('Page')} ${currentPage}` : '';

        newKeywords = this.combinePageParameter(pageParameter, newKeywords, addNumOption);
        const newDefault_keywords = this.combinePageParameter(pageParameter, default_keywords, addNumOption);

        return newKeywords || newDefault_keywords;
    }

    /**
     * overridden to add page num to description accordingly to Mageworx settings
     */
    _getDescription() {
        const {
            description,
            default_description,
            currentPage,
            seoConfigurationData: {
                addPageNumToMetaDescription: addNumOption = 'off'
            } = { addPageNumToMetaDescription: 'off' }
        } = this.props;
        // eslint-disable-next-line
        let newDescription = description;
        const pageParameter = (currentPage && currentPage !== 1) ? `${__('Page')} ${currentPage}` : '';

        newDescription = this.combinePageParameter(pageParameter, newDescription, addNumOption);
        const newDefault_description = this.combinePageParameter(pageParameter, default_description, addNumOption);

        return newDescription || newDefault_description;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
