/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const MAKECOMMERCE_METHOD_CODE = 'makecommerce';

export const UI_MODE_INLINE = 'inline';
export const UI_MODE_WIDGET = 'widget';
export const UI_INLINE_USE_LOGO_LOGO = 'logo';
export const UI_INLINE_USE_LOGO_TEXT = 'text';
export const FLAG_SELECTOR_CODE = 'flag';

export const ESTONIA_LABEL = __('Estonia');
export const LATVIA_LABEL = __('Latvia');
export const FINLAND_LABEL = __('Finland');
export const LITHUANIA_LABEL = __('Lithuania');

export const COUNTRY_NAMES_MAP = {
    ee: ESTONIA_LABEL,
    lv: LATVIA_LABEL,
    fi: FINLAND_LABEL,
    lt: LITHUANIA_LABEL
};

export const CATEGORY_NAMES_MAP = {
    banklinks: __('Banks'),
    cards: __('Credit Cards'),
    payLater: __('Pay Later'),
    other: __('Other')
};

export const SHIPPING_METHOD_CODES = [
    'parcel',
    'courier'
];
