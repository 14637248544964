/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { connect } from 'react-redux';

import DataContainer from 'SourceUtil/Request/DataContainer';

import { GDPRContext } from '../../context/GDPR.context';
import GDPRFooterLinkComponent from './GDPRFooterLink.component';

/** @namespace Scandiweb/AmastyGdpr/Component/GDPRFooterLink/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/GDPRFooterLink/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/GDPRFooterLink/Container */
export class GDPRFooterLinkContainer extends DataContainer {
    static propTypes = {};

    static contextType = GDPRContext;

    containerProps = () => {};

    containerFunctions = {
        showPopup: this.showPopup.bind(this)
    };

    showPopup() {
        const { showSettingsModal } = this.context;
        showSettingsModal();
    }

    render() {
        const { isExtensionAllowed } = this.context;
        if (!isExtensionAllowed) {
            return null;
        }

        return (
            <GDPRFooterLinkComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GDPRFooterLinkContainer);
