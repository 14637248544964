import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';

/** @namespace Stenders/LoginButton/Component/LoginButton/Component */
export class LoginButtonComponent extends PureComponent {
    static propTypes = {
        buttonTitle: PropTypes.string.isRequired,
        openMyAccountOverlay: PropTypes.func.isRequired,
        isSignedIn: PropTypes.bool.isRequired
    };

    renderButton() {
        const {
            buttonTitle,
            openMyAccountOverlay,
            isSignedIn
        } = this.props;

        if (!isSignedIn) {
            return (
                <button
                  block="LoginButton"
                  elem="Link"
                  mix={ { block: 'Button' } }
                  onClick={ openMyAccountOverlay }
                >
                    { buttonTitle }
                </button>
            );
        }

        return (
            <Link
              block="LoginButton"
              elem="Link"
              mix={ { block: 'Button' } }
              to="/customer/account"
            >
                { buttonTitle }
            </Link>
        );
    }

    render() {
        return (
            <div block="LoginButton">
                { this.renderButton() }
            </div>
        );
    }
}

export default LoginButtonComponent;
