/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    MFGiftWrapItemType
} from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/type/GiftWrap';
import {
    createGiftWrapObject
} from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/util/GiftWrap';
import GiftWrapCheckoutSummarySection from './GiftWrapCheckoutSummarySection.component';

/** @namespace Scandipwa/Component/GiftWrapCheckoutSummarySection/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currencyCode: state.CartReducer.cartTotals.quote_currency_code,
    giftWrapMessageFee: state.ConfigReducer.mpGiftWrap?.giftMessageFee,
    cartId: state.CartReducer.cartTotals?.id
});

/** @namespace Scandipwa/Component/GiftWrapCheckoutSummarySection/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandipwa/Component/GiftWrapCheckoutSummarySection/Container */
export class GiftWrapCheckoutSummarySectionContainer extends PureComponent {
    static propTypes = {
        giftWrapData: MFGiftWrapItemType.isRequired,
        giftWrapPostcardData: MFGiftWrapItemType.isRequired,
        currencyCode: PropTypes.string,
        giftWrapMessageFee: PropTypes.number.isRequired,
        popupId: PropTypes.string.isRequired,
        cartId: PropTypes.string.isRequired
    };

    static defaultProps = {
        currencyCode: null
    };

    containerProps() {
        const {
            currencyCode,
            giftWrapMessageFee,
            giftWrapData = {},
            giftWrapPostcardData = {},
            popupId
        } = this.props;

        return {
            giftWrapData: createGiftWrapObject('giftWrap', giftWrapData),
            postcardData: createGiftWrapObject('postcard', giftWrapPostcardData),
            currencyCode,
            giftWrapMessageFee,
            popupId
        };
    }

    render() {
        return (
            <GiftWrapCheckoutSummarySection
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftWrapCheckoutSummarySectionContainer);
