/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const PRODUCT_CONTENT_TYPE = 'products';
export const PRODUCT_SLIDER_DATA_APPEARANCE = 'carousel';
export const PRODUCT_SLIDER_RULE = 'products-carousel';

export const SLIDER_SKELETON = [{
    name: 'BaseSlider',
    type: 'div',
    isLoopParent: true,
    children: [
        { name: 'Slide', type: 'div' }
    ]
}];

export const DEFAULT_AUTOPLAY_DURATION_MS = 5000;
