/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import AvailableSocialLoginsQuery from '../query/AvailableSocialLogins.query';

const getAvailableSocials = (args, callback, _instance) => {
    return [
        ...callback(args),
        AvailableSocialLoginsQuery.getQuery()
    ]
}

export const config = {
    'Scandipwa/Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: getAvailableSocials
        }
    }
};

export default config;
