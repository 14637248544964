/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getCustomerData } from '../data/customer';
import {
    getNewlyAddedWishlistItemsData,
    getRemovedItemFromWishlist,
    getUpdatedWishlistItems
} from '../data/wishlist';
import {
    GTM_EVENT_KEY_ADD_TO_WISHLIST,
    GTM_EVENT_KEY_REMOVE_ALL_ITEMS_FROM_WISHLIST,
    GTM_EVENT_KEY_REMOVE_FROM_WISHLIST
} from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/Wishlist/fireAddToWishlistEvent */
export const fireAddToWishlistEvent = debounceCallback(
    async (newlyAddedWishlistItemsData = [], customerId) => {
    // ^^^ It accepts array for migration in the future
        newlyAddedWishlistItemsData.forEach(async (product) => {
            pushToDataLayer({
                event: GTM_EVENT_KEY_ADD_TO_WISHLIST,
                customerId,
                ecommerce: {
                    items: {
                        ...product
                    }
                }
            });
        });
    }
);

/** @namespace Scandiweb/Gtm/Event/Wishlist/fireRemoveItemFromWishlistEvent */
export const fireRemoveItemFromWishlistEvent = debounceCallback(
    async (newlyRemovedWishlistItemsData = [], customerId) => {
    // ^^^ It accepts array for migration in the future
        newlyRemovedWishlistItemsData.forEach(async (product) => {
            pushToDataLayer({
                event: GTM_EVENT_KEY_REMOVE_FROM_WISHLIST,
                customerId,
                ecommerce: {
                    items: {
                        ...product
                    }
                }
            });
        });
    }
);

/** @namespace Scandiweb/Gtm/Event/Wishlist/fireRemoveAllWishlistItemsEvent */
export const fireRemoveAllWishlistItemsEvent = debounceCallback(async (customerId) => {
    // ! You can't use getStore() because we're in a reducer
    // vvv I'm using == because value can be 0 too but it's acceptable we only need undefined (backup plan) and null
    // vvv and I'm checking for null because in this case it's same as using !isSignedIn()
    if (customerId == null) {
        return;
    }

    pushToDataLayer({
        event: GTM_EVENT_KEY_REMOVE_ALL_ITEMS_FROM_WISHLIST
    });
});

/** @namespace Scandiweb/Gtm/Event/Wishlist/addToWishlistEventHandler */
export const addToWishlistEventHandler = async (items = []) => {
    if (items.length < 1) {
        return;
    }

    const { customerId } = await getCustomerData();
    const newlyAddedWishlistItemsData = await getNewlyAddedWishlistItemsData(items);

    fireAddToWishlistEvent(newlyAddedWishlistItemsData, customerId);
};

/** @namespace Scandiweb/Gtm/Event/Wishlist/removeItemFromWishlistEventHandler */
export const removeItemFromWishlistEventHandler = async (itemId, productsInWishlist, customerId) => {
    // ! You can't use getStore() because we're in a reducer
    const removedItem = await getRemovedItemFromWishlist(itemId, productsInWishlist);

    if (!removedItem.id) {
        return;
    }
    // ^^^ if the item is invalid or we didn't find it don't push the data

    fireRemoveItemFromWishlistEvent([removedItem], customerId);
};

/** @namespace Scandiweb/Gtm/Event/Wishlist/wishlistUpdateHandler */
export const wishlistUpdateHandler = debounceCallback(async (newProductsInWishlist, productsInWishlist, customerId) => {
    // ! You can't use getStore() because we're in a reducer
    const updatedItems = await getUpdatedWishlistItems(newProductsInWishlist, productsInWishlist);

    if (!updatedItems.length) {
        return;
    }

    updatedItems.forEach((item) => {
        // vvv positive mean we clicked on '+' button
        if (item.quantity > 0) {
            fireAddToWishlistEvent([item], customerId);
        }

        // vvv negative mean we clicked on '-' button
        if (item.quantity < 0) {
            fireRemoveItemFromWishlistEvent(
                [{ ...item, quantity: item.quantity * -1 }], customerId
                // ^^^ We need a positive number for quantity
            );
        }
    });
});
