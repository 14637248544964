/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { Provider as UnstatedProvider } from 'unstated';

import Router from 'Component/Router';
import SharedTransition from 'Component/SharedTransition';
import SomethingWentWrong from 'Route/SomethingWentWrong';

import { GDPRProvider } from '../../../packages/@scandiweb/amasty-gdpr/src/context/GDPR.context';
import { LayeredNavigationProvider } from '../../context/LayeredNavigation.context';

/** @namespace Scandipwa/Component/App/Component */
export class AppComponent extends PureComponent {
    renderRedux = this.renderRedux.bind(this);

    static propTypes = {
        isSomethingWentWrong: PropTypes.bool.isRequired,
        errorDetails: PropTypes.objectOf(PropTypes.string).isRequired,
        handleErrorReset: PropTypes.func.isRequired,
        reduxStore: PropTypes.shape(PropTypes.arrayOf(PropTypes.object))
    };

    static defaultProps = {
        reduxStore: {}
    };

    rootComponents = [
        this.renderRouter.bind(this),
        this.renderSharedTransition
    ];

    contextProviders = [
        this.renderGDPR,
        this.renderRedux,
        this.renderUnStated,
        this.renderLayeredNavigation
    ];

    renderGDPR(children) {
        return (
            <GDPRProvider key="GDPR">
                { children }
            </GDPRProvider>
        );
    }

    renderRedux(children) {
        const {
            reduxStore = {}
        } = this.props;

        return (
            <Provider store={ reduxStore } key="redux">
                { children }
            </Provider>
        );
    }

    renderUnStated(children) {
        return (
            <UnstatedProvider key="unstated">
                { children }
            </UnstatedProvider>
        );
    }

    renderLayeredNavigation(children) {
        return (
            <LayeredNavigationProvider key="LayeredNavigation">
                { children }
            </LayeredNavigationProvider>
        );
    }

    renderSharedTransition() {
        return (
            <SharedTransition key="transition" />
        );
    }

    renderRouter() {
        return (
            <Router key="router" />
        );
    }

    renderRootComponents() {
        return this.rootComponents.map((render) => render());
    }

    renderContextProviders() {
        const {
            isSomethingWentWrong
        } = this.props;

        const child = isSomethingWentWrong
            ? this.renderSomethingWentWrong()
            : this.renderRootComponents();

        return this.contextProviders.reduce(
            (acc, render) => render(acc),
            [child]
        );
    }

    renderSomethingWentWrong() {
        const {
            errorDetails,
            handleErrorReset
        } = this.props;

        return (
            <SomethingWentWrong
              onClick={ handleErrorReset }
              errorDetails={ errorDetails }
            />
        );
    }

    render() {
        return this.renderContextProviders();
    }
}
