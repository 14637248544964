import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './HeroSliderText.style';

/** @namespace Scandipwa/Component/HeroSliderText/Component */
export class HeroSliderTextComponent extends PureComponent {
    static propTypes = {
        description: PropTypes.string.isRequired,
        textColor: PropTypes.string.isRequired,
        isVideoEnded: PropTypes.bool.isRequired
    };

    renderWidgetContent() {
        const {
            description,
            textColor,
            isVideoEnded
        } = this.props;

        return (
            <div block="HeroSliderText" elem="Content">
                <div block="HeroSliderText" elem="Title" style={ { color: textColor } } mods={ { isVideoEnded } } />
                <p block="HeroSliderText" elem="Description" style={ { color: textColor } }>
                    { description }
                </p>
            </div>
        );
    }

    render() {
        return (
            <div block="HeroSliderText">
                { this.renderWidgetContent() }
            </div>
        );
    }
}

export default HeroSliderTextComponent;
