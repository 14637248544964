/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    SET_IS_MC_LOADING,
    SET_MC_PAYMENT_CONFIG,
    SET_MC_SHIPPING_LOCATIONS,
    SET_SELECTED_MC_PAYMENT_METHOD,
    SET_SELECTED_MC_SHIPPING_LOCATION
} from './MakeCommerce.action';

/** @namespace Scandiweb/MakeCommerce/Store/MakeCommerce/Reducer/setIsMcLoading */
export const setIsMcLoading = (action, state) => {
    const { value } = action;
    return { ...state, isLoading: value };
};

/** @namespace Scandiweb/MakeCommerce/Store/MakeCommerce/Reducer/setMcPaymentConfig */
export const setMcPaymentConfig = (action, state) => {
    const { value: { paymentChannels: paymentMethods, ui } } = action;
    return { ...state, paymentMethods, ui };
};

/** @namespace Scandiweb/MakeCommerce/Store/MakeCommerce/Reducer/setSelectedMcPaymentMethod */
export const setSelectedMcPaymentMethod = (action, state) => {
    const { value } = action;
    const { selectedMCPaymentMethod: oldValues } = state;
    return { ...state, selectedMCPaymentMethod: { ...oldValues, ...value } };
};

/** @namespace Scandiweb/MakeCommerce/Store/MakeCommerce/Reducer/setMcShippingMethods */
export const setMcShippingMethods = (action, state) => {
    const { value } = action;
    return { ...state, mcShippingLocations: value };
};

/** @namespace Scandiweb/MakeCommerce/Store/MakeCommerce/Reducer/setSelectedMcShippingLocation */
export const setSelectedMcShippingLocation = (action, state) => {
    const { value } = action;
    return { ...state, selectedMCLocation: value };
};

/** @namespace Scandiweb/MakeCommerce/Store/MakeCommerce/Reducer/getInitialState */
export const getInitialState = () => ({
    isLoading: false,
    paymentMethods: {},
    selectedMCPaymentMethod: {
        url: '',
        name: '',
        country: '',
        countryLabel: ''
    },
    selectedMCLocation: {},
    mcShippingLocations: {},
    ui: {
        mode: 'inline',
        inlineUselogo: 'text&logo',
        chorder: '',
        widgetTitle: __('Make Commerce'),
        groupBanklinks: false,
        widgetCountryselector: 'flag',
        widgetGroupcc: false,
        widgetGroupTitle: __('Credit Cards'),
        widgetGroupCcInline: false,
        countrySortOrder: '',
        logoSize: 'm',
        openDefault: false,
        cardShopName: '',
        referenceText: '',
        cardPrefill: false
    }
});

/** @namespace Scandiweb/MakeCommerce/Store/MakeCommerce/Reducer/MakeCommerceReducer */
export const MakeCommerceReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case SET_IS_MC_LOADING:
        return setIsMcLoading(action, state);
    case SET_MC_PAYMENT_CONFIG:
        return setMcPaymentConfig(action, state);
    case SET_SELECTED_MC_PAYMENT_METHOD:
        return setSelectedMcPaymentMethod(action, state);
    case SET_MC_SHIPPING_LOCATIONS:
        return setMcShippingMethods(action, state);
    case SET_SELECTED_MC_SHIPPING_LOCATION:
        return setSelectedMcShippingLocation(action, state);
    default:
        return state;
    }
};

export default MakeCommerceReducer;
