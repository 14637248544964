/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import GiftWrapCartQuery from '../query/Cart.query';

const getLabel = (label, allCart) => {
    if (!allCart) {
        return label;
    }

    return `${label} (${__('All Cart')})`;
};

const addGiftWrapFieldsAfterSelectedOptions = (args, callback, instance) => {
    const {
        product: {
            mpGiftWrapData: {
                name: giftWrapName,
                gift_message: giftWrapMessage,
                all_cart: giftWrapAllCart
            } = {},
            mpPostcardData: {
                name: postcardName,
                gift_message: postcardMessage,
                all_cart: postcardAllCart
            } = {}
        }
    } = instance.props;

    const giftWrapNameToRender = {
        label: getLabel(__('Gift Wrap'), giftWrapAllCart),
        value: giftWrapName,
        items: !giftWrapName
    };
    const giftWrapNoteToRender = {
        label: getLabel(__('Gift Note'), giftWrapAllCart),
        value: giftWrapMessage,
        items: !giftWrapMessage
    };
    const giftPostcardNameToRender = {
        label: getLabel(__('Gift Postcard'), postcardAllCart),
        value: postcardName,
        items: !postcardName
    };
    const giftPostcardMessageToRender = {
        label: getLabel(__('Gift Message'), postcardAllCart),
        value: postcardMessage,
        items: !postcardMessage
    };

    return (
        <>
             { callback(...args) }
             { instance.renderOption(giftWrapNameToRender) }
             { instance.renderOption(giftWrapNoteToRender) }
             { instance.renderOption(giftPostcardNameToRender) }
             { instance.renderOption(giftPostcardMessageToRender) }
        </>
    );
};

const addGiftWrapFieldsToOrderItems = (args, callback) => ([
    ...callback(...args),
    GiftWrapCartQuery.getCartGiftWrapDataField(),
    GiftWrapCartQuery.getCartGiftWrapPostcardDataField()
]);

export default {
    'Component/MyAccountOrderItemsTableRow/Component': {
        'member-function': {
            renderSelectedAndEnteredOptions: addGiftWrapFieldsAfterSelectedOptions
        }
    },
    'Query/Order/Query': {
        'member-function': {
            _getOrderItemProductsFields: addGiftWrapFieldsToOrderItems,
            _getInvoiceItemProductsFields: addGiftWrapFieldsToOrderItems,
            _getRefundsItemsProductsFields: addGiftWrapFieldsToOrderItems,
            _getShipmentsItemsProductsFields: addGiftWrapFieldsToOrderItems

        }
    }
};
