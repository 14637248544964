import { ROOT_CATALOG_CATEGORY_ID } from 'Component/LayeredNavigation/LayeredNavigation.config';
import {
    MenuComponent as SourceMenu
} from 'SourceComponent/Menu/Menu.component';

import './AdditionalMenu.style';

/** @namespace Scandipwa/Component/AdditionalMenu/Component */
export class AdditionalMenuComponent extends SourceMenu {
    renderTopLevel() {
        const {
            menu,
            device: {
                isMobile
            } = {}
        } = this.props;

        const categoryArray = Object.values(menu);
        const filteredCategoryArray = categoryArray.length
            ? categoryArray.filter((item) => item.url.state.category !== ROOT_CATALOG_CATEGORY_ID)
            : [];

        if (!filteredCategoryArray.length || isMobile) {
            return null;
        }

        return (
            <div block="AdditionalMenu" elem="MainCategories">
                <ul
                  block="AdditionalMenu"
                  elem="ItemList"
                  mods={ { type: 'main' } }
                >
                    { filteredCategoryArray.map(this.renderFirstLevel.bind(this)) }
                </ul>
            </div>
        );
    }

    render() {
        const { closeMenu } = this.props;

        return (
            <div
              block="AdditionalMenu"
              elem="MenuWrapper"
              onMouseLeave={ closeMenu }
            >
                { this.renderTopLevel() }
            </div>
        );
    }
}

export default AdditionalMenuComponent;
