/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */

import {
    ESTONIA_COUNTRY_CODE, ESTONIA_PHONE_PREFIX, LATVIA_COUNTRY_CODE, LATVIA_PHONE_PREFIX, LITHUANIA_COUNTRY_CODE, LITHUANIA_PHONE_PREFIX
} from './MyAccountAddressForm.config';

/** @namespace Scandipwa/Plugin/MyAccountAddressForm/Validator/validatePhoneNumber */
export const validatePhoneNumber = (value, countryId, isOmnivaParcel) => {
    if (countryId && isOmnivaParcel) {
        const prefix = value.substring(0, 4);

        switch (countryId) {
        case ESTONIA_COUNTRY_CODE:
            if (prefix === ESTONIA_PHONE_PREFIX) {
                const phone = value.replace(ESTONIA_PHONE_PREFIX, '');
                const digNum = phone.length;
                const firstDigit = phone.substring(0, 1);
                if (firstDigit !== '5' && firstDigit !== '8') {
                    return __('Only Estonian mobile phone numbers (number starting with either 5 or 8) are allowed');
                }
                if (firstDigit === '5' && (digNum < 7 || digNum > 8)) {
                    return __('Estonian mobile phone number starting with 5 must be either 7 or 8 digits long');
                } if (firstDigit === '8' && digNum !== 8) {
                    return __('Estonian mobile phone number starting with 8 must be 8 digits long');
                }

                break;
            }

            return __(`Estonian numbers should start with ${ESTONIA_PHONE_PREFIX}`);
        case LATVIA_COUNTRY_CODE:
            if (prefix === LATVIA_PHONE_PREFIX) {
                const phone = value.replace(LATVIA_PHONE_PREFIX, '');
                const digNum = phone.length;
                const firstDigit = phone.substring(0, 1);
                if (firstDigit !== '2' || digNum !== 8) {
                    return __('Only Latvian mobile phone numbers -- starting with 2 and 8 digits long -- are allowed');
                }

                break;
            }

            return __(`Latvian numbers should start with ${LATVIA_PHONE_PREFIX}`);

        case LITHUANIA_COUNTRY_CODE:
            if (prefix === LITHUANIA_PHONE_PREFIX) {
                const phone = value.replace(LITHUANIA_PHONE_PREFIX, '');
                const digNum = phone.length;
                const firstDigit = phone.substring(0, 1);
                const firstTwoDig = phone.substring(0, 2);
                if (firstDigit !== '6' || digNum !== 8) {
                    if (firstTwoDig !== '86' || digNum !== 9) {
                        return __('Only Lithuanian mobile phone number -- starting with 6 and 8 digits long or starting with 86 and 9 digits long -- are allowed');
                    }
                }

                break;
            }

            return __(`Lithuanian numbers should start with ${LITHUANIA_PHONE_PREFIX}`);
        default:
            return true;
        }
    }

    return true;
};
