/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { convertToObjectFromEntries } from './Utils';

/**
* Helper function that will add a new tab to exact section of My Account dashboard
*
* @namespace Scandiweb/PaypalExpress/Util/MyAccount/addNewTabToTabsMap */
export const addNewTabToTabsMap = (tabs, tabToAdd) => {
    const { section: sectionToAdd } = Object.values(tabToAdd)[0];
    // eslint-disable-next-line fp/no-let
    let newTabs = tabs;

    Object.entries(tabs).forEach(([, { section: currentSection }], idx) => {
        if (sectionToAdd + 1 === currentSection) {
            const tabsBefore = convertToObjectFromEntries(Object.entries(tabs).slice(0, idx));
            const tabsAfter = convertToObjectFromEntries(Object.entries(tabs).slice(idx));

            newTabs = {
                ...tabsBefore,
                ...tabToAdd,
                ...tabsAfter
            };
        }
    });

    // vvv if we didn't find the section to add just add it to the end
    if (Object.entries(newTabs).length === Object.entries(tabs).length) {
        return {
            ...tabs,
            ...tabToAdd
        };
    }

    return newTabs;
};
