import { connect } from 'react-redux';

import {
    CheckoutDeliveryOptionContainer as SourceContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.container';

/** @namespace Scandipwa/Component/CheckoutDeliveryOption/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    generalStoreWorkingHours: state.ConfigReducer.general_store_hours,
    generalStoreStreet: state.ConfigReducer.general_store_street_1,
    generalStoreCity: state.ConfigReducer.general_store_city
});

/** @namespace Scandipwa/Component/CheckoutDeliveryOption/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Scandipwa/Component/CheckoutDeliveryOption/Container */
export class CheckoutDeliveryOptionContainer extends SourceContainer {
    containerProps() {
        const {
            generalStoreWorkingHours,
            generalStoreStreet,
            generalStoreCity
        } = this.props;

        return {
            ...super.containerProps(),
            generalStoreWorkingHours,
            generalStoreStreet,
            generalStoreCity
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDeliveryOptionContainer);
