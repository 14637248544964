/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { sha256 } from 'js-sha256';

import getStore from 'Util/Store';

import { DL_VAL_NOT_APPLICABLE } from '../data/common';
import { getCustomerData } from '../data/customer';
import { getPageData } from '../data/page';
import { GTM_EVENT_KEY_GENERAL } from '../util/events';
import { getLanguageNameByCode } from '../util/languageName';
import { pushToDataLayer } from '../util/push';
import { debounceCallback, waitForCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/General/fireGeneralEvent */
export const fireGeneralEvent = debounceCallback(async (urlRewriteData) => {
    const pageData = await getPageData(urlRewriteData);

    // vvv If there is no page data don't dispatched the event
    if (!pageData) {
        return;
    }
    // ^^ one of the reasons we might not have data is when we have data from url rewrite and we need to...
    // ... skip the the event fired by GTMGeneralEventTracker to prevent firing to events (which one of them is incorrect)

    await waitForCallback(() => getStore().getState().ConfigReducer?.code);
    await waitForCallback(() => getStore().getState().ConfigReducer?.locale);

    const {
        userLoginState,
        customerId,
        customerEmail,
        userExistingCustomer,
        userLifetimeValue,
        userLifetimeOrders
    } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_GENERAL,
        userLoginState,
        customerId,
        ...(customerEmail === DL_VAL_NOT_APPLICABLE ? {} : { customerHashedEmail: sha256(customerEmail) }),
        userExistingCustomer,
        userLifetimeValue,
        userLifetimeOrders,
        ...pageData,
        storeView: getStore().getState().ConfigReducer?.code || DL_VAL_NOT_APPLICABLE,
        language: getLanguageNameByCode(getStore().getState().ConfigReducer?.code)
    });

    if (pageData?.pageType === 'product') {
        const ProductCanFire = new Event('ProductCanFire');
        document.dispatchEvent(ProductCanFire);
    }

    if (pageData?.pageType === 'checkout') {
        const CheckoutCanFire = new Event('CheckoutCanFire');
        document.dispatchEvent(CheckoutCanFire);
    }

    if (window.location.href.includes('/checkout/success')) {
        const PurchaseCanFire = new Event('PurchaseCanFire');
        document.dispatchEvent(PurchaseCanFire);
    }
});
