/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const AvailableSocialType = PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    login_url: PropTypes.string
});

export const AvailableSocialsType = PropTypes.arrayOf(AvailableSocialType);
export const SetUsedSocialTypeType = PropTypes.func;
export const SetCustomerExistsType = PropTypes.func;
