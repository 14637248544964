import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import {
    FieldSelectComponent as StendersFieldSelectComponent
} from 'StendersComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.extended.style';

/** @namespace Scandipwa/Component/FieldSelect/Component */
export class FieldSelectComponent extends StendersFieldSelectComponent {
    renderOption(option) {
        const {
            id,
            label,
            subLabel,
            isPlaceholder = false,
            isHovered,
            isAvailable = true
        } = option;

        const {
            isExpanded,
            handleSelectListOptionClick,
            attr: {
                defaultValue = ''
            } = {}
        } = this.props;

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ {
                  isDisabled: defaultValue === id || !isAvailable,
                  isExpanded,
                  isPlaceholder,
                  isHovered
              } }
              key={ id }
                /**
                 * Added 'o' as querySelector does not work with
                 * ids, that consist of numbers only
                 */
              id={ `o${id}` }
              role="menuitem"
                // eslint-disable-next-line react/jsx-no-bind
              onMouseDown={ () => handleSelectListOptionClick(option) }
                // eslint-disable-next-line react/jsx-no-bind
              onTouchStart={ () => handleSelectListOptionClick(option) }
                // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { label }
                { subLabel && (
                    <strong>
                        { ` ${subLabel}` }
                    </strong>
                ) }
            </li>
        );
    }

    renderAltPlaceholder() {
        const {
            attr: {
                altPlaceholder
            }
        } = this.props;

        if (!altPlaceholder) {
            return null;
        }

        return (
            <div
              block="FieldSelect"
              elem="AltPlaceholder"
            >
                { altPlaceholder }
            </div>
        );
    }

    render() {
        const {
            attr: {
                id = '',
                defaultValue
            } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            isDisabled
        } = this.props;

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  id={ `${ id }_wrapper` }
                  block="FieldSelect"
                  mods={ { isExpanded } }
                  onClick={ !isDisabled && handleSelectExpand }
                  onKeyPress={ !isDisabled && handleSelectListKeyPress }
                  role="button"
                  tabIndex="0"
                  aria-label="Select dropdown"
                  aria-expanded={ isExpanded }
                >
                    <div
                      block="FieldSelect"
                      elem="Clickable"
                      mods={ {
                          hasDefault: !!defaultValue
                      } }
                    >
                        { this.renderAltPlaceholder() }
                        { this.renderSortSelect() }
                        { this.renderNativeSelect() }
                        <ChevronIcon direction={ isExpanded ? TOP : BOTTOM } />
                    </div>
                    { this.renderOptions() }
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelectComponent;
