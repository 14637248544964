/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { isSignedIn } from 'Util/Auth';

const addPhoneNumberField = async (args, callback, _instance) => {
    const { updateCustomerLoadingStatus } = _instance.props;
    const { showPasswordChangeField, showEmailChangeField } = _instance.state;
    const {
        mobilenumber = '',
        firstname = '',
        lastname = '',
        taxvat = '',
        password = '',
        newPassword = '',
        email = '',
        smssubscriber = false
    } = args[0];

    if (!isSignedIn()) {
        return;
    }

    updateCustomerLoadingStatus(true);
    try {
        if (showPasswordChangeField) {
            await _instance.handlePasswordChange({ password, newPassword });
        }

        if (showEmailChangeField) {
            await _instance.handleEmailChange({ email, password });
        }

        await _instance.handleInformationChange({
            firstname, lastname, taxvat, mobilenumber, smssubscriber
        });

        _instance.handleSuccessChange();
    } catch (e) {
        _instance.handleLockAccount(e);
    }
};

export default {
    'Component/MyAccountInformation/Container': {
        'member-function': {
            onCustomerSave: addPhoneNumberField
        }
    }
};
