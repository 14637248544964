/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ChildrenType, MixType, RefType } from 'Type/Common.type';

import './Draggable.style';

/** @namespace Scandipwa/Component/Draggable/Component */
export class DraggableComponent extends PureComponent {
    static propTypes = {
        handleFocus: PropTypes.func.isRequired,
        handleKey: PropTypes.func.isRequired,
        children: ChildrenType.isRequired,
        mix: MixType.isRequired,
        draggableRef: RefType.isRequired,
        style: PropTypes.shape(PropTypes.arrayOf(PropTypes.string)),
        handleTouchStart: PropTypes.func.isRequired,
        handleMouseDown: PropTypes.func.isRequired,
        handleClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        style: {}
    };

    render() {
        const {
            children,
            handleFocus,
            handleKey,
            draggableRef,
            mix,
            style = {},
            handleMouseDown,
            handleTouchStart,
            handleClick
        } = this.props;

        return (
            <div
              block="Draggable"
              mix={ mix }
              ref={ draggableRef }
              onMouseDown={ handleMouseDown }
              onTouchStart={ handleTouchStart }
              onClick={ handleClick }
              onContextMenu={ handleClick }
              onKeyDown={ handleKey }
              onFocus={ handleFocus }
              tabIndex={ 0 }
              role="button"
              aria-label="Draggable area"
              style={ style }
            >
                { children }
            </div>
        );
    }
}

export default DraggableComponent;
