import {
    CurrencySwitcher as SourceCurrencySwitcher
} from 'SourceComponent/CurrencySwitcher/CurrencySwitcher.component';

import './CurrencySwitcher.override.style';

/** @namespace Scandipwa/Component/CurrencySwitcher/Component */
export class CurrencySwitcherComponent extends SourceCurrencySwitcher {
    // TODO implement logic
}

export default CurrencySwitcherComponent;
