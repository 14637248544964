import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { LEFT, RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import Link from 'Component/Link/Link.component';
import { RefType } from 'Type/Common.type';

import './LandingPageSlider.style';

/** @namespace Scandipwa/Component/LandingPageSlider/Component */
export class LandingPageSliderComponent extends PureComponent {
    static propTypes = {
        currentPage: PropTypes.number.isRequired,
        onLeftClick: PropTypes.func.isRequired,
        onRightClick: PropTypes.func.isRequired,
        isStickyMenu: PropTypes.bool.isRequired,
        navItemsRef: RefType.isRequired,
        pages: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired
        })).isRequired
    };

    renderWidgetContent() {
        const {
            currentPage,
            navItemsRef,
            pages
        } = this.props;

        return (
            <div block="LandingPageNavigationItems" ref={ navItemsRef }>
                { pages.map(({ title, link }, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={ idx } block="LandingPageNavigationItem">
                        <Link
                          to={ link }
                          block="LandingPageNavigationItem"
                          elem={ currentPage === idx ? 'Link-Current' : 'Link' }
                        >
                            <div block="LandingPageNavigationItem" elem="text">
                                { title }
                            </div>
                        </Link>
                    </div>
                )) }
            </div>
        );
    }

    renderButton() {
        const {
            currentPage,
            onLeftClick,
            onRightClick,
            pages
        } = this.props;

        return (
            <div block="LandingPageNavigation" elem="Button">
                <button
                  onClick={ onLeftClick }
                  disabled={ currentPage === 0 }
                  elem="Left"
                >
                    <ChevronIcon direction={ LEFT } />
                </button>
                <button
                  onClick={ onRightClick }
                  disabled={ currentPage === pages.length - 1 }
                  elem="Right"
                >
                    <ChevronIcon direction={ RIGHT } />
                </button>
            </div>
        );
    }

    render() {
        const { isStickyMenu } = this.props;

        return (
            <div block="LandingPageStickyMenu" mods={ { isStickyMenu } }>
                <div block="LandingPageNavigation">
                    <div className="LandingPageSliderComponent">
                        <div className="sliderContent">
                            { this.renderWidgetContent() }
                        </div>
                    </div>
                </div>
                { this.renderButton() }
            </div>
        );
    }
}

export default LandingPageSliderComponent;
