// eslint-disable-next-line max-len
import AdditionalFieldsForm from '@scandiweb/mageplaza-social-login/src/component/AdditionalFieldsForm/AdditionalFieldsForm.container';
// eslint-disable-next-line max-len
import SocialLoginButtons from '@scandiweb/mageplaza-social-login/src/component/SocialLoginButtons/SocialLoginButtons.container';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import StyledButton from 'Component/StyledButton/StyledButton.container';
import {
    MyAccountSignInComponent as SourceMyAccountSignInComponent
} from 'StendersComponent/MyAccountSignIn/MyAccountSignIn.component';
import { createUID } from 'Util/Common';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

export * from 'StendersComponent/MyAccountSignIn/MyAccountSignIn.component';

/** @namespace Scandipwa/Component/MyAccountSignIn/Component */
export class MyAccountSignInComponent extends SourceMyAccountSignInComponent {
    renderSignInForm() {
        const {
            usedSocialType,
            customerExists,
            sendSocialTokenQuery,
            onSignInSuccess,
            onFormError,
            handleForgotPassword,
            emailValue,
            isCheckout,
            handleEmailInput,
            isLoading,
            renderNewsletterCheckbox
        } = this.props;

        if (customerExists) {
            sendSocialTokenQuery();
        }

        return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
                { usedSocialType && !customerExists
                    ? <AdditionalFieldsForm usedSocialType={ usedSocialType } />
                    : (
                        <div>
                            <Form
                              key="sign-in"
                              onSubmit={ onSignInSuccess }
                              onError={ onFormError }
                            >
                                <Field
                                  label={ __('Email') }
                                  type={ FIELD_TYPE.email }
                                  attr={ {
                                      id: createUID(),
                                      name: 'email',
                                      placeholder: ' ',
                                      altPlaceholder: isCheckout ? __('Email') : __('Your email address'),
                                      defaultValue: emailValue,
                                      autocomplete: isCheckout ? 'off' : 'email'
                                  } }
                                  validateOn={ ['onChange'] }
                                  validationRule={ {
                                      isRequired: true,
                                      inputType: VALIDATION_INPUT_TYPE.email
                                  } }
                                  events={ { onChange: handleEmailInput } }
                                  addRequiredTag
                                />
                                { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                                { renderNewsletterCheckbox ? renderNewsletterCheckbox() : null }
                                <Field
                                  label={ __('Password') }
                                  type={ FIELD_TYPE.password }
                                  attr={ {
                                      id: createUID(),
                                      name: 'password',
                                      placeholder: ' ',
                                      altPlaceholder: isCheckout ? __('Password') : __('Enter your password'),
                                      autocomplete: 'current-password'
                                  } }
                                  validateOn={ ['onChange'] }
                                  validationRule={ {
                                      isRequired: true,
                                      inputType: VALIDATION_INPUT_TYPE.password
                                  } }
                                  addRequiredTag
                                />
                                <button
                                  type="button"
                                  block="Button"
                                  mods={ { likeLink: true } }
                                  mix={ { block: 'MyAccountOverlay', elem: 'ForgotPassword' } }
                                  onClick={ handleForgotPassword }
                                >
                                    { __('Forgot password?') }
                                </button>
                                <div block="MyAccountOverlay" elem="SignInButton">
                                    <StyledButton>
                                        { __('Sign in') }
                                    </StyledButton>
                                </div>
                                <Loader isLoading={ isLoading } />
                            </Form>
                        </div>
                    ) }
            </>
        );
    }

    render() {
        const {
            usedSocialType,
            setUsedSocialType,
            setCustomerExists
        } = this.props;

        return (
            <>
                { super.render() }
                { !usedSocialType
                    && (
                        <SocialLoginButtons
                          setCustomerExists={ setCustomerExists }
                          setUsedSocialType={ setUsedSocialType }
                        />
                    ) }
            </>
        );
    }
}

export default MyAccountSignInComponent;
