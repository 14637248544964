/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import { GiftWrapperType } from '../../type/GiftWrap';
import GiftWrapCard from './GiftWrapCard.component';

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currency: state.CartReducer.cartTotals.quote_currency_code
});

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (giftWrapPopupId, title) => dispatch(showPopup(giftWrapPopupId, { title }))
});

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapCard/Container */
export class GiftWrapCardContainer extends PureComponent {
    static propTypes = {
        wrap: GiftWrapperType.isRequired,
        currency: PropTypes.string.isRequired,
        customerNotice: PropTypes.string.isRequired,
        isSelected: PropTypes.bool.isRequired,
        radioName: PropTypes.string.isRequired,
        onSelectWrap: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleWrapSelect: this.handleWrapSelect.bind(this)
    };

    containerProps() {
        const {
            wrap,
            currency,
            isSelected,
            customerNotice,
            radioName
        } = this.props;

        return {
            wrap,
            currency,
            isSelected,
            customerNotice,
            radioName
        };
    }

    handleWrapSelect() {
        const {
            wrap,
            onSelectWrap
        } = this.props;

        onSelectWrap(wrap);
    }

    render() {
        return (
            <GiftWrapCard
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftWrapCardContainer);
