import {
    UPDATE_IS_MENU_VISIBLE,
    UPDATE_IS_SLIDER_DRAGGING
} from './MenuOverlay.action';

/** @namespace Scandipwa/Store/MenuOverlay/Reducer/getInitialState */
export const getInitialState = () => ({
    isMenuVisible: false,
    isSliderDragging: false
});

/** @namespace Scandipwa/Store/MenuOverlay/Reducer/MenuOverlayReducer */
export const MenuOverlayReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_IS_MENU_VISIBLE:
        const { isMenuVisible } = action;
        return {
            ...state,
            isMenuVisible
        };
    case UPDATE_IS_SLIDER_DRAGGING:
        const { isSliderDragging } = action;
        return {
            ...state,
            isSliderDragging
        };
    default:
        return state;
    }
};

export default MenuOverlayReducer;
