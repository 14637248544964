/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ChevronIcon from 'Component/ChevronIcon';
import {
    MyAccountTabListItemComponent as StendersMyAccountTabListItem
} from 'StendersComponent/MyAccountTabListItem/MyAccountTabListItem.component';

import './MyAccountTabListItem.override.style';

/** @namespace Scandipwa/Component/MyAccountTabListItem/Component */
export class MyAccountTabListItemComponent extends StendersMyAccountTabListItem {
    render() {
        const { children, tabEntry: [, { tabName }], isActive } = this.props;

        return (
            <li
              block="MyAccountTabListItem"
              mods={ { isActive } }
            >
                <button
                  block="MyAccountTabListItem"
                  elem="Button"
                  onClick={ this.changeActiveTab }
                  role="link"
                >
                    { __(tabName) }
                    <ChevronIcon />
                </button>
                { children }
            </li>
        );
    }
}

export default MyAccountTabListItemComponent;
