/* eslint-disable no-magic-numbers */
import { validateZipCode } from 'Util/Validator/Validator';

import { validatePhoneNumber } from './Validator';

const myAccountAddressForm = (props, callback) => {
    const { countryId, mcSelectedShippingLocation: { id } } = props[0];

    const isOmnivaParcel = !!(id && id.includes('omnivamc_parcel'));

    const result = callback(...props);

    return result.map((item) => {
        if (item.type === 'tel') {
            return {
                ...item,
                validationRule: {
                    ...item.validationRule,
                    match: (value) => validatePhoneNumber(value, countryId, isOmnivaParcel)
                }
            };
        }
        if (item.name === 'addressGroup') {
            const itemFields = item.fields.map((field) => {
                if (field.attr.name === 'postcode') {
                    return {
                        ...field,
                        validationRule: {
                            ...field.validationRule,
                            match: (value) => validateZipCode(value, countryId, isOmnivaParcel)
                        }
                    };
                }

                return field;
            });

            return { ...item, fields: itemFields };
        }

        return item;
    });
};

export default {
    'Component/MyAccountAddressForm/Form/myAccountAddressForm': {
        function: myAccountAddressForm
    }
};
