import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import history from 'Util/History';

import LandingPageSliderComponent from './LandingPageSlider.component';
import { HEADER_HEIGHT, SLIDER_OFFSET } from './LandingPageSlider.config';

import './LandingPageSlider.style';

/** @namespace Scandipwa/Component/LandingPageSlider/Container */
export class LandingPageSliderContainer extends PureComponent {
    static propTypes = {
        'data-who-we-are': PropTypes.string.isRequired,
        'data-who-we-are-link': PropTypes.string.isRequired,
        'data-join-our-team': PropTypes.string.isRequired,
        'data-join-our-team-link': PropTypes.string.isRequired,
        'data-sustainability': PropTypes.string.isRequired,
        'data-sustainability-link': PropTypes.string.isRequired
    };

    componentDidMount() {
        const offsetValue = this.calculateOffsetBasedOnCurrentPage();

        document.documentElement.style.setProperty('--landing-slider-content-offset', `${offsetValue}px`);

        const childrenItems = this.navItemsRef.current.children;
        const currentElement = childrenItems[this.getCurrentPageIndex()];

        this.navItemsRef.current.scrollLeft = currentElement.offsetLeft;

        this.setState({
            currentPage: this.getCurrentPageIndex()
        });

        this.unlisten = history.listen(() => {
            this.setState({
                currentPage: this.getCurrentPageIndex()
            });
        });

        window.addEventListener('scroll', this.handleScroll.bind(this));
        this.handleScroll();
    }

    componentWillUnmount() {
        this.unlisten();

        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    __construct(props) {
        super.__construct(props);
        this.state = {
            currentPage: this.getCurrentPageIndex(),
            lastScrollTop: HEADER_HEIGHT,
            isStickyMenu: false
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.navItemsRef = createRef();
    }

    getCurrentPageIndex = () => {
        const {
            'data-who-we-are-link': whoWeAreLink,
            'data-join-our-team-link': joinOurTeamLink,
            'data-sustainability-link': sustainabilityLink
        } = this.props;

        const links = [whoWeAreLink, joinOurTeamLink, sustainabilityLink];
        const filteredLinks = links.filter((link) => !!link);

        const currentPath = history.location.pathname;
        const index = filteredLinks.indexOf(currentPath);
        return index === -1 ? 0 : index;
    };

    getPages = () => {
        const {
            'data-who-we-are': whoWeAre,
            'data-who-we-are-link': whoWeAreLink,
            'data-join-our-team': joinOurTeam,
            'data-join-our-team-link': joinOurTeamLink,
            'data-sustainability': sustainability,
            'data-sustainability-link': sustainabilityLink
        } = this.props;

        const pagesProps = [
            { title: whoWeAre, link: whoWeAreLink },
            { title: joinOurTeam, link: joinOurTeamLink },
            { title: sustainability, link: sustainabilityLink }
        ];

        return pagesProps.filter((page) => !!page.title);
    };

    getLinkForPage = (pageIndex) => {
        const {
            'data-who-we-are-link': whoWeAreLink,
            'data-join-our-team-link': joinOurTeamLink,
            'data-sustainability-link': sustainabilityLink
        } = this.props;
        const links = [whoWeAreLink, joinOurTeamLink, sustainabilityLink];
        const filteredLinks = links.filter((link) => !!link);

        return filteredLinks[pageIndex];
    };

    calculateOffsetBasedOnCurrentPage() {
        const { currentPage } = this.state;
        return currentPage * SLIDER_OFFSET;
    }

    handleRightClick = () => {
        const { currentPage } = this.state;
        const nextPage = Math.min(currentPage + 1, this.getPages().length - 1);

        const link = this.getLinkForPage(nextPage);
        history.push(link);
    };

    handleLeftClick = () => {
        const { currentPage } = this.state;
        const prevPage = Math.max(currentPage - 1, 0);

        const link = this.getLinkForPage(prevPage);
        history.push(link);
    };

    handleScroll() {
        const { lastScrollTop } = this.state;
        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

        const isStickyMenu = currentScrollTop > HEADER_HEIGHT && currentScrollTop <= lastScrollTop;

        const newState = {
            isStickyMenu,
            lastScrollTop: currentScrollTop <= HEADER_HEIGHT ? HEADER_HEIGHT : currentScrollTop
        };

        this.setState(newState);
    }

    render() {
        const { currentPage, isStickyMenu } = this.state;

        return (
            <LandingPageSliderComponent
              currentPage={ currentPage }
              onLeftClick={ this.handleLeftClick }
              onRightClick={ this.handleRightClick }
              isStickyMenu={ isStickyMenu }
              scrollView={ this.scrollView }
              navItemsRef={ this.navItemsRef }
              pages={ this.getPages() }
            />
        );
    }
}

export default LandingPageSliderContainer;
