/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageplazaGiftWrap/Query/Cart/Query */
export class CartQuery {
    getGiftWrapDataFields = () => ([
        'name',
        'status',
        'price_type',
        'amount',
        'description',
        'image',
        'category',
        'sort_order',
        'created_at',
        'updated_at',
        'price',
        'use_gift_message',
        'gift_message',
        'gift_message_fee',
        'all_cart',
        'wrap_id'
    ]);

    getGiftWrapDataField = () => new Field('mp_gift_wrap_data')
        .addFieldList(this.getGiftWrapDataFields());

    getGiftWrapPostcardDataField = () => new Field('mp_gift_wrap_postcard_data')
        .addFieldList(this.getGiftWrapDataFields());

    // TODO Maybe it's better to change ^^^ these query names to vvv these so we can reuse ^^^ these
    getCartGiftWrapDataField = () => new Field('mpGiftWrapData')
        .addFieldList(this.getGiftWrapDataFields());

    getCartGiftWrapPostcardDataField = () => new Field('mpPostcardData')
        .addFieldList(this.getGiftWrapDataFields());
}

export default new CartQuery();
