/** @namespace Scandipwa/Store/LoginSideBar/Reducer/getInitialState */
import { TOGGLE_SIDEBAR } from './LoginSideBar.action';

/** @namespace Scandipwa/Store/LoginSideBar/Reducer/getInitialState */
export const getInitialState = () => ({
    isToggled: false
});

/** @namespace Scandipwa/Store/LoginSideBar/Reducer/LoginSideBarReducer */
export const LoginSideBarReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case TOGGLE_SIDEBAR:
        return {
            ...state,
            isToggled: !state.isToggled
        };

    default:
        return state;
    }
};

export default LoginSideBarReducer;
