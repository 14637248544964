/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import transformToNameValuePair from '@scandipwa/scandipwa/src/util/Form/Transform';
import { fetchMutation } from '@scandipwa/scandipwa/src/util/Request';
import { sendTheGivenConsentLog } from '@scandipwa/src/util/GDPR/Functions';
import { REGISTRATION_SCOPE } from '@scandiweb/amasty-gdpr/src/config';
import SocialTokenQuery from '@scandiweb/mageplaza-social-login/src/query/SocialToken.query';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { transformDateForBE } from 'Util/Form/Form';
import { getFromShop } from 'Util/Loyalty/Loyalty';

import { executeLoginFlow } from '../../util/login';
import AdditionalFieldsForm from './AdditionalFieldsForm.component';
import { SUCCESS_POPUP_LIFETIME } from './AdditionalFieldsForm.config';

/** @namespace Scandiweb/MageplazaSocialLogin/Component/AdditionalFieldsForm/Container/mapStateToProps */
export const mapStateToProps = () => ({});
/** @namespace Scandiweb/MageplazaSocialLogin/Component/AdditionalFieldsForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message))
});

/** @namespace Scandiweb/MageplazaSocialLogin/Component/AdditionalFieldsForm/Container */
export class AdditionalFieldsFormContainer extends PureComponent {
    static propTypes = {
        showErrorNotification: PropTypes.func.isRequired,
        usedSocialType: PropTypes.string.isRequired
    };

    state = {
        isRegistrationSuccess: false,
        isSubmitButtonEnabled: false
    };

    // please DO NOT reorder fields here, because otherwise it does not see these methods
    // in the containerFunctions property below
    // eslint-disable-next-line react/sort-comp
    submitHandler = async (_, fields) => {
        const {
            // eslint-disable-next-line react/prop-types
            usedSocialType,
            // eslint-disable-next-line react/prop-types
            customerExists = false,
            showErrorNotification
        } = this.props;

        const {
            birthDate,
            is_subscribed,
            phoneNumber,
            smssubscriber
        } = transformToNameValuePair(fields);
        const { token: guestQuoteId } = BrowserDatabase.getItem('guest_quote_id');

        try {
            const { socialToken: { token } } = await fetchMutation([
                SocialTokenQuery.getQuery({
                    guestQuoteId,
                    customerExists,
                    usedSocialType,
                    birthDate: transformDateForBE(birthDate),
                    is_subscribed,
                    phoneNumber,
                    smssubscriber,
                    from_shop: getFromShop()
                })
            ]);

            if (!token) {
                // this should not happen. we should have token here.
                throw new Error('AdditionalFieldsForm user creation error');
            }

            this.setState({
                isRegistrationSuccess: true
            });

            setTimeout(async () => {
                await executeLoginFlow(token);
                sendTheGivenConsentLog(REGISTRATION_SCOPE, fields);
            }, SUCCESS_POPUP_LIFETIME);
        } catch (err) {
            const { message } = err[0];
            showErrorNotification(message);
        }
    };

    onError() {
    }

    onGDPRStateChanged(isAllowed) {
        this.setState({
            isSubmitButtonEnabled: isAllowed
        });
    }

    // please DO NOT reorder this field - see the comment above
    containerFunctions = {
        submitHandler: this.submitHandler.bind(this),
        onError: this.onError.bind(this),
        onGDPRStateChanged: this.onGDPRStateChanged.bind(this)
    };

    containerProps() {
        const { storeConfig: { code } } = BrowserDatabase.getItem('config');
        const {
            isRegistrationSuccess,
            isSubmitButtonEnabled
        } = this.state;

        return {
            code,
            isRegistrationSuccess,
            isSubmitButtonEnabled
        };
    }

    render() {
        const { usedSocialType } = this.props;
        return (
            <AdditionalFieldsForm
              usedSocialType={ usedSocialType }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalFieldsFormContainer);
