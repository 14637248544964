/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';

import { UI_INLINE_USE_LOGO_TEXT, UI_MODE_INLINE } from '../../config/MakeCommerce.config';
import { MakeCommercePaymentUiType } from '../../type/MakeCommerce.type';

import './MCPaymentMethod.style';

/** @namespace Scandiweb/MakeCommerce/Component/MCPaymentMethod/Component */
export class MCPaymentMethodComponent extends PureComponent {
    static propTypes = {
        ui: MakeCommercePaymentUiType.isRequired,
        isSelected: PropTypes.bool.isRequired,
        onClickHandler: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        displayName: PropTypes.string,
        country: PropTypes.string,
        logoUrl: PropTypes.string,
        imagePath: PropTypes.string
    };

    static defaultProps = {
        displayName: '',
        country: '',
        logoUrl: '',
        imagePath: ''
    };

    renderField() {
        const {
            label,
            country,
            displayName,
            isSelected
        } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.checkbox }
              attr={ {
                  id: `option-makecommerce-${ displayName }-${country}`,
                  name: `option-makecommerce-${ displayName}-${country}`,
                  checked: isSelected
              } }
              label={ label }
              isDisabled={ false }
            />
        );
    }

    renderLogo() {
        const {
            isSelected,
            ui: {
                inlineUselogo
            },
            name,
            logoUrl,
            imagePath
        } = this.props;

        if (inlineUselogo === UI_INLINE_USE_LOGO_TEXT) {
            return null;
        }

        const src = logoUrl || imagePath;
        if (!src) {
            return null;
        }

        return (
            <img
              block="CheckoutPayment"
              elem="Logo"
              mods={ { isSelected } }
              src={ src }
              alt={ name }
            />
        );
    }

    renderForListInnerContent() {
        return (
            <div block="CheckoutPayment" elem="MCInnerContent">
                { this.renderField() }
                { this.renderLogo() }
            </div>
        );
    }

    renderForList() {
        const {
            isSelected,
            onClickHandler
        } = this.props;

        return (
            <li block="CheckoutPayment">
                <button
                  block="CheckoutPayment"
                  mods={ { isSelected } }
                  elem="Button"
                  type="button"
                  onClick={ onClickHandler }
                >
                    { this.renderForListInnerContent() }
                </button>
            </li>
        );
    }

    renderForWidget() {
        const {
            isSelected,
            onClickHandler
        } = this.props;

        return (
            <button
              block="CheckoutPayment"
              mods={ { isSelected } }
              elem="Button"
              type="button"
              onClick={ onClickHandler }
            >
                { this.renderLogo() }
            </button>
        );
    }

    render() {
        const {
            ui: {
                mode
            }
        } = this.props;

        if (mode === UI_MODE_INLINE) {
            return this.renderForList();
        }

        return this.renderForWidget();
    }
}

export default MCPaymentMethodComponent;
