/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const addMobileNumberField = (args, callback) => [
    ...callback(...args),
    'mobilenumber'
];

export default {
    'Query/MyAccount/Query': {
        'member-function': {
            _getCustomerFields: addMobileNumberField
        }
    }
};
