/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import {
    cloneElement, createRef, lazy, Suspense
} from 'react';
import { LocomotiveScrollContext, LocomotiveScrollProvider } from 'react-locomotive-scroll';
import { Router as ReactRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import ErrorHandler from 'Component/ErrorHandler';
import { NO_MATCH } from 'Component/Header/Header.config';
import IntroPlaceHolder from 'Component/IntroPlaceHolder';
import Meta from 'Component/Meta';
import {
    PRINT_ALL_INVOICES,
    PRINT_ALL_REFUNDS,
    PRINT_ALL_SHIPMENT,
    PRINT_INVOICE,
    PRINT_ORDER as PRINT_ORDER_REQUEST,
    PRINT_REFUND,
    PRINT_SHIPMENT
} from 'Component/MyAccountOrderPrint/MyAccountOrderPrint.config';
import Page from 'Component/Page';
import {
    BEFORE_NARROW_DESKTOP_BREAKPOINT,
    BREADCRUMBS,
    DEMO_NOTICE,
    HEADER,
    NAVIGATION_TABS,
    NEW_VERSION_POPUP,
    ROUTES_WITHOUT_BREADCRUMBS
} from 'Component/Router/Router.config';
import SuccessPasswordChangePage from 'Component/SuccessPasswordChangePage';
import UrlRewrites from 'Route/UrlRewrites';
import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    OrderPrintPage,
    PasswordChangePage,
    ProductComparePage,
    RouterComponent as SourceRouter,
    SearchPage,
    SendConfirmationPage,
    SomethingWentWrong,
    StyleGuidePage,
    WishlistShared,
    withStoreRegex
} from 'StendersComponent/Router/Router.component';
import {
    ADDRESS_BOOK, MY_DOWNLOADABLE, MY_ORDERS, MY_WISHLIST, NEWSLETTER_SUBSCRIPTION
} from 'Type/Account.type';
import history from 'Util/History';
import { isHomePageUrl } from 'Util/Url';

import { isCrawler } from '../../util/Browser/Browser';
import {
    ACCOUNT_FORGOT_PASSWORD,
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    CART,
    CHANGE_PASSWORD,
    CHECKOUT,
    CMS_PAGE,
    COMPARE,
    CONFIRM_ACCOUNT,
    COOKIE_POPUP,
    CREATE_ACCOUNT,
    HOME,
    INGREDIENTS,
    LOGIN,
    MENU,
    MESSAGE_PAGE,
    MY_ACCOUNT,
    MY_ACCOUNT_ADDRESS,
    MY_ACCOUNT_DOWNLOADABLE,
    MY_ACCOUNT_NEWSLETTER,
    MY_ACCOUNT_ORDER,
    MY_ACCOUNT_ORDERS,
    MY_ACCOUNT_OVERLAY,
    MY_ACCOUNT_WISHLIST,
    PRINT_ORDER,
    SEARCH,
    SHARED_WISHLIST,
    STYLE_GUIDE,
    SUCCESS_PASSWORD_CHANGE,
    SWITCH_ITEMS_TYPE,
    URL_REWRITES
} from './Router.config';

import './Router.style';

export const Ingredients = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "ingredients" */ 'Route/Ingredients'));
export const AbandonedCart = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "abandoned-cart" */ 'Route/AbandonedCart'));
export const NoMatch = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/NoMatch'));
export const MessagePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/MessagePage'));
export const GMyAccountOverlay = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Component/GMyAccountOverlay'));

export {
    CartPage,
    Checkout,
    CmsPage,
    CookiePopup,
    DemoNotice,
    Header,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    SendConfirmationPage,
    ConfirmAccountPage,
    MenuPage,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    WishlistShared,
    OfflineNotice,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    SomethingWentWrong,
    StyleGuidePage,
    Breadcrumbs,
    OrderPrintPage,
    withStoreRegex
};

/** @namespace Scandipwa/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    static propTypes = {
        ...this.propTypes
    };

    static defaultProps = {
        ...this.defaultProps,
        pageType: ''
    };
    // eslint-disable-next-line
    __construct(props) {
        super.__construct(props);
        this.ref = createRef();
    }

    [BEFORE_ITEMS_TYPE] = [
        {
            component: <DemoNotice />,
            position: 15,
            name: DEMO_NOTICE
        },
        {
            component: <NavigationTabs />,
            position: 20,
            name: NAVIGATION_TABS
        },
        {
            component: <Header />,
            position: 25,
            name: HEADER
        },
        {
            component: <Breadcrumbs routesWithoutBreadcrumbs={ ROUTES_WITHOUT_BREADCRUMBS } />,
            position: 10,
            name: BREADCRUMBS
        },
        {
            component: <NewVersionPopup />,
            position: 35,
            name: NEW_VERSION_POPUP
        },
        {
            component: <GMyAccountOverlay />,
            position: 45,
            name: MY_ACCOUNT_OVERLAY
        }
    ];

    [SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={ withStoreRegex('/') } exact render={ (props) => <HomePage { ...props } /> } />,
            position: 10,
            name: HOME
        },
        /**
         * '/ingredients' page shouldn't be available for customers at all
         */
        {
            component: <Route
              path={ [
                  withStoreRegex('/ingredienti'),
                  withStoreRegex('/ingredients')
              ] }
              exact
              render={ (props) => <NoMatch { ...props } /> }
            />,
            position: 12,
            name: NO_MATCH
        },
        {
            component: <Route
              path={ [
                  '/(lv|ru)/ingredienti/category/:category_url_key',
                  '/(lv|ru)/ingredienti/:ingredient_url_key?',
                  withStoreRegex('/ingredients/category/:category_url_key'),
                  withStoreRegex('/ingredients/:ingredient_url_key?')
              ] }
              exact
              render={ (props) => <Ingredients { ...props } /> }
            />,
            position: 15,
            name: INGREDIENTS
        },
        {
            component: <Route path={ withStoreRegex('/search/:query/') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 25,
            name: SEARCH
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ (props) => <CmsPage { ...props } /> } />,
            position: 40,
            name: CMS_PAGE
        },
        {
            component: <Route path={ withStoreRegex('/cart') } exact render={ (props) => <CartPage { ...props } /> } />,
            position: 50,
            name: CART
        },
        {
            component: <Route path={ withStoreRegex('/checkout/:step?') } render={ (props) => <Checkout { ...props } /> } />,
            position: 55,
            name: CHECKOUT
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/successMessage') } render={ (props) => <SuccessPasswordChangePage { ...props } /> } />,
            position: 60,
            name: SUCCESS_PASSWORD_CHANGE
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/createPassword/') } render={ (props) => <PasswordChangePage { ...props } /> } />,
            position: 60,
            name: CHANGE_PASSWORD
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/create/') } render={ (props) => <CreateAccountPage { ...props } /> } />,
            position: 61,
            name: CREATE_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/login/') } render={ (props) => <LoginAccountPage { ...props } /> } />,
            position: 62,
            name: LOGIN
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/forgotpassword/') } render={ (props) => <ForgotPasswordPage { ...props } /> } />,
            position: 63,
            name: ACCOUNT_FORGOT_PASSWORD
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirmation') } render={ (props) => <SendConfirmationPage { ...props } /> } />,
            position: 64,
            name: CONFIRM_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirm') } render={ (props) => <ConfirmAccountPage { ...props } /> } />,
            position: 65,
            name: CONFIRM_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/view/order_id/:orderId?') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> } />,
            position: 70,
            name: MY_ACCOUNT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/history') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> } />,
            position: 71,
            name: MY_ACCOUNT_ORDERS
        },
        {
            component: <Route path={ withStoreRegex('/downloadable/customer/products') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_DOWNLOADABLE } /> } />,
            position: 72,
            name: MY_ACCOUNT_DOWNLOADABLE
        },
        {
            component: <Route path={ withStoreRegex('/wishlist') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_WISHLIST } /> } />,
            position: 73,
            name: MY_ACCOUNT_WISHLIST
        },
        {
            component: <Route path={ withStoreRegex('/customer/address') } render={ (props) => <MyAccount { ...props } selectedTab={ ADDRESS_BOOK } /> } />,
            position: 74,
            name: MY_ACCOUNT_ADDRESS
        },
        {
            component: <Route path={ withStoreRegex('/newsletter/manage') } render={ (props) => <MyAccount { ...props } selectedTab={ NEWSLETTER_SUBSCRIPTION } /> } />,
            position: 75,
            name: MY_ACCOUNT_NEWSLETTER
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/:tab?') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 76,
            name: MY_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ (props) => <MenuPage { ...props } /> } />,
            position: 80,
            name: MENU
        },
        {
            component: <Route path={ withStoreRegex('/wishlist/shared/:code') } render={ (props) => <WishlistShared { ...props } /> } />,
            position: 81,
            name: SHARED_WISHLIST
        },
        {
            component: <Route path={ withStoreRegex('/compare') } render={ (props) => <ProductComparePage { ...props } /> } />,
            position: 83,
            name: COMPARE
        },
        {
            component: <Route path={ withStoreRegex('/styleguide') } render={ (props) => <StyleGuidePage { ...props } /> } />,
            position: 84,
            name: STYLE_GUIDE
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/print/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ORDER_REQUEST } /> } />,
            position: 90,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printInvoice/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_INVOICES } /> } />,
            position: 91,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printShipment/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_SHIPMENT } /> } />,
            position: 92,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printCreditmemo/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_REFUNDS } /> } />,
            position: 93,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printInvoice/invoice_id/:invoiceId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_INVOICE } /> } />,
            position: 94,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printShipment/shipment_id/:shipmentId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_SHIPMENT } /> } />,
            position: 95,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printCreditmemo/creditmemo_id/:refundId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_REFUND } /> } />,
            position: 95,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/show-message/') } render={ (props) => <MessagePage { ...props } /> } />,
            position: 130,
            name: MESSAGE_PAGE
        },
        {
            component: <Route render={ (props) => <UrlRewrites { ...props } /> } />,
            position: 1000,
            name: URL_REWRITES
        },
        ...this.SWITCH_ITEMS_TYPE
    ];

    [AFTER_ITEMS_TYPE] = [
        {
            component:
                <LocomotiveScrollContext.Consumer>
                    { (scrollContext) => (
                        <CookiePopup scrollContext={ scrollContext } />
                    ) }
                </LocomotiveScrollContext.Consumer>,
            position: 20,
            name: COOKIE_POPUP
        }
    ];

    renderComponentsOfType(type) {
        return this.getSortedItems(type)
            .map(({ position, component }) => cloneElement(component, { key: position }));
    }

    renderAbandonedCartRestore() {
        return (
            <Suspense fallback={ null }>
                <Route
                  path={ withStoreRegex('/abandonedcart/track/restore/') }
                  render={ (props) => <AbandonedCart { ...props } /> }
                />
            </Suspense>
        );
    }

    renderHeaderScrollUp() {
        return (
            <Suspense fallback={ null }>
                <Header scrollUpHeader />
            </Suspense>
        );
    }

    renderNotificationList() {
        return (
            <Suspense fallback={ null }>
                <NotificationList />
            </Suspense>
        );
    }

    renderSectionOfType(type) {
        return (
            <Suspense fallback={ this.renderFallbackPage() }>
                { this.renderComponentsOfType(type) }
            </Suspense>
        );
    }

    renderDefaultRouterContent() {
        const {
            isOnlyMainItems,
            setBigOfflineNotice,
            isMobile
        } = this.props;

        const isHomePage = isHomePageUrl(window.location.pathname);
        const isAbandonedCartRestore = window.location.pathname.includes('/abandonedcart/track/restore/');
        const isCheckout = /^(\/[a-z]+)?\/checkout\/?/.test(window.location.pathname);
        const footerFragment = /^(\/[a-z]+)?\/checkout\/?/.test(window.location.pathname)
            ? null
            : <Suspense fallback={ null }><Footer /></Suspense>;

        if (isAbandonedCartRestore) {
            return (
                <Suspense fallback={ this.renderFallbackPage() }>
                    { this.renderAbandonedCartRestore() }
                </Suspense>
            );
        }

        if (isOnlyMainItems) {
            return this.renderMainItems();
        }

        const options = {
            smooth: true
        };

        /**
         * disable locomotive scroll on mobile and for checkout page
         */
        if (isMobile || isCheckout || window.innerWidth <= BEFORE_NARROW_DESKTOP_BREAKPOINT) {
            return (
                <>
                    { isHomePage && <IntroPlaceHolder /> }
                    { this.renderNotificationList() }
                    <ErrorHandler setBigOfflineNotice={ setBigOfflineNotice }>
                        { !isCheckout && this.renderHeaderScrollUp() }
                        <main block="Router" elem="MainContent" ref={ this.ref }>
                            { !isCheckout && this.renderSectionOfType(BEFORE_ITEMS_TYPE) }
                            <section className="intro">
                                <div block="Router" elem="MainItems">
                                    <Page>
                                        { this.renderMainItems() }
                                    </Page>
                                    { footerFragment }
                                </div>
                            </section>
                        </main>
                        { this.renderSectionOfType(AFTER_ITEMS_TYPE) }
                    </ErrorHandler>
                </>
            );
        }

        return (
            <>
                { isHomePage && <IntroPlaceHolder /> }
                { this.renderNotificationList() }
                <ErrorHandler setBigOfflineNotice={ setBigOfflineNotice }>
                    <LocomotiveScrollProvider
                      options={ options }
                      containerRef={ this.ref }
                    >
                        { !isCheckout && this.renderHeaderScrollUp() }
                        <main block="Router" elem="MainContent" ref={ this.ref } data-scroll-container>
                            { this.renderSectionOfType(BEFORE_ITEMS_TYPE) }
                            <section className="intro">
                                <div block="Router" elem="MainItems">
                                    <Page>
                                        { this.renderMainItems() }
                                    </Page>
                                    { footerFragment }
                                </div>
                            </section>
                        </main>
                        { this.renderSectionOfType(AFTER_ITEMS_TYPE) }
                    </LocomotiveScrollProvider>
                </ErrorHandler>
            </>
        );
    }

    renderMainItems() {
        const { isBigOffline } = this.props;

        if (!navigator.onLine && isBigOffline) {
            return <Suspense fallback={ this.renderFallbackPage() }><OfflineNotice isPage /></Suspense>;
        }

        return (
            <Suspense fallback={ this.renderFallbackPage() }>
                <Switch>
                    { this.renderComponentsOfType(SWITCH_ITEMS_TYPE) }
                </Switch>
            </Suspense>
        );
    }

    renderRouterContent() {
        const { hasError } = this.state;

        if (hasError) {
            return (
                <Suspense fallback={ this.renderFallbackPage() }>
                    { this.renderErrorRouterContent() }
                </Suspense>
            );
        }

        return this.renderDefaultRouterContent();
    }

    // overridden to remove loader
    render() {
        if (isCrawler()) {
            document.documentElement.classList.remove('has-scroll-smooth', 'has-scroll-init', 'scrollDisabled');
            document.documentElement.style.position = 'relative';
            document.documentElement.style.overflowY = 'auto';
        }

        return (
            <>
                <Meta />
                <ReactRouter history={ history }>
                    { this.renderRouterContent() }
                </ReactRouter>
            </>
        );
    }
}

export default RouterComponent;
