import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { showNotification } from 'Store/Notification/Notification.action';
import { LocationType } from 'Type/Router.type';

import { CP_CONV_COOKIE_LIFETIME_DAYS } from '../../config/CustomPopup.config';
import {
    setIsChoosePopup
} from '../../store/CustomPopup/CustomPopup.action';
import {
    storePopupViewedInBrowserDB
} from '../../util/CustomPopup.util';
import PopupSubscription from './PopupSubscription.component';

export const NewsletterSubscriptionDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NewsletterSubscription/NewsletterSubscription.dispatcher'
);

/** @namespace Stenders/CustomPopup/Component/PopupSubscription/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isGuestSubscribe: state.ConfigReducer.newsletter_subscription_allow_guest_subscribe,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    currentPopup: state.CustomPopupReducer.currentPopup
});

/** @namespace Stenders/CustomPopup/Component/PopupSubscription/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    subscribeToNewsletter: (email) => NewsletterSubscriptionDispatcher.then(
        ({ default: dispatcher }) => dispatcher.subscribeToNewsletter(dispatch, email)
    ),
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    dispatch
});

/** @namespace Stenders/CustomPopup/Component/PopupSubscription/Container */
export class PopupSubscriptionContainer extends Component {
    static propTypes = {
        location: LocationType.isRequired,
        subscribeToNewsletter: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        isGuestSubscribe: PropTypes.bool.isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        currentPopup: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired
    };

    containerFunctions = {
        onFormSubmit: this.onFormSubmit.bind(this)
    };

    elemRef = createRef();

    state = {
        isLoading: false,
        isCheckmarkShown: false
    };

    onFormSubmitDone = this.onFormSubmitDone.bind(this);

    shouldComponentUpdate(nextProps, nextState) {
        const {
            isGuestSubscribe,
            isSignedIn
        } = this.props;

        const { isLoading, isCheckmarkShown } = this.state;

        const {
            isGuestSubscribe: newIsGuestSubscribe,
            isSignedIn: newIsSignedIn
        } = nextProps;

        return isGuestSubscribe !== newIsGuestSubscribe
            || isSignedIn !== newIsSignedIn
            || isCheckmarkShown !== nextState.isCheckmarkShown
            || isLoading !== nextState.isLoading;
    }

    onFormSubmit(form, fields) {
        const {
            subscribeToNewsletter,
            isGuestSubscribe,
            isSignedIn,
            showErrorNotification
        } = this.props;

        const {
            value: email
        } = fields.find(({ name }) => name === 'newsletterEmail') || {};

        if (!isGuestSubscribe && !isSignedIn) {
            showErrorNotification(
                __('Guests can not subscribe to the newsletter. You must create an account or login to subscribe.')
            );

            return;
        }

        this.setState({ isLoading: true });

        subscribeToNewsletter(email)
            .then(this.onFormSubmitDone)
            .catch(this.onFormSubmitDone);
    }

    onFormSubmitDone() {
        const {
            currentPopup,
            dispatch
        } = this.props;

        storePopupViewedInBrowserDB(currentPopup.id, CP_CONV_COOKIE_LIFETIME_DAYS);
        dispatch(setIsChoosePopup(true));
        this.setState({ isLoading: false });
        // this.elemRef.current.reset();
        this.setState({ isCheckmarkShown: true });
    }

    containerProps() {
        const { isCheckmarkShown, isLoading } = this.state;

        return {
            isCheckmarkShown,
            isLoading,
            elemRef: this.elemRef
        };
    }

    render() {
        const { location: { pathname } } = this.props;

        return (
            <PopupSubscription
              { ...this.containerProps() }
              { ...this.containerFunctions }
              key={ pathname }
            />
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PopupSubscriptionContainer));
