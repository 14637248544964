/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';
import { LATVIA_LANGS_LIST } from 'Component/StoreSwitcher/StoreSwitcher.config';
import { StoreItemType } from 'Type/Config.type';

import './StoreItem.extended.style';

/** @namespace Scandipwa/Component/StoreItem/Component */
export class StoreItemComponent extends PureComponent {
    static propTypes = {
        item: StoreItemType.isRequired,
        currentStoreCode: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        handleStoreSwitch: PropTypes.func.isRequired
    };

    render() {
        const {
            item: { label, value },
            handleStoreSwitch,
            currentStoreCode,
            link
        } = this.props;

        const selectedStoreCode = Object.values(LATVIA_LANGS_LIST).includes(currentStoreCode)
            ? LATVIA_LANGS_LIST.lv
            : currentStoreCode;

        return (
            <div id={ value }>
                <Link
                  className={ `StoreItem-Item ${value === selectedStoreCode ? 'StoreItem-Item_isDisabled' : ''}` }
                  to={ link }
                  onClick={ handleStoreSwitch }
                >
                    { label }
                </Link>
            </div>
        );
    }
}

export default StoreItemComponent;
