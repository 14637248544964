/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageplazaSocialLogin/Query/AvailableSocialLogins/Query */
export class AvailableSocialLoginsQuery {
    getQuery() {
        return new Field('availableSocials')
            .addFieldList(this._getAvailableSocialsField());
    }

    _getAvailableSocialsField() {
        return [
            'label',
            'type',
            'login_url'
        ];
    }
}

export default new AvailableSocialLoginsQuery();
