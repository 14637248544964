/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BannerQuery from '../query/Banner.query';

export const addBannersToProductInterface = (args, callback) => [
    ...callback(...args),
    BannerQuery.getQuery()
];

export default {
    'Query/ProductList/Query': {
        'member-function': {
            _getProductInterfaceFields: addBannersToProductInterface
        }
    }
};
