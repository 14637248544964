import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Html from 'Component/Html';
import { fetchQuery } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';

import PromotionWidgetQuery from '../../query/PromotionWidget.query';

/** @namespace Scandipwa/Component/PromoWidgetContent/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Scandipwa/Component/PromoWidgetContent/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/PromoWidgetContent/Container */
export class PromoWidgetContentContainer extends DataContainer {
    static propTypes = {
        categoryId: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]).isRequired
    };

    state = {
        content: ''
    };

    componentDidMount() {
        this.getPromoWidgetContent();
    }

    componentDidUpdate(prevProps) {
        const { categoryId } = this.props;
        const { categoryId: prevCategoryId } = prevProps;

        if (categoryId && prevCategoryId !== categoryId) {
            this.setState({ content: '' });
            this.getPromoWidgetContent();
        }
    }

    async getPromoWidgetContent() {
        const { categoryId } = this.props;
        const { promoWidget } = await fetchQuery(PromotionWidgetQuery.getContent(parseInt(categoryId, 10)));
        this.setState({ content: promoWidget });
    }

    render() {
        const { content } = this.state;

        if (!content) {
            return null;
        }

        return (
            <div>
                { content && <Html content={ content[0] } /> }
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PromoWidgetContentContainer);
