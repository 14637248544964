export const SET_KEY_TREASURES_DATA = 'SET_KEY_TREASURES_DATA';

/** @namespace Scandipwa/Store/KeyTreasures/Action/setKeyTreasuresData */
export const setKeyTreasuresData = (productId, image, vimeoVideoURL, ingredients) => ({
    type: SET_KEY_TREASURES_DATA,
    productId,
    image,
    vimeoVideoURL,
    ingredients
});
