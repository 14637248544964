/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { waitForCallback } from '../util/wait';
import { getProductCategoriesData, getProductPriceData } from './product';

/** @namespace Scandiweb/Gtm/Data/Wishlist/extractItemsSKU */
export const extractItemsSKU = (items) => items.map((product) => product.sku);

/** @namespace Scandiweb/Gtm/Data/Wishlist/checkAreItemsAddedToWishList */
export const checkAreItemsAddedToWishList = (newlyAddedWishlistItemsSKU) => {
    const productsInWishlist = getStore().getState().WishlistReducer?.productsInWishlist;

    if (!productsInWishlist || productsInWishlist.length < 1) {
        return false;
    }

    const found = Object.values(productsInWishlist)
        .some((product) => newlyAddedWishlistItemsSKU.indexOf(product.sku) >= 0);

    return found;
};

/** @namespace Scandiweb/Gtm/Data/Wishlist/getNewlyAddedWishlistItemsData */
export const getNewlyAddedWishlistItemsData = async (items) => {
    const newlyAddedWishlistItemsSKU = extractItemsSKU(items);
    // vvv Wait for our items to be added to wishlist
    const areItemsAddedToWishList = await waitForCallback(
        () => checkAreItemsAddedToWishList(newlyAddedWishlistItemsSKU)
    );

    if (!areItemsAddedToWishList) {
        return [];
    }

    const { productsInWishlist } = getStore().getState().WishlistReducer;
    const productWishListArrayOfValues = Object.values(productsInWishlist);
    const newlyAddedWishlistItemsData = await Promise.all(
        productWishListArrayOfValues
            .filter(({ sku }) => newlyAddedWishlistItemsSKU.indexOf(sku) >= 0)
            .map(async (product) => ({
                id: product.sku,
                name: product.name,
                price: await getProductPriceData(product),
                // vvv because it will be at the end of the list
                position: productWishListArrayOfValues.length,
                // vvv These are not obligatory
                category: await getProductCategoriesData(product),
                quantity: product.quantity
                // ^^^ These are not obligatory
            }))
    );

    return newlyAddedWishlistItemsData;
};

/** @namespace Scandiweb/Gtm/Data/Wishlist/getRemovedItemFromWishlist */
export const getRemovedItemFromWishlist = async (itemId, productsInWishlist) => {
    const itemIndex = Object.keys(productsInWishlist).indexOf(itemId?.toString());
    const item = productsInWishlist[itemId] || {};
    const {
        sku: id,
        name,
        quantity
    } = item;

    return {
        id,
        name,
        price: await getProductPriceData(item),
        position: itemIndex,
        // vvv These are not obligatory
        category: await getProductCategoriesData(item),
        quantity
        // ^^^ These are not obligatory
    };
};

/** @namespace Scandiweb/Gtm/Data/Wishlist/getUpdatedWishlistItems */
export const getUpdatedWishlistItems = async (newProductsInWishlist, productsInWishlist) => {
    const newProductsInWishlistArrayOfKeys = Object.keys(newProductsInWishlist);
    // vvv to prevent duplication on addToWishlist and RemovedFromWishlist event we need to filter products first
    const updatedItems = Promise.all( // <<< because of async callback inside map
        Object.keys(productsInWishlist)
            .filter((productKey) => newProductsInWishlistArrayOfKeys.indexOf(productKey) >= 0)
            .map(async (productKey) => {
                const item = productsInWishlist[productKey];
                const {
                    sku: id,
                    name,
                    quantity: oldQuantity
                } = item;
                const {
                    quantity: newQuantity
                } = newProductsInWishlist[productKey];

                return {
                    id,
                    name,
                    price: await getProductPriceData(item),
                    position: newProductsInWishlistArrayOfKeys.indexOf(productKey),
                    // vvv These are not obligatory
                    category: await getProductCategoriesData(item),
                    quantity: newQuantity - oldQuantity
                // ^^^ These are not obligatory
                };
            })
    );

    return updatedItems;
};
