/** @namespace Scandipwa/Store/GMyAccountOverlay/Reducer/getInitialState */
import { TOGGLE_MYACCOUNT_OVERLAY } from './GMyAccountOverlay.action';

/** @namespace Scandipwa/Store/GMyAccountOverlay/Reducer/getInitialState */
export const getInitialState = () => ({
    isMyAccountOverlayVisible: false
});

/** @namespace Scandipwa/Store/GMyAccountOverlay/Reducer/GMyAccountOverlayReducer */
export const GMyAccountOverlayReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case TOGGLE_MYACCOUNT_OVERLAY:
        return {
            ...state,
            isMyAccountOverlayVisible: !state.isMyAccountOverlayVisible
        };

    default:
        return state;
    }
};

export default GMyAccountOverlayReducer;
