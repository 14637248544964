const addInstructionField = (args, callback) => {
    return [
        ...callback(...args),
        'instruction'
    ];
};

export default {
    'Query/Checkout/Query': {
        'member-function': {
            _getPaymentMethodFields: addInstructionField
        }
    }
};
