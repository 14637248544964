/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import DiscountRule from '../component/DiscountRule';

const addFullDiscountData = (isCartOverlay = false) => (args, callback, instance) => {
    const {
        totals: {
            amasty_discounts,
            applied_rule_ids,
            coupon_code,
            discount_amount,
            quote_currency_code,
            show_breakdown
        } = {}
    } = instance.props;

    if (!show_breakdown) {
        return null;
    }

    if (!applied_rule_ids) {
        return null;
    }

    const label = __('Discounts');
    const discountAmount = -Math.abs(discount_amount);
    const renderForCartOverlay = (children) => (
        <dl
          block="CartOverlay"
          elem="Discount"
        >
            { children }
        </dl>
    );

    const renderDiscountRule = () => (
        <DiscountRule
          price={ discountAmount }
          title={ label }
          coupon_code={ coupon_code }
          currency={ quote_currency_code }
          amastyDiscounts={ amasty_discounts }
        />
    );

    return (
        isCartOverlay ? renderForCartOverlay(renderDiscountRule()) : renderDiscountRule()
    );
};

export default {
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderDiscount: addFullDiscountData()
        }
    },
    'Component/CartOverlay/Component': {
        'member-function': {
            renderDiscount: addFullDiscountData(true)
        }
    }
};
