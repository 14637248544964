import { isArray } from '@tilework/mosaic-craco/lib/utils';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import {
    BreadcrumbsContainer as StendersBreadcrumbsContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'StendersComponent/Breadcrumbs/Breadcrumbs.container';
import BreadcrumbsReducer from 'Store/Breadcrumbs/Breadcrumbs.reducer';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import UrlRewritesReducer from 'Store/UrlRewrites/UrlRewrites.reducer';
import { BreadcrumbsType } from 'Type/ProductList.type';
import { withReducers } from 'Util/DynamicReducer';
import history from 'Util/History';
import { appendWithStoreCode, isHomePageUrl } from 'Util/Url';

import { MY_ACCOUNT_NAME, PAGES_WITHOUT_BREADCRUMBS } from './Breadcrumbs.config';

/** @namespace Scandipwa/Component/Breadcrumbs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    breadcrumbs: state.BreadcrumbsReducer?.breadcrumbs,
    pageType: state.UrlRewritesReducer.urlRewrite?.type,
    pageName: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState?.name
});

export {
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/Breadcrumbs/Container */
export class BreadcrumbsContainer extends StendersBreadcrumbsContainer {
    static propTypes = {
        ...this.propTypes,
        routesWithoutBreadcrumbs: PropTypes.arrayOf(PropTypes.string),
        breadcrumbs: BreadcrumbsType,
        renderWithoutCheck: PropTypes.bool,
        pageType: PropTypes.string,
        pageName: PropTypes.string.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        routesWithoutBreadcrumbs: undefined,
        renderWithoutCheck: false,
        pageType: null
    };

    containerFunctions = {
        ...this.containerFunctions,
        shouldHideBreadcrumbs: this.shouldHideBreadcrumbs.bind(this)
    };

    containerProps() {
        const { routesWithoutBreadcrumbs, pageType, pageName } = this.props;

        return {
            ...super.containerProps(),
            routesWithoutBreadcrumbs,
            pageType,
            pageName
        };
    }

    goBack() {
        const { breadcrumbs } = this.props;

        if (breadcrumbs.length === 1) {
            history.push({ pathname: '/' });
        }

        const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1] ?? {};

        const { url } = lastBreadcrumb;

        if (typeof url === 'string') {
            history.push({ pathname: url });

            return;
        }

        history.push(url);
    }

    checkPageIstMyAccount() {
        const {
            breadcrumbs
        } = this.props;

        if (!breadcrumbs || isArray(breadcrumbs) || breadcrumbs.length === 0) {
            return false;
        }

        return Array.from(breadcrumbs).findIndex(
            (breadcrumb) => __(breadcrumb?.name) === __(MY_ACCOUNT_NAME)
        ) !== -1;
    }

    shouldHideBreadcrumbs() {
        const {
            areBreadcrumbsVisible,
            routesWithoutBreadcrumbs,
            renderWithoutCheck
        } = this.props;

        if (renderWithoutCheck) {
            return false;
        }

        const { pathname = appendWithStoreCode('/') } = location;

        if (routesWithoutBreadcrumbs && routesWithoutBreadcrumbs.some((route) => pathname.includes(route))) {
            return true;
        }

        return (
            !areBreadcrumbsVisible
            || pathname.match(appendWithStoreCode(CHECKOUT_URL))
            || isHomePageUrl(pathname)
            || this.checkPageIstMyAccount()
            || PAGES_WITHOUT_BREADCRUMBS.some((page) => pathname.match(page))
        );
    }
}

export default withReducers({
    BreadcrumbsReducer,
    UrlRewritesReducer
})(connect(mapStateToProps, mapDispatchToProps)(BreadcrumbsContainer));
