/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { useEffect } from 'react';

import getStore from 'Util/Store';

import { fireGeneralEvent } from '../../event/general';
import { waitForCallback } from '../../util/wait';

// vvv Added a component to use location hook
function GTMGeneralEventTracker() {
    const regex = '/\/checkout\/?$/';
    useEffect(() => {
        if (!window.location.href.includes('/checkout/billing')
        && !(new RegExp(regex).test(window.location.href))
        && (!window.location.href.includes('checkout/success/?orderId='))
        && (!window.location.href.endsWith('checkout/'))
        && (!window.location.href.endsWith('checkout'))) {
            fireGeneralEvent();
        }
    }, [window.location.pathname]);

    return null;
}

const addGTMGeneralEventTracker = (args, callback) => (
    <>
        { callback(args) }
        <GTMGeneralEventTracker />
    </>
);

const addGTMGeneralEvent = async (args, callback) => {
    const [{ urlResolver = { notFound: true } },, { urlParam }] = args;
    const newUrlRewrite = urlResolver || { notFound: true };

    // vvv Wait for urlRewrite type
    await waitForCallback(() => getStore().getState().UrlRewritesReducer?.urlRewrite?.type);

    const {
        urlRewrite: oldUrlRewrite = {},
        requestedUrl = ''
    } = getStore().getState().UrlRewritesReducer;

    // check if URL changed and it's new and prevent sending duplicates event
    if (
        requestedUrl !== urlParam
        && (
            oldUrlRewrite.id !== newUrlRewrite.id
            || oldUrlRewrite.type !== newUrlRewrite.type
            || oldUrlRewrite.sku !== newUrlRewrite.sku
            || newUrlRewrite.notFound
        )
    ) {
        fireGeneralEvent(newUrlRewrite);
    }

    callback(...args);
};

export default {
    'Component/Router/Container': {
        'member-function': {
            render: addGTMGeneralEventTracker
        }
    },
    'Store/UrlRewrites/Dispatcher': {
        'member-function': {
            onSuccess: {
                position: 120,
                implementation: addGTMGeneralEvent
            }
        }
    }
};
