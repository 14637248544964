import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

const renderSelector = (args, callback, _instance) => {
    const { storeConfig: { code } } = BrowserDatabase.getItem('config');

    if (code === 'lv') {
        return (
            <h2>Latvia</h2>
        );
    }

    return callback(...args);
};

export default {
    'Scandiweb/MakeCommerce/Component/MCTabbedWidget/Component': {
        'member-function': {
            renderSelector
        }
    }
};
