/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/PaypalExpress/Query/PaypalBillingAgreements/Query */
export class PaypalBillingAgreementsQuery {
    getQuery() {
        return new Field('paypalBillingAgreementsPage')
            .addFieldList(this.getPaypalBillingAgreementsPageFields());
    }

    getPaypalBillingAgreementsPageFields() {
        return [
            this.getPaypalBillingAgreementsField(),
            this.getPaypalBillingAgreementPaymentMethodsField()
        ];
    }

    getPaypalBillingAgreementPaymentMethodsField() {
        return new Field('paymentMethods')
            .addFieldList(this.getPaypalBillingAgreementPaymentMethodsFields());
    }

    getPaypalBillingAgreementPaymentMethodsFields() {
        return [
            'code',
            'title'
        ];
    }

    getPaypalBillingAgreementsField() {
        return new Field('billingAgreements')
            .addFieldList(this.getPaypalBillingAgreementsFields());
    }

    getPaypalBillingAgreementsFields() {
        return [
            'id',
            'reference_id',
            'status',
            'created_at',
            'updated_at',
            'payment_method_label',
            'edit_url'
        ];
    }
}

export default new PaypalBillingAgreementsQuery();
