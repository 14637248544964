/**
 * Amasty Special Promotions Pro compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import { rearrangePriceString } from 'Util/Price';

import './DiscountRule.style';

/** @namespace Scandiweb/AmastySpecialPromotionsPro/Component/DiscountRule/Component */
export class DiscountRuleComponent extends PureComponent {
    static propTypes = {
        price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        currency: PropTypes.string,
        itemsQty: PropTypes.number,
        title: PropTypes.string.isRequired,
        amastyDiscounts: PropTypes.arrayOf(
            PropTypes.shape({
                rule_id: PropTypes.number.isRequired,
                discount_amount: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired
            })
        ),
        isOpen: PropTypes.bool.isRequired,
        handleShowAllButtonClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        itemsQty: 0,
        currency: 'USD',
        amastyDiscounts: []
    };

    renderTitle() {
        const { title, handleShowAllButtonClick } = this.props;

        return (
            <div block="AmastyDiscount" elem="Title" onClick={ handleShowAllButtonClick } aria-hidden>
                <strong>{ title }</strong>
                { this.renderShowAllButton() }
            </div>
        );
    }

    renderAmastyDiscounts() {
        const {
            amastyDiscounts,
            isOpen
        } = this.props;

        if (!amastyDiscounts) {
            return null;
        }

        return (
            <div
              block="AmastyDiscount"
              elem="ChildRules"
              mods={ { isOpen } }
            >
                { amastyDiscounts.map(this.renderAmastyRule) }
            </div>
        );
    }

    renderAmastyRule = (rule) => {
        const { discount_amount: amount, name: title, rule_id: id } = rule;
        const { currency } = this.props;

        const price = -Math.abs(amount);

        return (
            <li key={ id } block="CheckoutOrderSummary" elem="SummaryItem">
                <p block="CheckoutOrderSummary" elem="Text">
                    { title }
                </p>

                <div block="CheckoutOrderSummary" elem="Price">
                    { rearrangePriceString(price, currency) }
                </div>
            </li>
        );
    };

    renderShowAllButton() {
        const { isOpen } = this.props;

        const mods = isOpen ? { state: 'isOpen' } : {};

        return (
            <button
              mix={ { block: 'Button', mods: { likeLink: true } } }
              block="AmastyDiscount"
              elem="ShowAllButton"
              mods={ mods }
            >
                <ChevronIcon direction={ isOpen ? TOP : BOTTOM } />
            </button>
        );
    }

    renderPrice() {
        const { currency, amastyDiscounts } = this.props;
        // eslint-disable-next-line fp/no-let
        let { price } = this.props;

        if (price === 0 && amastyDiscounts) {
            // eslint-disable-next-line no-return-assign
            amastyDiscounts.map((item) => price += item.discount_amount);
        }

        return (
            <div block="AmastyDiscount" elem="Price">
                { rearrangePriceString(-Math.abs(price), currency) }
            </div>
        );
    }

    render() {
        const { itemsQty, amastyDiscounts } = this.props;
        // eslint-disable-next-line fp/no-let
        let { price } = this.props;

        if (price === 0 && amastyDiscounts) {
            // eslint-disable-next-line no-return-assign
            amastyDiscounts.map((item) => price += item.discount_amount);
        }

        if (!itemsQty && !price) {
            return null;
        }

        if (price === 0 && !itemsQty) {
            return null;
        }

        return (
            <li block="AmastyDiscount" elem="Main">
                { this.renderTitle() }
                { this.renderAmastyDiscounts() }
                { this.renderPrice() }
            </li>
        );
    }
}

export default DiscountRuleComponent;
