import GMyAccountOverlayReducer from '../store/GMyAccountOverlay/GMyAccountOverlay.reducer';
import KeyTreasuresReducer from '../store/KeyTreasures/KeyTreasures.reducer';
import LoginSideBarReducer from '../store/LoginSideBar/LoginSideBar.reducer';

export default {
    'Store/Index/getStaticReducers': {
        function: (args, callback) => ({
            ...callback(args),
            GMyAccountOverlayReducer,
            KeyTreasuresReducer,
            LoginSideBarReducer
        })
    }
};
