export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        seoConfigurationData: state.ConfigReducer.seoConfigurationData
    };
};

export class ProductActionsContainerPlugin {
    containerProps = (args, callback, instance) => {
        const {
            seoConfigurationData: {
                use_product_seo_name
            } = {}
        } = instance.props;

        return {
            ...callback(...args),
            use_product_seo_name
        };
    };
}

const {
    containerProps
} = new ProductActionsContainerPlugin();

export default {
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/ProductActions/Container/mapStateToProps': {
        function: [
            {
                position: 50,
                implementation: mapStateToProps
            }
        ]
    }
};
