import PropTypes from 'prop-types';

const hidePopupAndGoBack = (args, callback, _instance) => {
    const { isClosable } = _instance.props;

    if (!isClosable) {
        return;
    }

    callback(...args);
};

const renderCloseButton = (args, callback, _instance) => {
    const { isClosable } = _instance.props;

    if (!isClosable) {
        return;
    }

    // eslint-disable-next-line consistent-return
    return callback(...args);
};

const propTypes = (originalMember) => ({
    ...originalMember,
    isClosable: PropTypes.bool
});

const defaultProps = (originalMember) => ({
    ...originalMember,
    isClosable: true
});

const containerProps = (args, callback, instance) => {
    const {
        isClosable
    } = instance.props;

    return {
        ...callback(),
        isClosable
    };
};

export default {
    'Component/Popup/Container': {
        'static-member': {
            propTypes,
            defaultProps
        },
        'member-function': {
            containerProps
        }
    },
    'Component/Popup/Component': {
        'member-function': {
            hidePopupAndGoBack,
            renderCloseButton
        }
    }
};
