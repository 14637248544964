/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { connect } from 'react-redux';

import DataContainer from 'SourceUtil/Request/DataContainer';

import { GDPRContext } from '../../context/GDPR.context';
import PrivacySettingsPageComponent from './PrivacySettingsPage.component';

/** @namespace Scandiweb/AmastyGdpr/Route/PrivacySettingsPage/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyGdpr/Route/PrivacySettingsPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyGdpr/Route/PrivacySettingsPage/Container */
export class PrivacySettingsPageContainer extends DataContainer {
    static propTypes = {};

    static contextType = GDPRContext;

    containerProps() {
        const {
            privacySettings,
            isExtensionAllowed,
            isLoading,
            handleAnonymiseData,
            handleDeleteRequest
        } = this.context;

        return {
            privacySettings,
            isExtensionAllowed,
            isLoading,
            handleAnonymiseData,
            handleDeleteRequest
        };
    }

    containerFunctions = {
        ...this.containerFunctions
    };

    render() {
        return (
            <PrivacySettingsPageComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacySettingsPageContainer);
