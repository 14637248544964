/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    LV_STORE_CODE,
    RU_STORE_CODE
} from 'Component/MyAccountNewsletterSubscription/MyAccountNewsletterSubscription.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountOverlayContainer as SourceMyAccountOverlayContainer
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.container';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import {
    ADDRESS_BOOK,
    FIRST_SECTION,
    MY_ACCOUNT, MY_ORDERS,
    MY_WISHLIST, NEWSLETTER_SUBSCRIPTION,
    SECOND_SECTION, THIRD_SECTION
} from 'Type/Account.type';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import history from 'Util/History';

import { PRIVACY_SETTINGS, PRIVACY_SETTINGS_URL } from '../../../packages/@scandiweb/amasty-gdpr/src/config';
import MyAccountOverlay from './MyAccountOverlay.component';
import {
    STATE_LOGGED_IN,
    STATE_SIGN_IN
} from './MyAccountOverlay.config';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/MyAccountOverlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isSignedIn: state.MyAccountReducer.isSignedIn,
    isWishlistEnabled: state.ConfigReducer.wishlist_general_active,
    newsletterActive: state.ConfigReducer.newsletter_general_active
});

/** @namespace Scandipwa/Component/MyAccountOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    logout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(false, false, dispatch)
    )
});

/** @namespace Scandipwa/Component/MyAccountOverlay/Container */
export class MyAccountOverlayContainer extends SourceMyAccountOverlayContainer {
    static propTypes = {
        ...this.propTypes,
        isSignedIn: PropTypes.bool.isRequired,
        isWishlistEnabled: PropTypes.bool.isRequired,
        newsletterActive: PropTypes.bool.isRequired,
        logout: PropTypes.func.isRequired
    };

    state = {
        ...this.state,
        usedSocialType: '',
        customerExists: false,
        activeDiscount: 0
    };

    static tabMap = {
        [MY_ACCOUNT]: {
            url: '/customer/account',
            tabName: __('Account Overview'),
            section: FIRST_SECTION
        },
        [MY_ORDERS]: {
            url: '/sales/order/history',
            tabName: __('My Orders'),
            section: FIRST_SECTION,
            isFullUrl: true
        },
        [MY_WISHLIST]: {
            url: '/wishlist',
            tabName: __('My Wishlist'),
            section: FIRST_SECTION,
            isFullUrl: true
        },
        [ADDRESS_BOOK]: {
            url: '/customer/address',
            tabName: __('Address Book'),
            section: SECOND_SECTION,
            isFullUrl: true
        },
        [PRIVACY_SETTINGS]: {
            url: PRIVACY_SETTINGS_URL,
            tabName: __('Privacy Settings'),
            section: SECOND_SECTION,
            isFullUrl: true
        },
        [NEWSLETTER_SUBSCRIPTION]: {
            url: '/newsletter/manage',
            tabName: (() => {
                const { storeConfig: { code } = {} } = BrowserDatabase.getItem('config') || {};

                if (code === LV_STORE_CODE || code === RU_STORE_CODE) {
                    return __('Subscriptions');
                }

                return __('Newsletter Subscription');
            })(),
            section: THIRD_SECTION,
            isFullUrl: true
        }
    };

    containerFunctions = {
        ...this.containerFunctions,
        handleLinkClick: this.handleLinkClick.bind(this)
    };

    handleLinkClick() {
        const { toggleAccountState } = this.props;

        toggleAccountState();
    }

    getUserName() {
        const { firstname } = BrowserDatabase.getItem(CUSTOMER) || {};

        return firstname;
    }

    static isTabEnabled(props, tabName) {
        const { isWishlistEnabled, newsletterActive } = props;

        switch (tabName) {
        case MY_WISHLIST:
            return isWishlistEnabled;
        case NEWSLETTER_SUBSCRIPTION:
            return newsletterActive;
        default:
            return true;
        }
    }

    tabsFilterEnabled() {
        return Object.entries(MyAccountOverlayContainer.tabMap).reduce((enabledTabs, [key, value]) => (
            MyAccountOverlayContainer.isTabEnabled(this.props, key)
                ? { ...enabledTabs, [key]: value } : enabledTabs
        ), {});
    }

    containerProps() {
        const {
            isSignedIn
        } = this.props;

        const {
            usedSocialType,
            customerExists,
            activeDiscount
        } = this.state;

        const { sliderRef } = this;

        return {
            ...super.containerProps(),
            isSignedIn,
            firstname: this.getUserName(),
            usedSocialType,
            customerExists,
            activeDiscount,
            sliderRef
        };
    }

    handleLogout() {
        const { logout } = this.props;

        logout();
        this.setState(() => ({
            usedSocialType: '',
            customerExists: false
        }));
    }

    /**
     * New method to handle active discount change
     */
    onActiveDiscountChange(activeDiscount) {
        this.setState({ activeDiscount: Math.abs(activeDiscount) });
    }

    containerFunctions = {
        ...this.containerFunctions,
        handleLogout: this.handleLogout.bind(this),
        setUsedSocialTypeForOverlay: this.setUsedSocialTypeForOverlay.bind(this),
        setCustomerExistsForOverlay: this.setCustomerExistsForOverlay.bind(this),
        onActiveDiscountChange: this.onActiveDiscountChange.bind(this)
    };

    /**
     * Overriden to redirect to HomePage after forgotPassword success
     * */
    static getDerivedStateFromProps(props, state) {
        const {
            isPasswordForgotSend,
            isOverlayVisible,
            isMobile,
            goToPreviousHeaderState
        } = props;

        const {
            isPasswordForgotSend: currentIsPasswordForgotSend,
            state: myAccountState
        } = state;

        const { location: { pathname, state: { isForgotPassword } = {} } } = history;

        const stateToBeUpdated = {};
        const customerIsSignedIn = isSignedIn();

        if (!isMobile) {
            if (!isOverlayVisible && !customerIsSignedIn) {
                if (pathname !== '/forgot-password' && !isForgotPassword) {
                    stateToBeUpdated.state = STATE_SIGN_IN;
                }
            } else if (!isOverlayVisible && customerIsSignedIn) {
                stateToBeUpdated.state = STATE_LOGGED_IN;
            }
        }

        if (myAccountState !== STATE_LOGGED_IN && customerIsSignedIn) {
            stateToBeUpdated.state = STATE_LOGGED_IN;

            if (pathname.includes(CHECKOUT_URL)) {
                goToPreviousHeaderState();
            }
        }

        if (myAccountState === STATE_LOGGED_IN && !customerIsSignedIn) {
            stateToBeUpdated.state = STATE_SIGN_IN;
        }

        if (isPasswordForgotSend !== currentIsPasswordForgotSend) {
            stateToBeUpdated.isPasswordForgotSend = isPasswordForgotSend;

            stateToBeUpdated.state = STATE_SIGN_IN;
        }

        return Object.keys(stateToBeUpdated).length ? stateToBeUpdated : null;
    }

    setUsedSocialTypeForOverlay(type) {
        this.setState(() => ({
            usedSocialType: type
        }));
    }

    setCustomerExistsForOverlay(exists) {
        this.setState(() => ({
            customerExists: exists
        }));
    }

    render() {
        return (
            <MyAccountOverlay
              { ...this.containerProps() }
              { ...this.containerFunctions }
              tabMap={ this.tabsFilterEnabled(MyAccountOverlayContainer.tabMap) }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOverlayContainer);
