/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import CheckoutOrderSummaryPriceLine from 'Component/CheckoutOrderSummaryPriceLine';

// vvv This value comes from app/code/Mageplaza/GiftWrap/Observer/PaypalPrepareItems.php
const MINIMUM_VALUE_FOR_PRICE = 0.0001;

const mapStateToProps = (args, callback) => {
    const [
        {
            ConfigReducer: {
                mpGiftWrap: {
                    enabled: isMPGiftWrapEnabled
                } = {}
            } = {}
        }
    ] = args;

    return {
        ...callback(...args),
        isMPGiftWrapEnabled
    };
};

const renderGiftWrapPriceLine = (title, price, quoteCurrencyCode) => {
    // vvv Copied logic from app/code/Mageplaza/GiftWrap/Observer/PaypalPrepareItems.php
    if (!price || price < MINIMUM_VALUE_FOR_PRICE) {
        return null;
    }

    return (
        <CheckoutOrderSummaryPriceLine
          price={ price?.toFixed(2) }
          currency={ quoteCurrencyCode }
          title={ title }
        />
    );
};

const addGiftWrapperSummaryAfterTax = (args, callback, instance) => {
    const {
        totals: {
            mp_gift_wrap_amount: giftWrapTotal,
            mp_gift_wrap_postcard_amount: giftWrapPostcardTotal,
            mp_gift_wrap_tax: giftWrapTax,
            // TODO ^^^ Need more investigation, Where we can have it? what would be the label?
            quote_currency_code: quoteCurrencyCode
        } = {},
        isMPGiftWrapEnabled
    } = instance.props;

    // vvv if extension is disable then skip
    if (!isMPGiftWrapEnabled) {
        return callback(...args);
    }

    return (
        <>
            { callback(...args) }
            { renderGiftWrapPriceLine(__('Gift Wrap'), giftWrapTotal, quoteCurrencyCode) }
            { renderGiftWrapPriceLine(__('Post Card'), giftWrapPostcardTotal, quoteCurrencyCode) }
            { renderGiftWrapPriceLine(__('Gift Wrap Tax'), giftWrapTax, quoteCurrencyCode) }
        </>
    );
};

export default {
    'Component/CheckoutOrderSummary/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderSubTotal: addGiftWrapperSummaryAfterTax
        }
    }
};
