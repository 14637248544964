export const CP_MODAL_NAME = 'AMASTY_GDPR_NOTICE';
export const CP_MODAL_POSITION = 9000;
export const DAY_IN_MILLISECONDS = 86400000;
export const CP_POPUP_KEY = 'custom_popup';
export const CP_POPUP_ID_PREFIX = 'cp_';
export const CP_URL_COND_IS = 'is';
export const CP_URL_COND_IS_NOT = 'is_not';
export const CP_URL_COND_STARTS = 'starts';
export const CP_URL_COND_NOT_STARTS = 'not_starts';
export const CP_URL_COND_CONTAINS = 'contains';
export const CP_URL_COND_NOT_CONTAINS = 'not_contains';
export const CP_COOKIE_ACTION_SHOW = 'show';
export const CP_COOKIE_ACTION_HIDE = 'hide';
export const CP_DEFAULT_TEXT_COLOR = '#000000';
export const CP_DEFAULT_BG_COLOR = '#000000db';
export const CP_DEFAULT_YES_BTN_COLOR = '#000000';
export const CP_DEFAULT_YES_BTN_TEXT_COLOR = '#FFFFFF';
export const CP_DEFAULT_NO_BTN_COLOR = '#000000';
export const CP_DEFAULT_NO_BTN_TEXT_COLOR = '#FFFFFF';
export const CP_LOGIN_LINK = 'login';
export const CP_CONV_COOKIE_LIFETIME_DAYS = 360;
