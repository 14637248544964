/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Children, cloneElement } from 'react';

import PaypalMessages from '../../component/PaypalMessages';
import { PAYPAL_PLACEMENT_CART_PAGE } from '../../component/PaypalSmartButtons/PaypalSmartButtons.config';

const addPayPalMessagesToCartPage = (args, callback) => {
    const CartPage = callback(...args);
    const children = Children.toArray(CartPage.props.children);

    return (
        <>
            { cloneElement(CartPage, { ...CartPage.props }, [
                <PaypalMessages
                  key={ PAYPAL_PLACEMENT_CART_PAGE }
                  page={ PAYPAL_PLACEMENT_CART_PAGE }
                />,
                ...children
            ]) }
        </>
    );
};

export default {
    'Route/CartPage/Component': {
        'member-function': {
            render: addPayPalMessagesToCartPage
        }
    }
};
