/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { COUNTRY_NAMES_MAP, MAKECOMMERCE_METHOD_CODE, UI_INLINE_USE_LOGO_LOGO } from '../../config/MakeCommerce.config';
import { setSelectedMcPaymentMethod } from '../../store/MakeCommerce/MakeCommerce.action';
import { MakeCommercePaymentUiType } from '../../type/MakeCommerce.type';
import MCPaymentMethodComponent from './MCPaymentMethod.component';

/** @namespace Scandiweb/MakeCommerce/Component/MCPaymentMethod/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    selectedMCPaymentMethod: state.MakeCommerceReducer.selectedMCPaymentMethod,
    ui: state.MakeCommerceReducer.ui
});

/** @namespace Scandiweb/MakeCommerce/Component/MCPaymentMethod/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setSelectedMcPaymentMethod: (method) => dispatch(setSelectedMcPaymentMethod(method))
});

/** @namespace Scandiweb/MakeCommerce/Component/MCPaymentMethod/Container */
export class MCPaymentMethodContainer extends PureComponent {
    static propTypes = {
        isSelected: PropTypes.bool.isRequired,
        setSelectedMcPaymentMethod: PropTypes.func.isRequired,
        onMakeCommerceClick: PropTypes.func.isRequired,
        item: PropTypes.shape({
            name: PropTypes.string.isRequired,
            methodType: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            display_name: PropTypes.string,
            country: PropTypes.string,
            min_amount: PropTypes.number,
            max_amount: PropTypes.number,
            logo_url: PropTypes.string,
            imagePath: PropTypes.string,
            countries: PropTypes.arrayOf(PropTypes.string)
        }),
        selectedMCPaymentMethod: PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
            country: PropTypes.string,
            countryLabel: PropTypes.string
        }),
        ui: MakeCommercePaymentUiType.isRequired
    };

    static defaultProps = {
        item: {
            country: 'cc',
            display_name: '',
            min_amount: 0,
            max_amount: 0,
            logo_url: '',
            imagePath: '',
            countries: []
        },
        selectedMCPaymentMethod: {
            name: '',
            url: '',
            country: '',
            countryLabel: ''
        }
    };

    containerFunctions = {
        onClickHandler: this.onClickHandler.bind(this)
    };

    containerProps() {
        const {
            ui,
            isSelected,
            item: {
                name,
                display_name: displayName,
                methodType,
                url,
                country,
                min_amount: minAmount,
                max_amount: maxAmount,
                logo_url: logoUrl,
                imagePath,
                countries
            }
        } = this.props;

        return {
            ui,
            isSelected,
            name,
            label: this.getLabel(),
            displayName,
            methodType,
            url,
            country,
            minAmount,
            maxAmount,
            logoUrl,
            imagePath,
            countries
        };
    }

    getLabel() {
        const {
            ui: {
                inlineUselogo
            },
            item: {
                name,
                country,
                display_name
            }
        } = this.props;

        if (inlineUselogo === UI_INLINE_USE_LOGO_LOGO) {
            return country ? COUNTRY_NAMES_MAP[country] : name;
        }

        return country ? `${ display_name } (${ COUNTRY_NAMES_MAP[country] })` : display_name;
    }

    onClickHandler() {
        const {
            setSelectedMcPaymentMethod,
            onMakeCommerceClick,
            item: {
                name,
                url,
                country
            }

        } = this.props;

        onMakeCommerceClick({ code: MAKECOMMERCE_METHOD_CODE });
        setSelectedMcPaymentMethod({ name, url, country });
    }

    render() {
        return (
            <MCPaymentMethodComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MCPaymentMethodContainer);
