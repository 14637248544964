import { GET_HTML_SITEMAP, GET_STORE_VIEWS } from './HtmlSitemap.action';

export const initialState = {
    htmlSitemapData: {},
    stores: {}
};

/** @namespace SeoSuite/Store/HtmlSitemap/Reducer/HtmlSitemapReducer */
export const HtmlSitemapReducer = (state = initialState, action) => {
    const { type, data, stores } = action;
    switch (type) {
    case GET_HTML_SITEMAP:
        const { mwHtmlSitemap } = data;
        return {
            ...state,
            htmlSitemapData: mwHtmlSitemap
        };

    case GET_STORE_VIEWS:
        const { storeList } = stores;

        return {
            ...state,
            stores: storeList
        };

    default:
        return state;
    }
};

export default HtmlSitemapReducer;
