/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import ChevronIcon from '@scandipwa/scandipwa/src/component/ChevronIcon';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './MyAccountBreadcrumb.style.scss';

/** @namespace Scandipwa/Component/MyAccountBreadcrumb/Component */
export class MyAccountBreadcrumbComponent extends PureComponent {
    static propTypes = {
        removeTab: PropTypes.func.isRequired,
        tab: PropTypes.string.isRequired,
        isSelected: PropTypes.bool.isRequired
    };

    render() {
        const { tab, removeTab, isSelected } = this.props;

        return (
            <div block="MyAccount" elem="BreadcrumbWrapper">
                <ChevronIcon />
                <li
                  block="MyAccount"
                  elem="Breadcrumb"
                  mods={ { isSelected } }
                  onClick={ removeTab }
                  onKeyDown={ removeTab }
                >
                    { tab }
                </li>
            </div>
        );
    }
}

export default MyAccountBreadcrumbComponent;
