import { OrderQuery as SourceOrderQuery } from 'SourceQuery/Order.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Order/Query */
export class OrderQuery extends SourceOrderQuery {
    _getOrderProductSalePriceField() {
        return new Field('product_sale_price')
            .addFieldList(this._getExtendedPriceFields());
    }

    _getOrderProductRowSubtotalField() {
        return new Field('row_subtotal')
            .addFieldList(this._getExtendedPriceFields());
    }

    _getOrderSubtotalField() {
        return new Field('subtotal')
            .addFieldList(this._getExtendedPriceFields());
    }

    _getExtendedPriceFields() {
        return [
            'value',
            'value_incl_tax',
            'currency'
        ];
    }
}

export default new OrderQuery();
