/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import StyledButton from 'Component/StyledButton/StyledButton.container';

/** @namespace Scandiweb/AmastyGdpr/Component/DownloadCSVButton/Component */
export class DownloadCSVButtonComponent extends PureComponent {
    static propTypes = {
        onClickHandler: PropTypes.func.isRequired
    };

    render() {
        const { onClickHandler } = this.props;

        return (
            <StyledButton
              handleClick={ onClickHandler }
            >
                { __('Download CSV') }
            </StyledButton>
        );
    }
}

export default DownloadCSVButtonComponent;
