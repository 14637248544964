import {
    SET_CP_CHOOSE_POPUP,
    SET_CP_CLOSE,
    SET_CP_CURRENT,
    SET_CP_DATA
} from './CustomPopup.action';

/** @namespace Stenders/CustomPopup/Store/CustomPopup/Reducer/getInitialState */
export const getInitialState = () => ({
    popups: [],
    currentPopup: {},
    isChooseNext: false,
    isPopupClose: false
});

/** @namespace Stenders/CustomPopup/Store/CustomPopup/Reducer/CustomPopupReducer */
export const CustomPopupReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case SET_CP_DATA:
        const {
            popups
        } = action;

        return {
            ...state,
            popups
        };
    case SET_CP_CURRENT:
        const {
            currentPopup
        } = action;

        return {
            ...state,
            currentPopup
        };
    case SET_CP_CHOOSE_POPUP:
        const {
            isChoosePopup
        } = action;

        return {
            ...state,
            isChoosePopup
        };
    case SET_CP_CLOSE:
        const {
            isPopupClose
        } = action;

        return {
            ...state,
            isPopupClose
        };
    default:
        return state;
    }
};

export default CustomPopupReducer;
