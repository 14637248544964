import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { ChildrenType, MixType } from 'Type/Common.type';
import browserHistory from 'Util/History';

import { StyledButtonComponent } from './StyledButton.component';
/** @namespace Scandipwa/Component/StyledButton/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});
/** @namespace Scandipwa/Component/StyledButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});
/** @namespace Scandipwa/Component/StyledButton/Container */
export class StyledButtonContainer extends PureComponent {
    static propTypes = {
        innerText: PropTypes.string,
        to: PropTypes.string,
        type: PropTypes.string,
        ariaLabel: PropTypes.string,
        className: PropTypes.string,
        handleClick: PropTypes.func,
        handleMouseOver: PropTypes.func,
        handleFocus: PropTypes.func,
        handleMouseLeave: PropTypes.func,
        handleKeyDown: PropTypes.func,
        // eslint-disable-next-line react/forbid-prop-types
        style: PropTypes.object,
        tabIndex: PropTypes.number,
        mix: MixType.isRequired,
        children: ChildrenType,
        isDisabled: PropTypes.bool
    };

    static defaultProps = {
        innerText: undefined,
        handleClick: undefined,
        isDisabled: false,
        className: 'Button',
        handleMouseOver: undefined,
        handleFocus: undefined,
        handleMouseLeave: undefined,
        handleKeyDown: undefined,
        style: undefined,
        tabIndex: undefined,
        ariaLabel: undefined,
        children: undefined,
        type: undefined,
        to: undefined
    };

    containerFunctions = {
        handleButtonClick: this.handleButtonClick.bind(this)
    };

    state = {
        isFeedbackDisplayed: false,
        feedbackStyle: {
            top: '0px',
            left: '0px'
        }
    };

    buttonRef = createRef();

    spanRef = createRef();

    handleButtonClick(e) {
        const { handleClick, to } = this.props;
        if (to) {
            e.preventDefault();
        }

        const offset = this.buttonRef?.current?.getBoundingClientRect() || { x: 0, y: 0 };
        this.setState({
            feedbackStyle: {
                top: `${e.pageY - offset.y }px`,
                left: `${e.pageX - offset.x }px`
            },
            isFeedbackDisplayed: true
        });

        const delayInMs = 500;
        e.persist();
        setTimeout(() => {
            if (to && !handleClick) {
                if (/^(?:[a-z]+:)?\/\//i.test(to)) {
                    window.open(to, '_blank');
                    return;
                }

                browserHistory.push(to);
            } else if (handleClick) {
                handleClick(e);
            }
            this.setState({ isFeedbackDisplayed: false });
        }, delayInMs);
    }

    containerProps() {
        const {
            innerText,
            mix,
            children,
            to,
            type,
            isDisabled,
            ariaLabel,
            className,
            handleMouseOver,
            handleMouseLeave,
            handleFocus,
            handleKeyDown,
            style,
            tabIndex
        } = this.props;
        const {
            isFeedbackDisplayed,
            feedbackStyle
        } = this.state;

        return {
            innerText,
            spanRef: this.spanRef,
            buttonRef: this.buttonRef,
            children,
            to,
            type,
            isDisabled,
            ariaLabel,
            className,
            handleMouseOver,
            handleMouseLeave,
            handleFocus,
            handleKeyDown,
            style,
            tabIndex,
            mix,
            isFeedbackDisplayed,
            feedbackStyle
        };
    }

    render() {
        return (
            <StyledButtonComponent { ...this.containerFunctions } { ...this.containerProps() } />
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StyledButtonContainer);
