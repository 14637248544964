import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import MyAccountLoyalty from './MyAccountLoyalty.component';

/** @namespace Stenders/Loyalty/Component/MyAccountLoyalty/Container/mapStateToProps */
export const mapStateToProps = (state) => ({ state });

/** @namespace Stenders/Loyalty/Component/MyAccountLoyalty/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({ dispatch });

/** @namespace Stenders/Loyalty/Component/MyAccountLoyalty/Container */
export class MyAccountLoyaltyContainer extends PureComponent {
    static propTypes = {
        loyaltyNumber: PropTypes.string
    };

    static defaultProps = {
        loyaltyNumber: ''
    };

    containerFunctions = { };

    containerProps() {
        const { loyaltyNumber } = this.props;
        return {
            loyaltyNumber
        };
    }

    render() {
        const { loyaltyNumber } = this.props;
        if (!loyaltyNumber) {
            return null;
        }

        return (
            <MyAccountLoyalty
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountLoyaltyContainer);
