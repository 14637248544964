import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import HairPLPPromoBannerComponent from './HairPLPPromoBanner.component';

/** @namespace Scandipwa/Component/HairPLPPromoBanner/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/HairPLPPromoBanner/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/HairPLPPromoBanner/Container */
export class HairPLPPromoBannerContainer extends PureComponent {
    static propTypes = {
        'data-title': PropTypes.string.isRequired,
        'data-description': PropTypes.string.isRequired,
        'data-link-text': PropTypes.string.isRequired,
        'data-link-url': PropTypes.string.isRequired,
        'data-image': PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    openMyAccountOverlay() {
        document.getElementsByClassName('Header-MyAccountWrapper')[0].click();
    }

    render() {
        const {
            'data-title': title,
            'data-description': description,
            'data-link-text': linkText,
            'data-link-url': linkUrl,
            'data-image': image,
            isMobile
        } = this.props;

        return (
            <HairPLPPromoBannerComponent
              title={ title }
              description={ description }
              linkText={ linkText }
              linkUrl={ linkUrl }
              image={ image }
              openMyAccountOverlay={ this.openMyAccountOverlay }
              isMobile={ isMobile }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HairPLPPromoBannerContainer);
