/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/** @namespace Scandiweb/Gtm/Util/LanguageName/getLanguageNameByCode */
export const getLanguageNameByCode = (languageCode) => {
    const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });

    try {
        try { // Lets check the original code
            return languageNames.of(languageCode);
        } catch (_e) { // Now lets check if we need to convert something like "en_US" to "en-US"
            return languageNames.of(languageCode.replace('_', '-'));
        }
    } catch (_e) { // Nope? then return the code itself
        return languageCode;
    }
};
