/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import { VALIDATION_INPUT_TYPE } from '@scandipwa/scandipwa/src/util/Validator/Config';
import Checkboxes from '@scandiweb/amasty-gdpr/src/component/Checkboxes';
import { REGISTRATION_SCOPE } from '@scandiweb/amasty-gdpr/src/config';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Image from 'Component/Image/Image.component';
import { STORE_CODES_WITH_PHONE } from 'Component/MyAccount/MyAccount.config';
import StyledButton from 'Component/StyledButton/StyledButton.container';
import successSign from 'Style/icons/registration-success.svg';
import { validatePhoneNumber } from 'Util/PhoneValidator/Validator';

import './AdditionalFieldsForm.style.scss';
/** @namespace Scandiweb/MageplazaSocialLogin/Component/AdditionalFieldsForm/Component */
export class AdditionalFieldsFormComponent extends PureComponent {
    static propTypes = {
        submitHandler: PropTypes.func.isRequired,
        onError: PropTypes.func.isRequired,
        isSubmitButtonEnabled: PropTypes.bool.isRequired,
        onGDPRStateChanged: PropTypes.func.isRequired
    };

    renderCheckBoxes = () => {
        const {
            code,
            isFormSubmitted,
            onGDPRStateChanged
        } = this.props;

        return (
            <div block="AdditionalFieldsForm" elem="CheckboxesWrapper">
                <Checkboxes
                  scope={ REGISTRATION_SCOPE }
                  isFormSubmitted={ isFormSubmitted }
                  onValidityChanged={ onGDPRStateChanged }
                />
                <Field
                  type={ FIELD_TYPE.checkbox }
                  label={ __('Subscribe to newsletter') }
                  attr={ {
                      id: 'is_subscribed_additional',
                      name: 'is_subscribed',
                      placeholder: __('Your Tax/VAT Number')
                  } }
                />
                { (code in STORE_CODES_WITH_PHONE)
                    && (
                        <Field
                          type={ FIELD_TYPE.checkbox }
                          label={ __('Subscribe to SMS') }
                          attr={ {
                              id: 'smssubscriber',
                              name: 'smssubscriber',
                              placeholder: __('Enter your password')
                          } }
                          mix={ { block: 'MyAccountOverlay', elem: 'Checkbox' } }
                        />
                    ) }
            </div>
        );
    };

    renderSubmitButton = () => {
        const { isSubmitButtonEnabled } = this.props;

        return (
            <div block="MyAccountOverlay" elem="Buttons">
                <StyledButton
                  mix={ { block: 'MyAccountOverlay', elem: 'SignUpButton' } }
                  isDisabled={ !isSubmitButtonEnabled }
                  type="submit"
                >
                    { __('Submit') }
                </StyledButton>
            </div>
        );
    };

    renderFields = () => {
        const { code } = this.props;

        return (
            <div block="AdditionalFieldsForm" elem="FieldsWrapper">
                <Field
                  type={ FIELD_TYPE.date }
                  attr={ {
                      id: 'date_of_birth',
                      name: 'birthDate',
                      placeholder: 'dd/mm/yyyy',
                      altPlaceholder: __('Birthday (optional)')
                  } }
                  validateOn={ ['onBlur'] }
                  validationRule={ {
                      inputType: VALIDATION_INPUT_TYPE.date
                  } }
                  subLabel={ __('Let us know when your birthday is, and receive a surprise.').toString() }
                />
                { (code in STORE_CODES_WITH_PHONE)
                    && (
                        <Field
                          type={ FIELD_TYPE.tel }
                          attr={ {
                              id: 'phoneNumber',
                              name: 'phoneNumber',
                              altPlaceholder: __('Phone'),
                              autocomplete: 'tel',
                              defaultValue: '+371'
                          } }
                          validateOn={ ['onBlur'] }
                          validationRule={ {
                              isRequired: true,
                              inputType: VALIDATION_INPUT_TYPE.newPhoneNumber,
                              match: (value) => validatePhoneNumber(value)
                          } }
                          // eslint-disable-next-line max-len
                          subLabel={ __('You will be able to use your phone number as an identifier when shopping in ours stores in Latvia').toString() }
                        />
                    ) }
            </div>
        );
    };

    render() {
        const {
            submitHandler,
            onError,
            isRegistrationSuccess,
            usedSocialType
        } = this.props;

        if (isRegistrationSuccess) {
            return (
                <div block="MyAccountOverlay" elem="SocialRegSuccess">
                    <Image
                      alt="Thank you!"
                      className="MyAccountOverlay-SocialRegSuccessImage"
                      src={ successSign }
                      isPlain
                    />
                    <div block="MyAccountOverlay" elem="SocialRegSuccessThankYou">
                        { __('Thank you!') }
                    </div>
                    <div block="MyAccountOverlay" elem="SocialRegSuccessMessage">
                        { /* eslint-disable-next-line max-len */ }
                        { usedSocialType === 'facebook' && __('You have successfully logged in using your Facebook account.') }
                        { /* eslint-disable-next-line max-len */ }
                        { usedSocialType === 'google' && __('You have successfully logged in using your Google account.') }
                    </div>
                </div>
            );
        }

        return (
            <>
                <h4 block="MyAccountOverlay" elem="AdditionalFieldsFormHeader">{ __('Sign in to your account') }</h4>
                <div block="AdditionalFieldsForm">
                    <Form
                      onSubmit={ submitHandler }
                      onError={ onError }
                    >
                        { this.renderFields() }
                        { this.renderCheckBoxes() }
                        { this.renderSubmitButton() }
                    </Form>
                </div>
            </>
        );
    }
}
export default AdditionalFieldsFormComponent;
