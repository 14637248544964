/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { DirectionType } from 'Type/Direction.type';

import { RIGHT } from './ChevronIcon.config';

import './ChevronIcon.style';
import './ChevronIcon.override.style';
import './ChevronIcon.extended.style';

/** @namespace Scandipwa/Component/ChevronIcon/Component */
export class ChevronIconComponent extends PureComponent {
    static propTypes = {
        direction: DirectionType,
        isThin: PropTypes.bool
    };

    static defaultProps = {
        direction: RIGHT,
        isThin: false
    };

    /**
     * Overridden to remove width in height in absolute units
     */
    render() {
        const { direction, isThin } = this.props;

        if (isThin) {
            return (
                <svg
                  viewBox="0 0 8 14"
                  xmlns="http://www.w3.org/2000/svg"
                  block="ChevronIcon"
                  mods={ { direction, isThin } }
                  mix={ { block: 'ChevronIconThin' } }
                >
                    <path d="M1 1L7 7L1 13" strokeWidth="1.4" strokeLinecap="round" />
                </svg>
            );
        }

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              mix={ { block: 'ChevronIconWide' } }
            >
                <path d="M13.8214 8.82141C14.4723 8.17053 15.5276 8.17053 16.1784 8.82141L26.1784 18.8214C26.8293 19.4723 26.8293 20.5276 26.1784 21.1784L16.1784 31.1784C15.5276 31.8293 14.4723 31.8293 13.8214 31.1784C13.1705 30.5276 13.1705 29.4723 13.8214 28.8214L22.6429 19.9999L13.8214 11.1784C13.1705 10.5276 13.1705 9.47228 13.8214 8.82141Z" />
            </svg>
        );
    }
}

export default ChevronIconComponent;
