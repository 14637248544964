/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import transformToNameValuePair from 'Util/Form/Transform';
import { fetchMutation } from 'Util/Request';

import { MAKECOMMERCE_METHOD_CODE } from '../../config/MakeCommerce.config';
import PlaceOrderQuery from '../../query/PlaceOrder.query';

const debounce = (callback, delay) => {
    // eslint-disable-next-line fp/no-let
    let timeout;

    return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), delay);
    };
};

const placeOrder = async (instance, extractedFields, additional_data, billingAddress, orderDetails) => {
    const {
        showErrorNotification,
        totals: { id: cartId },
        selectedMCPaymentMethod: {
            url,
            country
        },
        setLoading,
        isSignedIn,
        customerEmail
    } = instance.props;

    setLoading(true);
    try {
        const res = await fetchMutation(PlaceOrderQuery.placeOrder(
            cartId,
            country,
            billingAddress,
            !isSignedIn,
            customerEmail,
            additional_data,
            orderDetails
        ));
        const { makeCommercePlaceOrder: transactionId } = res;
        const redirectUrl = url + transactionId;
        window.location.assign(redirectUrl);
    } catch (e) {
        const {
            message
        } = e[0];

        if (message === 'Internal server error') {
            showErrorNotification(__('Payment failed, try again later!'));
        }
        // eslint-disable-next-line no-console
        console.error('>>> ERROR', e);
    }
};

const handleMakeCommerceOnBillingSuccess = debounce(
    (args, callback, instance) => {
        // eslint-disable-next-line no-unused-vars
        const [form, fields, asyncData] = args;
        const extractedFields = transformToNameValuePair(fields);
        const {
            billingAddress,
            orderDetails,
            subscribeToNewsletterConditionally
        } = instance.props;

        // Get payment method code and additional data passed from other extensions
        const {
            code,
            additional_data
        } = instance._getPaymentData(extractedFields, asyncData);

        if (code !== MAKECOMMERCE_METHOD_CODE) {
            return callback(...args);
        }

        const {
            showErrorNotification,
            selectedMCPaymentMethod: {
                url,
                name
            },
            totals: {
                quote_currency_code
            }
        } = instance.props;

        // vvv the make commerce has been selected, but not an inner method has been selected.
        if (!url) {
            showErrorNotification(__('Please, select a payment method'));
            return null;
        }
        // vvv the make commerce has been selected, but customer doesn't use EUR currency.
        if (quote_currency_code !== 'EUR' && name !== 'visa' && name !== 'mastercard') {
            showErrorNotification(__('Please, use EUR'));
            return null;
        }

        subscribeToNewsletterConditionally();

        return placeOrder(instance, extractedFields, additional_data, billingAddress, orderDetails);
    },
    // eslint-disable-next-line no-magic-numbers
    300
);

// vvv add MakeCommerce to state
const mapStateToProps = (args, callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        isSignedIn: state.MyAccountReducer.isSignedIn,
        customerEmail: state.CheckoutReducer.email,
        selectedMCPaymentMethod: state.MakeCommerceReducer.selectedMCPaymentMethod
    };
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess: handleMakeCommerceOnBillingSuccess
        }
    },
    'Scandipwa/Component/CheckoutBilling/Container': {
        'member-function': {
            onBillingSuccess: handleMakeCommerceOnBillingSuccess
        }
    },
    'Component/CheckoutBilling/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Scandipwa/Component/CheckoutBilling/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
