import {
    MyAccountOrderTabComponent as StendersMyAccountOrderTab
} from 'StendersComponent/MyAccountOrderTab/MyAccountOrderTab.component';

import './StendersMyAccountOrderTab.override.styles.scss';
import './MyAccountOrderTab.override.styles.scss';

/** @namespace Scandipwa/Component/MyAccountOrderTab/Component */
export class MyAccountOrderTabComponent extends StendersMyAccountOrderTab {
}

export default MyAccountOrderTabComponent;
