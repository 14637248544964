/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable max-lines */

import PropTypes from 'prop-types';
import { Component } from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import Html from 'Component/Html';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import StyledButton from 'Component/StyledButton/StyledButton.container';

import DownloadCSVButton from '../../component/DownloadCSVButton';
import { COOKIES_PAGE_URL, PRIVACY_PAGE_URL } from './PrivacySettingsPage.config.js';

import './PrivacySettingsPage.style';
/** @namespace Scandiweb/AmastyGdpr/Route/PrivacySettingsPage/Component */
export class PrivacySettingsPageComponent extends Component {
    static propTypes = {
        handleAnonymiseData: PropTypes.func.isRequired,
        handleDeleteRequest: PropTypes.func.isRequired,
        isExtensionAllowed: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        privacySettings: PropTypes.shape({
            anonymise: PropTypes.shape({
                checkboxText: PropTypes.string,
                content: PropTypes.string,
                cssModifier: PropTypes.string,
                hasCheckbox: PropTypes.string,
                submitText: PropTypes.string,
                title: PropTypes.string
            }),
            delete: PropTypes.shape({
                checkboxText: PropTypes.string,
                content: PropTypes.string,
                cssModifier: PropTypes.string,
                hasCheckbox: PropTypes.string,
                submitText: PropTypes.string,
                title: PropTypes.string
            }),
            download: PropTypes.shape({
                checkboxText: PropTypes.string,
                content: PropTypes.string,
                cssModifier: PropTypes.string,
                hasCheckbox: PropTypes.string,
                submitText: PropTypes.string,
                title: PropTypes.string
            }),
            policies_text: PropTypes.shape({
                cssModifier: PropTypes.string,
                isCookieEnabled: PropTypes.string,
                policiesText: PropTypes.string,
                title: PropTypes.string
            }),
            dpo_info: PropTypes.shape({
                cssModifier: PropTypes.string,
                content: PropTypes.string,
                title: PropTypes.string
            })
        }).isRequired
    };

    renderHeader(title, cssModifier) {
        return (
            <div block="gdpr-checkbox-container">
                <label block={ `gdpr-label ${ cssModifier}` } htmlFor={ cssModifier }>
                        <span block="gdpr-title">{ title }</span>
                </label>
            </div>
        );
    }

    renderAnonymise() {
        const {
            handleAnonymiseData,
            privacySettings: {
                anonymise: {
                    content,
                    cssModifier,
                    submitText,
                    title
                }
            }
        } = this.props;

        return (
            <ExpandableContent
              isContentExpanded={ false }
              key={ title }
              heading={ this.renderHeader(title, cssModifier) }
              block="PrivacySetting"
            >
                <div block="PrivacySetting" elem="HiddenContent">
                    <p>{ content }</p>
                    <StyledButton
                      handleClick={ handleAnonymiseData }
                    >
                        { submitText }
                    </StyledButton>
                </div>
            </ExpandableContent>
        );
    }

    renderDelete() {
        const {
            handleDeleteRequest,
            privacySettings: {
                delete: {
                    content,
                    cssModifier,
                    submitText,
                    title
                }
            }
        } = this.props;

        return (
            <ExpandableContent
              isContentExpanded={ false }
              key={ title }
              heading={ this.renderHeader(title, cssModifier) }
              block="PrivacySetting"
            >
                <div block="PrivacySetting" elem="HiddenContent">
                    <p>{ content }</p>
                    <StyledButton
                      handleClick={ handleDeleteRequest }
                    >
                        { submitText }
                    </StyledButton>
                </div>
            </ExpandableContent>
        );
    }

    renderDownload() {
        const {
            privacySettings: {
                download: {
                    content,
                    cssModifier,
                    submitText,
                    title
                }
            }
        } = this.props;

        return (
            <ExpandableContent
              isContentExpanded={ false }
              key={ title }
              heading={ this.renderHeader(title, cssModifier) }
              block="PrivacySetting"
            >
                <div block="PrivacySetting" elem="HiddenContent">
                    <p>{ content }</p>
                    <DownloadCSVButton label={ submitText } />
                </div>
            </ExpandableContent>
        );
    }

    renderPolicies() {
        const {
            privacySettings: {
                policies_text: {
                    cssModifier,
                    title
                }
            }
        } = this.props;

        return (
            <ExpandableContent
              isContentExpanded={ false }
              key={ title }
              heading={ this.renderHeader(title, cssModifier) }
              block="PrivacySetting"
            >
                <div block="PrivacySetting" elem="HiddenContent">
                    <Link className="Link" to={ PRIVACY_PAGE_URL }>{ __('Read Privacy Policy') }</Link>
                    <Link className="Link" to={ COOKIES_PAGE_URL }>{ __('Read Cookie Policy') }</Link>
                </div>
            </ExpandableContent>
        );
    }

    renderDpo() {
        const {
            privacySettings: { dpo_info }
        } = this.props;

        if (!dpo_info) {
            return null;
        }

        const {
            cssModifier,
            content,
            title
        } = dpo_info;

        return (
            <ExpandableContent
              isContentExpanded={ false }
              key={ title }
              heading={ this.renderHeader(title, cssModifier) }
              block="PrivacySetting"
            >
                <div block="PrivacySetting" elem="HiddenContent">
                    <Html content={ content } />
                </div>
            </ExpandableContent>
        );
    }

    renderLoader() {
        return (
            <div block="PrivacySettingsPage">
                <div block="PrivacySettingsPage" elem="LoaderWrapper">
                    <Loader />
                </div>
            </div>
        );
    }

    render() {
        const {
            isExtensionAllowed,
            isLoading
        } = this.props;

        if (isLoading) {
            this.renderLoader();
        }

        if (!isExtensionAllowed) {
            return (
                <div block="PrivacySettingsPage">
                    <p>{ __('GDPR is not allowed in this region!') }</p>
                </div>
            );
        }

        return (
            <div block="PrivacySettingsPage">
                { this.renderAnonymise() }
                { this.renderDelete() }
                { this.renderDownload() }
                { this.renderPolicies() }
                { this.renderDpo() }
            </div>
        );
    }
}

export default PrivacySettingsPageComponent;
