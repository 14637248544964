/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import { MPGiftWrapConfigType } from '../../type/GiftWrap';
import ButtonComponent from './GiftWrapButton.component';

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    mpGiftWrap: state.ConfigReducer.mpGiftWrap
});

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (giftWrapPopupId, title) => dispatch(showPopup(giftWrapPopupId, { title }))
});

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapButton/Container */
export class GiftWrapButtonContainer extends PureComponent {
    static propTypes = {
        mpGiftWrap: MPGiftWrapConfigType.isRequired,
        showPopup: PropTypes.func.isRequired,
        isOptions: PropTypes.bool.isRequired,
        popupId: PropTypes.string.isRequired
    };

    containerFunctions = {
        showGiftWrapPopup: this.showGiftWrapPopup.bind(this)
    };

    containerProps() {
        const {
            mpGiftWrap,
            isOptions,
            popupId
        } = this.props;

        return {
            mpGiftWrap,
            isOptions,
            popupId
        };
    }

    showGiftWrapPopup(event) {
        const {
            showPopup,
            popupId
        } = this.props;

        // vvv Stops redirecting in cartItem
        event.preventDefault();

        showPopup(popupId, __('Gift wrapping'));
    }

    render() {
        return (
            <ButtonComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftWrapButtonContainer);
