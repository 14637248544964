export const getInitialState = (args, callback) => ({
    ...callback(...args),
    og_image: ''
});

export default {
    'Store/Meta/Reducer/getInitialState': {
        function: [
            {
                position: 50,
                implementation: getInitialState
            }
        ]
    }
};
