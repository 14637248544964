/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageplazaSocialLogin/Query/SocialToken/Query */
export class SocialTokenQuery {
    getQuery({
        guestQuoteId, customerExists, usedSocialType, birthDate, is_subscribed, phoneNumber, smssubscriber, from_shop
    }) {
        return new Field('socialToken')
            .addArgument('guestQuoteId', 'String', guestQuoteId)
            .addArgument('customerExists', 'Boolean!', customerExists)
            .addArgument('type', 'String!', usedSocialType)
            .addArgument('birthDate', 'String', birthDate)
            .addArgument('is_subscribed', 'String', is_subscribed)
            .addArgument('phoneNumber', 'String', phoneNumber)
            .addArgument('smssubscriber', 'Boolean', smssubscriber)
            .addArgument('from_shop', 'String', from_shop)
            .addFieldList(this._getChildFields());
    }

    _getChildFields() {
        return [
            'token'
        ];
    }
}

export default new SocialTokenQuery();
