import { REVIEW_POPUP_ID } from 'Component/ProductReviews/ProductReviews.config';
import MyAccountQuery from 'Query/MyAccount.query';
import { REQUEST_APPROVED } from 'Route/MyAccount/MyAccount.config';
import {
    CartDispatcher, CUSTOMER, MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS, ProductCompareDispatcher, WishlistDispatcher
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import {
    updateCustomerDetails,
    updateCustomerPasswordResetStatus,
    updateCustomerSignInStatus,
    updateIsLoading,
    updateIsLocked
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import {
    deleteAuthorizationToken,
    getAuthorizationToken,
    GRAPHQL_AUTH,
    setAuthorizationToken
} from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { transformDateForFE } from 'Util/Form/Form';
import { prepareQuery } from 'Util/Query';
import { executePost, fetchMutation, getErrorMessage } from 'Util/Request';
import { getStore } from 'Util/Store';

export {
    CartDispatcher,
    ProductCompareDispatcher,
    WishlistDispatcher,
    CUSTOMER,
    ONE_MONTH_IN_SECONDS
};

/** @namespace Scandipwa/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    customerDataRequestInstance = null;

    confirmAccount(options = {}) {
        const mutation = MyAccountQuery.getConfirmAccountMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/confirmAccount/fetchMutation/then */
            (data) => {
                const { confirmCustomerEmailById: { email } } = data;
                const returnData = email ? { email, msgType: 'success' } : { msgType: 'error' };

                return returnData;
            }
        );
    }

    /**
     * Added a bugfix to delete the auth token when it's not valid anymore.
     * @param dispatch
     * @returns {Promise}
     */
    requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();

        if (!this.customerDataRequestInstance) {
            this.customerDataRequestInstance = executePost(prepareQuery([query])).then(
                /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then */
                ({ customer }) => {
                    this.customerDataRequestInstance = null;

                    if (customer?.deleteRequestStatus === REQUEST_APPROVED) {
                        deleteAuthorizationToken();
                        this.logout(false, false, dispatch);

                        return;
                    }

                    if (!getAuthorizationToken()) {
                        return;
                    }

                    dispatch(updateIsLocked(false));
                    dispatch(updateCustomerDetails({ ...customer, dob: transformDateForFE(customer.dob) }));
                    BrowserDatabase.setItem({ ...customer, dob: transformDateForFE(customer.dob) },
                        CUSTOMER,
                        ONE_MONTH_IN_SECONDS);
                },
                /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then/catch */
                (error) => {
                    this.customerDataRequestInstance = null;
                    const { extensions: { category } } = error[0];

                    if (category === GRAPHQL_AUTH) {
                        dispatch(updateIsLocked(true));
                        deleteAuthorizationToken();
                    }
                    dispatch(showNotification('error', getErrorMessage(error)));
                }
            );
        }

        return this.customerDataRequestInstance;
    }

    /**
     * Sign in action
     *
     * override to add condition for Review Popup
     * @param {{email: String, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);

        const result = await fetchMutation(mutation);
        const { generateCustomerToken: { token } } = result;

        setAuthorizationToken(token);

        ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.assignCompareList(dispatch)
        );

        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch, true);

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));

        const {
            OverlayReducer: {
                activeOverlay: prevActiveOverlay = ''
            }
        } = getStore().getState();

        dispatch(hideActiveOverlay());
        if (prevActiveOverlay === REVIEW_POPUP_ID) {
            dispatch(showPopup(REVIEW_POPUP_ID, { title: __('Write a review') }));
        }

        dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }

    resetPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getResetPasswordMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/resetPassword/fetchMutation/then/dispatch */
            ({ s_resetPassword: { status } }) => dispatch(updateCustomerPasswordResetStatus(status)),
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/resetPassword/fetchMutation/then/dispatch/catch */
            (errors) => dispatch(updateCustomerPasswordResetStatus('error', getErrorMessage(errors)))
        );
    }
}

export default new MyAccountDispatcher();
