/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import MCPaymentWrapper from '../../component/MCPaymentWrapper';
import { MAKECOMMERCE_METHOD_CODE } from '../../config/MakeCommerce.config';

const renderMakeCommercePaymentComponent = (args, callback, instance) => {
    const {
        selectPaymentMethod,
        selectedPaymentCode
    } = instance.props;

    const [method] = args;
    const { code } = method;

    if (code !== MAKECOMMERCE_METHOD_CODE) {
        return callback(...args);
    }

    return (
        <MCPaymentWrapper
          key={ code }
          selectedPaymentCode={ selectedPaymentCode }
          makeCommerceMethod={ method }
          onMakeCommerceClick={ selectPaymentMethod }
        />
    );
};

const addSelectedMakeCommercePaymentState = (member) => ({
    ...member,
    mcSelectedPayment: ''
});

export default {
    'Component/CheckoutPayments/Component': {
        'member-function': {
            renderPayment: renderMakeCommercePaymentComponent
        }
    },
    'Component/CheckoutPayments/Container': {
        'member-property': {
            state: addSelectedMakeCommercePaymentState
        }
    }
};
