/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const displayMessage = (args, callback, instance) => {
    callback(...args);

    const message = new URLSearchParams(window.location.search).get('message');

    if (message) {
        const { showInfoNotification } = instance.props;
        showInfoNotification(message);
    }
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            componentDidMount: displayMessage
        }
    }
};
