import {
    KeyValueTableComponent as StendersKeyValueTable
} from 'StendersComponent/KeyValueTable/KeyValueTable.component';

import './KeyValueTable.extended.style';

/** @namespace Scandipwa/Component/KeyValueTable/Component */
export class KeyValueTableComponent extends StendersKeyValueTable {
    renderTableRow(data) {
        const { key, label } = data;
        const value = this.getValueFromSource(data);

        if (value === undefined || value === null || value === '') {
            return null;
        }

        if (key === 'smssubscriber') {
            return null;
        }

        return (
            <tr key={ key }>
                <th>{ label }</th>
                <td>{ value }</td>
            </tr>
        );
    }
}

export default KeyValueTableComponent;
