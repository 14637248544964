/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { fireProductDetailsEvent } from '../../event/product';

const formatUrl = (url) => (url[0] === '/' ? url : `/${url}`);

const isCorrectProduct = (product = {}) => {
    try {
        const { url_rewrites: urlRewrites = [] } = product;

        const possibleValidUrls = urlRewrites.map((urlObj) => formatUrl(urlObj.url));

        if (
            possibleValidUrls.some((url) => window.location.pathname.includes(url))
        ) {
            return true;
        }

        return false;
    } catch (err) {
        return false;
    }
};

const addFireProductDetailEvent = (args, callback, instance) => {
    callback(...args);

    const { product = {} } = instance.props;

    // vvv means the product is not loaded
    if (!product.id || !isCorrectProduct(product)) {
        return;
    }

    fireProductDetailsEvent(product);
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            updateMeta: addFireProductDetailEvent
        }
    }
};
