import {
    MyAccountOrderTotalsComponent as StendersMyAccountOrderTotals
} from 'StendersComponent/MyAccountOrderTotals/MyAccountOrderTotals.component';
import { rearrangePriceString } from 'Util/Price';

import './MyAccountOrderTotals.extended.style';

/** @namespace Scandipwa/Component/MyAccountOrderTotals/Component */
export class MyAccountOrderTotalsComponent extends StendersMyAccountOrderTotals {
    /**
     * Rearranged price string
     */
    renderPriceLine(title, price, currency, mix = {}, key) {
        const {
            total: { grand_total: { currency: defaultCurrency } }
        } = this.props;

        return (
            <tr mix={ mix } key={ key }>
                { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                <th colSpan="2" aria-hidden="true" block="hidden-mobile" />
                <th colSpan="2">{ title }</th>
                <td colSpan="1">{ rearrangePriceString(price, currency || defaultCurrency) }</td>
            </tr>
        );
    }

    /**
     * Rearranged price string
     */
    renderTax(tax) {
        const { amount: { value, currency }, title, rate } = tax;

        return (
            <tr key={ `${title}-${rate}` }>
                { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                <th colSpan="2" aria-hidden="true" block="hidden-mobile" />
                <th colSpan="2">{ `${title} (${rate})` }</th>
                <td colSpan="1">{ rearrangePriceString(value, currency) }</td>
            </tr>
        );
    }

    renderDiscount({ label, amount: { value, currency } }, index) {
        const discountLabel = label ? __('Discount (%s)', label) : __('Discount');
        return this.renderPriceLine(discountLabel, -value, currency, {}, `discount-${index}`);
    }

    renderContent() {
        const {
            total: {
                subtotal: {
                    value_incl_tax: subtotalPrice,
                    currency
                },
                shipping_handling: {
                    amount_including_tax: {
                        value: shippingHandlingPrice
                    }
                },
                grand_total: {
                    value: grandTotalPrice
                },
                total_tax: {
                    value: totalTaxPrice
                }
            }
        } = this.props;

        const grandTotalMix = { block: 'MyAccountOrderTotals', elem: 'GrandTotal' };

        return (
            <>
                { this.renderPriceLine(__('Subtotal'), subtotalPrice, currency) }
                { this.renderDiscounts() }
                { this.renderPriceLine(__('Shipping & Handling'), shippingHandlingPrice, currency) }
                { this.renderPriceLine(
                    __('Grand Total (Excl.Tax)'),
                    grandTotalPrice - totalTaxPrice,
                    currency,
                    grandTotalMix
                ) }
                { this.renderTaxes() }
                { this.renderPriceLine(__('Tax'), totalTaxPrice, currency) }
                { this.renderPriceLine(__('Grand Total (Incl.Tax)'), grandTotalPrice, currency, grandTotalMix) }
                { this.renderBaseGrandTotal() }
            </>
        );
    }
}

export default MyAccountOrderTotalsComponent;
