import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import StyledButton from 'Component/StyledButton';

import { BANNER_HEIGHT, BANNER_RATIO, LOGIN } from '../PLPPromoBanner/PLPPromoBanner.config';

import './FacePLPPromoBanner.style';

/** @namespace Scandipwa/Component/FacePLPPromoBanner/Component */
export class FacePLPPromoBannerComponent extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        linkText: PropTypes.string.isRequired,
        linkUrl: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        openMyAccountOverlay: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    renderButton() {
        const {
            linkUrl,
            linkText,
            openMyAccountOverlay,
            isMobile
        } = this.props;

        if (linkUrl === LOGIN && !isMobile) {
            return (
                <StyledButton
                  mix={ { block: 'FacePLPPromoBanner', elem: 'Link' } }
                  handleClick={ openMyAccountOverlay }
                >
                    { linkText }
                </StyledButton>
            );
        }

        if (linkUrl === LOGIN && isMobile) {
            return (
                <StyledButton
                  mix={ { block: 'FacePLPPromoBanner', elem: 'Link' } }
                  to="/customer/account/login"
                >
                  { linkText }
                </StyledButton>
            );
        }

        if (linkUrl) {
            return (
                <StyledButton
                  mix={ { block: 'FacePLPPromoBanner', elem: 'Link' } }
                  to={ linkUrl }
                >
                    { linkText }
                </StyledButton>
            );
        }

        return null;
    }

    renderWidgetContent() {
        const {
            title,
            description
        } = this.props;

        return (
            <div block="FacePLPPromoBanner" elem="Content">
                <h2 block="FacePLPPromoBanner" elem="Title">
                    { title }
                </h2>
                <p block="FacePLPPromoBanner" elem="Text">
                    { description }
                </p>
                { this.renderButton() }
            </div>
        );
    }

    render() {
        const { image } = this.props;

        return (
            <div block="FacePLPPromoBanner">
                <div block="FacePLPPromoBanner" elem="Image">
                    <Image src={ image } ratio={ BANNER_RATIO } wrapperSize={ { height: BANNER_HEIGHT } } />
                </div>
                { this.renderWidgetContent() }
            </div>
        );
    }
}

export default FacePLPPromoBannerComponent;
