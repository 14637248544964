/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { DETAILS_STEP } from 'Route/Checkout/Checkout.config';
import { scrollToTop } from 'Util/Browser';

const showSuccessIfOrderIsPassed = (args, callback, instance) => {
    callback(...args);

    // vvv Check for order ID coming from URL, if present, show success
    const orderID = new URLSearchParams(window.location.search).get('orderId');

    if (!orderID) {
        return;
    }

    // eslint-disable-next-line no-param-reassign
    instance.state = {
        ...instance.state,
        checkoutStep: DETAILS_STEP,
        orderID
    };
};

const resetCartIfOrderIsPassed = (args, callback, instance) => {
    const { orderID } = instance.state;
    // ^^^ This can only happen if it was originally passed via URL

    scrollToTop();

    if (!orderID) {
        callback(...args);
        return;
    }

    // vvv Calling this to reset cart, do nothing else
    instance.setDetailsStep(orderID);
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            __construct: showSuccessIfOrderIsPassed,
            componentDidMount: resetCartIfOrderIsPassed
        }
    }
};
