/* eslint-disable no-useless-return */
/* eslint-disable consistent-return */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { CART_OVERLAY } from 'Component/Header/Header.config';
import { RIGHT } from 'Component/MenuOverlay/MenuOverlay.config';
import {
    MenuOverlayComponent as StendersMenuOverlayComponent
} from 'StendersComponent/MenuOverlay/MenuOverlay.component';

import { OVERLAYS_CREATE_DELAY, OVERLAYS_DESTROY_DELAY } from './MenuOverlay.config';

import './MenuOverlay.extended.style';

/** @namespace Scandipwa/Component/MenuOverlay/Component */
export class MenuOverlayComponent extends StendersMenuOverlayComponent {
    __construct(props) {
        const { isVisible } = props;

        this.state = {
            showContent: isVisible
        };
    }

    componentDidUpdate(prevProps) {
        const { isVisible: prevIsVisible } = prevProps;
        const { isVisible } = this.props;

        if (isVisible !== prevIsVisible) {
            if (isVisible) {
                setTimeout(() => this.setState({ showContent: true }), OVERLAYS_CREATE_DELAY);
            } else {
                setTimeout(() => this.setState({ showContent: false }), OVERLAYS_DESTROY_DELAY);
            }
        }
    }

    render() {
        const {
            isVisible,
            children,
            toggleMenu,
            mix,
            direction,
            isPortal,
            onTouchStart,
            onTouchEnd,
            onTouchMove,
            id
        } = this.props;
        const { showContent } = this.state;

        if (!isVisible && !showContent && id !== CART_OVERLAY) {
            return null;
        }

        if (!isPortal) {
            if ((isVisible && !showContent) || (!isVisible && showContent)) {
                return (
                    <div block="MenuOverlay" mix={ { ...mix } } mods={ { isVisible: false } }>
                        <div
                          block="MenuOverlay"
                          elem="Container"
                          mods={ { isVisible: false, isRight: direction === RIGHT } }
                        />
                    </div>
                );
            }

            return (
                <div block="MenuOverlay" mix={ { ...mix } } mods={ { isVisible } }>
                    <div block="MenuOverlay" elem="Container" mods={ { isVisible, isRight: direction === RIGHT } }>
                        <div block="MenuHeader">
                            { this.renderTitle() }
                            { this.renderCloseButton() }
                        </div>
                        { children }
                    </div>
                    { /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */ }
                    <div
                      block="MenuOverlay"
                      elem="ClickOutside"
                      mods={ { isVisible } }
                      onClick={ toggleMenu }
                      onKeyDown={ toggleMenu }
                    />
                </div>
            );
        }

        if (id !== CART_OVERLAY && ((isVisible && !showContent) || (!isVisible && showContent))) {
            return this.renderInPortal(
                <div block="MenuOverlay" mix={ { ...mix } } mods={ { isVisible: false } }>
                    <div
                      block="MenuOverlay"
                      elem="Container"
                      mods={ { isVisible: false, isRight: direction === RIGHT } }
                      onTouchStart={ onTouchStart }
                      onTouchMove={ onTouchMove }
                      onTouchEnd={ onTouchEnd }
                    />
                </div>
            );
        }

        return this.renderInPortal(
            <div block="MenuOverlay" mix={ { ...mix } } mods={ { isVisible } }>
                <div
                  block="MenuOverlay"
                  elem="Container"
                  mods={ { isVisible, isRight: direction === RIGHT } }
                  onTouchStart={ onTouchStart }
                  onTouchMove={ onTouchMove }
                  onTouchEnd={ onTouchEnd }
                >
                    <div block="MenuHeader">
                        { this.renderTitle() }
                        { this.renderCloseButton() }
                    </div>
                    { children }
                </div>
                { /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */ }
                <div
                  block="MenuOverlay"
                  elem="ClickOutside"
                  mods={ { isVisible } }
                  onClick={ toggleMenu }
                  onKeyDown={ toggleMenu }
                />
            </div>
        );
    }
}

export default MenuOverlayComponent;
