import { CustomPopupReducer } from '../store/CustomPopup/CustomPopup.reducer';

export default {
    'Store/Index/getStaticReducers': {
        function: (args, callback) => ({
            ...callback(args),
            CustomPopupReducer
        })
    }
};
