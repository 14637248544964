/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

import { PAGE_SIZE } from '../component/GiftWrapPopup/GiftWrapPopup.config';

/** @namespace Scandiweb/MageplazaGiftWrap/Query/GiftWrapCategories/Query */
export class GiftWrapCategoriesQuery {
    getCategoriesFields = () => ([
        'total_count',
        this.getItemsField(),
        this.getPageInfoField()
    ]);

    getItemsFields = () => ([
        'category_id',
        'name',
        'status',
        'description',
        'store_id',
        'customer_group',
        'sort_order',
        'created_at',
        'updated_at'
    ]);

    getPageInfoFields = () => ([
        'pageSize',
        'currentPage',
        'hasNextPage',
        'hasPreviousPage',
        'startPage',
        'endPage'
    ]);

    getPageInfoField = () => new Field('page_info')
        .addFieldList(this.getPageInfoFields());

    getItemsField = () => new Field('items')
        .addFieldList(this.getItemsFields());

    getGiftWrapCategories = (
        currentPage = 1,
        pageSize = PAGE_SIZE,
        filter = {}
    ) => new Field('mpGiftWrapCategories')
        .addArgument('filter', 'GiftWrapCategoryFilterInput', filter)
        .addArgument('pageSize', 'Int', pageSize)
        .addArgument('currentPage', 'Int', currentPage)
        .addFieldList(this.getCategoriesFields());
}

export default new GiftWrapCategoriesQuery();
