import BrowserDatabase from 'Util/BrowserDatabase';

import {
    CP_COOKIE_ACTION_HIDE,
    CP_COOKIE_ACTION_SHOW,
    CP_LOGIN_LINK,
    CP_POPUP_ID_PREFIX,
    CP_URL_COND_CONTAINS,
    CP_URL_COND_IS,
    CP_URL_COND_IS_NOT,
    CP_URL_COND_NOT_CONTAINS,
    CP_URL_COND_NOT_STARTS,
    CP_URL_COND_STARTS,
    DAY_IN_MILLISECONDS
} from '../config/CustomPopup.config';

/** @namespace Stenders/CustomPopup/Util/CustomPopup/Util/getDaysInMilliSeconds */
export const getDaysInMilliSeconds = (days) => (days * DAY_IN_MILLISECONDS);

/** @namespace Stenders/CustomPopup/Util/CustomPopup/Util/getPopupIdName */
export const getPopupIdName = (popupId) => (CP_POPUP_ID_PREFIX + popupId);

/** @namespace Stenders/CustomPopup/Util/CustomPopup/Util/storePopupViewedInBrowserDB */
export const storePopupViewedInBrowserDB = (popupId, days) => {
    BrowserDatabase.setItem(
        1,
        getPopupIdName(popupId),
        getDaysInMilliSeconds(days)
    );
};
/** @namespace Stenders/CustomPopup/Util/CustomPopup/Util/checkPopupViewedInBrowserDB */
export const checkPopupViewedInBrowserDB = (popupId) => BrowserDatabase.getItem(getPopupIdName(popupId));

/** @namespace Stenders/CustomPopup/Util/CustomPopup/Util/hasCPCookie */
export const hasCPCookie = (popupId) => {
    const popup_id_name = getPopupIdName(popupId);
    return document.cookie.indexOf(`${popup_id_name}=`) >= 0;
};

/** @namespace Stenders/CustomPopup/Util/CustomPopup/Util/getCookieValue */
export const getCookieValue = (key) => {
    const b = document.cookie.match(`(^|;)\\s*${ key }\\s*=\\s*([^;]+)`);
    return b ? b.pop() : '';
};

/** @namespace Stenders/CustomPopup/Util/CustomPopup/Util/validateCookieTargetingRule */
export const validateCookieTargetingRule = (cName, cValue, cAction) => {
    if (cName.length > 0 && cValue.length > 0) {
        if (getCookieValue(cName) === cValue) {
            if (cAction === CP_COOKIE_ACTION_HIDE) {
                return false;
            }
        } else if (cAction === CP_COOKIE_ACTION_SHOW) {
            return false;
        }
    }

    return true;
};

/** @namespace Stenders/CustomPopup/Util/CustomPopup/Util/validateUrlTargetingRule */
export const validateUrlTargetingRule = (urlCondition, urlValue) => {
    const urlPath = window.location.pathname;
    if (urlCondition.length > 0 && urlValue.length > 0) {
        switch (urlCondition) {
        case CP_URL_COND_IS:
            return (urlPath === urlValue);
        case CP_URL_COND_IS_NOT:
            return (urlPath !== urlValue);
        case CP_URL_COND_STARTS:
            return urlPath.indexOf(urlValue) === 0;
        case CP_URL_COND_NOT_STARTS:
            return urlPath.indexOf(urlValue) !== 0;
        case CP_URL_COND_CONTAINS:
            return urlPath.indexOf(urlValue) >= 0;
        case CP_URL_COND_NOT_CONTAINS:
            return urlPath.indexOf(urlValue) === -1;
        default:
            return true;
        }
    }

    return true;
};

/** @namespace Stenders/CustomPopup/Util/CustomPopup/Util/isVisibleByUrlTargeting */
export const isVisibleByUrlTargeting = (urlRules) => {
    if (urlRules.length > 0) {
        const nResult = [];
        const iResult = [];
        urlRules.forEach((rule) => {
            const urlCondition = rule.url_condition;
            const urlValue = rule.url_value;
            if (urlCondition.indexOf('not') >= 0) {
                nResult.push(validateUrlTargetingRule(urlCondition, urlValue));
            } else {
                iResult.push(validateUrlTargetingRule(urlCondition, urlValue));
            }
        });

        return ((iResult.length === 0 || iResult.includes(true)) && (nResult.length === 0 || !nResult.includes(false)));
    }

    return true;
};

/** @namespace Stenders/CustomPopup/Util/CustomPopup/Util/isVisibleByCookieTargeting */
export const isVisibleByCookieTargeting = (cookieRules) => {
    if (cookieRules.length > 0) {
        const result = [];
        cookieRules.forEach((rule) => {
            const cName = rule.cookie_name;
            const cValue = rule.cookie_value;
            const cAction = rule.cookie_action;
            result.push(validateCookieTargetingRule(cName, cValue, cAction));
        });

        return (result.length === 0 || !result.includes(false));
    }

    return true;
};

/** @namespace Stenders/CustomPopup/Util/CustomPopup/Util/isPopupAllowed */
export const isPopupAllowed = (popup, isSignedIn, isSubscribed) => (
    popup.id > 0
    && !hasCPCookie(popup.id)
    && !checkPopupViewedInBrowserDB(popup.id)
    && isVisibleByCookieTargeting(popup.cookieTargeting)
    && isVisibleByUrlTargeting(popup.urlTargeting)
    && (!isSubscribed || !popup.isSubscriptionForm)
    && (!isSignedIn || popup.yesBtnUrl !== CP_LOGIN_LINK)
);
