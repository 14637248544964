/* eslint-disable radix */
/* eslint-disable default-case */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-param-reassign */

import PropTypes from 'prop-types';

import {
    FieldContainer as StendersFieldContainer
} from 'StendersComponent/Field/Field.container';
import { createUID } from 'Util/Common';
import { validate } from 'Util/Validator';

import { ASCII_CODES, FIELD_TYPE } from './Field.config';

/** @namespace Scandipwa/Component/Field/Container */
export class FieldContainer extends StendersFieldContainer {
    static propTypes = {
        ...this.propTypes,
        customValidate: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        relatedValue: PropTypes.any
    };

    __construct(props) {
        super.__construct(props);
        this.state = {
            ...this.state,
            uid: createUID()
        };
    }

    componentDidUpdate(prevProps) {
        const { relatedValue: prevRelatedValue } = prevProps;
        const { relatedValue } = this.props;

        if (prevRelatedValue !== relatedValue && this.fieldRef?.value) {
            this.validate();
        }
    }

    formatDateField(e) {
        const charCode = e.which ? e.which : e.keyCode;
        const char = e.key;

        /** Date field does allow typing only 0-9, bkspace, enter */
        if (charCode !== ASCII_CODES.bakspace
            && charCode !== ASCII_CODES.enter
            && (charCode < ASCII_CODES.regZero || charCode > ASCII_CODES.regNine)
            && (charCode < ASCII_CODES.numpadZero || charCode > ASCII_CODES.numpadNine)) {
            e.preventDefault();
        }

        // eslint-disable-next-line fp/no-let
        let dateString = this.fieldRef.value;

        /**
         *  this makes sure out of the range values for day, month, year cannot be typed
         */
        switch (dateString.length) {
        case 0:
            if (parseInt(char) > 3) {
                dateString = '0';
            }
            break;
        case 1:
            if (parseInt(`${dateString}${char}`) > 31) {
                dateString = '31';
                e.preventDefault();
            } else if (`${dateString}${char}` === '00') {
                dateString = '01';
                e.preventDefault();
            }
            break;
        case 2:
            if (parseInt(char) > 1) {
                dateString = `${dateString}/0`;
            }
            break;
        case 3:
            if (parseInt(char) > 1) {
                dateString = `${dateString}0`;
            }
            break;
        case 4:
            if (parseInt(`${dateString[3]}${char}`) > 12) {
                dateString = `${dateString.slice(0, 3)}12`;
                e.preventDefault();
            } else if (`${dateString[3]}${char}` === '00') {
                dateString = `${dateString}1`;
                e.preventDefault();
            }
            break;
        case 9:
            if (`${dateString.slice(6, 9)}${char}` === '0000') {
                dateString = `${dateString.slice(0, 9)}1`;
                e.preventDefault();
            }
            break;
        }

        /** add slashes at 3rd & 5th index */
        if (charCode !== ASCII_CODES.bakspace && charCode !== ASCII_CODES.enter) {
            switch (dateString.length) {
            case 2:
                this.fieldRef.value = `${dateString.slice(0, 2)}/`;
                break;
            case 5:
                this.fieldRef.value = `${dateString.slice(0, 2)}/${dateString.slice(3, 5)}/${dateString.slice(5)}`;
                break;
            default:
                this.fieldRef.value = dateString;
            }
        }
    }

    moveCursorToEnd() {
        const input = this.fieldRef;
        input.setSelectionRange(input.value.length, input.value.length);
    }

    containerFunctions = {
        ...this.containerFunctions,
        formatDateField: this.formatDateField.bind(this),
        moveCursorToEnd: this.moveCursorToEnd.bind(this)
    };

    validate(data) {
        const {
            validationRule: { range: { max: maxValidLength = 0 } = {} },
            type,
            attr: { name } = {},
            onValidated,
            customValidate
        } = this.props;
        const { showLengthError } = this.state;
        const newValidRule = this.handleShowLengthError();

        // eslint-disable-next-line fp/no-let
        let value = type === FIELD_TYPE.checkbox
            || type === FIELD_TYPE.newsletterCheckbox
            || type === FIELD_TYPE.radio
            ? !!this.fieldRef.checked
            : this.fieldRef.value;

        if (type === FIELD_TYPE.date && this.fieldRef.value.length > 0) {
            const day = this.fieldRef.value.slice(0, 2);
            const month = this.fieldRef.value.slice(3, 5);
            const year = this.fieldRef.value.slice(6, 10);

            value = `${year}-${day}-${month}`;
        }

        // eslint-disable-next-line fp/no-let
        let response;
        if (customValidate) {
            response = customValidate(this.fieldRef.value);
        } else {
            response = validate(type === FIELD_TYPE.file ? value.toLowerCase() : value, newValidRule);
        }
        const output = response !== true ? { ...response, type, name } : response;

        this.setState({ textAreaLength: value.length });

        // If validation is called from different object you can pass object
        // to store validation error values
        if (data && data.detail && response !== true) {
            if (!data.detail.errors) {
                // eslint-disable-next-line no-param-reassign
                data.detail.errors = [];
            }

            // Validates length on submit, renders special message
            if (maxValidLength && value.length > maxValidLength && !showLengthError) {
                this.setState({ showLengthError: true });
                output.errorMessages.unshift(__('Please enter no more than %s characters.', maxValidLength));
            }

            data.detail.errors.push(output);
        }

        // When submit and response equals true (it can be object) reset show length error
        if (response === true) {
            this.setState({ showLengthError: false });
        }

        this.setState({ validationResponse: output });

        if (onValidated) {
            onValidated(name, output);
        }

        return output;
    }

    containerProps() {
        const {
            invalidInput,
            textAreaLength,
            showPassword,
            uid
        } = this.state;

        const {
            validationRule,
            isArrow,
            isCountShown,
            isCheckmarkShown,
            isCheckmarkControlled,
            isLoading,
            isCheckout
        } = this.props;

        return {
            ...super.containerProps(),
            invalidInput,
            validationRule,
            isArrow,
            textAreaLength,
            showPassword,
            isCountShown,
            isCheckmarkShown,
            isCheckmarkControlled,
            isLoading,
            isCheckout,
            uid
        };
    }
}

export default FieldContainer;
