/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import {
    GiftWrapOptionsType
} from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/type/GiftWrap';
import GiftWrapInfoSectionComponent from './GiftWrapInfoSection.component';

/** @namespace Scandipwa/Component/GiftWrapInfoSection/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currency: state.CartReducer.cartTotals?.quote_currency_code,
    giftWrapMessageFee: state.ConfigReducer.mpGiftWrap?.giftMessageFee
});

/** @namespace Scandipwa/Component/GiftWrapInfoSection/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (giftWrapPopupId, title) => dispatch(showPopup(giftWrapPopupId, { title }))
});

/** @namespace Scandipwa/Component/GiftWrapInfoSection/Container */
export class GiftWrapInfoSectionContainer extends PureComponent {
    static propTypes = {
        currency: PropTypes.string.isRequired,
        popupId: PropTypes.string.isRequired,
        selectedOptions: GiftWrapOptionsType.isRequired,
        handleWrapData: PropTypes.func.isRequired,
        giftWrapMessageFee: PropTypes.number.isRequired
    };

    containerFunctions = {
        onPopupSave: this.onPopupSave.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        const {
            selectedOptions
        } = props;

        this.state = {
            giftWrapOptions: selectedOptions || null
        };
    }

    getGiftWrapOptions() {
        const {
            selectedOptions
        } = this.props;
        const {
            giftWrapOptions
        } = this.state;

        if (giftWrapOptions) {
            return giftWrapOptions;
        } if (selectedOptions) {
            return selectedOptions;
        }

        return null;
    }

    containerProps() {
        const {
            currency,
            popupId,
            handleWrapData,
            giftWrapMessageFee
        } = this.props;

        return {
            giftWrapOptions: this.getGiftWrapOptions(),
            currency,
            popupId,
            handleWrapData,
            giftWrapMessageFee
        };
    }

    // vvv setState is an async function so when I save the data was passed partially in the function,
    // vvv so we are waiting for all data set
    async onPopupSave(obj) {
        await this.setState({ giftWrapOptions: obj });
    }

    render() {
        return (
            <GiftWrapInfoSectionComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftWrapInfoSectionContainer);
