/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable simple-import-sort/sort */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
    CategoryItemComponent as StendersCategoryItem
} from 'StendersComponent/CategoryItem/CategoryItem.component';
import ChevronIcon from 'Component/ChevronIcon';
import Link from 'Component/Link';
import './CategoryItem.extended.style.scss';
import { getSortedItems } from 'Util/Menu';

/** @namespace Scandipwa/Component/CategoryItem/Component */
export class CategoryItemComponent extends StendersCategoryItem {
    static propTypes = {
        ...this.propTypes,
        categoryChildren: PropTypes.any.isRequired,
        isCategoryReset: PropTypes.bool.isRequired,
        isCategorySelected: PropTypes.bool.isRequired
    };

    renderSubCategories(child) {
        const {
            toggleMenuState
        } = this.props;
        const { item_id, url, title } = child;

        return (
            <li
              block="CategorySubItem CategorySubItem_isLink"
              key={ item_id }
              onClick={ toggleMenuState }
              onKeyDown={ toggleMenuState }
            >
                <Link to={ url } block="CategorySubItem" elem="Link">
                    <div block="CategorySubItem" elem="Text">
                        { title }
                    </div>
                </Link>
            </li>
        );
    }

    render() {
        const {
            isLink,
            category,
            currentCategoryFunc,
            url,
            toggleMenuState,
            categoryChildren,
            isCategoryReset,
            isCategorySelected
        } = this.props;

        const categoryChildrenSorted = categoryChildren ? getSortedItems(Object.values(categoryChildren)) : {};

        if (isLink) {
            return (
                <li
                  block="FirstLevel CategoryItem"
                  mods={ { isLink, isHidden: !isCategoryReset } }
                  onClick={ toggleMenuState }
                  onKeyDown={ toggleMenuState }
                  id={ category }
                >
                    <Link to={ url } block="CategoryItem" elem="Link">
                        <div block="CategoryItem" elem="Text">
                            { category }
                        </div>
                    </Link>
                </li>
            );
        }

        return (
            <li
              block="FirstLevel CategoryItem"
              id={ category }
              mods={ { isHidden: !isCategoryReset, isDisplayed: isCategorySelected } }
            >
                <div
                  block="CategoryItem"
                  elem="Wrapper"
                  onClick={ currentCategoryFunc }
                  onKeyDown={ currentCategoryFunc }
                  mods={ { isHidden: !isCategoryReset } }
                >
                    <div
                      block="CategoryItem"
                      elem="Text"
                    >
                        { category }
                    </div>
                    <div
                      block="CategoryItem"
                      elem="ChevronIconContainer"
                    >
                        <ChevronIcon isThin />
                    </div>
                </div>
                <ul
                  block="SubCategoryList"
                  mods={ { isHidden: isCategoryReset } }
                >
                    { Object.values(categoryChildrenSorted).map((child) => this.renderSubCategories(child)) }
                </ul>
            </li>
        );
    }
}

export default CategoryItemComponent;
