import CategoryDetails from 'Component/CategoryDetails';

export class CategoryPageComponentPlugin {
    renderCategoryDetails(args, callback, instance) {
        const {
            category,
            isCurrentCategoryLoaded,
            seoConfigurationData: {
                use_category_seo_name: useCategorySeoName = false
            } = {},
            isSearchPage
        } = instance.props;

        if (isSearchPage) {
            return callback(...args);
        }

        return (
            <CategoryDetails
              category={ category }
              isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
              useCategorySeoName={ useCategorySeoName }
            />
        );
    }
}

export const { renderCategoryDetails } = new CategoryPageComponentPlugin();

export default {
    'Route/CategoryPage/Component': {
        'member-function': {
            renderCategoryDetails
        }
    }
};
