/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import { noopFn } from 'Util/Common';
import { rearrangePriceString } from 'Util/Price';

import GiftWrapButton
from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/component/GiftWrapButton';
import GiftWrapPopup
from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/component/GiftWrapPopup';
import {
    CartGiftWrapType,
    CartPostcardType,
    MPGiftWrapConfigType
} from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/type/GiftWrap';
import {
    getIsOptions,
    getSelectedOptions
} from '../../../packages/@scandiweb/mageplaza-gift-wrap/src/util/GiftWrap';
import { NOTE_TEXT_MAX_LENGTH } from './GiftWrapCheckoutSection.config';

import './GiftWrapCheckoutSection.style';

/** @namespace Scandipwa/Component/GiftWrapCheckoutSection/Component */
export class GiftWrapCheckoutSectionComponent extends PureComponent {
    static propTypes = {
        mpGiftWrap: MPGiftWrapConfigType.isRequired,
        giftWrapData: CartGiftWrapType.isRequired,
        postcardData: CartPostcardType.isRequired,
        currencyCode: PropTypes.string.isRequired,
        popupId: PropTypes.string.isRequired,
        onGiftWrapChange: PropTypes.func
    };

    static defaultProps = {
        ...this.defaultProps,
        onGiftWrapChange: noopFn
    };

    renderIcon() {
        const { mpGiftWrap: { giftWrapIcon } } = this.props;

        if (!giftWrapIcon) {
            return null;
        }

        return <Image src={ giftWrapIcon } />;
    }

    renderNote(id, text, price) {
        const {
            currencyCode
        } = this.props;

        if (!id) {
            return null;
        }

        const displayedText = text.slice(0, NOTE_TEXT_MAX_LENGTH).trim()
            + (text.length > NOTE_TEXT_MAX_LENGTH ? '...' : '');

        return (
            <p block="GiftWrapCheckoutSection" elem="Note">
                <span block="GiftWrapCheckoutSection" elem="NoteText">{ displayedText }</span>
                <span
                  block="GiftWrapCheckoutSection"
                  elem="NotePrice"
                >
                    { rearrangePriceString(price, currencyCode) }
                </span>
            </p>
        );
    }

    renderInfo() {
        const {
            popupId,
            postcardData: {
                postcardId,
                postcardMessage,
                postcardName,
                postcardPrice
            } = {},
            giftWrapData: {
                giftWrapId,
                giftWrapMessage,
                giftWrapName,
                giftWrapPrice
            } = {},
            onGiftWrapChange
        } = this.props;
        const isOptions = getIsOptions(giftWrapId, postcardId);
        const selectedOptions = getSelectedOptions(giftWrapId, postcardId, giftWrapMessage, postcardMessage);

        const giftWrapText = `${__('Wrapper')}: ${giftWrapName}`;
        const postcardText = `${__('Postcard')}: ${postcardName}`;

        return (
            <div
              block="GiftWrapCheckoutSection"
              elem="Info"
            >
                { this.renderNote(giftWrapId, giftWrapText, giftWrapPrice) }
                { this.renderNote(postcardId, postcardText, postcardPrice) }
                { !isOptions && this.renderDescription() }
                <GiftWrapButton
                  popupId={ popupId }
                  isOptions={ isOptions }
                />
                <GiftWrapPopup
                  popupId={ popupId }
                  selectedOptions={ selectedOptions }
                  isAllCart
                  onGiftWrapChange={ onGiftWrapChange }
                />
            </div>
        );
    }

    renderContent() {
        return (
            <div
              block="GiftWrapCheckoutSection"
              elem="Content"
            >
                { this.renderIcon() }
                { this.renderInfo() }
            </div>
        );
    }

    renderHeader() {
        return (
            <h4
              block="GiftWrapCheckoutSection"
              elem="Header"
            >
                { __('Gift wrapping') }
            </h4>
        );
    }

    renderDescription() {
        const {
            mpGiftWrap: {
                description
            }
        } = this.props;

        return (
            <p
              block="GiftWrapCheckoutSection"
              elem="Description"
            >
                { description }
            </p>
        );
    }

    render() {
        return (
            <div
              block="GiftWrapCheckoutSection"
            >
                { this.renderHeader() }
                { this.renderContent() }
            </div>
        );
    }
}

export default GiftWrapCheckoutSectionComponent;
