/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as stendersMapStateToProps,
    MyAccountTabListContainer as StendersMyAccountTabListContainer
} from 'StendersComponent/MyAccountTabList/MyAccountTabList.container';
import { isSignedIn } from 'Util/Auth';
import { noopFn } from 'Util/Common';

export { mapDispatchToProps };

/** @namespace Scandipwa/Component/MyAccountTabList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...stendersMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/MyAccountTabList/Container */
export class MyAccountTabListContainer extends StendersMyAccountTabListContainer {
    static propTypes = {
        ...this.propTypes,
        changeActiveTab: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired,
        hideMenu: PropTypes.func,
        handleTabArray: PropTypes.func,
        showBreadcrumbs: PropTypes.bool.isRequired
    };

    static defaultProps = {
        ...this.defaultProps,
        hideMenu: noopFn,
        handleTabArray: noopFn
    };

    containerFunction = {
        ...this.containerFunction,
        onTabClick: this.onTabClick.bind(this)
    };

    onTabClick(key) {
        const {
            changeActiveTab, showBreadcrumbs, hideMenu, handleTabArray
        } = this.props;

        if (!isSignedIn()) {
            return;
        }

        if (showBreadcrumbs) {
            hideMenu();
        }

        changeActiveTab(key);
        handleTabArray(key);
    }

    containerProps() {
        const {
            isMobile,
            handleTabArray,
            hideMenu,
            changeActiveTab,
            showBreadcrumbs
        } = this.props;

        return {
            ...super.containerProps(),
            isMobile,
            handleTabArray,
            hideMenu,
            changeActiveTab,
            showBreadcrumbs
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountTabListContainer);
