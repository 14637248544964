/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyGdpr/Query/PolicyRestriction/Query */
export class PolicyRestrictionQuery {
    getData() {
        return new Field('gdpr')
            .addField('noPolicyRestriction');
    }
}

export default new PolicyRestrictionQuery();
