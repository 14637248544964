/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */

import {
    ESTONIA_PHONE_PREFIX,
    LATVIA_PHONE_PREFIX,
    LITHUANIA_PHONE_PREFIX
} from './Config';

/** @namespace Scandipwa/Util/PhoneValidator/Validator/validatePhoneNumber */
export const validatePhoneNumber = (passedVal) => {
    if (passedVal.charAt(0) !== '+') {
        return __('Phone number must start with a "+" sign');
    }

    const value = passedVal.replace(/\s/g, '');
    const prefix = value.substring(0, 4);

    // ESTONIA
    if (prefix === ESTONIA_PHONE_PREFIX) {
        const phone = value.replace(ESTONIA_PHONE_PREFIX, '');
        const digNum = phone.length;
        const firstDigit = phone.substring(0, 1);

        if (firstDigit !== '5' && firstDigit !== '8' && firstDigit !== '6') {
            return __('Only Estonian  phone numbers (number starting with either 5, 6 or 8) are allowed');
        }
        if (firstDigit === '5' && (digNum < 7 || digNum > 8)) {
            return __('Estonian mobile phone number starting with 5 must be either 7 or 8 digits long');
        } if (firstDigit === '8' && digNum !== 8) {
            return __('Estonian mobile phone number starting with 8 must be 8 digits long');
        } if (firstDigit === '6' && (digNum < 6 || digNum > 7)) {
            return __('Estonian phone number starting with 6 must be either 6 or 7 digits long');
        }

        return true;
    }

    // LATVIA
    if (prefix === LATVIA_PHONE_PREFIX) {
        const phone = value.replace(LATVIA_PHONE_PREFIX, '');
        const digNum = phone.length;
        const firstDigit = phone.substring(0, 1);

        if (firstDigit !== '2' && firstDigit !== '6') {
            return __('Only Latvian phone numbers (number starting with either 2 or 6) are allowed');
        } if (firstDigit === '2' && digNum !== 8) {
            return __('Latvian phone number starting with 2 must be 8 digits long');
        } if (firstDigit === '6' && digNum !== 8) {
            return __('Latvian phone number starting with 6 must be 8 digits long');
        }

        return true;
    }

    // LITHUANIA
    if (prefix === LITHUANIA_PHONE_PREFIX) {
        const phone = value.replace(LITHUANIA_PHONE_PREFIX, '');
        const digNum = phone.length;

        if (digNum < 8 || digNum > 9) {
            return __('Only Lithuanian phone number -- 8 or 9 digits long -- are allowed');
        }

        return true;
    }

    // DEFAULT
    if (value.length < 6) {
        return __("Phone numbers mustn't be less then 6 digits");
    }

    return true;
};
