/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const addBlogConfigFields = (args, callback) => ([
    ...callback(...args),
    'mfblog_design_publication_date',
    'mfblog_design_format_date',
    'mfblog_index_page_display_mode',
    'mfblog_index_page_template',
    'mfblog_index_page_title',
    'mfblog_index_page_posts_sort_by',
    'mfblog_index_page_meta_title',
    'mfblog_index_page_meta_keywords',
    'mfblog_index_page_meta_description',
    'mfblog_index_page_post_ids',
    'mfblog_post_view_related_posts_enabled',
    'mfblog_post_view_related_posts_number_of_posts',
    'mfblog_post_view_related_products_enabled',
    'mfblog_post_view_related_products_number_of_products',
    'mfblog_post_view_comments_type',
    'mfblog_post_view_comments_number_of_comments',
    'mfblog_post_view_comments_number_of_replies',
    'mfblog_post_view_comments_fb_app_id',
    'mfblog_post_view_comments_fb_app_id_header',
    'mfblog_post_view_comments_disqus_forum_shortname',
    'mfblog_post_view_comments_guest_comments',
    'mfblog_post_view_comments_display_privacy_policy_checkbox',
    'mfblog_post_view_comments_default_status',
    'mfblog_post_view_comments_format_date',
    'mfblog_post_view_nextprev_enabled',
    'mfblog_post_list_posts_per_page',
    'mfblog_post_list_template',
    'mfblog_sidebar_search_enabled',
    'mfblog_sidebar_search_sort_order',
    'mfblog_sidebar_categories_enabled',
    'mfblog_sidebar_categories_show_posts_count',
    'mfblog_sidebar_categories_max_depth',
    'mfblog_sidebar_categories_sort_order',
    'mfblog_sidebar_recent_posts_enabled',
    'mfblog_sidebar_recent_posts_posts_per_page',
    'mfblog_sidebar_recent_posts_sort_ordere',
    'mfblog_sidebar_recent_posts_display_image',
    'mfblog_sidebar_featured_posts_enabled',
    'mfblog_sidebar_featured_posts_posts_ids',
    'mfblog_sidebar_featured_posts_sort_order',
    'mfblog_sidebar_featured_posts_display_image',
    'mfblog_sidebar_popular_posts_enabled',
    'mfblog_sidebar_popular_posts_posts_per_page',
    'mfblog_sidebar_popular_posts_sort_order',
    'mfblog_sidebar_popular_posts_display_image',
    'mfblog_sidebar_archive_enabled',
    'mfblog_sidebar_archive_format_date',
    'mfblog_sidebar_archive_sort_order',
    'mfblog_sidebar_tag_claud_enabled',
    'mfblog_sidebar_tag_claud_tag_count',
    'mfblog_sidebar_tag_claud_sort_order',
    'mfblog_sidebar_tag_claud_animated',
    'mfblog_sidebar_tag_claud_cloud_height',
    'mfblog_sidebar_tag_claud_text_highlight_color',
    'mfblog_sidebar_custom_enabled',
    'mfblog_sidebar_custom_sort_order',
    'mfblog_sidebar_custom_html',
    'mfblog_sidebar_custom2_enabled',
    'mfblog_sidebar_custom2_sort_order',
    'mfblog_sidebar_custom2_html',
    'mfblog_sidebar_rss_feed_enabled',
    'mfblog_sidebar_rss_feed_sort_order',
    'mfblog_sidebar_rss_feed_title',
    'mfblog_sidebar_rss_feed_description',
    'mfblog_product_page_related_posts_enabled',
    'mfblog_product_page_number_of_related_posts',
    'mfblog_author_enabled',
    'mfblog_author_page_enabled',
    'mfblog_seo_use_canonical_meta_tag_for',
    'mfblog_top_menu_show_item',
    'mfblog_top_menu_item_text',
    'mfblog_top_menu_include_categories',
    'mfblog_top_menu_max_depth'
]);

export default {
    'Scandipwa/Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields: addBlogConfigFields
        }
    }
};
