/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import StyledButton from 'Component/StyledButton/StyledButton.container';

import { MPGiftWrapConfigType } from '../../type/GiftWrap';

import './GiftWrapButton.style';

/** @namespace Scandiweb/MageplazaGiftWrap/Component/GiftWrapButton/Component */
export class GiftWrapButtonComponent extends PureComponent {
    static propTypes = {
        showGiftWrapPopup: PropTypes.func.isRequired,
        mpGiftWrap: MPGiftWrapConfigType.isRequired,
        isOptions: PropTypes.bool.isRequired
    };

    render() {
        const {
            showGiftWrapPopup,
            mpGiftWrap: {
                enabled,
                addButtonLabel,
                changeButtonLabel
            },
            isOptions
        } = this.props;
        const buttonLabel = isOptions ? changeButtonLabel : addButtonLabel;

        if (!enabled) {
            return null;
        }

        return (
            <StyledButton
              mix={ { block: 'ProductActions', elem: 'GiftWrapButton' } }
              handleClick={ showGiftWrapPopup }
              className=""
            >
                <span>
                    { buttonLabel }
                </span>
            </StyledButton>
        );
    }
}

export default GiftWrapButtonComponent;
