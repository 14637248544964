/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyGdpr/Query/OrderSearch/Query */
export class OrderSearchQuery {
    getSearchOrderMutation(options) {
        const mutation = new Field('gdprSearchOrder')
            .addFieldList(this.getSubFields());

        this.addSendSearchFormMutationArguments(mutation, options);

        return mutation;
    }

    addSendSearchFormMutationArguments(mutation, options) {
        return mutation.addArgument('orderId', 'String!', options.orderId)
            .addArgument('billingLastName', 'String', options.billingLastName)
            .addArgument('findBy', 'String!', options.findBy)
            .addArgument('email', 'String', options.email);
    }

    getSubFields() {
        return [
            this.getOrderInfoFields(),
            this.getPersonalDataFields(),
            this.getBillingAddressFields(),
            this.getShippingAddressFields()
        ];
    }

    getOrderInfoFields() {
        return new Field('OrderInfo')
            .addFieldList(this.getOrderInfoFieldList());
    }

    getOrderInfoFieldList() {
        return [
            'order_state',
            'order_status',
            'shipping_method',
            'payment_method'
        ];
    }

    getPersonalDataFields() {
        return new Field('PersonalData')
            .addFieldList(this.getPersonalDataFieldList());
    }

    getPersonalDataFieldList() {
        return [
            'customer_email',
            'customer_lastname',
            'customer_firstname',
            'customer_middlename'
        ];
    }

    getBillingAddressFields() {
        return new Field('BillingAddress')
            .addFieldList(this.getBillingAddressFieldList());
    }

    getBillingAddressFieldList() {
        return [
            'Billing_city',
            'Billing_email',
            'Billing_region',
            'Billing_street',
            'Billing_postcode'
        ];
    }

    getShippingAddressFields() {
        return new Field('ShippingAddress')
            .addFieldList(this.getShippingAddressFieldList());
    }

    getShippingAddressFieldList() {
        return [
            'Shipping_city',
            'Shipping_email',
            'Shipping_region',
            'Shipping_street',
            'Shipping_postcode'
        ];
    }
}
export default new OrderSearchQuery();
