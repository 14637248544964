/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DataContainer from 'SourceUtil/Request/DataContainer';

import { GDPRContext } from '../../context/GDPR.context';
import CheckboxesComponent from './Checkboxes.component';

/** @namespace Scandiweb/AmastyGdpr/Component/Checkboxes/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/Checkboxes/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyGdpr/Component/Checkboxes/Container */
export class CheckboxesContainer extends DataContainer {
    /** defines the props for this container */
    static propTypes = {
        scope: PropTypes.string.isRequired,
        onValidityChanged: PropTypes.func
    };

    static defaultProps = {
        onValidityChanged: null
    };

    state = {
        CheckboxGroupData: []
    };

    containerFunctions = {
        ...this.containerFunctions,
        onCheckboxStateChanged: this.onCheckboxStateChanged.bind(this)
    };

    static contextType = GDPRContext;

    checkboxesState = {}; // [name] -> isChecked

    async getData() {
        const { handleCheckScope } = this.context;
        const { scope } = this.props;
        const data = handleCheckScope(scope);
        data.then(
            /** @namespace Scandiweb/AmastyGdpr/Component/Checkboxes/Container/CheckboxesContainer/getData/data/then */
            (data) => {
                this.setState({ CheckboxGroupData: data });
                data.forEach((item) => {
                    this.checkboxesState[item.name] = item.isChecked;
                });

                this._refreshValidity();
            }
        );
    }

    onCheckboxStateChanged(data) {
        this.checkboxesState[data.name] = data.isChecked;
        this._refreshValidity();
    }

    _refreshValidity() {
        const { onValidityChanged } = this.props;

        if (onValidityChanged) {
            const checkboxStates = Object.values(this.checkboxesState);
            // eslint-disable-next-line fp/no-let
            let isAllChecked = true;
            checkboxStates.forEach((state) => {
                if (!state) {
                    isAllChecked = false;
                }
            });

            onValidityChanged(isAllChecked);
        }
    }

    componentDidMount() {
        this.getData();
    }

    containerProps = () => {
        const {
            scope
        } = this.props;
        const {
            CheckboxGroupData
        } = this.state;

        return {
            scope,
            CheckboxGroupData
        };
    };

    render() {
        return (
            <CheckboxesComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxesContainer);
