/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import StyledButton from 'Component/StyledButton/StyledButton.container';

import { itemType } from '../../type/MagefanBlog';
import { parsePostPath } from '../../util/BlogPage';

import './PDPRelatedPosts.style';

/** @namespace Scandiweb/MagefanBlog/Component/PDPRelatedPosts/Component */
export class PDPRelatedPostsComponent extends PureComponent {
    static propTypes = {
        relatedPosts: PropTypes.arrayOf({
            itemType
        }).isRequired,
        blogButtonLink: PropTypes.func.isRequired
    };

    renderRelatedPost(post, index) {
        const {
            is_active: isActive,
            title,
            featured_image,
            short_filtered_content
        } = post;

        // Might return one less item, needs to be double checked
        if (!isActive || index > 0) {
            return null;
        }

        return (
            <div block="PDPRelatedPosts" elem="Container">
                <div block="PDPRelatedPosts" elem="TextWrapper">
                    <div block="PDPRelatedPosts" elem="Heading">
                        { __("STENDER'S BLOG") }
                    </div>
                    <div block="PDPRelatedPosts" elem="Title">
                        { title }
                    </div>
                    <div block="PDPRelatedPosts" elem="Content">
                        <p>
                            <Html content={ short_filtered_content } />
                        </p>
                    </div>
                    { this.renderButtons(post) }
                </div>
                <div
                  data-scroll
                  data-scroll-speed="-0.7"
                  block="PDPRelatedPosts"
                  elem="ImageWrapper"
                >
                    <Image src={ featured_image } isPlain />
                </div>
            </div>
        );
    }

    renderButtons(post) {
        const {
            blogButtonLink
        } = this.props;
        const { post_url: postUrl } = post;
        const url = parsePostPath(postUrl);

        return (
            <div block="PDPRelatedPosts" elem="ButtonWrapper">
                <div block="PDPRelatedPosts" elem="ReadMoreButtonWrapper">
                    <StyledButton
                      mix={ { block: 'PDPRelatedPosts', elem: 'ReadMoreButton' } }
                      to={ url }
                    >
                        { __('Read More') }
                    </StyledButton>
                </div>
                <div block="PDPRelatedPosts" elem="ExploreBlogButtonWrapper">
                    <StyledButton
                      mix={ { block: 'PDPRelatedPosts', elem: 'ExploreBlogButton' } }
                      to={ blogButtonLink() }
                    >
                        { __('Explore Blog') }
                    </StyledButton>
                </div>
            </div>
        );
    }

    renderRelatedPosts() {
        const { relatedPosts } = this.props;

        return relatedPosts.map((post, index) => this.renderRelatedPost(post, index));
    }

    render() {
        return (
            <ContentWrapper wrapperMix={ { block: 'PDPRelatedPosts', elem: 'Wrapper' } } label="PDPRelatedPosts">
                    { this.renderRelatedPosts() }
            </ContentWrapper>
        );
    }
}

export default PDPRelatedPostsComponent;
