export * from 'SourceRoute/MyAccount/MyAccount.config';

export const BILLING_AGREEMENTS = 'billing-agreements';
export const ACCOUNT_SAVE_SUCCESS_MESSAGE = 'You saved the account information.';
export const MY_ACCOUNT_TAB = 'my-account';
export const HOME_PAGE_URL = '/';
export const INFORMATION_EDIT_POPUP = 'INFORMATION_EDIT_POPUP';
export const REQUEST_APPROVED = 'REQUEST_APPROVED';
export const REQUEST_PENDING = 'REQUEST_PENDING';
export const NOT_REQUESTED = 'NOT_REQUESTED';
export const BREADCRUMBS_BREAKPOINT = 960;
