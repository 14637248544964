import { createRef } from 'react';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    SearchBarDispatcher,
    SearchItemContainer as SourceSearchItemContainer
} from 'StendersComponent/SearchItem/SearchItem.container';
import { transitionElementFromTo } from 'Util/Transition';

/** @namespace Scandipwa/Component/SearchItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile
});

export {
    mapDispatchToProps,
    SearchBarDispatcher
};

/** @namespace Scandipwa/Component/SearchItem/Container */
export class SearchItemContainer extends SourceSearchItemContainer {
    imageRef = createRef();

    handleItemClick() {
        const { hideActiveOverlay, isMobile } = this.props;

        if (isMobile) {
            const imageToCallback = () => document.querySelector('[data-is-pdp=true]');
            transitionElementFromTo({
                elFrom: this.imageRef.current,
                elToCallback: imageToCallback
            });
        }

        hideActiveOverlay();
    }

    containerProps() {
        return {
            ...super.containerProps(),
            imageRef: this.imageRef
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchItemContainer);
