import {
    ESTONIA_COUNTRY_CODE,
    ESTONIAN_ZIP_CODE_MAX_LENGTH,
    LATVIA_COUNTRY_CODE,
    LATVIAN_ZIP_CODE_MAX_LENGTH,
    LITHUANIA_COUNTRY_CODE,
    LITHUANIAN_ZIP_CODE_MAX_LENGTH,
    VALIDATION_INPUT_TYPE,
    VALIDATION_INPUT_TYPE_NUMBER,
    VALIDATION_MESSAGES,
    VALIDATION_RULES
} from 'Util/Validator/Config';

export * from 'SourceUtil/Validator/Validator';
export { default } from 'SourceUtil/Validator/Validator';

/**
 * Validates parameter based on rules
 * @param value
 * @param rule
 * @returns {boolean|{errorMessages: *[], value}}
 * @namespace Scandipwa/Util/Validator/validate */
export const validate = (value, rule) => {
    const {
        isRequired,
        inputType,
        match,
        range,
        fileExtension,
        customErrorMessages: {
            onRequirementFail,
            onInputTypeFail,
            onMatchFail,
            onRangeFailMin,
            onRangeFailMax,
            onExtensionFail
        } = {}
    } = rule;

    const output = {
        value,
        errorMessages: []
    };

    // #region IS REQUIRED
    if (isRequired && !value) {
        output.errorMessages.push(onRequirementFail || __(VALIDATION_MESSAGES.isRequired));
    }
    // #endregion

    if (
        inputType
        && inputType === 'checkout_phone'
        && value
        && !(value.replace(/\s/g, '')).match(VALIDATION_RULES[VALIDATION_INPUT_TYPE.phone])
    ) {
        output.errorMessages.push(onInputTypeFail || __(VALIDATION_MESSAGES[VALIDATION_INPUT_TYPE.phone]));
    }

    // #region INPUT TYPE
    if (inputType && inputType !== 'checkout_phone' && value && !value.match(VALIDATION_RULES[inputType])) {
        output.errorMessages.push(onInputTypeFail || __(VALIDATION_MESSAGES[inputType]));
    }
    // #endregion

    // #region MATCH
    if (typeof match === 'function') {
        const response = match(value);

        if (response !== true) {
            output.errorMessages.push(response === false ? onMatchFail || __(VALIDATION_MESSAGES.match) : response);
        }
    } else if (match && !value.match(match)) {
        output.errorMessages.push(onMatchFail || __(VALIDATION_MESSAGES.match));
    }
    // #endregion

    // #region RANGE
    if (range) {
        const { min, max, showLengthError } = range;
        const isNumber = !!VALIDATION_INPUT_TYPE_NUMBER[inputType];

        if (isNumber) {
            if (min && +value < min) {
                output.errorMessages.push(onRangeFailMin || __('Minimal value is %s!', min));
            }

            if (max && +value > max) {
                output.errorMessages.push(onRangeFailMax || __('Maximum value is %s!', max));
            }
        } else {
            if (min && value.length < min && value.length > 0) {
                output.errorMessages.push(onRangeFailMin || __('Minimum %s characters!', min));
            }

            if (max && value.length > max) {
                const tooMany = value.length - max;
                output.errorMessages.push(onRangeFailMax || __('Maximum %s characters (%s too many)', max, tooMany));

                if (showLengthError) {
                    output.errorMessages.unshift(__('Please enter no more than %s characters.', max));
                }
            }
        }
    }

    if (fileExtension && value !== '') {
        const { accept } = fileExtension;
        const acceptedExtensions = accept.split(', ');
        const currentFileExtension = value.split('.').pop();

        if (!acceptedExtensions.includes(currentFileExtension)) {
            output.errorMessages.push(onExtensionFail || __(VALIDATION_MESSAGES.fileExtension));
        }
    }
    // #endregion

    const { errorMessages } = output;
    return errorMessages.length === 0 ? true : output;
};

/** @namespace Scandipwa/Util/Validator/validateZipCode */
export const validateZipCode = (value, countryId, isOmnivaParcel) => {
    if (countryId && isOmnivaParcel) {
        // eslint-disable-next-line no-magic-numbers
        const prefix = value.substring(0, 3);
        const len = value.length - prefix.length;
        const output = {
            value,
            errorMessages: []
        };

        switch (countryId) {
        case LATVIA_COUNTRY_CODE:
            if (prefix !== `${LATVIA_COUNTRY_CODE}-`) {
                output.errorMessages.push(__('Latvian zip codes should start with LV-'));
            } else if (len !== LATVIAN_ZIP_CODE_MAX_LENGTH) {
                output.errorMessages.push(__('Latvian zip codes should contain 4 digits'));
            }
            break;
        case LITHUANIA_COUNTRY_CODE:
            if (prefix !== `${LITHUANIA_COUNTRY_CODE}-`) {
                output.errorMessages.push(
                    __(`Lithuanian zip codes should start with ${LITHUANIA_COUNTRY_CODE}-`)
                );
            } else if (len !== LITHUANIAN_ZIP_CODE_MAX_LENGTH) {
                output.errorMessages.push(__('Lithuanian zip codes should contain 5 digits'));
            }
            break;
        case ESTONIA_COUNTRY_CODE:
            if (value.length !== ESTONIAN_ZIP_CODE_MAX_LENGTH) {
                output.errorMessages.push(__('Estonian zip codes should contain 5 digits'));
            }
            break;
        default:
            return true;
        }

        return output.errorMessages.length > 0 ? output : true;
    }

    return true;
};
