import { PureComponent } from 'react';

import 'StendersComponent/CheckBoxIcon/CheckBoxIcon.style';
import './CheckBoxIcon.extended.style';

/** @namespace Scandipwa/Component/CheckBoxIcon/Component */
export class CheckBoxIconComponent extends PureComponent {
    /**
     * Removed the fixed width and height of 24px
     */
    render() {
        return (
            <svg block="CheckBoxIcon" viewBox="0 0 20 20">
                <path d="M0,0 L20,0 L20,0L20,20 L0,20 L0,20 L0,20 Z" />
                <polyline points="4 11 8 15 16 6" />
            </svg>
        );
    }
}

export default CheckBoxIconComponent;
