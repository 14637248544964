import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';

import { BANNER_HEIGHT, BANNER_RATIO, LOGIN } from '../PLPPromoBanner/PLPPromoBanner.config';

import './HairPLPPromoBanner.style';

/** @namespace Scandipwa/Component/HairPLPPromoBanner/Component */
export class HairPLPPromoBannerComponent extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        linkText: PropTypes.string.isRequired,
        linkUrl: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        openMyAccountOverlay: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    renderButton() {
        const {
            linkUrl,
            linkText,
            openMyAccountOverlay,
            isMobile
        } = this.props;

        if (linkUrl === LOGIN && !isMobile) {
            return (
                <button block="HairPLPPromoBanner" elem="Link" onClick={ openMyAccountOverlay }>{ linkText }</button>
            );
        }

        if (linkUrl === LOGIN && isMobile) {
            return (
                <Link block="HairPLPPromoBanner" elem="Link" to="/customer/account/login">
                    { linkText }
                </Link>
            );
        }

        if (linkUrl) {
            return (
                <Link block="HairPLPPromoBanner" elem="Link" to={ linkUrl }>
                    { linkText }
                </Link>
            );
        }

        return null;
    }

    renderWidgetContent() {
        const {
            title,
            description
        } = this.props;

        return (
            <div block="HairPLPPromoBanner" elem="Content">
                <h2 block="HairPLPPromoBanner" elem="Title">
                    { title }
                </h2>
                <p block="HairPLPPromoBanner" elem="Text">
                    { description }
                </p>
                { this.renderButton() }
            </div>
        );
    }

    render() {
        const { image } = this.props;

        return (
            <div block="HairPLPPromoBanner">
                <div block="HairPLPPromoBanner" elem="Image">
                    <Image src={ image } ratio={ BANNER_RATIO } wrapperSize={ { height: BANNER_HEIGHT } } />
                </div>
                { this.renderWidgetContent() }
            </div>
        );
    }
}

export default HairPLPPromoBannerComponent;
