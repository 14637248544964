import ExpandableContent from 'Component/ExpandableContent';
import MyAccountAddressTable from 'Component/MyAccountAddressTable';
import {
    MyAccountOrderInformationComponent as StendersMyAccountOrderInformation
} from 'StendersComponent/MyAccountOrderInformation/MyAccountOrderInformation.component';

import {
    BILLING_ADDRESS_TITLE,
    PAYMENT_METHOD_TITLE,
    SHIPPING_ADDRESS_TITLE,
    SHIPPING_METHOD_TITLE
} from './MyAccountOrderInformation.config';

import './MyAccountOrderInformation.extended.style.scss';

/** @namespace Scandipwa/Component/MyAccountOrderInformation/Component */
export class MyAccountOrderInformationComponent extends StendersMyAccountOrderInformation {
    renderShippingAddress() {
        const {
            order: { shipping_address } = {},
            activeOrderInformationTab,
            handleInformationTabClick
        } = this.props;

        if (!shipping_address) {
            return null;
        }

        return (
            <ExpandableContent
              heading={ __(SHIPPING_ADDRESS_TITLE) }
              key={ SHIPPING_ADDRESS_TITLE }
              isExpandableOnDesktop
              isArrow
              mix={ { block: 'MyAccountOrderInformation' } }
              isContentExpanded={ activeOrderInformationTab === SHIPPING_ADDRESS_TITLE }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => handleInformationTabClick(SHIPPING_ADDRESS_TITLE) }
            >
                <MyAccountAddressTable
                  address={ shipping_address }
                  mix={ { block: 'MyAccountOrderInformation', elem: 'Address' } }
                />
            </ExpandableContent>
        );
    }

    renderShippingMethod() {
        const {
            order: { shipping_method },
            activeOrderInformationTab,
            handleInformationTabClick
        } = this.props;

        if (!shipping_method) {
            return null;
        }

        return (
            <ExpandableContent
              heading={ __(SHIPPING_METHOD_TITLE) }
              key={ SHIPPING_METHOD_TITLE }
              isExpandableOnDesktop
              isArrow
              mix={ { block: 'MyAccountOrderInformation' } }
              isContentExpanded={ activeOrderInformationTab === SHIPPING_METHOD_TITLE }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => handleInformationTabClick(SHIPPING_METHOD_TITLE) }
            >
                <div
                  block="MyAccountOrderInformation"
                  elem="Column"
                >
                    <span
                      block="MyAccountOrderInformation"
                      elem="ShippingMethod"
                    >
                        { shipping_method }
                    </span>
                </div>
            </ExpandableContent>
        );
    }

    renderBillingAddress() {
        const {
            order: { billing_address } = {},
            activeOrderInformationTab,
            handleInformationTabClick
        } = this.props;

        if (!billing_address) {
            return null;
        }

        return (
            <ExpandableContent
              heading={ __(BILLING_ADDRESS_TITLE) }
              key={ BILLING_ADDRESS_TITLE }
              isExpandableOnDesktop
              isArrow
              mix={ { block: 'MyAccountOrderInformation' } }
              isContentExpanded={ activeOrderInformationTab === BILLING_ADDRESS_TITLE }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => handleInformationTabClick(BILLING_ADDRESS_TITLE) }
            >
                <div
                  block="MyAccountOrderInformation"
                  elem="Column"
                  mods={ { type: 'billingAddress' } }
                >
                    <MyAccountAddressTable
                      address={ billing_address }
                      mix={ { block: 'MyAccountOrderInformation', elem: 'Address' } }
                    />
                </div>
            </ExpandableContent>
        );
    }

    renderPaymentMethods() {
        const {
            order: { payment_methods = [] },
            activeOrderInformationTab,
            handleInformationTabClick
        } = this.props;

        return (
            <ExpandableContent
              heading={ __(PAYMENT_METHOD_TITLE) }
              key={ PAYMENT_METHOD_TITLE }
              isExpandableOnDesktop
              isArrow
              mix={ { block: 'MyAccountOrderInformation' } }
              isContentExpanded={ activeOrderInformationTab === PAYMENT_METHOD_TITLE }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => handleInformationTabClick(PAYMENT_METHOD_TITLE) }
            >
                <div
                  block="MyAccountOrderInformation"
                  elem="Column"
                >
                    { payment_methods.map(this.renderPaymentMethod.bind(this)) }
                </div>
            </ExpandableContent>
        );
    }
}

export default MyAccountOrderInformationComponent;
