/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

export const MPGiftWrapConfigType = PropTypes.shape({
    enabled: PropTypes.bool,
    addButtonLabel: PropTypes.string,
    changeButtonLabel: PropTypes.string,
    taxClass: PropTypes.number,
    // vvv if disabled then returns null
    giftWrapIcon: PropTypes.string,
    giftWrapType: PropTypes.number,
    customerNotice: PropTypes.string,
    showOnProduct: PropTypes.bool,
    giftMessageEnabled: PropTypes.bool,
    description: PropTypes.string,
    giftMessageMaxCharacters: PropTypes.number,
    giftMessageFee: PropTypes.number,
    giftMessageVisible: PropTypes.arrayOf(PropTypes.number),
    showReport: PropTypes.bool,
    // TODO vvv  do not know the type yet, need more investigation
    syncWrapHistory: null
});

export const GiftWrapperType = PropTypes.shape({
    amount: PropTypes.number,
    category: PropTypes.string,
    created_at: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    price_type: PropTypes.number,
    sort_order: PropTypes.number,
    status: PropTypes.number,
    updated_at: PropTypes.string,
    wrap_id: PropTypes.number,
    wrap_type: PropTypes.string
});

export const GiftWrapCategoryType = PropTypes.shape({
    category_id: PropTypes.number,
    created_at: PropTypes.string,
    customer_group: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    sort_order: PropTypes.number,
    status: PropTypes.number,
    store_id: PropTypes.number,
    updated_at: PropTypes.string
});

export const PageInfoType = PropTypes.shape({
    currentPage: PropTypes.number,
    endPage: PropTypes.number,
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    pageSize: PropTypes.number,
    startPage: PropTypes.number
});

export const MpGiftWrapWrappersType = PropTypes.shape({
    items: PropTypes.arrayOf(GiftWrapperType),
    pageInfo: PageInfoType,
    total_count: PropTypes.number
});

export const MpGiftWrapCategoriesType = PropTypes.shape({
    items: PropTypes.arrayOf(GiftWrapCategoryType),
    page_info: PageInfoType,
    total_count: PropTypes.number
});

export const MFGiftWrapItemType = PropTypes.shape({
    wrap_id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.number,
    price_type: PropTypes.number,
    amount: PropTypes.number,
    description: PropTypes.string,
    image: PropTypes.string,
    category: PropTypes.string,
    sort_order: PropTypes.number,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    price: PropTypes.string,
    use_gift_message: PropTypes.bool,
    gift_message: PropTypes.string,
    gift_message_fee: PropTypes.number,
    all_cart: PropTypes.bool
});

export const CartGiftWrapType = PropTypes.shape({
    giftWrapId: PropTypes.number,
    giftWrapName: PropTypes.string,
    giftWrapPrice: PropTypes.number,
    giftWrapUseGiftMessage: PropTypes.bool,
    giftWrapMessage: PropTypes.string,
    giftWrapAllCart: PropTypes.bool
});

export const CartPostcardType = PropTypes.shape({
    postCardId: PropTypes.number,
    postCardName: PropTypes.string,
    postCardPrice: PropTypes.number,
    postCardUseGiftMessage: PropTypes.bool,
    postCardMessage: PropTypes.string,
    pstCartAllCart: PropTypes.bool
});

export const GiftWrapOptionsPostcardAndWrapCommonTypes = {
    amount: PropTypes.number,
    category: PropTypes.string,
    created_at: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    price_type: PropTypes.number,
    sort_order: PropTypes.number,
    status: PropTypes.number,
    updated_at: PropTypes.string,
    wrap_id: PropTypes.number,
    wrap_type: PropTypes.string
};

export const GiftWrapOptionsWrapType = PropTypes.shape({
    ...GiftWrapOptionsPostcardAndWrapCommonTypes,
    giftNoteMessage: PropTypes.shape({
        message: PropTypes.string,
        fee: PropTypes.number
    })
});

export const GiftWrapOptionsPostcardType = PropTypes.shape({
    ...GiftWrapOptionsPostcardAndWrapCommonTypes,
    postcardMessage: PropTypes.string
});

export const GiftWrapOptionsType = PropTypes.shape({
    totalPrice: PropTypes.number,
    wrap: GiftWrapOptionsWrapType,
    postcard: GiftWrapOptionsPostcardType
});
