/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import FIELD_TYPE from 'Component/Field/Field.config';
import { isSignedIn } from 'Util/Auth';
import { composeCheckBoxText } from 'Util/GDPR/Functions';

import { CONSENT_OPTING } from '../../../../../../src/component/GivenConsent/GivenConsent.config';
import { REGISTRATION_SCOPE } from '../../config';
import { CheckboxItemDataType } from '../../type/gdpr.type';
import ConsentCheckboxField from '../ConsentCheckboxField/ConsentCheckboxField.container';

/** @namespace Scandiweb/AmastyGdpr/Component/Checkbox/Component */
export class CheckboxComponent extends PureComponent {
    static propTypes = {
        data: CheckboxItemDataType,
        handleCheckboxChange: PropTypes.func.isRequired,
        scope: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
    };

    static defaultProps = {
        data: {}
    };

    renderRequiredTag(isRequired) {
        if (!isRequired) {
            return null;
        }

        return (
            <span block="Field" elem="Label" mods={ { isRequired: true } }>
                { ' *' }
            </span>
        );
    }

    renderCheckboxTextElement(element) {
        return element || null;
    }

    renderLink(consentText, isRequired) {
        const splitConsentTextFormatted = composeCheckBoxText(consentText);

        return (
            <span className="gdpr-label">
                { splitConsentTextFormatted.map(this.renderCheckboxTextElement) }
                { this.renderRequiredTag(isRequired) }
            </span>
        );
    }

    renderLabel() {
        const {
            data: {
                consentText,
                isRequired
            } = {}
        } = this.props;

        return (
            <>
                { composeCheckBoxText(consentText).map(this.renderCheckboxTextElement) }
                { this.renderRequiredTag(isRequired) }
            </>
        );
    }

    render() {
        const {
            scope,
            data: {
                name,

                isEnabled,
                isChecked,
                hide_the_consent,
                isRequired
            } = {},
            handleCheckboxChange,
            id
        } = this.props;

        if (
            !isEnabled
            || (hide_the_consent && isRequired
                && scope !== CONSENT_OPTING && isChecked && scope !== REGISTRATION_SCOPE && isSignedIn())
        ) {
            return null;
        }

        return (
            <ConsentCheckboxField
              type={ FIELD_TYPE.checkbox }
              attr={ {
                  defaultChecked: isChecked || scope === CONSENT_OPTING,
                  name,
                  disabled: scope === CONSENT_OPTING,
                  id
              } }
              events={ {
                  onChange: handleCheckboxChange
              } }
              validationRule={ {
                  isRequired
              } }
              validateOn={ ['onChange'] }
              showErrorAsLabel
              label={ this.renderLabel() }
              scope={ scope }
            />
        );
    }
}

export default CheckboxComponent;
