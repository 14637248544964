/* eslint-disable react/boolean-prop-naming */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { Children, PureComponent } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { LEFT, RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import Draggable from 'Component/Draggable';
import { ChildrenType, MixType, RefType } from 'Type/Common.type';

import './Slider.style';

/**
 * Slider component
 * @class Slider
 * @namespace Scandipwa/Component/Slider/Component */
export class SliderComponent extends PureComponent {
    renderCrumb= this.renderCrumb.bind(this);

    static propTypes = {
        showCrumbs: PropTypes.bool.isRequired,
        showArrows: PropTypes.bool.isRequired,
        showCounter: PropTypes.bool.isRequired,
        activeImage: PropTypes.number.isRequired,
        mix: MixType.isRequired,
        children: ChildrenType.isRequired,
        isVertical: PropTypes.bool.isRequired,
        handleDragStart: PropTypes.func.isRequired,
        handleDrag: PropTypes.func.isRequired,
        handleDragEnd: PropTypes.func.isRequired,
        goNext: PropTypes.func.isRequired,
        goPrev: PropTypes.func.isRequired,
        handleClick: PropTypes.func.isRequired,
        changeActiveImage: PropTypes.func.isRequired,
        getDir: PropTypes.func.isRequired,
        draggableRef: RefType.isRequired,
        getSlideWidth: PropTypes.func.isRequired,
        getIsSlider: PropTypes.func.isRequired,
        getSliderRef: PropTypes.func.isRequired,
        averageSliderWidth: PropTypes.number.isRequired
    };

    renderCounter() {
        const {
            children,
            showCounter,
            activeImage
        } = this.props;

        if (!showCounter || children.length <= 1) {
            return null;
        }

        return (
            <div
              block="Slider"
              elem="Counter"
            >
                { activeImage + 1 }
                /
                { children.length }
            </div>
        );
    }

    renderCrumbs() {
        const {
            children,
            showCrumbs
        } = this.props;

        if (!showCrumbs || children.length <= 1) {
            return null;
        }

        return (
            <div
              block="Slider"
              elem="Crumbs"
            >
                { Children.map(children, this.renderCrumb) }
            </div>
        );
    }

    renderCrumb(_, i) {
        const {
            activeImage,
            changeActiveImage
        } = this.props;
        const isActive = i === Math.abs(-activeImage);

        return (
            <button
              block="Slider"
              elem="Image"
              mods={ { type: 'single' } }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => changeActiveImage(i) }
              aria-label={ __('Slide crumb') }
            >
                <div
                  block="Slider"
                  elem="Crumb"
                  mods={ { isActive } }
                />
            </button>
        );
    }

    renderArrows() {
        const {
            showArrows,
            activeImage,
            children,
            goPrev,
            goNext
        } = this.props;
        const nextIsDisabled = activeImage + 1 === children.length;
        const prevIsDisabled = activeImage === 0;

        if (!showArrows) {
            return null;
        }

        return (
            <>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isPrev: true, isDisabled: prevIsDisabled } }
                  aria-label={ __('Previous') }
                  onClick={ goPrev }
                >
                    <ChevronIcon direction={ LEFT } />
                </button>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isNext: true, isDisabled: nextIsDisabled } }
                  aria-label={ __('Next') }
                  onClick={ goNext }
                >
                    <ChevronIcon direction={ RIGHT } />
                </button>
            </>
        );
    }

    renderSliderContent() {
        const {
            activeImage,
            children,
            isVertical,
            getDir,
            draggableRef,
            handleDragStart,
            handleDragEnd,
            handleDrag,
            handleClick,
            getSlideWidth,
            getIsSlider,
            averageSliderWidth
        } = this.props;
        const dir = getDir();

        if (!getIsSlider()) {
            return children;
        }

        const style = averageSliderWidth ? { width: `${averageSliderWidth}px` } : {};

        return (
            <Draggable
              mix={ { block: 'Slider', elem: 'Wrapper', mods: { isVertical } } }
              draggableRef={ draggableRef }
              onDragStart={ handleDragStart }
              onDragEnd={ handleDragEnd }
              onDrag={ handleDrag }
              onClick={ handleClick }
              shiftX={ -activeImage * getSlideWidth() * dir }
              shiftY={ -activeImage * getSlideWidth() }
              style={ style }
            >
                { children }
            </Draggable>
        );
    }

    render() {
        const {
            mix,
            getSliderRef
        } = this.props;

        return (
            <>
                <div
                  block="Slider"
                  mix={ mix }
                  ref={ getSliderRef() }
                >
                    { this.renderSliderContent() }
                    { this.renderCrumbs() }
                    { this.renderCounter() }
                </div>
                { this.renderArrows() }
            </>
        );
    }
}

export default SliderComponent;
