/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageplazaGiftWrap/Query/Config/Query */
export class ConfigQuery {
    getConfigFields = () => ([
        'enabled',
        'addButtonLabel',
        'changeButtonLabel',
        'taxClass',
        'giftWrapIcon',
        'giftWrapType',
        'customerNotice',
        'showOnProduct',
        'giftMessageEnabled',
        'description',
        'giftMessageMaxCharacters',
        'giftMessageFee',
        'giftMessageFeeFormat',
        'giftMessageVisible',
        'showReport',
        'syncWrapHistory'
    ]);

    getGiftWrapField = () => new Field('mpGetGiftWrapConfig')
        .setAlias('mpGiftWrap')
        .addFieldList(this.getConfigFields());
}

export default new ConfigQuery();
