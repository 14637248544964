/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateIsLoading } from 'Store/MyAccount/MyAccount.action.js';
import { fetchQuery } from 'Util/Request';

import GetSocialAuthResultQuery from '../../query/GetSocialAuthResult.query';
import { AvailableSocialType, SetUsedSocialTypeType } from '../../type/Social.type';
import SocialLoginButton from './SocialLoginButton.component';

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButton/Container/mapStateToProps */
export const mapStateToProps = () => ({
});

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setMyAccountLoading: (isLoading) => dispatch(updateIsLoading(isLoading))
});

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButton/Container */
export class SocialLoginButtonContainer extends PureComponent {
    static propTypes = {
        social: AvailableSocialType.isRequired,
        setMyAccountLoading: PropTypes.func.isRequired,
        setUsedSocialType: SetUsedSocialTypeType.isRequired,
        setCustomerExists: PropTypes.func.isRequired
    };

    containerFunctions = {
        onClick: this.onClick.bind(this)
    };

    onClick() {
        const { setMyAccountLoading } = this.props;

        this.openSocialsPopup();
        setMyAccountLoading(true);
    }

    async checkAuthorizationResult(type) {
        const {
            setCustomerExists,
            setUsedSocialType,
            setMyAccountLoading
        } = this.props;

        const {
            getSocialAuthResult: {
                isLoggedIn,
                isAuthorizedByExternalProvider
            }
        } = await fetchQuery([
            GetSocialAuthResultQuery.getQuery()
        ]);

        if (isLoggedIn) {
            // the customer is already in the session.
            // this will trigger a hack in the render() of authorization components to fetch user data from server
            setCustomerExists(true);
        } else if (isAuthorizedByExternalProvider) {
            // we have made a request to a social auth provider, but there is no Magento Customer yet
            // so show the AdditionalFieldsForm
            setUsedSocialType(type);
            setCustomerExists(false);
            setMyAccountLoading(false);
        } else {
            // the authorization attempt was not successive. Either user closed the popup without granting
            // authorization, or the provider refused to give access for some reason.
            setMyAccountLoading(false);
        }
    }

    openSocialsPopup() {
        const { social: { login_url, type } } = this.props;

        const currentTime = Date.now().toString();
        const popup = window.open(
            `${login_url}?${currentTime}`,
            type,
            this.getPopupDimensions()
        );

        document.cookie = `social_login_url=${login_url}`;
        popup.socialUrl = login_url;
        const CLOSED_CHECK_INTERVAL = 1000;

        const timer = setInterval(() => {
            if (popup.closed) {
                clearInterval(timer);

                // removing the cookies set in opening
                document.cookie = 'social_login_url=; expires=Thu, 01 Jan 1970 00:00:00 UTC';
                this.checkAuthorizationResult(type);
            }
        }, CLOSED_CHECK_INTERVAL);
    }

    getPopupDimensions = () => {
        const width = 500;
        const height = 420;

        const {
            screenLeft, screenTop, outerWidth, outerHeight
        } = window;

        const left = parseInt(screenLeft + ((outerWidth - width) / 2), 10);
        // eslint-disable-next-line no-magic-numbers
        const top = parseInt(screenTop + ((outerHeight - height) / 2.5), 10);

        return Object.entries({
            width,
            height,
            left,
            top
        }).map(
            ([key, val]) => `${key}=${val}`
        ).join(',');
    };

    containerProps() {
        const { social: { label, type } } = this.props;

        return {
            label,
            type
        };
    }

    render() {
        return (
            <SocialLoginButton
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialLoginButtonContainer);
