import { Field } from 'Util/Query';

/** @namespace SeoSuite/Query/SeoHtmlTemplates/Query */
export class SeoHtmlTemplatesQuery {
    _getProducts = () => new Field('products').addFieldList([this.getItemsFields('items')]);

    getItemsFields(itemFieldType) {
        const items = ['title', 'url'];
        const itemsFields = new Field('items');

        if (itemFieldType === 'categories') {
            items.push('level');
            itemsFields.addFieldList([...items, this._getProducts()]);

            return itemsFields;
        }
        if (itemFieldType === 'items') {
            itemsFields.addFieldList(Array.from(items));

            return itemsFields;
        }

        return null;
    }

    _getSections() {
        const sections = new Field('sections').addFieldList([
            'section_title',
            this.getItemsFields('items')
        ]);

        return sections;
    }

    _getSiteMapCategory = () => new Field('categories').addFieldList([
        this.getItemsFields('categories')
    ]);

    _getCmsPages = () => new Field('cms_pages').addFieldList([this.getItemsFields('items')]);

    _getAdditionalLinks = () => new Field('additional_links').addFieldList([
        this.getItemsFields('items')
    ]);

    _getCustomLinks = () => new Field('custom_links').addFieldList([this._getSections()]);

    requestAllStoreViews() {
        const getStoreFields = new Field('storeList').addFieldList([
            'code',
            'id',
            'base_link_url'
        ]);

        return getStoreFields;
    }

    getMwHtmlSitemap(storeId) {
        const htmlSitemapFields = new Field('mwHtmlSitemap')
            .addArgument('storeId', 'Int', storeId)
            .addFieldList([
                'title',
                'meta_description',
                'meta_keywords',
                this._getSiteMapCategory(),
                this._getCmsPages(),
                this._getAdditionalLinks(),
                this._getCustomLinks()
            ]);

        return htmlSitemapFields;
    }
}

export default new SeoHtmlTemplatesQuery();
