import MyAccountLoyalty from '../component/MyAccountLoyalty';

const addLoyaltyNumber = (args, callback, instance) => {
    const { customer } = instance.props;
    const { loyalty_number } = customer;
    return (
        <>
            <MyAccountLoyalty loyaltyNumber={ loyalty_number } />
            { callback(...args) }
        </>
    );
};

export default {
    'Component/MyAccountDashboard/Component': {
        'member-function': {
            renderCustomerTable: addLoyaltyNumber
        }
    }
};
