/**
 * Mageplaza Gift Wrap compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PRODUCT_TYPE from 'Component/Product/Product.config';

export const GIFT_WRAP_PER_ITEM = 1;
export const GIFT_WRAP_PER_CART = 2;
export const GIFT_WRAP_BOTH = 3;

export const allowedProductType = {
    [PRODUCT_TYPE.simple]: PRODUCT_TYPE.simple,
    [PRODUCT_TYPE.configurable]: PRODUCT_TYPE.configurable,
    [PRODUCT_TYPE.bundle]: PRODUCT_TYPE.bundle
};

export const ESCAPE_KEY = 27;
