/**
 * MakeCommerce compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { MakeCommerceReducer } from '../store/MakeCommerce/MakeCommerce.reducer';

const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    MakeCommerceReducer
});

export default {
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    }
};
