import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Cart/Query */
export class CartQuery extends SourceCartQuery {
    _getCartTotalsFields() {
        return [
            ...super._getCartTotalsFields(),
            this._getChooseYourGiftRulesFields(),
            this._getYProductsField(),
            this.getCartCurrencyField(),
            'show_breakdown'
        ];
    }

    getCartCurrencyField() {
        return new Field('cart_currency')
            .addFieldList([
                'currency_code',
                'currency_rate'
            ]);
    }

    _getChooseYourGiftRulesFields() {
        return new Field('choose_your_gift_rules')
            .addFieldList([
                'rule_id',
                'is_applied',
                'gifts_count',
                'is_login_required'
            ])
            .addField(this._getSubtotalConditionField())
            .addField(this._getChooseYourGiftOptionsFields());
    }

    _getSubtotalConditionField() {
        return new Field('subtotal_condition')
            .addFieldList([
                'name',
                'value'
            ]);
    }

    _getChooseYourGiftOptionsFields() {
        return new Field('options')
            .addFieldList([
                'sku',
                'title',
                'image_url',
                'product_url',
                'salable_qty'
            ]);
    }

    _getYProductsField() {
        return new Field('yProducts')
            .addFieldList(['sku', 'rule_id']);
    }

    _getCartItemFields() {
        return [
            ...super._getCartItemFields(),
            'is_gift'
        ];
    }

    getSliderContentQuery() {
        return new Field('sliderContent')
            .addField('content');
    }

    getUnsetShippingMethodOnCart(cartId) {
        return new Field('unsetShippingMethodOnCart')
            .addArgument('cartId', 'String!', cartId)
            .addField(this._getCartField());
    }

    _getCartField() {
        return new Field('cart')
            .addFieldList(this._getCartFieldList());
    }

    _getCartFieldList() {
        return [
            'id'
        ];
    }
}

export default new CartQuery();
