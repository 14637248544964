/**
 * Amasty GDPR frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { FieldContainer as SourceFieldContainer } from 'Component/Field/Field.container';

import { CONSENT_OPTING } from '../../../../../../src/component/GivenConsent/GivenConsent.config';
import ConsentCheckboxField from './ConsentCheckboxField.component';

export * from 'Component/Field/Field.container';

/** @namespace Scandiweb/AmastyGdpr/Component/ConsentCheckboxField/Container */
export class ConsentCheckboxFieldContainer extends SourceFieldContainer {
    validate(data) {
        const { scope } = this.props;
        return (scope === CONSENT_OPTING) || super.validate(data);
    }

    render() {
        return (
            <ConsentCheckboxField
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default ConsentCheckboxFieldContainer;
